import React, { useState, useCallback, createContext, useContext } from 'react';

const useSidebarState = () => {
  const [sidebarOpen, setSidebar] = useState(useSidebarState.defaults.sidebarOpen);
  const toggleSidebar = useCallback((v) => setSidebar(typeof v === 'boolean' ? v : (s) => !s), []);
  return { sidebarOpen, toggleSidebar };
};
useSidebarState.defaults = {
  sidebarOpen: false,
  toggleSidebar: () => {},
};

const useTheme = () => {
  const [theme, setTheme] = React.useState(useTheme.defaults.theme);
  const themes = ['primary', 'green'];
  const changeTheme = React.useCallback(
    (selectedTheme) => {
      if (themes.includes(selectedTheme)) setTheme(selectedTheme);
    },
    [themes],
  );
  return { theme, changeTheme };
};
useTheme.defaults = {
  theme: 'primary',
  changeTheme: () => {},
};

const useDevMenuState = () => {
  const [devMenuOpen, setDevMenu] = useState(useDevMenuState.defaults.devMenuOpen);
  const toggleDevMenu = useCallback((v) => setDevMenu(typeof v === 'boolean' ? v : (s) => !s), []);
  return { devMenuOpen, toggleDevMenu };
};
useDevMenuState.defaults = {
  devMenuOpen: false,
  toggleDevMenu: () => {},
};

export const AppContext = createContext({
  ...useSidebarState.defaults,
  ...useDevMenuState.defaults,
  ...useTheme.defaults,
});

export const AppContextProvider = ({ children, ...value }) => {
  const sidebarState = useSidebarState();
  const devMenuState = useDevMenuState();
  const themeManagement = useTheme();

  return (
    <AppContext.Provider value={{ ...value, ...sidebarState, ...devMenuState, ...themeManagement }}>
      {children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => useContext(AppContext);
