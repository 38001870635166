import Alert from 'components/common/Alert';
import isEqual from 'lodash/isEqual';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from '../../utils/helpers';
import s from './AffordableLoanInfo.module.scss';

export function PotentialAffordableLoanInfo({ myBudget, divider = true }) {
  const { monthlyRateLoan, amountOfLoan, potentialAmountOfLoan, potentialMonthlyRateLoan } = myBudget;
  const { formatMessage: t } = useIntl();

  const isEqualCalculations = useMemo(
    () => isEqual(potentialMonthlyRateLoan, monthlyRateLoan) && isEqual(potentialAmountOfLoan === amountOfLoan),
    [amountOfLoan, monthlyRateLoan, potentialAmountOfLoan, potentialMonthlyRateLoan],
  );

  if (isEqualCalculations) return null;

  return (
    <>
      {divider ? <div className={s.divider} /> : null}
      <p>{t({ id: 'budget.affordable_loan_info.potential_amount_of_loan' })}</p>
      <b style={{ fontSize: '20px' }}>
        {potentialAmountOfLoan > 0 ? formatCurrency(potentialAmountOfLoan) : formatCurrency(0)}
      </b>
      <p>
        {t({ id: 'budget.affordable_loan_info.amount_of_loan' })}{' '}
        <b>{potentialMonthlyRateLoan > 0 ? formatCurrency(potentialMonthlyRateLoan) : formatCurrency(0)}</b>
      </p>
    </>
  );
}

const ActualAffordableLoanInfo = ({ myBudget }) => {
  const { potentialAmountOfLoan, potentialMonthlyRateLoan, monthlyRateLoan, amountOfLoan } = myBudget;
  const { formatMessage: t } = useIntl();

  return (
    <Alert color="success" className="text-center">
      <p>
        <b style={{ fontSize: '20px' }}>{t({ id: 'budget.affordable_loan_info.congratulations' })}</b>
      </p>
      <b style={{ fontSize: '20px' }}>{amountOfLoan > 0 ? formatCurrency(amountOfLoan) : formatCurrency(0)}</b>
      <p>
        {t({ id: 'budget.affordable_loan_info.affordable_amount_of_loan' })}
        <b>{monthlyRateLoan > 0 ? formatCurrency(monthlyRateLoan) : formatCurrency(0)}</b>
      </p>
      {potentialAmountOfLoan && potentialMonthlyRateLoan ? <PotentialAffordableLoanInfo {...{ myBudget }} /> : null}
    </Alert>
  );
};

export default ActualAffordableLoanInfo;
