import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

const InfiniteScrollComponent = ({ hasMoreData, fetchMoreData, children }) => {
  return (
    <InfiniteScroll loadMore={fetchMoreData} hasMore={hasMoreData}>
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollComponent;
