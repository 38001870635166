import React, { useMemo } from 'react';
import { BudgetStep5 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import * as yup from 'yup';
import ButtonSelect from 'components/inputs/ButtonSelect';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';

export const OPTIONS = [
  {
    label: 'budget_calculator.not_important_option_label',
    value: 'not_important',
  },
  {
    label: 'budget_calculator.important_option_label',
    value: 'important',
  },
  {
    label: 'budget_calculator.very_important_option_label',
    value: 'very_important',
  },
];

const validationSchema = yup.object().shape({
  socializing: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  luxuries: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  new_car: yup.mixed().when('cars', {
    is: (cars) => !!cars,
    then: yup
      .string()
      .nullable()
      .required(__('errors.choose_one')),
  }),
  taking_a_break: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  hobbies: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  technology: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

const FifthStep = ({
  initialValues,
  isEditMode,
  onSubmit,
  onSubmitStep,
  formValuesRef,
  nextStep,
  onBack,
  previousStep,
}) => {
  const { formatMessage: t } = useIntl();

  const localizedOptions = useMemo(() => OPTIONS.map(({ label, ...rest }) => ({ label: t({ id: label }), ...rest })), [
    t,
  ]);

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.fifth_step.title' })}</PageTitle>

        <Formik
          initialValues={isEditMode ? initialValues : formValuesRef.current}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions);
            } else {
              await onSubmitStep(values, actions);
              nextStep();
            }
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />
              <Field
                name="socializing"
                label={t({ id: 'budget_calculator.fifth_step.socializing' })}
                description={t({ id: 'budget_calculator.fifth_step.socializing_descriptions' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />
              <Field
                name="luxuries"
                label={t({ id: 'budget_calculator.fifth_step.luxuries' })}
                description={t({ id: 'budget_calculator.fifth_step.luxuries_description' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />

              {values.cars ? (
                <Field
                  name="new_car"
                  label={t({ id: 'budget_calculator.fifth_step.new_car' })}
                  description={t({ id: 'budget_calculator.fifth_step.new_car_description' })}
                  options={localizedOptions}
                  component={ButtonSelect}
                  nullable={false}
                />
              ) : null}

              <Field
                name="taking_a_break"
                label={t({ id: 'budget_calculator.fifth_step.taking_a_break' })}
                description={t({ id: 'budget_calculator.fifth_step.taking_a_break_description' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />

              <Field
                name="hobbies"
                label={t({ id: 'budget_calculator.fifth_step.hobbies' })}
                description={t({ id: 'budget_calculator.fifth_step.hobbies_description' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />

              <Field
                name="technology"
                label={t({ id: 'budget_calculator.fifth_step.technology' })}
                description={t({ id: 'budget_calculator.fifth_step.technology_description' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep5 title={t({ id: 'alt_text.budget_calculator.fifth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default FifthStep;
