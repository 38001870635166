import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import { useModal } from 'components/common/Modal';
import { Formik } from 'formik';
import { pick } from 'lodash';
import React, { useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import buyingHouseValuationSchema from '../BuyingHouseForm/yupSchemas/buyingHouseSecondStepSchema';

import BuyingHouseEstimationForm from '../BuyingHouseForm/tabs/BuyingHouseEstimationForm';

const whiteListFormValues = [
  'country',
  'zip',
  'form_values.construction_year',
  'form_values.full_address',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
  'form_values.land_area',
  'form_values.house_area',
  'form_values.house_category',
  'form_values.basement_type',
  'form_values.parking',
  'form_values.heating_system',
];

const Buttons = ({ showModal, estimationRequestCompleted, isSubmitting }) => {
  const { formatMessage: t } = useIntl();
  return (
    <div className="text-right">
      <Button
        {...(!estimationRequestCompleted
          ? { onClick: showModal, loading: false, disabled: false, type: 'button' }
          : { loading: isSubmitting, disabled: isSubmitting, type: 'submit' })}
        endIcon={<ArrowForward />}
      >
        {t({ id: 'property_valuation.plan_project_btn' })}
      </Button>
    </div>
  );
};

const BuyingHouseValuationForm = ({ project, onSubmit, isGuest, estimationRequestCompleted, ...props }) => {
  const { form_values, ...rest } = pick(project, whiteListFormValues);
  const initialValues = { ...form_values, ...rest };
  const { showModal, hideModal, isVisible } = useModal();
  const formikRef = useRef();

  const sideEffectOnImmazingRequest = useCallback(async () => {
    await onSubmit(formikRef.current.values, { setSubmitting: formikRef.current.setSubmitting }, false);
  }, [onSubmit]);

  return (
    <Formik {...{ initialValues, innerRef: formikRef, onSubmit, validationSchema: buyingHouseValuationSchema }}>
      {({ values, isSubmitting, setSubmitting }) => (
        <BuyingHouseEstimationForm
          {...{
            values,
            isSubmitting,
            setSubmitting,
            onSubmit,
            projectPrice: project.price,
            isEditMode: true,
            hideModal,
            isVisible,
            hasAdditionalFeatures: true,
            showLandValuation: false,
            sideEffectOnImmazingRequest,
            buttons: <Buttons {...{ showModal, isGuest, estimationRequestCompleted, isSubmitting }} />,
            ...props,
          }}
        />
      )}
    </Formik>
  );
};

export default BuyingHouseValuationForm;
