import { useState, useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { first, get } from 'lodash';

const useLoadingData = ({ length, fetchMore, key, variables }) => {
  const [hasMoreData, setHasMoreData] = useState(true);
  const [prevVariables, setPrevVariables] = useState(variables);

  useEffect(() => {
    if (!isEqual(variables, prevVariables)) {
      setHasMoreData(true);
      setPrevVariables(variables);
    }
  }, [prevVariables, variables]);

  const fetchMoreData = useCallback(async () => {
    await fetchMore({
      variables: {
        ...variables,
        offset: length,
        limit: 10,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const res = get(fetchMoreResult, key, 0);
        if (res.length === 0) {
          setHasMoreData(false);
          return prev;
        }
        setHasMoreData(true);
        const prevData = first(Object.values(prev));
        const { payments, ...rest } = prevData;
        return { paymentsWithTotal: { ...rest, payments: [...get(prev, key), ...res] } };
      },
    });
  }, [fetchMore, variables, length, key]);

  return { hasMoreData, fetchMoreData };
};

export default useLoadingData;
