/* eslint-disable import/prefer-default-export */

import { ReactComponent as BuyingHouse } from 'assets/images/quick-calculator/buying-house.svg';
import { ReactComponent as BuyingApartment } from 'assets/images/quick-calculator/buying-apartment.svg';
import { ReactComponent as BuildingHouse } from 'assets/images/quick-calculator/building-house.svg';
import { ReactComponent as RenovationHouse } from 'assets/images/quick-calculator/renovation-house.svg';
import { ReactComponent as PropertyValuation } from 'assets/images/quick-calculator/property_valuation.svg';
import { ReactComponent as LifestyleCalculator } from 'assets/images/quick-calculator/lifestyle-calculator.svg';
import {
  BUYING_HOUSE,
  LIFESTYLE_CALCULATOR,
  NEW_APARTMENT,
  NEW_BUILDING,
  PROPERTY_VALUATION,
  RENOVATION_HOUSE,
} from 'constants/variables';

export const projects = [
  {
    label: 'project_types.renovation_house',
    type: RENOVATION_HOUSE,
    img: RenovationHouse,
    altText: 'alt_text.quick_calculator.renovation_house',
  },
  {
    label: 'project_types.buying_house',
    type: BUYING_HOUSE,
    img: BuyingHouse,
    altText: 'alt_text.quick_calculator.buying_house',
  },
  {
    label: 'project_types.new_building',
    type: NEW_BUILDING,
    img: BuildingHouse,
    altText: 'alt_text.quick_calculator.new_building',
  },
  {
    label: 'project_types.new_apartment',
    type: NEW_APARTMENT,
    img: BuyingApartment,
    altText: 'alt_text.quick_calculator.new_apartment',
  },
];

export const tools = [
  {
    label: 'project_types.lifestyle_calculator',
    type: LIFESTYLE_CALCULATOR,
    img: LifestyleCalculator,
    altText: 'alt_text.quick_calculator.lifestyle_calculator',
  },
  {
    label: 'project_types.property_valuation',
    type: PROPERTY_VALUATION,
    img: PropertyValuation,
    altText: 'alt_text.quick_calculator.property_valuation',
  },
];
