import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import { cloneDeep } from 'lodash';
import { useIntl } from 'react-intl';
import { PROJECT_DASHBOARD_QUERY } from './useProjectDashboardQuery';

const COMPLETE_PROJECT_STEP = gql`
  mutation completeProjectStep($projectId: ID!, $step: Step!) {
    completeProjectStep(projectId: $projectId, step: $step) {
      success
    }
  }
`;

const useCompleteProjectStep = (projectId) => {
  const [completeProjectStepMutation] = useMutation(COMPLETE_PROJECT_STEP);
  const client = useApolloClient();
  const { locale } = useIntl();

  const completeProjectStep = useCallback(
    async (step) => {
      try {
        await completeProjectStepMutation({ variables: { projectId, step } });

        const data = client.readQuery({ query: PROJECT_DASHBOARD_QUERY, variables: { id: projectId, locale } });
        if (!data) return;

        const projectDashboardData = cloneDeep(data);
        projectDashboardData.project.progress[step] = true;

        client.writeQuery({
          query: PROJECT_DASHBOARD_QUERY,
          variables: { id: projectId, locale },
          data: { ...projectDashboardData },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [client, completeProjectStepMutation, locale, projectId],
  );

  return completeProjectStep;
};

export default useCompleteProjectStep;
