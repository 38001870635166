/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useCallback } from 'react';
import Switch from 'react-switch';
import cn from 'classnames';
import { grayColor, secondPrimaryColor, successColor } from 'styles/variables.scss';
import { areEqualFields } from 'utils/form';
import isNil from 'lodash/isNil';
import { useAppContext } from 'contexts/AppContext';
import s from './Switch.module.scss';

const themeToSwitchOnStyles = {
  primary: s.switchOn,
  green: s.greenSwitchOn,
};

const SwitchInput = ({ field, form: { touched, errors, setFieldValue }, onChange, ...props }) => {
  const { label, style } = props;
  const { theme } = useAppContext();

  const handleChange = useCallback(
    (value) => {
      setFieldValue(field.name, value);
    },
    [field.name, setFieldValue],
  );

  useEffect(() => {
    if (isNil(field.value)) setFieldValue(field.name, false);
  }, [field.name, field.value, setFieldValue]);

  const hasError = touched[field.name] && errors[field.name];

  return (
    <div className={cn('field-wrapper', s.switchWrapper)} style={style}>
      <label className={s.switchLabel} htmlFor={field.name}>
        {label}
      </label>
      <Switch
        {...field}
        onChange={onChange || handleChange}
        checked={field.value || false}
        handleDiameter={16}
        offColor="#fff"
        onHandleColor={theme === 'green' ? successColor : secondPrimaryColor}
        offHandleColor={grayColor}
        onColor="#fff"
        height={20}
        width={40}
        uncheckedIcon={false}
        checkedIcon={false}
        className={cn(s.switch, { 'error-input': hasError }, field.value ? themeToSwitchOnStyles[theme] : null)}
      />
      {hasError && <div className="error-text">{errors[field.name]}</div>}
    </div>
  );
};

export default memo(SwitchInput, areEqualFields);
