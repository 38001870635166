import { range } from 'lodash';
import React from 'react';
import { formatValue } from 'utils/helpers';
import s from './SliderWithTooltip.module.scss';

export default function({ withAllMarks, min, max, step, units, thousandSeparator }) {
  const minValue = thousandSeparator === '' ? min : formatValue(min);
  const maxValue = thousandSeparator === '' ? max : formatValue(max);

  return (
    <div className={s.marks}>
      {withAllMarks ? (
        range(min, max + 1, step).map((v) => {
          const value = thousandSeparator === '' ? v : formatValue(v);
          return (
            <span key={v}>
              {value} {units}
            </span>
          );
        })
      ) : (
        <>
          <span>
            {minValue} {units}
          </span>
          <span>
            {maxValue} {units}
          </span>
        </>
      )}
    </div>
  );
}
