import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import WarningMessage from 'components/common/WarningMessage';
import useGetFinancing from 'hooks/financing/useFinancing';
import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import LoanCalculator from 'pages/LoanCalculator';
import { PROPERTY_VALUATION } from 'constants/variables';
import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import Loan from './Loan';

export default () => {
  const { id: projectId } = useParams();
  const [financing, loading] = useGetFinancing(projectId);
  const { formatMessage: t } = useIntl();

  if (loading) {
    return <LoadingOverlay />;
  }

  const { project, budget } = financing;

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  if (!budget) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.loan' })}
        message={t({ id: 'loan.complete_previous_step' })}
        btnLabel={t({ id: 'loan.complete_previous_step_btn' })}
        btnLink={routePatterns.lifestyleOverviewWithProject.stringify({ id: projectId })}
      />
    );
  }

  if (project.type === PROPERTY_VALUATION) return <StartProjectPlanning />;

  if (project.price === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.loan' })}
        message={t({ id: 'loan.project_price_zero' })}
        btnLabel={t({ id: 'loan.start_planning' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  if (!project.loan) return <LoanCalculator {...{ project }} />;

  if (project.loan?.amount <= 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.loan' })}
        message={t({ id: 'loan.no_need' })}
        btnLabel={t({ id: 'loan.edit' })}
        btnLink={routePatterns.loanCalculator.stringify({ id: projectId })}
      />
    );
  }

  const { monthly_rate_loan } = budget?.calculation;
  const monthlyFinancing = {
    label:
      monthly_rate_loan.calculated_value - project.loan.monthly_rate < 0
        ? t({ id: 'loan.monthly_deficit' })
        : t({ id: 'loan.monthly_reserve' }),
    value: monthly_rate_loan.calculated_value - project.loan.monthly_rate,
  };

  return <Loan {...{ project, projectId, monthlyRateLoan: monthly_rate_loan, monthlyFinancing, t }} />;
};
