import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { NEW_APARTMENT } from 'constants/variables';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: NEW_APARTMENT, group: 'indoor' }),
  sanitary_renovation_area: createConditionalValidator('sanitary_renovation', 'number'),
  new_flooring_area: createConditionalValidator('new_flooring', 'number'),
  renovation_walls_and_interior_doors_area: createConditionalValidator('renovation_walls_and_interior_doors', 'number'),
});

export default validationSchema;
