import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { cloneDeep, pick, get } from 'lodash';
import { toast } from 'react-toastify';
import { whitelistedPropsCO2Calculator } from 'pages/ProjectDetailsCalculator/RenovationHouseCalculator.data';
import { CO2_CALCULATOR_RESULT } from 'graphql/fragments/co2-calculator';
import { RENOVATION_HOUSE_CALCULATIONS } from './useRenovationHouseCalculations';

const UPDATE_CO2_CALCULATOR_VALUES_MUTATION = gql`
  mutation updateCO2CalculatorValues($projectId: ID!, $formData: CO2CalculatorInput!) {
    updateCO2CalculatorValues(projectId: $projectId, formData: $formData) {
      calculations {
        ... on RenovationHouseCalculations {
          co2_emissions_before {
            result {
              ...co2CalculatorResult
            }
          }
          co2_emissions_potential {
            result {
              heating_ghg_emission
              hot_water_ghg_emission
              ghg_balance_of_power_consumption
              specific_heating_demand
            }
          }
          co2_emissions_after {
            result {
              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
        }
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
`;

export const useUpdateCO2CalculatorValuesHandler = ({ projectId }) => {
  const updateValues = useUpdateCO2CalculatorValues();
  const handleUpdate = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const newValues = cloneDeep(pick(values, whitelistedPropsCO2Calculator));
        const { energy_standard, is_solar_power_system } = newValues;
        if (energy_standard === 'passive_house' || energy_standard === 'low_energy_house') {
          newValues.renovations = [];
        }
        if (!is_solar_power_system) {
          newValues.solar_power_system_size = null;
          newValues.solar_power_system_consuming_percentage = null;
        }
        await updateValues({ projectId, formData: newValues });
        window.scrollTo(0, 0);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateValues],
  );
  return handleUpdate;
};

const useUpdateCO2CalculatorValues = () => {
  const [updateCO2CalculatorValuesMutation] = useMutation(UPDATE_CO2_CALCULATOR_VALUES_MUTATION);
  const client = useApolloClient();

  const co2Calculator = useCallback(
    async (variables) => {
      try {
        const result = await updateCO2CalculatorValuesMutation({
          variables,
          refetchQueries: [{ query: RENOVATION_HOUSE_CALCULATIONS, variables: { id: variables.projectId } }],
          awaitRefetchQueries: true,
        });
        const existingProjectCalculations = client.readQuery({
          query: RENOVATION_HOUSE_CALCULATIONS,
          variables: { id: variables.projectId },
        });
        const newProjectCalculations = get(result, 'data.updateCO2CalculatorValues');
        client.writeQuery({
          query: RENOVATION_HOUSE_CALCULATIONS,
          variables: { id: variables.projectId },
          data: { ...existingProjectCalculations, ...newProjectCalculations },
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [client, updateCO2CalculatorValuesMutation],
  );
  return co2Calculator;
};

export default useUpdateCO2CalculatorValues;
