import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { CO2_CALCULATOR_RESULT } from 'graphql/fragments/co2-calculator';

export const RENOVATION_HOUSE_CALCULATIONS = gql`
  query projectCalculations($id: ID!) {
    project(id: $id) {
      _id
      calculations {
        ... on RenovationHouseCalculations {
          co2_emissions_before {
            result {
              ...co2CalculatorResult
            }
          }
          co2_emissions_potential {
            result {
              heating_ghg_emission
              hot_water_ghg_emission
              ghg_balance_of_power_consumption
              specific_heating_demand

              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
          co2_emissions_after {
            result {
              specific_heating_demand
              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
        }
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
`;

const useRenovationHouseCalculations = ({ projectId }) => {
  const { loading, data } = useQuery(RENOVATION_HOUSE_CALCULATIONS, { variables: { id: projectId } });
  const project = get(data, 'project', {});
  return [project, loading];
};

export default useRenovationHouseCalculations;
