/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useUploadImage from 'hooks/payments/useUploadImage';
import imageCompression from 'browser-image-compression';
import Loading from 'components/common/Loading';
import Modal, { useModal } from 'components/common/Modal';
import { ReactComponent as AddPhoto } from 'assets/icons/add_photo.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useIntl } from 'react-intl';
import ImageCropper from 'components/common/Avatar/ImageCropper';
import { v4 as uuid } from 'uuid';
import useUser from 'hooks/user/useUser';
import useRemoveImage from 'hooks/payments/useRemoveImage';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { confirmAlert } from 'react-confirm-alert';
import s from './Avatar.module.scss';

const EditAvatarDropDown = ({ getRootProps, form, setAvatarUrl, avatarUrl }) => {
  const { formatMessage: t } = useIntl();
  const [visible, setVisible] = useState(false);
  const onMenuItemClick = useCallback(() => setVisible(false), []);

  const deleteAvatarFromForm = useCallback(async () => {
    form.setFieldValue('avatar', null);
    setAvatarUrl(null);
  }, [form, setAvatarUrl]);

  const onDeleteAvatarClick = useCallback(() => {
    confirmAlert({
      title: t({ id: 'profile.avatar.delete_avatar_modal.title' }),
      message: t({ id: 'profile.avatar.delete_avatar_modal.message' }),
      buttons: [{ label: t({ id: 'app.cancel' }) }, { label: t({ id: 'app.confirm' }), onClick: deleteAvatarFromForm }],
    });
  }, [deleteAvatarFromForm, t]);

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu className={s.editAvatarMenu} selectable={false}>
          <MenuItem key="1" className={s.menuItem} {...getRootProps()}>
            <button type="button" onClick={onMenuItemClick}>
              {t({ id: 'profile.avatar.change_avatar' })}
            </button>
          </MenuItem>
          {avatarUrl ? (
            <MenuItem key="2" className={s.deleteAvatarMenuItem} onClick={onDeleteAvatarClick}>
              <button type="button" onClick={onMenuItemClick}>
                {t({ id: 'profile.avatar.delete_avatar' })}
              </button>
            </MenuItem>
          ) : null}
        </Menu>
      }
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
    >
      <button type="button" className={s.editButton}>
        <EditIcon />
      </button>
    </Dropdown>
  );
};

const AvatarDropzone = ({ dropzone: { getRootProps, getInputProps }, avatarUrl, loading, form, setAvatarUrl }) => (
  <div style={{ textAlign: 'center' }}>
    <input {...getInputProps()} disabled={loading} />
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <div {...getRootProps()} className={s.dropzone}>
        {loading ? <Loading size={30} /> : null}
        {avatarUrl ? (
          <div className={s.imgBox} style={{ backgroundImage: `url("${avatarUrl}")` }} />
        ) : (
          <AddPhoto className={s.addAvatarIcon} />
        )}
      </div>
      <EditAvatarDropDown {...{ getRootProps, form, setAvatarUrl, avatarUrl }} />
    </div>
  </div>
);

const AvatarField = ({ form, avatar }) => {
  const imageRef = useRef(null);
  const { showModal, hideModal, isVisible, modalProps } = useModal();
  const { formatMessage: t } = useIntl();
  const [uploadImage, loading] = useUploadImage();
  const [avatarUrl, setAvatarUrl] = useState(avatar?.url);
  const [me] = useUser();
  const onRemoveAvatar = useRemoveImage();

  const handleUploadImage = useCallback(
    async (newImage) => {
      const compressedImg = await imageCompression(newImage, { maxSizeMB: 0.08 });

      const fileName = `avatars/${uuid()}(${me._id}).${compressedImg.type.replace('image/', '')}`;

      const url = await uploadImage({ fileName, mime: compressedImg.type }, compressedImg);
      setAvatarUrl(url);
      form.setFieldValue('avatar', fileName);
      hideModal();
    },
    [form, hideModal, me._id, uploadImage],
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles.pop();
      imageRef.current = file;
      showModal({ file });
    },
    [showModal],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
  const dropzone = { getInputProps, getRootProps };

  return (
    <>
      <AvatarDropzone {...{ onRemoveAvatar, dropzone, loading, avatarUrl, form, setAvatarUrl }} />
      <Modal headerText={t({ id: 'payments.preview_image' })} close={hideModal} isVisible={isVisible} size="sm">
        <ImageCropper imageProps={modalProps} onUploadImage={handleUploadImage} loading={loading} />
      </Modal>
    </>
  );
};

export default AvatarField;
