import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import { Close } from 'assets/icons';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import modalStyles from './modalStyles';
import s from './Modal.module.scss';
import PageTitle from './PageTitle';

ReactModal.setAppElement(document.getElementById('root'));

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const showModal = useCallback((data = {}) => {
    setModalProps(data);
    setIsVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsVisible(false);
    setModalProps({});
  }, []);

  return { showModal, hideModal, modalProps, isVisible };
};

export const colorToClassNameMap = {
  primary: s.primary,
  white: s.white,
};

const Modal = ({
  children,
  isVisible,
  close,
  backgroundColor = 'white',
  size = 'lg',
  headerText = '',
  modalStyle = {},
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={close}
      style={{ ...modalStyles[size], ...modalStyle }}
      bodyOpenClassName={s.bodyOpen}
      className={{
        base: cn(s.modal, colorToClassNameMap[backgroundColor]),
        afterOpen: s.modalAfterOpen,
        beforeClose: s.modalBeforeClose,
      }}
      overlayClassName={{
        base: s.overlay,
        afterOpen: s.overlayAfterOpen,
        beforeClose: s.overlayBeforeClose,
      }}
      closeTimeoutMS={300}
    >
      <div className={s.modalHeader}>
        <PageTitle>{headerText}</PageTitle>
        <button type="button" className={s.modalCloseButton} onClick={close}>
          <Close width="100%" height="100%" title={t({ id: 'alt_text.icons.close' })} />
        </button>
      </div>
      <div className={s.modalBody}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
