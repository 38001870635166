import * as yup from 'yup';
import { __ } from 'utils/form';
// import { validZipCodes } from 'constants/variables';

const validationSchema = yup.object().shape({
  name: yup.string().required(__('errors.fill_field')),
  zip: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  // .when('country', (val, schema) =>
  //   validZipCodes[val] ? schema.matches(validZipCodes[val], { message: __('errors.wrong_zip_code') }) : schema,
  // ),
  house_area: yup
    .number()
    .required(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
  number_of_floors: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

export default validationSchema;
