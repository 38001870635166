import MultiSelect from 'components/inputs/MultiSelect';
import React, { useState, useCallback } from 'react';
import { Formik, Field, Form } from 'formik';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { User } from 'assets/icons/sidebar';
import { useIntl } from 'react-intl';
import useGetCategories from 'hooks/payments/useGetCategories';
import { ArrowForward } from 'assets/icons';
import useGetFilters from 'hooks/payments/useGetFilters';
import ScrollToError from 'components/common/ScrollToError';
import s from './SearchPaymentsSection.module.scss';

const FilterPaymentsModal = ({ hideModal, isVisible, initialValues, onSubmit, payers, projects }) => {
  const { filters } = useGetFilters();
  const [tempProjectIds, setTempProjectIds] = useState(filters.projects);
  const { formatMessage: t } = useIntl();
  const { categories } = useGetCategories(tempProjectIds);

  const handleChangeOnChooseProject = useCallback((values) => {
    setTempProjectIds(values);
  }, []);

  return (
    <Modal close={hideModal} isVisible={isVisible} headerText={t({ id: 'payments.modal_header_text' })} size="md">
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <ScrollToError inModal />
            <Field
              name="projects"
              component={MultiSelect}
              callbackOnChange={handleChangeOnChooseProject}
              label={t({ id: 'payments.projects' })}
              options={projects}
            />
            <Field
              name="categories"
              component={MultiSelect}
              label={t({ id: 'payments.categories' })}
              options={categories.map((category) => ({ label: t({ id: category.label }), value: category.name }))}
            />
            <Field
              name="payers"
              component={MultiSelect}
              label={t({ id: 'payments.payers' })}
              options={payers.map(({ payer: { _id, profile: { name, avatar } } }) => ({
                label: (
                  <div className={s.optionWrapper}>
                    {avatar ? (
                      <img src={avatar.url} alt="avatar" className={s.avatar} />
                    ) : (
                      <User className={s.defaultAvatar} />
                    )}
                    <span>{name}</span>
                  </div>
                ),
                value: _id,
              }))}
            />
            <div style={{ textAlign: 'center' }}>
              <Button type="submit" loading={isSubmitting} disabled={isSubmitting} endIcon={<ArrowForward />}>
                {t({ id: 'spendings.apply_filters_btn' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FilterPaymentsModal;
