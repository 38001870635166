import React from 'react';
import { IntlProvider } from 'react-intl';
import i18nMessages from '../i18n';
import { useLocaleContext } from './LocaleContext';

export default ({ children }) => {
  const { locale } = useLocaleContext();

  return (
    <IntlProvider locale={locale} messages={i18nMessages[locale]} key={locale}>
      {children}
    </IntlProvider>
  );
};
