import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECTS_PAYERS_QUERY } from 'hooks/payments/useProjectsPayersQuery';
import useGetFilters from 'hooks/payments/useGetFilters';
import { cloneDeep, get, set } from 'lodash';
import { ME_QUERY } from './useUser';

const EDIT_USER_INFO_MUTATION = gql`
  mutation editUserInfo($input: UserProfileInput!) {
    editUserInfo(input: $input)
  }
`;

const useEditUserInfo = (hideModal) => {
  const [editUserInfoMutation] = useMutation(EDIT_USER_INFO_MUTATION);
  const { filters } = useGetFilters();
  const client = useApolloClient();

  const updatedMeInPayersList = useCallback(
    ({ paymentsTotalByPayers, projects }) => {
      const { me } = client.readQuery({ query: ME_QUERY });
      const paymentsTotalByMe = cloneDeep(paymentsTotalByPayers.find(({ payer: { _id } }) => _id === 'me'));
      const restPayers = paymentsTotalByPayers.filter(({ payer: { _id } }) => _id !== 'me');

      set(paymentsTotalByMe.payer.profile, 'name', get(me, 'profile.firstName', ''));
      set(paymentsTotalByMe.payer.profile, 'avatar.file_name', get(me, 'profile.avatar.file_name', ''));
      set(paymentsTotalByMe.payer.profile, 'avatar.url', get(me, 'profile.avatar.url', ''));

      client.writeQuery({
        query: PROJECTS_PAYERS_QUERY,
        variables: { filters },
        data: { projects, paymentsTotalByPayers: [...restPayers, paymentsTotalByMe] },
      });
    },
    [client, filters],
  );

  const handleEditUserInfo = useCallback(
    async (userInfo) => {
      try {
        await editUserInfoMutation({
          variables: { input: { ...userInfo } },
          refetchQueries: [{ query: ME_QUERY }],
          awaitRefetchQueries: true,
        });
        const projectsPayersQueryData = client.readQuery({ query: PROJECTS_PAYERS_QUERY, variables: { filters } });
        const paymentsTotalByPayers = get(projectsPayersQueryData, 'paymentsTotalByPayers');
        if (paymentsTotalByPayers) updatedMeInPayersList(projectsPayersQueryData);
        hideModal();
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    },
    [client, editUserInfoMutation, filters, hideModal, updatedMeInPayersList],
  );
  return handleEditUserInfo;
};

export default useEditUserInfo;
