import React from 'react';
import LifestyleCalculatorForm from 'components/budget/form';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useMyBudget from 'hooks/budget/useMyBudget';

export const lifestyleCalculatorInitialValues = {
  project_type: null,
  square_meters: null,
  alone_or_partner: null,
  monthly_income: null,
  monthly_net_salary: null,
  socializing: null,
  luxuries: null,
  taking_a_break: null,
  hobbies: null,
  technology: null,
  pension_and_insurances: null,
  savings: null,
  new_car: null,
  kids_quantity: null,
  cars: null,
  age: 30,
};

const LifestyleCalculator = () => {
  const [myBudget, loading] = useMyBudget();

  if (loading) return <LoadingOverlay />;

  const initialValues = { ...lifestyleCalculatorInitialValues, ...myBudget?.formData };

  return <LifestyleCalculatorForm {...{ initialValues }} />;
};

export default LifestyleCalculator;
