import React from 'react';
import { BudgetStep2 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import * as yup from 'yup';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';

const validationSchema = yup.object().shape({
  monthly_net_salary: yup.string().required(__('errors.fill_field')),
  monthly_net_salary_partner: yup.mixed().when('alone_or_partner', {
    is: 'partner',
    then: yup.string().required(__('errors.fill_field')),
  }),
  other_revenues: yup.string().required(__('errors.fill_field')),
});

const SecondStep = ({
  initialValues,
  isEditMode,
  previousStep,
  formValuesRef,
  onSubmit,
  onSubmitStep,
  nextStep,
  onBack,
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.second_step.title' })}</PageTitle>

        <Formik
          initialValues={isEditMode ? initialValues : formValuesRef.current}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions);
            } else {
              await onSubmitStep(values, actions);
              nextStep();
            }
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />
              <Field
                name="monthly_net_salary"
                label={t({ id: 'budget_calculator.second_step.monthly_net_salary' })}
                component={SliderWithTooltip}
                bigLabel
                description={t({ id: 'budget_calculator.second_step.monthly_net_salary_description' })}
                min={400}
                max={10000}
                defaultValue={2500}
                step={100}
                units="€"
              />

              {values.alone_or_partner === 'partner' ? (
                <Field
                  name="monthly_net_salary_partner"
                  label={t({ id: 'budget_calculator.second_step.monthly_net_income_partner' })}
                  component={SliderWithTooltip}
                  bigLabel
                  description={t({ id: 'budget_calculator.second_step.monthly_net_salary_description' })}
                  min={400}
                  max={10000}
                  defaultValue={2500}
                  step={100}
                  units="€"
                />
              ) : null}

              <Field
                name="other_revenues"
                label={t({ id: 'budget_calculator.second_step.other_regular_revenues' })}
                component={SliderWithTooltip}
                description={t({ id: 'budget_calculator.second_step.other_regular_revenues_description' })}
                min={0}
                max={10000}
                defaultValue={0}
                step={100}
                units="€"
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep2 title={t({ id: 'alt_text.budget_calculator.second_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default SecondStep;
