import React from 'react';
import { primaryLoader, smallLoader } from 'components/common/loaders';
import ContentLoader from 'react-content-loader';

const typeLoader = {
  primary: primaryLoader,
  small: smallLoader,
};

export default function({ type = 'primary', ...props }) {
  const loader = type;
  const { height, width, speed, viewBox, backgroundColor, foregroundColor, rects, circles } = typeLoader[loader];
  return (
    <div style={{ backgroundColor: 'white' }}>
      <ContentLoader
        height={height}
        width={width}
        speed={speed}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        {...props}
      >
        {rects.map(({ id, x, y, rx, ry, width: rectWidth, height: rectHeight }) => (
          <rect key={id} x={x} y={y} rx={rx} ry={ry} width={rectWidth} height={rectHeight} />
        ))}
        {circles.map(({ id, cx, cy, r }) => (
          <circle key={id} cx={cx} cy={cy} r={r} />
        ))}
      </ContentLoader>
    </div>
  );
}
