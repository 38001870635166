import { yellowColor, secondPrimaryColor, grayLightColor } from 'styles/variables.scss';
import React, { memo, useEffect } from 'react';
import Slider from 'rc-slider';
import range from 'lodash/range';
import { spliceLabelOnMobile } from 'utils/helpers';
import 'rc-slider/assets/index.css';
import { areEqualFields } from 'utils/form';
import { isNil } from 'lodash';
import styles from './Slider.module.scss';

const generateMarks = (min, max, step = 1) => {
  return range(min, max + 1, step).reduce((acc, v) => ({ ...acc, [v]: v }), {});
};

export const sliderStyleProps = {
  railStyle: {
    backgroundColor: grayLightColor,
    height: '8px',
    marginBottom: '16px',
  },
  trackStyle: {
    backgroundColor: secondPrimaryColor,
    height: '8px',
  },
  handleStyle: {
    backgroundColor: yellowColor,
    border: '3px solid white',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    boxSizing: 'border-box',
    boxShadow: 'none',
    marginTop: '-10px',
  },
  dotStyle: {
    display: 'none',
  },
};

const SliderInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { label, style, min, max, note, step = 1 } = props;
  const { value, name } = field;
  const handleChange = React.useCallback(
    (v) => {
      setFieldValue(name, v);
      setFieldTouched(name, true);
    },
    [setFieldValue, name, setFieldTouched],
  );

  useEffect(() => {
    if (isNil(field.value)) setFieldValue(field.name, false);
  }, [field.name, field.value, setFieldValue]);

  return (
    <div className="field-wrapper" style={style}>
      <div className={styles.sliderContainer} name={name}>
        <Slider
          {...sliderStyleProps}
          className={styles.slider}
          name={name}
          value={value}
          step={step}
          onChange={handleChange}
          dots={false}
          id={name}
          min={min}
          max={max}
          marks={generateMarks(min, max, step)}
        />
      </div>
      <label htmlFor={name} className="label-when-focused-input number-label" id={`label-${name}`}>
        {spliceLabelOnMobile(label)}
      </label>
      {note ? <small className="muted">{note}</small> : null}
      {touched[name] && errors[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(SliderInput, areEqualFields);
