import UrlPattern from 'url-pattern';

const routePaths = {
  quickCalculator: '/',
  quickCalculatorResult: '/result/:id',
  login: '/login',
  login2FA: '/login-two-fa',
  signUp: '/sign-up/:id',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  verifyEmail: '/verify-email/:token',
  activateAccount: '/activate-account',
  cookieDeclaration: '/cookie-declaration',
  newProject: '/secure/new-project',
  myProjects: '/secure/projects',
  payments: '/secure/project/:id/payments',
  spendings: '/secure/project/:id/spendings',
  projectDetailsCalculator: '/secure/project/:id/details',
  dashboard: '/secure/project/:id/dashboard',
  lifestyleOverview: '/secure/lifestyle-overview',
  lifestyleOverviewWithProject: '/secure/project/:id/lifestyle-overview',
  lifestyleCalculator: '/secure/lifestyle-calculator',
  lifestyleCalculatorEditValues: '/secure/lifestyle-calculator/edit',
  contact: '/secure/contact',
  logout: '/secure/logout',
  profile: '/secure/profile',
  plan: '/secure/project/:id/plan',
  planForm: '/secure/project/:id/plan/form',
  loan: '/secure/project/:id/loan',
  loanCalculator: '/secure/project/:id/loan/calculator',
  guestSignUp: '/secure/sign-up',
  guestLogin: '/secure/login',
  guestForgotPassword: '/secure/forgot-password',
  guestResetPassword: '/secure/reset-password/:token',
  debugMode: '/secure/project/:id/debug-mode',
};

routePaths.default = routePaths.quickCalculator;

export const routePatterns = Object.entries(routePaths).reduce(
  (p, [key, pattern]) => ({ ...p, [key]: new UrlPattern(pattern) }),
  {},
);

export default routePaths;
