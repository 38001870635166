import { useCallback } from 'react';
import gql from 'graphql-tag';
import useAuth from 'hooks/auth/useAuth';
import { useHistory, useParams } from 'react-router-dom';
import { CURRENT_USER_FRAGMENT } from 'hooks/user/useUser';
import { GOOGLE_SERVICE, FACEBOOK_SERVICE } from 'constants/variables';
import routePaths, { routePatterns } from 'router/route-paths';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

export const AUTH_WITH_GOOGLE_MUTATION = gql`
  mutation authWithGoogle($token: String!, $projectId: ID, $otp: String) {
    authWithGoogle(token: $token, projectId: $projectId, otp: $otp) {
      id
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const AUTH_WITH_FACEBOOK_MUTATION = gql`
  mutation authWithFacebook($token: String!, $responseId: String!, $projectId: ID, $otp: String) {
    authWithFacebook(token: $token, responseId: $responseId, projectId: $projectId, otp: $otp) {
      id
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const useAuthWithThirdPartyServiceHandler = ({ projectId, service, showModal }) => {
  const [onAuthWithThirdPartyService, loading] = useAuthWithThirdPartyService(service);
  const history = useHistory();
  const params = useParams();
  const variables = {};

  const onSubmitAdminLogin = useCallback(
    async ({ otp, auth, dataForTwoFA }) => {
      try {
        await auth({ ...dataForTwoFA, otp });
        history.push(params.id ? routePatterns.dashboard.stringify({ id: params.id }) : routePaths.myProjects);
      } catch (e) {
        toast.error(e.message);
      }
    },
    [history, params.id],
  );

  const onError = useCallback(
    (error, auth, dataForTwoFA) => {
      if (error.message === 'AUTH_OTP_REQUIRED_ERROR') {
        showModal({
          onSubmitAdminLogin: async ({ otp }) => {
            await onSubmitAdminLogin({ auth, dataForTwoFA, otp });
          },
        });
      }
    },
    [onSubmitAdminLogin, showModal],
  );

  const onSuccess = useCallback(
    async (response) => {
      try {
        if (service === GOOGLE_SERVICE) {
          variables.token = response.tokenObj.id_token;
        }
        if (service === FACEBOOK_SERVICE) {
          variables.token = response.accessToken;
          variables.responseId = response.id;
        }
        await onAuthWithThirdPartyService({ ...variables, projectId }, onError);
      } catch (error) {
        if (error.message === 'AUTH_OTP_REQUIRED_ERROR') return;
        toast.error(error.message);
      }
    },
    [onAuthWithThirdPartyService, onError, projectId, service, variables],
  );

  return [onSuccess, loading];
};

const useAuthWithThirdPartyService = (service) => {
  const [auth, loading] = useAuth(service === GOOGLE_SERVICE ? AUTH_WITH_GOOGLE_MUTATION : AUTH_WITH_FACEBOOK_MUTATION);
  const history = useHistory();

  const login = useCallback(
    async ({ projectId, ...serviceData }, onError) => {
      try {
        await auth({ projectId, ...serviceData });
        history.push(projectId ? routePatterns.dashboard.stringify({ id: projectId }) : routePaths.myProjects);
      } catch (error) {
        onError(error, auth, { projectId, ...serviceData });
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [auth, history],
  );

  return [login, loading];
};

export default useAuthWithThirdPartyService;
