import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

export const PAYMENT_FRAGMENT = gql`
  fragment payment on ProjectPayment {
    _id
    name
    notes
    categoryName
    payee
    amount
    payment_type
    paid_by
    project_id
    created_at
    attachments {
      url
      file_name
      width
      height
    }
    date_paid
  }
`;

export const GET_PAYMENT_QUERY = gql`
  query paymentById($_id: ID!) {
    paymentById(_id: $_id) {
      ...payment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

const useGetPaymentById = (paymentId) => {
  const { data, loading } = useQuery(GET_PAYMENT_QUERY, { variables: { _id: paymentId } });

  const payment = useMemo(() => get(data, 'paymentById', []), [data]);

  return { payment, loading };
};

export default useGetPaymentById;
