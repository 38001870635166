import LoadingOverlay from 'components/common/LoadingOverlay';
import { useModal } from 'components/common/Modal';
import WarningMessage from 'components/common/WarningMessage';
import { LAND_ESTIMATION } from 'constants/variables';
import useGetProjectType from 'hooks/project/useGetProjectTypes';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import LandValuationTypeModal, { useChangeProjectTypeHandler } from './LandValuationTypeModal';

const StartProjectPlanning = () => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const { plannedProjectType, loading } = useGetProjectType(projectId);
  const { showModal, hideModal, isVisible } = useModal();

  const [onChangeProjectType, isSubmitting] = useChangeProjectTypeHandler(projectId);

  const onSubmit = useCallback(
    async ({ type }, actions) => {
      await onChangeProjectType({ projectId, type }, actions);
    },
    [onChangeProjectType, projectId],
  );

  const onStartPlanningProject = useCallback(async () => {
    if (plannedProjectType === LAND_ESTIMATION) {
      showModal();
    } else {
      await onChangeProjectType({ projectId, type: plannedProjectType });
    }
  }, [onChangeProjectType, plannedProjectType, projectId, showModal]);

  if (loading) return <LoadingOverlay />;

  return (
    <>
      <WarningMessage
        message={t({ id: 'property_valuation.start_project_planning' })}
        btnLabel={t({ id: 'property_valuation.plan_project_btn' })}
        disabledBtn={isSubmitting}
        onBtnClick={onStartPlanningProject}
      />
      <LandValuationTypeModal {...{ hideModal, isVisible, t, onSubmit }} />
    </>
  );
};

export default StartProjectPlanning;
