import * as yup from 'yup';
import { __ } from 'utils/form';

const validationSchema = yup.object().shape({
  name: yup.string().required(__('errors.fill_field')),
  estimated_property_price: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  land_area: yup
    .number()
    .required(__('errors.fill_field'))
    .min(200, __('errors.values_within_range', { min: 200, max: 3000 }))
    .max(3000, __('errors.values_within_range', { min: 200, max: 3000 })),
  avg_property_price_sq_m: yup
    .mixed()
    .notRequired()
    .when('has_property', {
      is: false,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  living_space_by_floor_0: yup
    .mixed()
    .notRequired()
    .when('floors', {
      is: (floors) => floors >= 1,
      then: yup
        .number()
        .required(__('errors.fill_field'))
        .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
        .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
    }),
  living_space_by_floor_1: yup
    .mixed()
    .notRequired()
    .when('floors', {
      is: (floors) => floors >= 2,
      then: yup
        .number()
        .required(__('errors.fill_field'))
        .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
        .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
    }),
  living_space_by_floor_2: yup
    .mixed()
    .notRequired()
    .when('floors', {
      is: (floors) => floors >= 3,
      then: yup
        .number()
        .required(__('errors.fill_field'))
        .min(25, __('errors.values_within_range'), { min: 25, max: 300 })
        .max(300, __('errors.values_within_range'), { min: 25, max: 300 }),
    }),
  terrace_area: yup
    .number()
    .nullable()
    .required(__('errors.fill_field'))
    .min(5, __('errors.values_within_range'), { min: 5, max: 200 })
    .max(200, __('errors.values_within_range'), { min: 5, max: 200 }),
  demolition_area: yup
    .mixed()
    .notRequired()
    .when('features.demolition_costs', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  floors: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  house_type: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  sole_plate_or_basement: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  living_room_direction: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  heating_system_type: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  personal_contribution: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  bathrooms: yup.number().required(__('errors.fill_field')),
});

export default validationSchema;
