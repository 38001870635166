/* eslint-disable no-unused-expressions */
import { locales } from 'constants/variables';
import React, { useState, useCallback, createContext, useContext } from 'react';
import settings from '../config/settings';

function getLocale() {
  let locale = settings.defaultLocale;
  const localStorageLocale = window?.localStorage?.getItem('language');
  if (locales.includes(localStorageLocale)) {
    locale = localStorageLocale;
  }
  const localeFromUrl = new URLSearchParams(window?.location?.search).get('lang');
  if (locales.includes(localeFromUrl)) {
    locale = localeFromUrl;
  }
  return locale || settings.defaultLocale;
}

const useLocaleState = () => {
  const [locale, setLocale] = useState(useLocaleState.defaults.locale);
  const changeLocale = useCallback((l) => {
    if (!locales.includes(l)) return;
    setLocale(l);
    window?.localStorage?.setItem('language', l);
  }, []);
  return { locale, changeLocale };
};
useLocaleState.defaults = {
  locale: getLocale(),
  changeLocale: () => {},
};

export const LocaleContext = createContext({ ...useLocaleState.defaults });

export const LocaleContextProvider = ({ children, ...value }) => {
  const localeState = useLocaleState();

  return <LocaleContext.Provider value={{ ...value, ...localeState }}>{children}</LocaleContext.Provider>;
};

export const LocaleContextConsumer = LocaleContext.Consumer;

export const useLocaleContext = () => useContext(LocaleContext);
