import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

export const GET_PROJECTS_QUERY = gql`
  query projects($locale: String) {
    projects {
      _id
      name
      pdf(locale: $locale)
      price
      type
    }
  }
`;

const useProjects = () => {
  const { locale } = useIntl();
  const { data, loading } = useQuery(GET_PROJECTS_QUERY, { variables: { locale } });

  const projects = useMemo(() => get(data, 'projects', []), [data]);
  return [projects, loading];
};

export default useProjects;
