import React from 'react';
import { BudgetStep4 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import PageTitle from 'components/common/PageTitle';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import Select from 'components/inputs/Select';
import * as yup from 'yup';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';

const validationSchema = yup.object().shape({
  square_meters: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  project_type: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

const FourthStep = ({
  initialValues,
  isEditMode,
  onSubmit,
  onSubmitStep,
  formValuesRef,
  nextStep,
  onBack,
  previousStep,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.fourth_step.title' })}</PageTitle>

        <Formik
          initialValues={isEditMode ? initialValues : formValuesRef.current}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions);
            } else {
              await onSubmitStep(values, actions);
              nextStep();
            }
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />

              <Field
                name="project_type"
                label={t({ id: 'budget_calculator.fourth_step.project_type_finance' })}
                component={Select}
                options={[
                  {
                    label: t({ id: 'budget_calculator.fourth_step.building_new_house' }),
                    value: 'new_building',
                  },
                  {
                    label: t({ id: 'budget_calculator.fourth_step.buying_house' }),
                    value: 'buying_house',
                  },
                  {
                    label: t({ id: 'budget_calculator.fourth_step.buying_a_flat' }),
                    value: 'new_apartment',
                  },
                  {
                    label: t({ id: 'budget_calculator.fourth_step.renovation_house' }),
                    value: 'renovation_house',
                  },
                  {
                    label: t({ id: 'budget_calculator.fourth_step.renovating_a_flat' }),
                    value: 'renovating_a_flat',
                  },
                ]}
              />

              <Field
                name="square_meters"
                label={t({ id: 'budget_calculator.fourth_step.square_meters' })}
                description={t({ id: 'budget_calculator.fourth_step.square_meters_description' })}
                component={SliderWithTooltip}
                bigLabel
                min={25}
                max={300}
                defaultValue={100}
                step={5}
                units="m²"
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep4 title={t({ id: 'alt_text.budget_calculator.fourth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default FourthStep;
