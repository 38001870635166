import React from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import s from '../CO2CalculatorResult.module.scss';

const EnergyAndCO2Balance = ({ project }) => {
  const { formatMessage: t } = useIntl();

  const whiteListValues = [
    'total_energy_balance_per_year',
    'energy_ghg_balance_per_year',
    'energy_ghg_equivalent_car',
    'energy_ghg_equivalent_plane',
    'energy_ghg_equivalent_trees',
    'total_energy_costs',
  ];

  const energyResultValues = Object.entries(
    pick(project.calculations.co2_emissions_before.result, whiteListValues),
  ).reduce((acc, [key, value]) => ({ ...acc, [key]: parseFloat(value).toFixed(3) * 1 }), {});

  return (
    <table className={cn('table last-col-text-right', s.resultTable)}>
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.table_title' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.total_energy_balance_per_year' })}
            <Small>
              {t({
                id: 'renovation_house_wizard.energy_and_co2_balance_result.total_energy_balance_per_year_description',
              })}
            </Small>
          </td>
          <td>{formatValue(energyResultValues.total_energy_balance_per_year)} kWh/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_balance_per_year' })}
            <Small>
              {t({
                id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_balance_per_year_description',
              })}
            </Small>
          </td>
          <td>{formatValue(energyResultValues.energy_ghg_balance_per_year)} kg/a</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_car' })}
            <Small>
              {t({
                id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_car_description',
              })}
            </Small>
          </td>
          <td>{formatValue(energyResultValues.energy_ghg_equivalent_car)} km</td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_plane' })}
            <Small>
              {t({
                id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_plane_description',
              })}
            </Small>
          </td>
          <td>
            {formatValue(energyResultValues.energy_ghg_equivalent_plane)}
            <Small>{t({ id: 'renovation_house_wizard.number_of_economy_class_flights' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_trees' })}
            <Small>
              {t({
                id: 'renovation_house_wizard.energy_and_co2_balance_result.energy_ghg_equivalent_trees_description',
              })}
            </Small>
          </td>
          <td>
            {formatValue(energyResultValues.energy_ghg_equivalent_trees)}
            <Small>{t({ id: 'renovation_house_wizard.number_stored_trees' })}</Small>
          </td>
        </tr>
        <tr>
          <td>
            {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.total_energy_costs' })}
            <Small>
              {t({ id: 'renovation_house_wizard.energy_and_co2_balance_result.total_energy_costs_description' })}
            </Small>
          </td>
          <td>{formatCurrency(energyResultValues.total_energy_costs)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default EnergyAndCO2Balance;
