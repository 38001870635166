import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import { useAppContext } from 'contexts/AppContext';
import s from './ToggleButtons.module.scss';

const ToggleButtons = ({ isCalculated, onCalculatedClick, onQuotedClick, disabled }) => {
  const { formatMessage: t } = useIntl();
  const { theme } = useAppContext();
  return (
    <div className={cn(s.toggleButtons, theme === 'green' ? s.greenToggleButtons : '')}>
      <Button
        className={cn(s.toggleButton, isCalculated ? s.activeButton : null)}
        onClick={onCalculatedClick}
        disabled={disabled}
      >
        {t({ id: 'planning.calculated_button' })}
      </Button>
      <Button
        className={cn(s.toggleButton, !isCalculated ? s.activeButton : null)}
        onClick={onQuotedClick}
        disabled={disabled}
      >
        {t({ id: 'planning.quoted_button' })}
      </Button>
    </div>
  );
};

export default ToggleButtons;
