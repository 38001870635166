import React, { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useUploadImage from 'hooks/payments/useUploadImage';
// import useRemoveImage from 'hooks/payments/useRemoveImage';
import { v4 as uuid } from 'uuid';
import useUser from 'hooks/user/useUser';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import { areEqualFields } from 'utils/form';
import AddPaymentPhotoDropzone from './AddPaymentPhotoDropzone';

const useRemoveImageHandler = ({ form, images, setImages }) => {
  const { formatMessage: t } = useIntl();
  // const removeImage = useRemoveImage();

  const confirmDeletePaymentImg = useCallback(
    (fileName) => {
      // await removeImage(fileName); Removed call action to remove the image. The image will be deleted after submitting the forms
      const newImages = images.filter(({ file_name: currentFileName }) => currentFileName !== fileName);
      const formImages = form.values.attachments.filter(
        ({ file_name: currentFileName }) => currentFileName !== fileName,
      );

      setImages(newImages);
      form.setFieldValue('attachments', formImages);
    },
    [form, images, setImages],
  );

  const handleRemove = useCallback(
    async (fileName) => {
      confirmAlert({
        title: t({ id: 'profile.avatar.delete_avatar_modal.title' }),
        message: t({ id: 'profile.avatar.delete_avatar_modal.message' }),
        buttons: [
          { label: t({ id: 'app.cancel' }) },
          { label: t({ id: 'app.confirm' }), onClick: () => confirmDeletePaymentImg(fileName) },
        ],
      });
    },
    [confirmDeletePaymentImg, t],
  );
  return handleRemove;
};

const ImagesField = ({ field, form, label, imageLoading, setImageLoading }) => {
  const [me] = useUser();
  const [uploadImage] = useUploadImage();
  const [images, setImages] = useState(field.value || []);
  const removeImage = useRemoveImageHandler({ form, images, setImages });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setImageLoading(true);
      const image = acceptedFiles.pop();
      const fileName = `payments/${uuid()}(${me._id}).${image.type.replace('image/', '')}`;
      const url = await uploadImage({ fileName, mime: image.type }, image);

      if (url) {
        setImages([...images, { url, file_name: fileName }]);
        form.setFieldValue('attachments', [...field.value, { file_name: fileName }]);
      }
      setImageLoading(false);
    },
    [field.value, form, images, me._id, setImageLoading, uploadImage],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
  const dropzone = { getInputProps, getRootProps };

  return (
    <AddPaymentPhotoDropzone {...{ dropzone, images, label }} loading={imageLoading} onRemoveImage={removeImage} />
  );
};

export default memo(ImagesField, (...props) => areEqualFields(...props, ['imageLoading']));
