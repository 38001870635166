import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import axios from 'axios';
import { formatGraphqlErrors } from 'utils/helpers';

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($fileName: String!, $mime: String!) {
    uploadImage(fileName: $fileName, mime: $mime) {
      url
      signedRequest
    }
  }
`;

const uploadToGS = async (fileDoc, signedRequest) => {
  const options = {
    headers: {
      'content-type': fileDoc.type,
    },
  };
  await axios.put(signedRequest, fileDoc, options);
};

const useUploadImage = () => {
  const [uploadImageMutation, { loading }] = useMutation(UPLOAD_IMAGE);

  const uploadImage = useCallback(
    // eslint-disable-next-line consistent-return
    async (variables, image) => {
      try {
        const {
          data: {
            uploadImage: { url, signedRequest },
          },
        } = await uploadImageMutation({
          variables,
        });
        await uploadToGS(image, signedRequest);
        return url;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    },
    [uploadImageMutation],
  );

  return [uploadImage, loading];
};

export default useUploadImage;
