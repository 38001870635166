/* eslint-disable no-plusplus */
import React, { useState, useCallback, useRef } from 'react';
import Editor from 'react-avatar-editor';
import Button from 'components/common/Button';
import { sliderStyleProps } from 'components/inputs/Slider';
import { useIntl } from 'react-intl';
import Slider from 'rc-slider';
import { ReactComponent as UploadIcon } from 'assets/icons/cloud_upload.svg';
import { isMobile } from 'react-device-detect';
import { ReactComponent as ArrowRotate } from 'assets/icons/arrow-rotate.svg';
import 'rc-slider/assets/index.css';
import styles from './Avatar.module.scss';

const ImageCropper = ({ imageProps: { file }, onUploadImage, loading }) => {
  const { formatMessage: t } = useIntl();
  const editorRef = useRef();
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [rotate, setRotation] = useState(0);

  const dataURLtoFile = useCallback((dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }, []);

  const handleCrop = useCallback(async () => {
    if (editorRef.current !== null) {
      const url = editorRef.current.getImageScaledToCanvas().toDataURL();
      const newImage = dataURLtoFile(url, 'newImage');
      await onUploadImage(newImage);
    }
  }, [dataURLtoFile, onUploadImage]);

  const handleScale = useCallback((s) => {
    setScale(s);
  }, []);

  const handlePositionChange = useCallback((pos) => {
    setPosition(pos);
  }, []);

  const handleOnChangeRotation = useCallback(
    (condition) => {
      if (condition === 'turnLeft') setRotation(rotate - 90);
      if (condition === 'turnRight') setRotation(rotate + 90);
    },
    [rotate],
  );

  return (
    <div className={styles.cropImageContainer}>
      <Editor
        disableHiDPIScaling
        image={file || ' '}
        scale={parseFloat(scale)}
        ref={editorRef}
        width={isMobile ? 250 : 360}
        height={isMobile ? 250 : 360}
        position={position}
        rotate={rotate}
        onPositionChange={handlePositionChange}
      />
      <div className={styles.toolsWrapper}>
        <div className={styles.arrowsContainer}>
          <ArrowRotate className={styles.leftArrow} onClick={() => handleOnChangeRotation('turnLeft')} />
          <ArrowRotate className={styles.rightArrow} onClick={() => handleOnChangeRotation('turnRight')} />
        </div>
        <Slider
          {...sliderStyleProps}
          className={styles.slider}
          name="scale"
          onChange={handleScale}
          value={scale}
          min={0.3}
          max={2}
          step={0.01}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button onClick={handleCrop} loading={loading} disabled={loading} endIcon={<UploadIcon />}>
          {t({ id: 'payments.upload' })}
        </Button>
      </div>
    </div>
  );
};

export default ImageCropper;
