import React from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as SaunaIcon } from 'assets/icons/co2-calculator/power-consumers/sauna.svg';
import { ReactComponent as WhirlpoolIcon } from 'assets/icons/co2-calculator/power-consumers/whirlpool.svg';
import { ReactComponent as SwimmingPoolIcon } from 'assets/icons/co2-calculator/power-consumers/swimming-pool.svg';
import { ReactComponent as AirConditionIcon } from 'assets/icons/co2-calculator/power-consumers/air-condition.svg';
import { ReactComponent as WaterbedIcon } from 'assets/icons/co2-calculator/power-consumers/waterbed.svg';
import NumberInput from 'components/inputs/NumberInput';
import Counter from 'components/inputs/Counter';
import Switch from 'components/inputs/Switch';

const FourthSection = ({ t, values, onSolarPowerSystemSizeChange }) => (
  <>
    <h2 className="text-primary text-left">{t({ id: 'renovation_house_wizard.more_info_about_electricity' })}</h2>
    <Field
      label={t({ id: 'renovation_house_wizard.is_devices_younger_than_10_years' })}
      name="is_devices_younger_than_10_years"
      component={Switch}
    />
    <Field
      label={t({ id: 'renovation_house_wizard.power_consumers.name' })}
      name="power_consumers"
      multi
      contentInColumn
      inOneRow={false}
      component={ButtonSelect}
      options={[
        {
          icon: ({ altText }) => <SaunaIcon className="stroke" title={altText} />,
          label: t({ id: 'renovation_house_wizard.power_consumers.sauna' }),
          value: 'sauna',
        },
        {
          icon: ({ altText }) => <WhirlpoolIcon className="stroke" title={altText} />,
          label: t({ id: 'renovation_house_wizard.power_consumers.outdoor_whirlpool' }),
          value: 'outdoor_whirlpool',
        },
        {
          icon: ({ altText }) => <SwimmingPoolIcon className="stroke" title={altText} />,
          label: t({ id: 'renovation_house_wizard.power_consumers.swimming_pool' }),
          value: 'swimming_pool',
        },
        {
          icon: ({ altText }) => <AirConditionIcon className="stroke" title={altText} />,
          label: t({ id: 'renovation_house_wizard.power_consumers.air_condition' }),
          value: 'air_condition',
        },
        {
          icon: ({ altText }) => <WaterbedIcon className="stroke" title={altText} />,
          label: t({ id: 'renovation_house_wizard.power_consumers.waterbed' }),
          value: 'waterbed',
        },
      ]}
    />
    <Field
      label={t({ id: 'renovation_house_wizard.is_solar_power_system' })}
      name="is_solar_power_system"
      component={Switch}
    />
    {values.is_solar_power_system ? (
      <>
        <Field
          label={t({ id: 'renovation_house_wizard.solar_power_system_size' })}
          name="solar_power_system_size"
          component={Counter}
          callbackOnChange={onSolarPowerSystemSizeChange}
          min={3}
          max={15}
        />
        <Field
          label={t({ id: 'renovation_house_wizard.solar_power_system_consuming_percentage' })}
          name="solar_power_system_consuming_percentage"
          defaultValue={50}
          component={NumberInput}
        />
      </>
    ) : null}
  </>
);

export default FourthSection;
