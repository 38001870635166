/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowRight } from 'assets/icons';
import CandleChart from 'components/CandleChart';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { formatCurrency } from 'utils/helpers';
import Collapsible from 'react-collapsible';
import { PaymentCard } from 'components/payments/PaymentsList/PaymentsList';
import { getBodyPriceText, getCategoryStage, getColor } from '../helpers';
import s from './SpendingsPlanningCard.module.scss';

const Card = ({
  category: {
    name,
    label,
    user_price,
    calculated_price,
    subitems,
    avg_equipment_type,
    has_mixed_equipment_type,
    description,
  },
  paymentsTotalByCategory: { isFinalPayment, paymentsTotal },
  showModal,
  projectId,
  t,
}) => {
  const categoryTotal = Number.isFinite(user_price) ? user_price : calculated_price;
  const percentage = Number.isFinite(paymentsTotal / categoryTotal)
    ? Math.round((paymentsTotal / categoryTotal) * 100)
    : 0;
  const hasPayments = !!paymentsTotal;
  const stage = useMemo(
    () =>
      getCategoryStage({
        paymentsTotal,
        user_price,
        subitems,
        t,
      }),
    [paymentsTotal, subitems, t, user_price],
  );

  const cardAccentColor = useMemo(
    () =>
      getColor({
        categoryTotal,
        percentage,
        hasPayments,
        stage,
        isFinalPayment,
      }),
    [categoryTotal, hasPayments, isFinalPayment, percentage, stage],
  );

  return (
    <>
      <Link
        to={{ pathname: routePatterns.planForm.stringify({ id: projectId }), search: `?category=${name}` }}
        style={{ textDecoration: 'none' }}
      >
        <div className={s.wrapper}>
          <div className={s.leftContent}>
            <div className={s.cardHeader}>
              <h4 className={s.cartTitle}>{t({ id: label })}</h4>
              <small className={s.cardEquipmentType}>
                {avg_equipment_type
                  ? t(
                      { id: 'spendings.materials_quality', defaultMessage: ' ' },
                      { equipment_type: t({ id: `equipment_types.${avg_equipment_type}`, defaultMessage: ' ' }) },
                    )
                  : null}
                {has_mixed_equipment_type ? `(${t({ id: 'equipment_types.mixed', defaultMessage: ' ' })})` : null}
              </small>
              {description ? (
                <small className={s.cardDescription}>{t({ id: description, defaultMessage: ' ' })}</small>
              ) : null}
            </div>
            <div className={s.cardBody}>
              <div style={{ color: cardAccentColor }} className={s.cardPriceText}>
                {getBodyPriceText({
                  stage,
                  percentage,
                  hasPayments,
                  categoryTotal,
                  paymentsTotal,
                  isFinalPayment,
                  t,
                })}
              </div>
              {hasPayments ? (
                <small className={s.cardCurrencyValue}>
                  {formatCurrency(paymentsTotal)} / {formatCurrency(categoryTotal)} ({percentage}%){' '}
                  {t({ id: 'spendings.budget_spent' })}
                </small>
              ) : null}
            </div>
          </div>
          <div className={s.rightContent}>
            <CandleChart complete={isFinalPayment} value={percentage} vertical />
          </div>
        </div>
      </Link>
      <div className={s.cardFooter}>
        <div
          className={s.recordPayment}
          onClick={(e) => {
            e.stopPropagation();
            showModal({ category: name });
          }}
        >
          <div>{t({ id: 'spendings.record_payment' })}</div>
          <ArrowRight />
        </div>
        <div className={s.collapse} />
      </div>
    </>
  );
};

const SpendingsPlanningCard = ({ category, paymentsTotalByCategory, showModal }) => {
  const { formatMessage: t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { payments } = paymentsTotalByCategory;
  const { id: projectId } = useParams();

  return (
    <>
      {payments.length ? (
        <div className={s.card}>
          <Collapsible
            triggerClassName="collapsible-trigger"
            open={isOpen}
            transitionTime={400}
            handleTriggerClick={() => setIsOpen(!isOpen)}
            contentOuterClassName={s.contentOuter}
            trigger={<Card {...{ paymentsTotalByCategory, category, t, showModal, projectId }} />}
          >
            <div className={s.paymentsContainer}>
              <h4>{t({ id: 'spendings.payments' })}</h4>
              {payments.map((payment) => (
                <PaymentCard key={payment._id} {...{ ...payment, t, showModal, projectId }} withoutClick />
              ))}
            </div>
          </Collapsible>
        </div>
      ) : (
        <div className={s.card}>
          <Card {...{ paymentsTotalByCategory, category, t, showModal, projectId }} />
        </div>
      )}
    </>
  );
};

export default SpendingsPlanningCard;
