import React from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';

const ThirdSection = ({ t }) => (
  <>
    <h2 className="text-primary text-left">{t({ id: 'renovation_house_wizard.more_info_about_hot_water' })}</h2>
    <Field
      label={t({ id: 'renovation_house_wizard.hot_water_producer.name' })}
      name="hot_water_producer"
      component={ButtonSelect}
      inOneRow={false}
      options={[
        {
          label: t({ id: 'renovation_house_wizard.hot_water_producer.heating_system' }),
          value: 'heating_system',
        },
        {
          label: t({ id: 'renovation_house_wizard.hot_water_producer.electric_boiler' }),
          value: 'electric_boiler',
        },
        {
          label: t({ id: 'renovation_house_wizard.hot_water_producer.gas_heater' }),
          value: 'gas_heater',
        },
        {
          label: t({ id: 'renovation_house_wizard.hot_water_producer.heat_pump' }),
          value: 'heat_pump',
        },
      ]}
    />
    <Field
      label={t({ id: 'renovation_house_wizard.amount_of_hot_water.name' })}
      name="amount_of_hot_water"
      component={ButtonSelect}
      description={t({ id: 'renovation_house_wizard.amount_of_hot_water.description' })}
      inOneRow
      options={[
        {
          label: t({ id: 'renovation_house_wizard.amount_of_hot_water.low' }),
          value: 'low',
        },
        {
          label: t({ id: 'renovation_house_wizard.amount_of_hot_water.medium' }),
          value: 'medium',
        },
        {
          label: t({ id: 'renovation_house_wizard.amount_of_hot_water.hight' }),
          value: 'hight',
        },
      ]}
    />
  </>
);

export default ThirdSection;
