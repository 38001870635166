import React, { memo, useCallback, useMemo } from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';
import { areEqualFields } from 'utils/form';
import { get } from 'lodash';
import { SelectCustomStyles } from './customStyle';

const Select = ({
  field,
  form: { touched, errors, setFieldValue },
  className = '',
  onChange,
  bigLabel = false,
  ...props
}) => {
  const { value, name } = field;
  const { label, options, style, isSearchable = false, note } = props;

  // const handleBlur = useCallback(() => {
  //   form.setFieldTouched(field.name);
  // }, [field, setFieldTouched]);

  const handleChange = useCallback(
    (selectedOption) => {
      const v = selectedOption ? selectedOption.value : null;
      setFieldValue(name, v);
    },
    [setFieldValue, name],
  );

  const optionValue = useMemo(() => {
    const defaultOption = options.find((o) => o.value === field.defaultValue);
    if (defaultOption) {
      handleChange(defaultOption);
      return defaultOption;
    }
    const option = options && options.find((o) => o.value === value);
    return option;
  }, [options, field.defaultValue, handleChange, value]);

  return (
    <div className={cn('field-wrapper', className, bigLabel && 'field-with-big-label')} style={style}>
      <ReactSelect
        inputProps={{
          autoComplete: 'off',
        }}
        inputId={name}
        value={optionValue}
        // onBlur={handleBlur}
        onChange={onChange || handleChange}
        name={name}
        options={options}
        className={touched[name] && errors[name] && 'error-input'}
        styles={SelectCustomStyles}
        placeholder=""
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={isSearchable}
        blurInputOnSelect
      />
      <label id={`label-${name}`} htmlFor={name}>
        {label}
      </label>
      {note ? (
        <div className="text-left">
          <small className="muted">{note}</small>
        </div>
      ) : null}
      {get(touched, name) && get(errors, name) && <div className="error-text">{get(errors, name)}</div>}
    </div>
  );
};

export default memo(Select, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['options']));
