import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PROJECTS_QUERY } from './useProjects';
import useLastProjectId, { LAST_PROJECT_ID_QUERY } from './useLastProjectId';

const REMOVE_PROJECT_MUTATION = gql`
  mutation removeProject($projectId: ID!) {
    removeProject(projectId: $projectId) {
      success
    }
  }
`;

const useRemoveProject = () => {
  const [removeProjectMutation] = useMutation(REMOVE_PROJECT_MUTATION, {
    onCompleted: () => {},
  });
  const client = useApolloClient();
  const { lastProjectId } = useLastProjectId();

  const handleRemoveProject = useCallback(
    async (projectId) => {
      try {
        await removeProjectMutation({
          variables: { projectId },
          refetchQueries: [{ query: GET_PROJECTS_QUERY }],
          awaitRefetchQueries: true,
        });

        if (projectId === lastProjectId) {
          const { projects } = client.readQuery({ query: GET_PROJECTS_QUERY });

          client.writeQuery({
            query: LAST_PROJECT_ID_QUERY,
            data: { lastProject: { _id: projects[projects.length - 1]._id, __typename: 'Project' } },
          });
        }
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    },
    [client, lastProjectId, removeProjectMutation],
  );
  return handleRemoveProject;
};

export default useRemoveProject;
