/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, memo, useState } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import useInjectImmazingValues from 'hooks/useInjectImmazingValues';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import { BUYING_HOUSE, GUEST, NEW_APARTMENT } from 'constants/variables';
import { useParams } from 'react-router-dom';
import Loading from 'components/common/Loading';
import { toast } from 'react-toastify';
import { cloneDeep, pick } from 'lodash';
import equal from 'fast-deep-equal/es6/react';
import {
  EstimationInfo,
  EstimationNotAvailable,
  EstimationNotAvailableForGuest,
  NoActualEstimationInfo,
  NoEstimationRequests,
} from './ImmazingEstimation.components';

const whiteListApartmentValues = [
  'construction_year',
  'coordinates.lat',
  'coordinates.lng',
  'apartment_area',
  'floor_number',
  'garden',
  'loggia',
  'balcony',
  'terrace',
  'elevator',
  'basement',
  'is_rooftop',
  'parking',
  'condition_of_apartment',
];

export const whiteListBuyingHouseValues = [
  'construction_year',
  'coordinates.lat',
  'coordinates.lng',
  'house_area',
  'land_area',
  'house_category',
  'basement_type',
  'parking',
];

const whiteListFormValuesByProjectType = {
  [NEW_APARTMENT]: whiteListApartmentValues,
  [BUYING_HOUSE]: whiteListBuyingHouseValues,
};

const useInjectImmazingValuesHandler = ({ values, type, sideEffectOnRequest }) => {
  const { id: projectId } = useParams();
  const getImmazingValues = useInjectImmazingValues({ projectId, type });
  const { formatMessage: t } = useIntl();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleInject = useCallback(async () => {
    setSubmitting(true);
    try {
      const formData = pick(values, whiteListFormValuesByProjectType[type]);
      const isFormInvalid = Object.values(formData).some((value) => value === null || typeof value === 'undefined');
      if (isFormInvalid || !values.full_address) {
        toast.error(t({ id: 'errors.fill_all_fields' }));
      } else {
        await sideEffectOnRequest();
        await getImmazingValues();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }, [getImmazingValues, sideEffectOnRequest, t, type, values]);

  return [handleInject, isSubmitting];
};

function compareSpecificValuesHandler(_formValues, _immazingFormValues) {
  const formValues = cloneDeep(_formValues);
  const immazingFormValues = cloneDeep(_immazingFormValues);
  const immazingParkingValue = get(immazingFormValues, 'parking');
  const immazingBasementType = get(immazingFormValues, 'basement_type');

  if (immazingParkingValue === 'other' && formValues.parking !== 'garage') {
    delete formValues.parking;
    delete immazingFormValues.parking;
  }
  if (immazingBasementType === 'other' && formValues.basement_type !== 'no_basement') {
    delete formValues.basement_type;
    delete immazingFormValues.basement_type;
  }
  return [formValues, immazingFormValues];
}

const ImmazingEstimation = ({
  projectPrice,
  type,
  values,
  sideEffectOnRequest,
  calculations,
  loading,
  requestsCount,
}) => {
  const { formatMessage: t } = useIntl();
  const [getImmazingValues, isSubmitting] = useInjectImmazingValuesHandler({ values, type, sideEffectOnRequest });
  const isGuest = useHasRequiredRole(GUEST);

  const isAvailableRequests = requestsCount < 10;
  const immazing = get(calculations, 'immazing', {});

  const isActualInfo = useMemo(() => {
    const formValues = pick(values, whiteListFormValuesByProjectType[type]);
    const immazingFormValues = pick(calculations?.immazing?.form_values, whiteListFormValuesByProjectType[type]);
    const [newFormValues, newImmazingValues] = compareSpecificValuesHandler(formValues, immazingFormValues);
    return equal(newFormValues, newImmazingValues);
  }, [calculations, type, values]);

  if (isGuest) {
    return <EstimationNotAvailableForGuest t={t} />;
  }

  if (loading) {
    return <Loading size={50} />;
  }

  if (!isActualInfo) {
    return <NoActualEstimationInfo {...{ t, requestsCount, isSubmitting, getImmazingValues }} />;
  }

  if (immazing?.vw) return <EstimationInfo {...{ t, projectPrice, immazing }} />;
  if (immazing?.error) return <EstimationNotAvailable t={t} />;
  if (!isAvailableRequests) return <NoEstimationRequests {...{ t, requestsCount }} />;

  return <NoActualEstimationInfo {...{ t, requestsCount, isSubmitting, getImmazingValues }} />;
};

export default memo(ImmazingEstimation, equal);
