import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

export const PROJECT_PLAN_FRAGMENT = gql`
  fragment projectPlan on ProjectPlan {
    pdf(locale: $locale)
    price
    planningCategories {
      name
      label
      description
      group
      costs_type
      calculated_price
      user_price
      equipment_type
      avg_equipment_type
      has_mixed_equipment_type
      subitems {
        name
        label
        description
        calculated_price
        user_price
        equipment_type
        has_equipment_type
      }
    }
  }
`;

export const PROJECT_PLAN_QUERY = gql`
  query projectPlan($projectId: ID!, $locale: String) {
    projectPlan(projectId: $projectId) {
      ...projectPlan
    }
    project(id: $projectId) {
      _id
      price
      type
      personal_contribution_value
      calculations {
        ... on RenovationHouseCalculations {
          co2_emissions_before {
            result {
              energy_ghg_equivalent_plane
              energy_ghg_equivalent_car
              energy_ghg_equivalent_trees

              specific_heating_demand
              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
          co2_emissions_after {
            result {
              energy_ghg_equivalent_plane
              energy_ghg_equivalent_car
              energy_ghg_equivalent_trees

              specific_heating_demand
              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
        }
      }
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useProjectPlan = (projectId) => {
  const { locale } = useIntl();
  const { data, loading, error } = useQuery(PROJECT_PLAN_QUERY, { variables: { projectId, locale } });
  const projectPlan = get(data, 'projectPlan', null);
  const project = get(data, 'project', null);

  return { projectPlan, project, loading, error };
};

export default useProjectPlan;
