/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React from 'react';
import { primaryColor } from 'styles/variables.scss';
import cn from 'classnames';
import { useAppContext } from 'contexts/AppContext';
import Loading from './Loading';
import s from './Button.module.scss';

const themeToClassNameMap = {
  primary: {
    primary: s.primary,
    outline: s.outline,
    danger: s.danger,
  },
  green: {
    primary: s.primarySuccess,
    outline: s.outlineSuccess,
    danger: s.danger,
  },
};

export default function Button({
  children,
  className,
  color = 'primary',
  disabled,
  loading,
  inlineContent,
  type = 'button',
  onClick,
  startIcon,
  endIcon,
  ...rest
}) {
  const { theme } = useAppContext();

  return (
    <button
      className={cn(s.btn, themeToClassNameMap[theme][color], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <div
        className={cn(s.btnContent, (startIcon || endIcon) && s.btnContentWithIcon)}
        style={loading ? { visibility: 'hidden' } : {}}
      >
        {startIcon && <div>{startIcon}</div>}
        {children ? <div className={s.btnChildren}>{children}</div> : null}
        {endIcon && <div>{endIcon}</div>}
      </div>
      {loading ? (
        <div className={s.btnLoading}>
          <Loading size={25} color={color === 'primary' ? 'white' : primaryColor} />
        </div>
      ) : null}
    </button>
  );
}
