import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECTS_PAYERS_QUERY } from './useProjectsPayersQuery';
import useGetFilters from './useGetFilters';

const DELETE_PAYER_MUTATION = gql`
  mutation deletePayer($payerId: ID!) {
    deletePayer(payerId: $payerId)
  }
`;

const useDeletePayer = () => {
  const [deletePayerMutation] = useMutation(DELETE_PAYER_MUTATION);
  const { filters } = useGetFilters();

  const handleDeletePayer = useCallback(
    async (payerId) => {
      try {
        await deletePayerMutation({
          variables: { payerId },
          refetchQueries: [{ query: PROJECTS_PAYERS_QUERY, variables: { filters } }],
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [deletePayerMutation, filters],
  );

  return handleDeletePayer;
};

export default useDeletePayer;
