import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PAYMENTS_QUERY } from './useGetPayments';
import useGetFilters from './useGetFilters';

const POPULATE_PAYMENTS = gql`
  mutation _DEV_populatePayments {
    _DEV_populatePayments
  }
`;

const usePopulatePayments = () => {
  const [populatePayments] = useMutation(POPULATE_PAYMENTS);
  const { filters } = useGetFilters();
  const [isSubmitting, setSubmitting] = useState(false);

  const handlePopulate = useCallback(async () => {
    setSubmitting(true);
    try {
      await populatePayments({
        refetchQueries: [{ query: GET_PAYMENTS_QUERY, variables: { filters, limit: 10, offset: 0 } }],
      });
      toast.success('Payment added');
    } catch (error) {
      const e = formatGraphqlErrors(error);
      toast.error(e.message);
    } finally {
      setSubmitting(false);
    }
  }, [filters, populatePayments]);
  return [handlePopulate, isSubmitting];
};

export default usePopulatePayments;
