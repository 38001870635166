import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Row } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import useRemoveProject from 'hooks/project/useRemoveProject';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import useLastProjectId, { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';
import { routePatterns } from 'router/route-paths';
import { useAppContext } from 'contexts/AppContext';
import s from './ProjectsList.module.scss';
import ProjectCard from './ProjectCard';

const useRemoveProjectHandler = () => {
  const removeProject = useRemoveProject();
  const { formatMessage: t } = useIntl();

  const handleRemove = useCallback(
    async (projectId) => {
      try {
        confirmAlert({
          title: t({ id: 'my_projects.delete_project.title' }),
          message: t({ id: 'my_projects.delete_project.message' }),
          buttons: [
            {
              label: t({ id: 'my_projects.delete_project.cancel' }),
            },
            {
              label: t({ id: 'my_projects.delete_project.confirm' }),
              onClick: async () => {
                await removeProject(projectId);
              },
            },
          ],
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
    [t, removeProject],
  );
  return handleRemove;
};

const ProjectsList = ({ projects }) => {
  const removeProject = useRemoveProjectHandler();
  const { lastProjectId } = useLastProjectId();
  const history = useHistory();
  const { theme } = useAppContext();
  const client = useApolloClient();

  const changeLastProjectId = useCallback(
    (_id) => {
      client.writeQuery({
        query: LAST_PROJECT_ID_QUERY,
        data: { lastProject: { _id, __typename: 'Project' } },
      });
    },
    [client],
  );

  const onCardClick = useCallback(
    (e, _id) => {
      e.stopPropagation();
      changeLastProjectId(_id);
      history.push(routePatterns.dashboard.stringify({ id: _id }));
    },
    [changeLastProjectId, history],
  );

  return (
    <Row className={s.projectsRow}>
      {projects.map((project) => (
        <ProjectCard
          key={project._id}
          {...{
            isLastProject: projects.length <= 1,
            removeProject,
            onAdjustProject: changeLastProjectId,
            onCardClick,
            isActive: project._id === lastProjectId,
            theme,
            ...project,
          }}
        />
      ))}
    </Row>
  );
};

export default ProjectsList;
