import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import useQuickCalculatorMutation from 'hooks/useQuickCalculatorMutation';
import useUser from 'hooks/user/useUser';
import { Col, Grid, Row } from 'react-flexbox-grid';
import routePaths, { routePatterns } from 'router/route-paths';
import PageTitle from 'components/common/PageTitle';
import { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useModal } from 'components/common/Modal';
import { LIFESTYLE_CALCULATOR, PROJECT_TYPES, PROPERTY_VALUATION } from 'constants/variables';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { projects, tools } from './QuickCalculator.projects';
import s from './QuickCalculator.module.scss';
import { ProjectCard, QuickCalculatorModal } from './QuickCalculator.components';

const initialValues = {
  name: 'Mein Wohntraum',
  planned_project_type: null,
  type: '',
  country: 'AT',
  consent: false,
};

function validProjectType(projectType) {
  const validProjectTypes = PROJECT_TYPES;
  return validProjectTypes.includes(projectType);
}

const useSubmitQuickCalculator = (me) => {
  const quickCalculatorSubmit = useQuickCalculatorMutation();
  const history = useHistory();
  const client = useApolloClient();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setSubmitting(true);
        const { name, type, country, planned_project_type } = values;

        let result = {};
        if (me && type === LIFESTYLE_CALCULATOR) {
          history.push(routePaths.lifestyleOverview);
        } else {
          result = await quickCalculatorSubmit({ name, type, country, planned_project_type });
        }

        setSubmitting(false);

        if (type === LIFESTYLE_CALCULATOR) {
          history.push(routePaths.lifestyleOverview);
        } else {
          client.writeQuery({
            query: LAST_PROJECT_ID_QUERY,
            data: { lastProject: { _id: result.projectId, __typename: 'Project' } },
          });
          history.push(routePatterns.projectDetailsCalculator.stringify({ id: result.projectId }));
        }
      } catch (error) {
        toast.error(error.message);
        setSubmitting(false);
      }
    },
    [client, history, me, quickCalculatorSubmit],
  );

  return [onSubmit, isSubmitting];
};

const QuickCalculator = () => {
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, isVisible } = useModal();
  const [me, loading] = useUser();
  const [projectType, setProjectType] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchParamsProjectType = searchParams.get('type');
  const projectTypeLanding = searchParamsProjectType || location.state?.projectType;
  const [onSubmit, isSubmitting] = useSubmitQuickCalculator(me);

  useEffect(() => {
    const isValidProjectType = validProjectType(projectTypeLanding);
    if (!isValidProjectType) return;
    if (me && projectTypeLanding !== PROPERTY_VALUATION) {
      onSubmit({ ...initialValues, type: projectTypeLanding });
    } else {
      setProjectType(projectTypeLanding);
      showModal();
    }
  }, [me, onSubmit, projectType, projectTypeLanding, showModal]);

  const handleSelect = useCallback(
    async (type) => {
      if (!me || type === PROPERTY_VALUATION) {
        setProjectType(type);
        showModal();
        if (type === PROPERTY_VALUATION) initialValues.type = PROPERTY_VALUATION;
      } else {
        await onSubmit({ ...initialValues, type });
      }
    },
    [me, onSubmit, showModal],
  );

  if (loading) return <LoadingOverlay />;

  return (
    <Grid className={s.container}>
      <Row>
        <Col xs={12}>
          <PageTitle auth>{t({ id: 'project_wizard.first_step.title' })}</PageTitle>

          <section>
            <h3 className={s.subtitle}>{t({ id: 'project_wizard.first_step.property_tools' })}</h3>
            <p className={s.description}>{t({ id: 'project_wizard.first_step.property_tools_description' })}</p>
            <Row>
              {projects.map(({ type, ...rest }) => (
                <ProjectCard key={type} {...{ type, handleSelect, t, isSubmitting, ...rest }} />
              ))}
            </Row>
          </section>

          <section className="mt-8">
            <h3 className={s.subtitle}>{t({ id: 'project_wizard.first_step.finance_tools' })}</h3>
            <p className={s.description}>{t({ id: 'project_wizard.first_step.finance_tools_description' })}</p>
            <Row>
              {tools.map(({ type, ...rest }) => (
                <ProjectCard key={type} {...{ type, handleSelect, t, isSubmitting, ...rest }} />
              ))}
            </Row>
          </section>

          {!me || projectType === PROPERTY_VALUATION ? (
            <QuickCalculatorModal
              {...{ hideModal, isVisible, t, isSubmitting, me, projectType, onSubmit, initialValues }}
            />
          ) : null}
        </Col>
      </Row>
    </Grid>
  );
};

export default QuickCalculator;
