export default {
  cookie_declaration: {
    title: 'Cookie Declaration',
  },
  quick_calc_result: {
    costs: 'ESTIMATED PROJECT COSTS',
    monthly_rate: 'MONTHLY CREDIT RATE {value}',
    monthly_rate_description: 'Taking into account your own funds of {budget}',
    calculated_price_message:
      "The calculated price is for a 'ready to move into' house but without extras like furniture, terrace etc.",
    personal_area: 'Personal area',
    advantages: 'use it free of charge and without obligation',
    registration: 'Registration',
    register_now: 'Register now and use your personal area free of charge and without obligation',
    result: 'Result',
  },
  project_types: {
    new_building: 'New single-family house',
    new_apartment: 'Buying apartment',
    buying_house: 'Buying family-house',
    renovation_house: 'Renovation house',
    property_valuation: 'Property valuation',
    lifestyle_calculator: 'Lifestyle calculator',
  },
  project_details: {
    tabs: {
      house_info: 'House info',
      apartment_info: 'Apartment info',
      co2_status: 'CO2 Status',
      common: 'Common',
      energy: 'Energy',
      indoor: 'Indoor',
      outdoor: 'Outdoor',
      other: 'Other investments',
      cost_overview: 'Cost overview',
      immazing_estimation: 'Immazing evaluation',
    },
  },
  immazing_estimation: {
    immazing: {
      title: 'Apartment cost estimation',
      tooltip_label: 'Immazing',
      tooltip_description:
        'ImmAzing is an external specialist in electronic real estate evaluations whose services we use for valuation purposes. Talo IT assumes no guarantee, liability or responsibility towards the user or third parties that the data, prices and values determined in the context of the home calculator are completely correct or that certain purchase prices can actually be achieved on the market.',
      not_available_for_guest:
        'The property price evaluation is only available to registered users. You can skip this step or register here without obligation.',
    },
    immazing_land: {
      title: 'Land estimation',
      not_available_for_guest:
        'The property price evaluation is only available to registered users. You can skip this step or register here without obligation.',
    },
    modal_header_text: 'Immazing estimation',
    reminder:
      'Do you really want to proceed without trying Immazing property evaluation? To get the estimation please click the "{buttonTitle}" button.',
    reminder_for_guest: 'Do you really want to proceed without evaluation - you can {link_label} to use this feature!',
    reminder_for_guest_link_label: 'sign up here',
    estimated_range: 'Estimated sales range',
    btn_label: 'Receive instant Immazing evaluation',
    not_available_request: 'All {requestsCount} evaluation requests were used',
    complete_immazing_form_btn: 'Complete immazing form',
    complete_immazing_step: 'You must complete an immazing estimation form to be able to get the evaluation',
  },
  auth: {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    password: 'Password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    sign_up_submit: 'Sign up',
    sign_in_submit: 'Sign in',
    already_have_account: 'Already have an account?',
    already_have_account_sign_in_link: 'Sign in',
    do_not_have_account: "Don't have an account?",
    do_not_have_account_sign_up_link: 'Sign up',
    login_page_title: 'Login',
    login_page_text:
      '<p> If you have already registered, you can log in here and continue with the saved status for the following modules: </p><ul> <li> Detailed planning of more than 30 detail categories! </li> <li> Calculation of the budget surplus (affordable rate) </li> <li> Free PDF download of all summaries </li> <li> Real-time determination of an expected interest rate indication (variable or fixed interest rate) </li> <li> Request a loan offer with a personal Advice </li> </ul>',
    forgot_password: {
      note: 'Forgot password?',
      message:
        'You will receive an email with instructions to reset your password if there is an account associated with this email',
      reset_password: 'Reset password',
      password_changed_successfully: 'Password changed successfully',
    },
    repeat_password: 'Repeat password',
    verification_code_tip: 'The Google Authenticator app will give you a 6-digit code that you have to enter here.',
    link_to_information: 'here',
    create_account_using: 'Create an account using:',
    sign_in_with: 'Sign in with',
    divider: 'or',
    guest_sign_up: 'Continue as guest',
    terms_of_use: 'I accept the {link}',
    terms_of_use_text: 'Please accept our terms of use to continue',
    terms_of_use_link_label: 'Terms of use',
    terms_of_use_required: 'You must agree with our Terms of Use',
    privacy_policy: 'I have read the {link} and I agree',
    privacy_policy_link_label: 'privacy policy',
    privacy_policy_required: 'You must agree with our Privacy Policy',
    modal_header: 'Terms of use',
    link_has_expired: 'Link has expired',
  },
  app: {
    back: 'Back',
    save: 'Save',
    send: 'Send',
    start: 'Start',
    skip: 'Skip',
    cancel: 'Cancel',
    confirm: 'Confirm',
    proceed: 'Proceed',
    continue: 'Continue',
    submit_form_final: 'Finish',
    submit_form_next: 'Next',
    data_saved_successfully: 'Data saved successfully',
  },
  errors: {
    full_address: 'Please select a suggested address',
    fill_field: 'Please fill out this field',
    choose_one: 'Please choose one',
    no_server_response: 'Cannot connect to the server. Please check your internet connection and try again',
    not_found: 'Project not found',
    wrong_zip_code: 'Wrong zip-code',
    email_invalid: 'Email is invalid',
    email_required: 'Email is required',
    password_required: 'Password is required',
    password_too_small: 'Password should be at least 8 characters',
    passwords_must_match: 'Passwords must match',
    first_name_required: 'First Name is required',
    last_name_required: 'Last Name is required',
    values_within_range: 'Enter values within this range {min} - {max}',
    project_not_found: 'Project not found',
    min_value: 'Minimum value - {min}',
    max_value: 'Maximum value - {max}',
    wrong_date: 'Download',
    fill_all_fields: 'Fill in all input fields in the form',
    invalid_date: 'Invalid date',
  },

  contact: {
    note: 'How can we help you?',
    successfully_sent: 'Your message has been sent successfully',
  },

  profile: {
    first_name: 'First name',
    last_name: 'Last name',
    edit_info: 'Edit info',
    logout: {
      title: 'Logout',
      message: 'Are you sure you want to logout? All your data will be lost',
    },
    delete_my_account: {
      title: 'Delete my account',
      message: 'Are you sure you want to delete your account?',
    },
    avatar: {
      change_avatar: 'Change photo',
      delete_avatar: 'Delete photo',
      delete_avatar_modal: {
        title: 'Delete avatar',
        message: 'Are you sure you want to delete your avatar?',
      },
    },
  },
  verify_account: {
    title: 'ALMOST THERE...',
    message: 'Go to your inbox and click the activation link to verify your email.',
    resend_link: 'Resend link',
    logout: 'Log out',
    email_sent: 'Email sent',
    email_verified: 'Email verified',
    email_confirmed: 'I have confirmed my email',
    email_not_verified_message:
      'The email is not verified, please check your inbox and click on the verification link in the email message',
    contact_support: 'If you have issues receiving emails, please contact us',
  },

  page_not_found: {
    title: 'Page not found',
    back_to_quick_calculator: 'Back to the quick calculator',
  },

  my_projects: {
    budget: 'Budget',
    price: 'Price',
    new_project: 'New project',
    download: 'PDF Download',
    adjust: 'Adjust project',
    create_project_message: 'Try to create your first project',
    delete_project: {
      title: 'Delete project',
      message: 'Are you sure you want to delete this project?',
      cancel: 'Cancel',
      confirm: 'Confirm',
      tooltip: "You can't delete the last project",
      tooltip_max_count_of_projects: "You can't create more than 10 projects",
    },
  },

  property_valuation: {
    planned_project_types: {
      label: 'What would you like to evaluate?',
      new_apartment: 'Apartment',
      new_apartment_description: 'How much the apartment is currently worth..',
      buying_house: 'Buying House',
      buying_house_description: 'How much the house or the property belonging to it is worth..',
      new_building: 'Building house',
      new_building_description: 'How much it costs to build the house..',
      land_estimation: 'Land',
      land_estimation_description: 'How much does the building land cost..',
    },
    planning_project_type_label: 'What type of project would you like to start planning?',
    start_project_planning: 'Start planning your project to access this page',
    plan_project_btn: 'Plan Project',
  },

  renovation_house_wizard: {
    complete_previous_step: 'You must complete the Project details step first to have access to Dashboard',
    complete_previous_step_btn: 'Calculate project details',
    wrong_min_house_area: `The area of the house can't be less than the heated living area - {value} m²`,
    enu_partner: {
      description: 'Get support from the specialists at the Lower Austria energy and environmental organization',
      request_btn: 'Request',
    },
    heated_living_area_disclaimer:
      'if you want to increase the limit heated living area, you need to change the living area in step 3 {link_to_the_third_step} ',
    link_to_the_third_step: 'go to step 3',
    house_energy_balance: {
      header: 'Current energy balance of a house',
      before_and_after_header: 'Savings potential through the renovation measures',
      before: 'Before',
      after: 'After',
      label: 'Energy figures for your house without renovation measures',
      specific_heating_demand: '{value} kWh/m²a',
      co2_demand: 'CO₂-Tax {value}€/a',
      your_house: 'Your house',
      heating: 'Heating {value} kg CO₂/a',
      hot_water: 'Hot water {value} kg CO₂/a',
      electricity: 'Electricity {value} kg CO₂/a',
    },
    potential_energy_balance: {
      title: 'Your CO₂ saving potential',
      description: 'Your values of full renovation and installation of a photovoltaic system (5kwP)',
      redevelopment_house_label: 'Would you like to calculate your individual energy saving potential?',
      ghg_balance: {
        label: 'CO₂ Saving',
        value: '{value} kg p.a.',
      },
      total_energy_costs: {
        label: 'Energy costs',
        value: '{value} € p.a.',
      },
      total_energy_balance_per_year: {
        label: 'Power consumption',
        value: '{value} kwh/a',
      },
    },
    energy_ghg_equivalents: {
      label: 'The CO₂ saving of your renovation measures',
      reduction: 'How much are you saving with your renovations',
      equivalent_car: '{value} Car kilometers',
      equivalent_plane: '{value} Flights Vienna - New York',
      equivalent_trees: '{value} Trees',
    },
    floors: 'Number of floors (without basement)',
    update_btn_label: 'Update values',
    should_show_all_calculations: 'Do you want to see all CO2 calculations?',
    improve_or_renovate_house: 'Would you like to improve or renovate your house?',
    improve_house_btn_label: 'Improve house',
    show_calculations: 'Show calculations',
    hide_calculations: 'Hide calculations',
    edit_co2_calculator_values: 'Edit values',
    second_step_title: 'Renovate your house & make it climate-friendly',
    heated_living_area: 'Heated living area',
    kind_of_house: {
      name: 'Which kind of house do you have?',
      single_family_house: 'Single family house',
      bungalow_or_complex_floor_plan: 'Bungalow or non cubic-layout',
      town_house: 'Town house',
      semi_detached_house: 'Semi detached house',
    },
    energy_standard: {
      name: 'Energy standard of the house',
      description: 'as of 2006 (low energy)',
      before_1960: 'Before 1960',
      between_1960_and_1975: '1960-1975',
      between_1976_and_1990: '1976-1990',
      after_1990: 'After 1990',
      low_energy_house: 'Low energy',
      passive_house: 'Passive',
    },
    renovations: {
      name: 'Has there been renovations?',
      new_windows: 'New windows',
      insulation_top_ceiling: 'Insulation roof/top ceiling',
      insulation_basement_ceiling: 'Insulation basement ceiling',
      insulation_facade: 'Insulation facade',
      controlled_living_space_ventilation: 'Controlled living space ventilation',
    },
    number_of_people: 'How many people are living in the house?',
    tell_about_your_heating: 'Tell us about your heating',
    more_info_about_hot_water: 'More information about your hot water',
    more_info_about_electricity: 'More information about your electricity',
    heating_system: {
      name: 'Heating system',
      natural_gas: 'Natural gas',
      district_heating_biomass: 'District heating biomass',
      district_heating_standard: 'District heating standard',
      wood_chip: 'Wood chip',
      wood_pellets: 'Wood pellets',
      firewood: 'Firewood',
      coal: 'Coal',
      heating_oil: 'Heating oil',
      electric: 'Electric',
      heat_pump: 'Heating pump',
    },
    is_solar_heating_system: 'Is there a solar heating system?',
    solar_heating_system_type: {
      name: 'What kind of solar thermal do you have',
      heating_and_hot_water: 'For heating and hot water',
      only_hot_water: 'Only hot water',
    },
    age_of_heating_system: {
      name: 'How old is your heating-system',
      description: 'New technology means that the heater is less than 10 years old',
      less_than_10_years: 'New technology',
      between_10_and_20_years: 'Between 10 and 20  years',
      more_than_20_years: 'Older than  20 years',
    },
    temperature: {
      name: 'Which temperature do you have?',
      description: 'Each degree increases the energy demand  about 6%',
      less_than_21: 'Less than 21',
      between_21_and_23: 'Between 21 and 23',
      more_than_23: 'More than 23',
    },
    hot_water_producer: {
      name: 'How are you producing hot water?',
      heating_system: 'With the heating-system ',
      electric_boiler: 'Electric boiler ',
      gas_heater: 'Gas heater',
      heat_pump: 'Heat pump',
    },
    amount_of_hot_water: {
      name: 'How much hot water do you need',
      description: 'Low (just showering) \n Medium (50l/day showering and bathing) \n High (only bathing)',
      low: 'Low',
      medium: 'Medium',
      hight: 'High',
    },
    is_devices_younger_than_10_years:
      'Are most of your devices like cooling- and washing-equipment, cooker, oven younger than 10 years?',
    power_consumers: {
      name: 'Which extraordinary power consumer do you have?',
      sauna: 'Sauna',
      outdoor_whirlpool: 'Outdoor whirlpool',
      swimming_pool: 'Swimming pool (heated)',
      air_condition: 'Air condition',
      waterbed: 'Waterbed',
    },
    is_solar_power_system: 'Do you have a PV system?',
    solar_power_system_size: 'What size is it?',
    solar_power_system_consuming_percentage: 'How high is the self-consumption in %?',
    number_of_economy_class_flights: 'Number of economy-class flights Vienna-New York (round trip)',
    number_stored_trees: 'A tree stores around 10kg of CO₂ per year',
    heating_result: {
      table_title: 'Heating result',
      specific_heating_demand: 'Specific heating demand',
      specific_heating_demand_description:
        'Describes the amount of heat required per square meter of heated gross floor area',
      heating_factor_kea: 'Heating Factor KEA',
      heating_factor_kea_description:
        'The KEA includes all energetic expenditures for production, transport and consumption of the heating medium.',
      heating_factor_ghg: 'Heating Factor GHG',
      heating_factor_ghg_description:
        'Indicates how many kg of CO₂ per kWh of heat are produced with the selected heating medium.',
      total_heating_demand: 'Total heating demand',
      total_heating_demand_description: 'Thermal energy that is necessary to heat the house to the desired temperature',
      primary_energy_for_heating: 'Primary energy for heating',
      primary_energy_for_heating_description:
        'This is the amount of energy in (kWh) that you need to heat the house, i.e. to cover the heating demand. A very strong influencing factor here is of course the efficiency of the heating system and the amount of energy that is required to produce the heating medium.',
      heating_ghg_emission: 'GHG emission heating',
      heating_ghg_emission_description: 'Total amount of CO₂ generated by your heating',
      heating_ghg_equivalent_car: 'This corresponds to kilometers with a petrol car',
      heating_ghg_equivalent_car_description: 'The basis is an amount of CO₂ of 150g / km',
      heating_ghg_equivalent_plane: 'Number of air travel that corresponds to this amount of CO₂',
      heating_ghg_equivalent_plane_description: 'Vienna - New York - Vienna flights (economy class)',
      heating_ghg_equivalent_trees: 'Number of trees that store this amount of CO₂',
      heating_ghg_equivalent_trees_description: 'A tree stores around 10kg of CO₂ per year',
      heating_costs: 'Heating-costs',
      heating_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    hot_water_result: {
      table_title: 'Hot water results',
      primary_energy_for_hot_water: 'Primary energy for hot water',
      primary_energy_for_hot_water_description:
        'This is the amount of energy in (kWh) that you need to produce hot water in your house',
      hot_water_ghg_emission: 'GHG emission',
      hot_water_ghg_emission_description: 'Total amount of CO₂ that results from hot water production',
      hot_water_ghg_equivalent_car: 'This corresponds to kilometers with a petrol car',
      hot_water_ghg_equivalent_car_description: 'The basis is an amount of CO₂ of 150g / km',
      hot_water_ghg_equivalent_plane: 'Number of air travel that corresponds to this amount of CO₂',
      hot_water_ghg_equivalent_plane_description: 'Vienna - New York - Vienna flights (economy class)',
      hot_water_ghg_equivalent_trees: 'Number of trees that store this amount of CO₂',
      hot_water_ghg_equivalent_trees_description: 'A tree stores around 10kg of CO₂ per year',
      hot_water_costs: 'HotWater costs',
      hot_water_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    electricity_result: {
      table_title: 'Electricity results',
      total_power_consumption_per_year: 'Your total power consumption per year',
      total_power_consumption_per_year_description: 'Total power consumption for your household',
      total_power_production_per_year: 'Your total power production per year',
      total_power_production_per_year_description:
        'Amount of electricity that is produced on average by your PV system',
      own_consumption: 'Annual self-consumption',
      own_consumption_description: 'Estimated self-consumption of the PV electricity',
      power_consumption_from_energy: 'Amount of electricity that is purchased from the grid operator',
      power_consumption_from_energy_description:
        'This value can also be negative if more PV electricity is produced than electricity is consumed',
      electrical_feed_in_to_grid: 'Amount of electricity that is fed into the grid',
      electrical_feed_in_to_grid_description: 'Surplus electricity that can be sold',
      ghg_savings_solar_power_system: 'CO₂ savings through PV systems',
      ghg_savings_solar_power_system_description:
        'The basis here is the amount of CO₂ that is generated on average when generating electricity in Europe',
      ghg_balance_of_power_consumption: 'CO₂ balance of your electricity consumption',
      ghg_balance_of_power_consumption_description:
        'This amount of CO₂ results from your electricity consumption when generating electricity in Austria',
      electricity_ghg_equivalent_car: 'This corresponds to kilometers with a petrol car',
      electricity_ghg_equivalent_car_description: 'The basis is an amount of CO₂ of 150g / km',
      electricity_ghg_equivalent_plane: 'Number of air travel that corresponds to this amount of CO₂',
      electricity_ghg_equivalent_plane_description: 'Vienna - New York - Vienna flights (economy class)',
      electricity_ghg_equivalent_trees: 'Number of trees that store this amount of CO₂',
      electricity_ghg_equivalent_trees_description: 'A tree stores around 10kg of CO₂ per year',
      solar_power_system_helps_to_save: 'Your PV system saves you annually',
      solar_power_system_helps_to_save_description:
        'Based on the green electricity tariff subsidy, the subsidy tariff in 2021 is 7.06 cents / kWh',
      total_electricity_costs: 'Total Electricity costs',
      total_electricity_costs_description: 'based avg. Prices for your heating-system in Austria',
    },
    energy_and_co2_balance_result: {
      table_title: 'The energy & CO2  balance of your house',
      total_energy_balance_per_year: 'Your total annual energy requirement',
      total_energy_balance_per_year_description: 'How many kWh do you need for heating, hot water and electricity',
      energy_ghg_balance_per_year: 'Your GHG balance per year',
      energy_ghg_balance_per_year_description: 'Amount of CO₂ that is produced as a result',
      energy_ghg_equivalent_car: 'This corresponds to kilometers with a petrol car',
      energy_ghg_equivalent_car_description: 'The basis is an amount of CO₂ of 150g / km',
      energy_ghg_equivalent_plane: 'Number of air travel that corresponds to this amount of CO₂',
      energy_ghg_equivalent_plane_description: 'Vienna - New York - Vienna flights (economy class)',
      energy_ghg_equivalent_trees: 'Number of trees that store this amount of CO₂',
      energy_ghg_equivalent_trees_description: 'A tree stores around 10kg of CO₂ per year',
      total_energy_costs: 'Total energy costs',
      total_energy_costs_description: 'based on avg. prices in Austria',
    },
    new_windows_number: 'How many windows should be changed',
    number_of_extra_large_windows: 'How many extra large windows (balcony-doors) do you have?',
    number_of_lift_and_slide_doors: 'How many lift and slide doors should there be?',
    heating_system_for_renovation: {
      name: 'Which heating system do you want to use in the future?',
      hot_water_with_new_heating_system: 'Should hot-water be produced with new heating-system?',
      hot_water_with_new_heating_system_description: 'Hot water be produced with new heating system description',
      district: 'District Heating',
      gas: 'Gas heating',
      pellet: 'Pellet heating',
      heat_pump: 'Heat pump',
    },
    roof_renewal: {
      description: ' ',
      name: 'Which roof-type does the house have?',
      gable: 'Gable Roof',
      flat: 'Flat Roof',
      hipped: 'Hipped Roof',
      gable_description: 'The area of an average steep pitched roof without dormer is calculated.',
      flat_description: 'The area of a flat roof with a small overhang is calculated.',
      hipped_description: 'The area of an average steep hipped roof without dormer is calculated.',
      area: 'Estimated roof area (m²)',
    },
    facade_insulation: {
      name: 'Facade insulation',
      description: ' ',
      type: {
        name: 'What type of house do you live in?',
        single_house: 'Detached house for one family',
        double_house: 'Double House',
        middle_house: 'Middle House',
        single_house_description: 'All sides of the house must be insulated.',
        double_house_description: 'Only 3 sides of the house need to be insulated',
        middle_house_description: 'Only 2 sides of the house need to be insulated',
      },
      area: 'Estimated facade area (m²)',
    },
    house_area: 'Usable living area (m²)',
    insulation_top_ceiling_area: 'Area of the top floor ceiling in m²',
    insulation_basement_ceiling_area: 'Insulation basement ceiling area',
    sanitary_renovation_area: 'How many m² do the new sanitary areas have in total?',
    number_of_bathrooms_for_renovation: 'How many bathrooms should the house have?',
    number_of_toilets_for_renovation: 'How many toilets should the house have?',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    number_of_interior_doors_for_renovation: 'How many interior doors should be replaced?',
  },

  renovation_house_features: {
    solar_heating: 'Solar heating',
    solar_heating_subitem: 'Solar heating',
    solar_heating_description: 'The solar system is used to heat water and stores it in a boiler.',
    solar_heating_equipment_type_description:
      'The <b> standard version </b> is used for hot water, in the more expensive categories the system is also used for heating support and has higher quality components.',
    domestic_ventilation: 'Active living space ventilation',
    domestic_ventilation_subitem: 'Active living space ventilation',
    domestic_ventilation_description:
      'Active living space ventilation ensures automatic and energy-efficient air exchange through air inlets and outlets.',
    domestic_ventilation_equipment_type_description:
      'Differences arise in the control options, the filters and the quality of the components.',
    insulation_top_ceiling: 'Insulation top ceiling',
    insulation_top_ceiling_subitem: 'Insulation top ceiling',
    insulation_top_ceiling_description:
      'A cost-effective variant instead of the roof truss renovation only takes into account the application of an insulating material to the top floor ceiling.',
    insulation_top_ceiling_equipment_type_description:
      '<b> Standard design </b> are loosely laid insulation panels with limited accessibility. In the higher categories, solid and sustainably produced insulation boards are used.',
    insulation_basement_ceiling: 'Insulation basement ceiling',
    insulation_basement_ceiling_subitem: 'Insulation basement ceiling',
    insulation_basement_ceiling_description:
      'In an unheated basement, the ceiling should be well insulated to minimize heat loss.',
    insulation_basement_ceiling_equipment_type_description:
      'There are differences in the quality and (sustainable) execution of the insulation.',
    energy_certificate: 'Energy certificate',
    energy_certificate_description:
      'An energy pass is issued by certified bodies and is required for many funding applications.',
    reserves_for_unexpected_costs: 'Reserve for unexpected costs',
    reserves_for_unexpected_costs_description:
      'In the case of renovations in particular, we recommend a financial reserve of at least 10% for unforeseen additional expenses.',
    kitchen: 'New Kitchen',
    kitchen_subitem: 'New Kitchen',
    kitchen_description: ' ',
    kitchen_equipment_type_description:
      'The difference in the categories results from the number and design of the boxes, surfaces and devices. Bear in mind that a kitchen is usually exposed to high levels of stress from heat, moisture, and frequent opening and closing, and must therefore be of a correspondingly high quality in order to last for many years. Typical price drivers are stone slabs, pharmacy cabinets, steamers, wine cabinets, etc., which are not included in the <b> standard version </b>.',
    air_condition: 'Installation of an air conditioning system for the entire living area',
    air_condition_subitem: 'Installation of an air conditioning system for the entire living area',
    air_condition_description:
      'A permanently installed air conditioning system consists of an outdoor unit with corresponding air outlets in the living rooms.',
    air_condition_equipment_type_description:
      'Differences in the categories result from the separate control and programming in the different rooms (multi-split systems), the noise level and ultimately also the energy consumption.',
    sanitary_renovation: 'New bathrooms and toilet',
    sanitary_renovation_subitem: 'New bathrooms and toilet',
    sanitary_renovation_description:
      'The renewal of all water and drainage pipes, the complete replacement of the old sanitary equipment, as well as new tiling are calculated. The calculation is based on the m² (usable area) of the sanitary areas and the number of bathrooms or toilets.',
    sanitary_renovation_equipment_type_description:
      'Differences in the categories are price drivers. e.g. large-format tiles, high-quality bathroom furniture, underfloor heating, a floor-to-ceiling shower, a free-standing bathtub, etc.',
    new_flooring: 'New flooring',
    new_flooring_subitem: 'New flooring',
    new_flooring_description:
      'Old floors have to be removed and new ones laid. Old but solid parquet floors are sanded, repaired and repainted.',
    new_flooring_equipment_type_description:
      'In the <b> standard variant </b>, inexpensive coverings in hardware store quality are assumed. With the <b> Premium variant </b>, parquet floors or inexpensive tiles are laid. You can find natural stone or high-quality natural wood floors in the <b> superior variant </b>.',
    renovation_walls_and_doors: 'Renovation of walls and interior doors',
    renovation_walls_and_doors_subitem: 'Renovation of walls and interior doors',
    renovation_walls_and_doors_description:
      'The square meters refer to the living space - the fundamental renovation or renewal of the interior walls and interior doors is calculated.',
    renovation_walls_and_doors_equipment_type_description:
      'Differences result primarily from room heights, specific designs such as stucco elements, wallpaper, etc. Interior doors in the <b> standard design </b> often have poor sound insulation and a plastic surface.',
    renewal_of_electric_installation: 'Renewal of electric installation',
    renewal_of_electric_installation_subitem: 'Renewal of electric installation',
    renewal_of_electric_installation_description:
      'Dismantling and renewal of the piping and wiring, the fuses, the sockets and the switches.',
    renewal_of_electric_installation_equipment_type_description:
      'In the <b> standard variant </b>, the renewal of the control cabinet including fuses is taken into account. With the <b> Premium variant </b>, sockets and switches are also swapped. The <b> superior variant </b>, for example, offers a BUS system that can be used to control heating, lighting and shading.',
    renewal_of_front_door: 'Renewal of front door',
    renewal_of_front_door_subitem: 'Renewal of front door',
    renewal_of_front_door_description: 'The basis is a modern security door including installation.',
    renewal_of_front_door_equipment_type_description:
      'Differences arise in the size and design of the door (material and technology such as fingerprint sensor).',
    new_windows: 'New windows',
    new_windows_subitem: 'New windows',
    new_windows_description:
      'The main price drivers here are the size of the windows, the design (plastic, wood or wood / aluminum) and additional equipment such as sun protection, fall protection, etc.',
    new_windows_equipment_type_description:
      'The <b> standard variant </b> includes the disposal of old windows and the installation of inexpensive plastic windows. The <b> premium variant </b> consists of high-quality plastic-aluminum windows and partial sun protection. The <b> superior variant </b> contains high-quality wood-aluminum windows with continuous shading, which can also be controlled via an app (smartphone, etc.).',
    carport: 'Carport',
    carport_subitem: 'Carport',
    carport_description: ' ',
    carport_equipment_type_description:
      'The <b> standard version </b> has a simple construction. With the <b> Premium variant </b>, a massive design including extensions (e.g. space for garbage cans, bicycles, etc.) is taken into account. The <b> superior variant </b> stands out due to its appearance (e.g. lighting, pavement, etc.).',
    garage: 'Garage',
    garage_subitem: 'Garage',
    garage_description: ' ',
    garage_equipment_type_description:
      'The <b> standard garage </b> is approx. 6 m long and approx. 3 m wide for each parking space and is inexpensive. The <b> premium version </b> offers additional freedom of movement and space for bicycles. The <b> superior variant </b> stands out due to its optics (e.g. lighting, garage door, etc.).',
    renovation_walls_and_interior_doors: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_subitem: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_description:
      'Die Quadratmeter beziehen sich auf die Wohnfläche - die grundlegende Sanierung bzw. Erneuerung der Innenwände, Decken und Innentüren wird kalkuliert',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_subitem: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description:
      'The existing heating will be dismantled and replaced by a new one. When switching to a new heating system, e.g. from oil to pellet heating, additional costs may arise. In many cases there are funding opportunities for this - inform yourself about this!',
    renewal_of_heating_system_equipment_type_description:
      'With the <b> standard variant </b>, only the heating including all necessary pumps is exchanged. The <b> Premium variant </b> also includes new radiators and modern controls in addition to heating. With the <b> Superior variant </b>, the costs for switching to underfloor heating are also taken into account, and the system can also be controlled via an app (smartphone, etc.).',
    roof_renewal: 'Roof renewal',
    roof_renewal_subitem: 'Roof renewal',
    roof_renewal_description:
      'The basis is the removal of the old roof covering and a new roof covering including insulation based on the existing roof structure.',
    roof_renewal_equipment_type_description:
      'The <b> standard variant </b> includes the renewal of the roof covering including insulation based on the existing roof structure. With the <b> Premium variant </b>, a new roof sub-layer and the renewal of all plumbing work are also taken into account. The <b> superior variant </b> includes a complete replacement of the existing roof structure, including insulation and a homely interior design. ',
    facade_insulation: 'Facade insulation',
    facade_insulation_subitem: 'Facade insulation',
    facade_insulation_description:
      'The basis is the insulation of the external walls, the facade is given a new external plaster. Please note that a possible replacement of the windows should also be planned.',
    facade_insulation_equipment_type_description:
      'The <b> standard version </b> uses inexpensive insulation and plastering materials as well as simple window sills. The <b> Premium variant </b> includes higher quality materials (insulation, external plaster, window sills). In the <b> superior variant </b>, ecological insulation materials (e.g. hemp) are combined with high-quality exterior plaster and window sills.',
    alarm_system: 'Alarm system',
    alarm_system_subitem: 'Alarm system',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description:
      'Differences result from the type and number of sensors (e.g. reed contacts, motion detectors, etc.), the control options (e.g. app) and the additional installation of cameras.',
    solar_power_system: 'Solar power system',
    solar_power_system_subitem: 'Solar power system',
    solar_power_system_description:
      'A PV system makes you somewhat independent. Often there is also public funding for this - inform yourself about this!',
    pool: 'Pool',
    pool_subitem: 'Pool',
    pool_description: 'Massive pool including earthworks or a natural pond for swimming in a size of around 8x4m.',
    pool_equipment_type_description:
      'There are differences in the design and the water treatment systems, heating and roofing (e.g. in a pool).',
    winter_garden: 'Winter garden',
    winter_garden_subitem: 'Winter garden',
    winter_garden_description: 'Includes the extension of a winter garden including flooring.',
    winter_garden_equipment_type_description:
      'A <b> standard winter garden </b> is a closed terrace. The <b> premium version </b> also offers ventilation and shading. The <b> superior variant </b> is a fully-fledged living space that can be used all year round.',
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_subitem: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
  },

  apartment_wizard: {
    cost_apartment: 'How much does it cost?',
    main_residence: 'Will it be your main residence?',
    additional_costs: "Additional costs are - change it if we haven't calc. them right:",
    property_transfer_tax: 'Property transfer tax (3.5%)',
    land_register_fee: 'Land register fee (1.1%)',
    notary_fee: "Notary's fee (1%)",
    broker_commission: 'Broker commission (3.6%)',
    subsidized_condominium: 'Subsidized condominium',
    total_cost_apartment: 'Total cost apartment:',

    full_address: 'Full address (city, street, building)',
    floor_number: 'Floor number',
    is_rooftop: 'Is it in the rooftop?',
    apartment_area: 'How many m² does the apartment have',
    condition_of_apartment: {
      label: 'Condition of the apartment',
      not_renovated: 'First occupancy / not renovated',
      fully_renovated: 'Fully renovated',
      partly_renovated: 'Partly refurbished',
      modernized: 'Modernized',
    },
    construction_year: 'Construction-year of the building',
    loggia: 'Loggia',
    loggia_area: 'm²',
    terrace: 'Terrace',
    terrace_area: 'm²',
    garden: 'Garden',
    garden_area: 'm²',
    balcony: 'Balcony',
    elevator: 'Elevator',
    basement: 'Basement',
    parking: {
      name: 'Parking',
      underground_car_park: 'Underground car park',
      outdoor_parking_space: 'Outdoor parking space',
      garage: 'Garage',
      none: 'None',
    },
    heating_system: {
      name: 'Heating system',
      central_or_district: 'Central heating or district heating',
      gas: 'Gas heating',
      electric: 'Electric heating',
      wood_coal: 'Wood-coal heating',
    },
    renovations_or_additional_investments: 'Renovations or additional investments',
    sanitary_renovation_area: 'How many m² do the sanitary areas have in total?',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    new_windows_number: 'How many windows will be exchanged',
  },

  apartment_features: {
    cost_apartment: 'Apartment cost',
    cost_apartment_description: 'Enter the price without taxes and fees.',
    taxes_and_fees: 'Taxes and fees',
    taxes_and_fees_description:
      'We calculate the incidental costs incurred in Austria, but these can be individually adjusted here.',
    property_transfer_tax: 'Property transfer tax (3.5%)',
    property_transfer_tax_description: 'Statutory fee based on the purchase price.',
    land_register_fee: 'Land register fee (1,1%)',
    land_register_fee_description: 'Statutory fee based on the purchase price.',
    notary_fee: 'Notary´s fee (1%)',
    notary_fee_description: 'Notary fees can also be reduced through good preparation and negotiation.',
    broker_commission: 'Broker commission (3,6%)',
    broker_commission_description: 'When it comes to the broker`s commission, it is worth negotiating a bit!',
    reserves_for_unexpected_costs: 'Reserve for unexpected costs',
    reserves_for_unexpected_costs_description:
      'In the case of renovations in particular, we recommend a financial reserve of at least 10% for unforeseen additional expenses.',
    renovation_walls_and_interior_doors_title: 'Renovation of walls an interior doors',
    renovation_walls_and_interior_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_interior_doors_description:
      'The basic renovation of the interior walls, ceilings and interior doors is calculated here.',
    renovation_walls_and_interior_doors_equipment_type_description:
      'Differences result primarily from room heights, specific designs such as stucco elements, wallpaper, etc. - interior doors in the <b> standard design </b> often have poor sound insulation and stability.',
    kitchen: 'New kitchen',
    kitchen_description: ' ',
    kitchen_equipment_type_description:
      'The difference in the categories results from the number and design of the boxes, surfaces and devices. Bear in mind that a kitchen is usually exposed to high levels of stress from heat, moisture, and frequent opening and closing, and must therefore be of a correspondingly high quality in order to last for many years. Typical price drivers are stone slabs, pharmacy cabinets, steamers, wine cabinets, etc., which are not included in the <b> standard version </b>.',
    new_flooring: 'Parquet, carpet and / or tiled floors',
    sanitary_renovation_title: 'New bathrooms and toilet',
    sanitary_renovation: 'Basic renovation of the bathroom and toilet',
    sanitary_renovation_description:
      'The renewal of all water and drainage pipes, the complete replacement of the old sanitary equipment, as well as new tiling are calculated.',
    sanitary_renovation_equipment_type_description:
      'Differences in the categories are price drivers. e.g. large-format tiles, high-quality bathroom furniture, underfloor heating, a floor-to-ceiling shower, a free-standing bathtub, etc.',
    aircondition: 'Installation of an aircondition for the whole apartment',
    aircondition_description:
      'A permanently installed air conditioning system consists of an outdoor unit with corresponding air outlets in the living rooms.',
    aircondition_equipment_type_description:
      'Differences in the categories result from the separate control and programming in the different rooms (multi-split systems), the noise level and ultimately also the energy consumption',
    new_flooring_title: 'Renewal of the floors',
    new_flooring_description:
      'Old floors have to be removed and new ones laid. Old but solid parquet floors are sanded, repaired and repainted.',
    new_flooring_equipment_type_description:
      'In the <b> standard variant </b>, inexpensive coverings in hardware store quality are assumed. With the <b> Sustainable variant </b>, parquet floors or inexpensive tiles are laid. You can find natural stone or high-quality natural wood floors in the <b> exclusive version </b>.',
    renovation_walls_and_doors: 'Renovation of the walls, ceilings and interior doors',
    renovation_walls_and_doors_description:
      'The basic renovation or renewal of the interior walls, ceilings and interior doors is calculated.',
    renovation_walls_and_doors_equipment_type_description:
      'Differences arise primarily from room heights, specific designs such as stucco elements, wallpaper and the like.',
    renewal_of_electric_installation: 'Renewal of the electrical installation',
    renewal_of_electric_installation_description:
      'Dismantling and renewal of the piping and wiring, the fuses, the sockets and the switches.',
    renewal_of_electric_installation_equipment_type_description:
      'The main price drivers here are the type and number of plugs and switches or, in the Exclusive area, a bus system.',
    new_windows_title: 'New windows',
    new_windows: 'New windows',
    new_windows_description: 'Exchange of the old windows for new ones including the necessary plastering work.',
    new_windows_stove_description: 'Exchange of the old windows for new ones including the necessary plastering work.',
    new_windows_equipment_type_description:
      'The main price drivers here are the size of the windows, the design (plastic, wood or wood / aluminum) and additional equipment such as sun protection, fall protection, etc.',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description:
      'The existing heating will be dismantled and replaced by a new one (gas heating, district heating or house central heating).',
    renewal_of_heating_system_equipment_type_description:
      'The main price drivers here are underfloor heating, high-quality radiators and control options via app.',
  },
  buying_house_features: {
    solar_heating: 'Solar heating',
    solar_heating_subitem: 'Solar heating',
    solar_heating_description: 'Solar system for hot water preparation or heating support',
    solar_heating_equipment_type_description:
      'The <b> standard version </b> is used for hot water, in the more expensive categories the system is also used for heating support and has higher quality components.',
    domestic_ventilation: 'Domestic ventilation',
    domestic_ventilation_subitem: 'Domestic ventilation',
    domestic_ventilation_description:
      'Active living space ventilation ensures automatic and energy-efficient air exchange through air inlets and outlets.',
    domestic_ventilation_equipment_type_description:
      'Differences arise in the control options, the filters and the quality of the components.',
    insulation_top_ceiling: 'Insulation top ceiling',
    insulation_top_ceiling_subitem: 'Insulation top ceiling',
    insulation_top_ceiling_description:
      'A more cost-effective variant instead of the roof truss renovation only takes into account the application of an insulating material to the top floor ceiling.',
    insulation_top_ceiling_equipment_type_description:
      '<b> Standard design </b> are loosely laid insulation panels with limited accessibility. In the higher categories, solid and sustainably produced insulation boards are used.',
    insulation_basement_ceiling: 'Insulation basement ceiling',
    insulation_basement_ceiling_subitem: 'Insulation basement ceiling',
    insulation_basement_ceiling_description:
      'In an unheated basement, the ceiling should be well insulated to minimize heat loss.',
    insulation_basement_ceiling_equipment_type_description:
      'There are differences in the quality and (sustainable) execution of the insulation.',
    energy_certificate: 'Energy certificate',
    energy_certificate_description:
      'An energy pass is issued by certified bodies and is required for many funding applications.',
    reserves_for_unexpected_costs: 'Reserves for unexpected costs',
    reserves_for_unexpected_costs_description:
      'In the case of renovations in particular, we recommend a financial reserve of at least 10% for unforeseen additional expenses.',
    cost_house: 'House costs',
    cost_house_description: 'Please enter the price without taxes and fees.',
    taxes_and_fees: 'Taxes and fees',
    taxes_and_fees_description:
      'We calculate the incidental costs incurred in Austria, but these can be individually adjusted here.',
    property_transfer_tax: 'Property transfer tax (3.5%)',
    property_transfer_tax_description: 'This is a statutory fee that is based on the purchase price.',
    land_register_fee: 'Land register fee (1.1%)',
    land_register_fee_description: 'This is a legal fee based on the purchase price.',
    notary_fee: 'Notary´s fee (1.0%)',
    notary_fee_description: 'Notary fees can also be reduced through good preparation and negotiation.',
    broker_commission: 'Broker commission (3.6%)',
    broker_commission_description: 'this is the maximum fee in Austria - negotiating is allowed here.',
    kitchen: 'New Kitchen',
    kitchen_subitem: 'New Kitchen',
    kitchen_description: ' ',
    kitchen_equipment_type_description:
      'The difference in the categories results from the number and design of the boxes, surfaces and devices. Bear in mind that a kitchen is usually exposed to high levels of stress from heat, moisture, and frequent opening and closing, and must therefore be of a correspondingly high quality in order to last for many years. Typical price drivers are stone slabs, apothecary cabinets, steamers, wine cabinets and the like. which are not included in the <b> "standard calculation" </b>.',
    air_condition: 'Installation of an aircondition for the whole apartment',
    air_condition_subitem: 'Installation of an aircondition for the whole apartment',
    air_condition_description:
      'A permanently installed air conditioning system consists of an outdoor unit with corresponding air outlets in the living rooms.',
    air_condition_equipment_type_description:
      'Differences in the categories result from the separate control and programming in the different rooms (multi-split systems), the noise level and ultimately also the energy consumption.',
    sanitary_renovation: 'New bathrooms and toilet',
    sanitary_renovation_subitem: 'New bathrooms and toilet',
    sanitary_renovation_description:
      'The renewal of all water and drainage pipes, the complete replacement of the old sanitary equipment, as well as new tiling are calculated. The calculation is based on the m² (usable area) of the sanitary areas and the number of bathrooms or toilets.',
    sanitary_renovation_equipment_type_description:
      'Differences in the categories are price drivers. e.g. large-format tiles, high-quality bathroom furniture, underfloor heating, a floor-to-ceiling shower, a free-standing bathtub, etc.',
    new_flooring: 'New flooring',
    new_flooring_subitem: 'New flooring',
    new_flooring_description:
      'Old floors have to be removed and new ones laid. Old but solid parquet floors are sanded, repaired and repainted.',
    new_flooring_equipment_type_description:
      'In the <b> standard variant </b>, inexpensive coverings in hardware store quality are assumed. With the <b> sustainable version </b>, parquet floors or inexpensive tiles are laid. You can find natural stone or high-quality natural wood floors in the <b> exclusive version </b>.',
    renovation_walls_and_doors: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_subitem: 'Renovation of walls an interior doors',
    renovation_walls_and_doors_description:
      'The square meters refer to the living space - the fundamental renovation or renewal of the interior walls, ceilings and interior doors is calculated.',
    renovation_walls_and_doors_equipment_type_description:
      'Differences result primarily from room heights, specific designs such as stucco elements, wallpaper, etc. Interior doors in the <b> standard design </b> often have poor sound insulation and a plastic surface.',
    renewal_of_electric_installation: 'Renewal of electric installation',
    renewal_of_electric_installation_subitem: 'Renewal of electric installation',
    renewal_of_electric_installation_description:
      'Dismantling and renewal of the piping and wiring, the fuses, the sockets and the switches.',
    renewal_of_electric_installation_equipment_type_description:
      'In the <b> standard variant </b>, the renewal of the control cabinet including fuses is taken into account. With the <b> sustainable version </b>, sockets and switches are also swapped. The <b> exclusive version </b>, for example, offers a BUS system that can be used to control heating, lighting and shading.',
    renewal_of_front_door: 'Renewal of front door',
    renewal_of_front_door_subitem: 'Renewal of front door',
    renewal_of_front_door_description: 'The basis is a modern security door including installation.',
    renewal_of_front_door_equipment_type_description:
      'Differences arise in the size and design of the door (material and technology such as fingerprint sensor).',
    new_windows: 'New windows',
    new_windows_subitem: 'New windows',
    new_windows_description:
      'The main price drivers here are the size of the windows, the design (plastic, wood or wood / aluminum) and additional equipment such as sun protection.',
    new_windows_equipment_type_description:
      'The <b> standard variant </b> includes the disposal of old windows and the installation of inexpensive plastic windows. The <b> sustainable version </b> consists of high-quality plastic-aluminum windows and partial sun protection. The <b> exclusive version </b> contains high-quality wood-aluminum windows with continuous shading, which can also be controlled via an app (smartphone, etc.).',
    carport: 'Carport',
    carport_subitem: 'Carport',
    carport_description: ' ',
    carport_equipment_type_description:
      'The <b> standard version </b> has a simple construction. With the <b> sustainable version </b>, a massive design including extensions (e.g. space for garbage cans, bicycles, etc.) is taken into account. The <b> exclusive version </b> stands out due to its appearance (e.g. lighting, pavement, etc.).',
    garage: 'Garage',
    garage_subitem: 'Garage',
    garage_description: ' ',
    garage_equipment_type_description:
      'The <b> standard garage </b> is approx. 6 m long and approx. 3 m wide for each parking space and is inexpensive. The <b> sustainable version </b> offers additional freedom of movement and space for bicycles. The <b> exclusive version </b> stands out due to its appearance (e.g. lighting, garage door, etc.).',
    renovation_walls_and_interior_doors: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_subitem: 'Renovation of walls and interior doors',
    renovation_walls_and_interior_doors_description:
      'The basic renovation of the interior walls, ceilings and interior doors is calculated here.',
    renewal_of_heating_system: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_subitem: 'Renewal of heating system (inc. Radiators)',
    renewal_of_heating_system_description:
      'The existing heating will be dismantled and replaced by a new one. When switching to a new heating system, e.g. from oil to pellet heating, additional costs may arise. In many cases there are funding opportunities for this - find out more!',
    renewal_of_heating_system_equipment_type_description:
      'With the <b> standard variant </b>, only the heating including all necessary pumps is exchanged. The <b> sustainable version </b> also includes new radiators and modern controls in addition to heating. With the <b> exclusive version </b>, the costs for switching to underfloor heating are also taken into account, and the system can also be controlled via an app (smartphone, etc.).',
    roof_renewal: 'Roof renewal',
    roof_renewal_subitem: 'Roof renewal',
    roof_renewal_description:
      'The basis is the removal of the old roof covering and a new roof covering including insulation based on the existing roof structure.',
    roof_renewal_equipment_type_description:
      'The <b> standard variant </b> includes the renewal of the roof covering including insulation based on the existing roof structure. With the <b> sustainable version </b>, a new roof sub-layer and the renewal of all plumbing work are also taken into account. The <b> exclusive version </b> includes a complete replacement of the existing roof structure, including insulation and a homely interior. ',
    facade_insulation: 'Facade insulation',
    facade_insulation_subitem: 'Facade insulation',
    facade_insulation_description:
      'The basis is the insulation of the external walls, the facade is given a new external plaster. Please note that a possible replacement of the windows should also be planned.',
    facade_insulation_equipment_type_description:
      'The <b> standard version </b> uses inexpensive insulation and plastering materials as well as simple window sills. The <b> sustainable variant </b> includes higher quality materials (insulation, external plaster, window sills). In the <b> exclusive variant </b>, ecological insulation materials (e.g. hemp) are combined with high-quality exterior plaster and window sills.',
    alarm_system: 'Alarm system',
    alarm_system_subitem: 'Alarm system',
    alarm_system_description: ' ',
    alarm_system_equipment_type_description:
      'Differences result from the type and number of sensors (e.g. reed contacts, motion detectors, etc.), the control options (e.g. app) and the additional installation of cameras.',
    solar_power_system: 'Solar power system',
    solar_power_system_subitem: 'Solar power system',
    solar_power_system_description:
      'A PV system makes you somewhat independent. Often there is also public funding for this - find out more!',
    pool: 'Pool',
    pool_subitem: 'Pool',
    pool_description: 'Massive pool including earthworks or a natural pond for swimming in a size of around 8x4m.',
    pool_equipment_type_description:
      'There are differences in the design and the water treatment systems, heating and roofing (e.g. in a pool).',
    winter_garden: 'Winter garden',
    winter_garden_subitem: 'Winter garden',
    winter_garden_description: 'Includes the extension of a winter garden including flooring.',
    winter_garden_equipment_type_description:
      'A <b> standard winter garden </b> is a closed terrace. The <b> sustainable version </b> also offers ventilation and shading. The <b> exclusive version </b> is a fully-fledged living space that can be used all year round.',
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_subitem: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider to install a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
  },

  buying_house_wizard: {
    cost_house: 'How much does it cost?',
    main_residence: 'Will it be your main residence?',
    addition_information:
      'For your individual loan-offer we´ll do a valution of your apartment. Therfore we need some information!',
    title: 'Detailed Planning',
    second_step_house_title: 'Tell us more about your house',
    total_cost_house: 'Total cost house:',
    additional_costs: "Additional costs are - change it if we haven't calculated them right:",
    project_name: 'Project name',
    budget: 'Own funds (€)',
    zip_code: 'ZIP code',
    property_transfer_tax: 'Property transfer tax (3.5%)',
    land_register_fee: 'Land register fee (1.1%)',
    notary_fee: "Notary's fee (1%)",
    broker_commission: 'Broker commission (3.6%)',
    full_address: 'Full address (city, street, building)',
    land_area: 'How many m² does the land have',
    house_area: 'How many m² does the house have',
    floors: 'Number of floors (without basement)',
    construction_year: 'Construction year of the house?',
    year_of_outer_wall_renovation: 'Year of outer wall renovation',
    roof_renewed: 'Has the roof been renewed meanwhile?',
    year_of_roof_renewal: 'Year of roof renewal?',
    renovation_outer_wall: {
      name: 'Has the outer wall of the building been renovated?',
      no_renovation: 'No renovation',
      new_plastering_and_painting: 'New plastering & painting',
      thermal_insulation: 'Thermal insulation',
    },
    house_type: {
      name: 'How is the house built?',
      solid_construction: 'Solid construction',
      prefabricated_house: 'Prefabricated house',
      timber_house: 'Timber house',
    },
    category: {
      name: 'Which category of house do you want to buy?',
      detached_house: 'Detached house',
      townhouse: 'Townhouse',
      semi_detached_house: 'Semi detached house',
    },
    basement: {
      name: 'Does the house have a basement?',
      full_basement: 'Full basement',
      partially_basement: 'Partially basement',
      no_basement: 'No basement',
    },
    condition_of_house: {
      name: 'Condition of the house',
      first_occupancy: 'First occupancy',
      fully_renovated: 'Fully renovated',
      partly_renovated: 'Partly renovated',
      renovation_needed: 'Renovation needed',
    },
    number_of_toilets: 'How many toilets does the house have?',
    number_of_bathrooms: 'How many bathrooms does the house have?',
    year_of_renovation: 'Year of the last renovation',
    parking: {
      name: 'Parking',
      garage: 'Garage',
      carport: 'Carport',
      outdoor_parking_space: 'Outdoor parking space',
      none: 'None',
    },
    heating_system: {
      name: 'Heating system',
      district: 'District Heating',
      gas: 'Gas heating',
      oil: 'Oil heating',
      pellet: 'Pellet heating',
      heat_pump: 'Heat pump',
      wood_or_coal: 'Wood or Coal heating',
      other: 'Other',
      electric: 'Electric heating',
    },
    heating_system_for_renovation: {
      name: 'Which heating system?',
      district: 'District Heating',
      gas: 'Gas heating',
      pellet: 'Pellet heating',
      heat_pump: 'Heat pump',
    },
    roof_renewal: {
      name: 'Roof renewal',
      description: ' ',
      type: {
        name: 'Which roof-type does the house have?',
        gable: 'Gable Roof',
        flat: 'Flat Roof',
        hipped: 'Hipped Roof',
        gable_description: 'The area of an average steep pitched roof without dormer is calculated.',
        flat_description: 'The area of a flat roof with a small overhang is calculated.',
        hipped_description: 'The area of an average steep hipped roof without dormer is calculated.',
      },
      area: 'Estimated roof area (m²)',
    },
    facade_insulation: {
      name: 'Facade insulation',
      description: ' ',
      type: {
        name: 'What type of house do you live in?',
        single_house: 'Detached house for one family',
        double_house: 'Double House',
        middle_house: 'Middle House',
        single_house_description: 'All sides of the house must be insulated.',
        double_house_description: 'Only 3 sides of the house need to be insulated',
        middle_house_description: 'Only 2 sides of the house need to be insulated',
      },
      area: 'Estimated facade area (m²)',
    },
    insulation_top_ceiling_area: 'Insulation top ceisling area',
    insulation_basement_ceiling_area: 'Insulation basement ceiling area',
    number_of_interior_doors_for_renovation: 'Number of interior doors for renovation',
    renovations_or_additional_investments: 'Are you planning renovations or additional investments?',
    additional_options: 'Additional options',
    number_of_car_ports: 'Number of Car ports',
    number_of_car_ports_description: 'how many parking spaces',
    number_of_garage_place: 'Number of Garage places',
    number_of_garage_place_description: 'for how many cars',
    solar_power_size_kwp: 'How big should the system be? (kWp)',
    solar_power_size_kwp_description: 'Please note that for 1 kWp ("Kilowatt peak") you need 7m² on your rooftop',
    solar_power_equipment_type: {
      name: 'Which equipment category should your PV system have',
      standard_description:
        'A Standard Solar Power System is mounted on your rooftop. The system is directly connected to your grid.',
      premium_description:
        'A <b> sustainable </b> PV system is based on an additional battery buffer and an app-based energy management system. The result is a higher self-use of the energy produced. In addition, the PV modules are integrated directly into the roof, which makes this version stand out from the <b> standard </b>.',
      superior_description:
        'In the case of an <b> Exclusive </b> PV system, for example solar roof tiles, which enable a particularly aesthetic look. Any additional costs that arise are compensated for by lower costs for the roofing',
    },
    sanitary_renovation_area: 'How many m² have the sanitary facilities',
    number_of_bathrooms_for_renovation: 'How many bathrooms should the house have?',
    number_of_toilets_for_renovation: 'How many toilets should the house have?',
    new_flooring_area: 'How many m² should be renewed',
    renovation_walls_and_interior_doors_area: 'How many m² should be renewed',
    new_windows_number: 'How many windows should be exchanged',
    number_of_lift_and_slide_doors: 'Number of list & slide doors?',
    number_of_extra_large_windows: 'How many extra large windows (balcony-doors) do you have?',
    blocks: {
      energyrelated_modernisation: 'Energy related Modernisation',
      energyrelated_modernisation_description:
        'These renovation measures make your house climate-friendly and you also reduce energy costs.',
      heap: ' ',
      heap_description: ' ',
    },
  },
  project_wizard: {
    first_step: {
      title: 'Tell us basic info about your new project',
      property_tools: 'Our services for everything to do with your property',
      property_tools_description:
        'The living calculator helps you to determine and plan the costs of buying, building or renovating and is the most innovative & fastest way to get a financing offer. We also offer all owners of a single-family home the opportunity to determine the CO2 footprint of their home as well as the renovation costs and savings potentials more easily than ever before! Try it out...',
      finance_tools: 'Finance tools',
      finance_tools_description: 'We also have tools to help you manage and plan your capital and finances',
    },
    map: 'Map',
    street_view: 'Street View',
    edit_values_btn: 'Edit values',
    step: 'Step {step}',
    first_step_title: 'Tell us basic info about your new project',
    project_name: 'Project name',
    project_type: 'Project type',
    project_type_hint: 'Currently we only have this project type, but we are working hard to offer you more',
    new_building: 'New single-family house',
    new_apartment: 'Buying apartment',
    buying_house: 'Buying family-house',
    land_estimation: 'The market value of this land is around:',
    land_estimation_per_square_meter: 'or {value} €/m²',
    land_estimation_note: '(this amount will be added to your own-funds)',
    budget: 'Own funds (€)',
    estimated_property_price: 'Estimated market value of your property (€)',
    total_own_funds: 'Total own funds',
    country: 'Country',
    austria: 'Austria',
    zip_code: 'ZIP code',
    full_address: 'Full address (city, street, building)',
    second_step: 'Second step',
    second_step_title: 'Now, describe your building location',
    land_area: 'Land area m²',
    additional_information: 'Additional information',
    old_building_demolition: 'Old building demolition',
    area_of_old_building: 'm² of old building',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access (no truck)',
    special_underground: 'Special underground',
    underground_type: 'Underground type',
    groundwater_level_high: 'Groundwater level high',
    rocky: 'Rocky',
    marshy: 'Marshy',
    third_step: 'Third step',
    third_step_title: 'What house do you want to build?',
    final_step: 'Final step',
    final_step_title: 'Which type of equipment would you like to use?',
    final_step_subtitle: 'This choice will strongly affect the project cost. \nYou can tune all the details later.',
    finish_project_wizard_button: 'Finish',
    equipment_type: 'Equipment type',
    living_space: 'Living space m²',
    number_of_floors: 'Number of floors',
    number_of_floors_hint: "Note that 'Number of floors' does not include a basement",
    number_of_floors_values: {
      '1': '1',
      '2': '2',
      '3': '3',
    },
    options: 'Options',
    controlled_domestic_ventilation: 'Controlled domestic ventilation',
    number_of_car_ports: 'Number of Car ports',
    number_of_car_ports_description: 'how many parking spaces',
    number_of_garage_place: 'Number of Garage places',
    number_of_garage_place_description: 'for how many cars',
    number_of_car_ports_format_value: {
      one: 'Car port: {{value}} place',
      other: 'Car port: {{value}} places',
    },
    number_of_garage_place_format_value: {
      one: 'Garage: {{value}} place',
      other: 'Garage: {{value}} places',
    },
    interior_equipment: 'Interior equipment',
    pool: 'Pool',
    fence: 'Fence',
    garden_design: 'Garden design (lawn, plants)',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    more_info: 'More info',
    superior: 'Superior',
    premium: 'Premium',
    standard: 'Standard',
    first_step_summary_title: 'Basic info',
    second_step_summary_title: 'Location',
    third_step_summary_title: 'House type',
    final_step_summary_title: 'Equipment type',
    equipment_type_update_prompt_title: 'Warning',
    equipment_type_update_prompt_message:
      'This action will override all the equipment types in categories. It will not affect the prices that were set manually',
    equipment_type_update_prompt_confirm_button_label: 'Ok',
    equipment_type_update_prompt_cancel_button_label: 'Cancel',
    equipment_type_content: {
      standard_title: 'Standard',
      standard_description:
        'By standard we mean houses with a slim, functional architecture and an inexpensive design. These include exterior walls with thermal insulation, interior walls with plasterboard, laminate floors, interior doors with decorative coatings, plastic windows without shading, etc.',
      premium_title: 'Premium',
      premium_description:
        'In addition to a more individual architecture, sustainable means higher quality and sustainable materials and products (which are produced in your own country if possible) in all areas. These include exterior walls with 50cm flat brick, solid interior walls, parquet floors, lacquered interior doors, plastic / aluminum windows with partial shading and insect protection, balconies, etc.',
      superior_title: 'Superior',
      superior_description:
        'Exclusive means an impressive individual architecture and very high quality of the materials. These include wood / aluminum windows including lift-and-slide doors with shading and insect protection, architectural details such as facade panels, large glass fronts, veneered interior doors, floors partly made of natural stone, modern electronics with BUS system, etc.',
    },
    has_property: 'Do you already own a property?',
    prefabricated: 'Prefabricated house',
    architect: 'Architect´s house',
    builder: 'Builder´s house',
    house_type_title: 'Your house will be a:',
    city: 'City',
    street: 'Street',
    building: 'Building',
    avg_property_price_sq_m: 'Average costs per m² in you region (€)',
    estimate_final_step_title: 'Which features do you want to include in your project?',
    next_button: 'Next Step',
    sole_plate_or_basement: 'Sole plate or Basement',
    basement: 'Basement',
    sole_plate: 'Sole plate',
    has_additional_features: 'Are you planning a renovation or additional investments?',
  },
  detailed_planning_wizard: {
    title: 'Detailed Planning',
    house_information_title: 'Information about your house',
    floors: 'Number of floors',
    living_space_by_floor: {
      0: 'Living space – ground floor (m²)',
      1: 'Living space – first floor (m²)',
      2: 'Living space – second floor (m²)',
    },
    equipment_type: 'Equipment type',
    total_living_space: 'Total living space:',
    terrace_area: 'Terrace and balcony (m²)',
    sole_plate_or_basement: 'Sole plate or Basement',
    basement: 'Basement',
    basement_description: 'Price for basement includes brickwork, floors, windows and doors and electrical work',
    sole_plate: 'Sole plate',
    sole_plate_description: 'Price includes a soleplate with insulating',
    basement_area: 'Basement (m²)',
    sole_plate_area: 'Sole plate (m²)',
    direction: {
      north: 'North',
      south: 'South',
      east: 'East',
      west: 'West',
    },
    living_room_direction: 'Direction of the living room',
    bathrooms: 'Number of bathrooms',
    shower: 'Shower',
    bathtub: 'Bathtub',
    heating_system_type: 'Type of heating system',
    heating_system_types: {
      gas: 'Gas',
      solid_fuel: 'Solid fuel',
      district_heating: 'District heating',
      heat_pump: 'Heat pump',
      electric: 'Electric',
      other: 'Other',
    },
    solar_power_system: 'Solar Power System',
    solar_power_system_description:
      'A solar power system in our times is cost-efficient and helps to prevent climate-change. In many regions you also get public sponsorship.',
    solar_power_size_kwp: 'How big should the system be? (kWp)',
    solar_power_size_kwp_description: 'Please note that for 1 kWp ("Kilowatt peak") you need 7m² on your rooftop',
    solar_power_equipment_type: {
      name: 'The type of equipments of the Solar Power System',
      standard_description:
        'A Standard Solar Power System is mounted on your rooftop. The system is directly connected to your grid.',
      premium_description:
        'A <b> sustainable </b> PV system is based on an additional battery buffer and an app-based energy management system. The result is a higher self-use of the energy produced. In addition, the PV modules are integrated directly into the roof, which makes this version stand out from the <b> standard </b>.',
      superior_description:
        'In the case of an <b> Exclusive </b> PV system, for example, one speaks of solar roof tiles, which enable a particularly aesthetic look. Any additional costs that arise are compensated for by lower costs for the roofing.',
    },
    cost_drivers: 'Cost drivers',
    hillside_location: 'Hillside location',
    demolition_costs: 'Demolition costs',
    demolition_area: 'Demolition area (m²)',
    personal_contribution: 'My contribution is:',
    personal_contribution_header: 'Personal contribution',
    personal_contribution_description:
      'You have bricklayer skills, you have enough time and maybe also some skilled friends? Then you can reduce the costs of your project, but please don´t overestimate yourself when you think about your personal contribution of the project!',
    personal_contribution_options: {
      none: 'none',
      low: 'low 7%',
      moderate: 'moderate 12%',
      high: 'high 20%',
    },
    personal_contribution_note:
      'Note: it reduces your costs \n low 7% (Paint the walls, lay the floor, etc.) \n moderate 12% (Comprehensive assistance with installations, interior work, etc.) \n high 20% (intensive participation in most of the trades including the shell construction)',
    additional_options: 'Additional options',
    number_of_car_ports: 'Number of Car ports',
    number_of_car_ports_description: 'for how many cars',
    number_of_garage_place: 'Number of Garage places',
    number_of_garage_place_description: 'how many parking spaces',
  },
  equipment_types: {
    label: 'Equipment type',
    superior: 'Superior',
    premium: 'Premium',
    standard: 'Standard',
    mixed: 'mixed',
  },

  feature_groups: {
    options: 'Options',
    main: 'Default',
    additional_costs: 'Additional Costs',
    cost_drivers: "Cost drivers'",
  },

  payments: {
    add_payment: 'Record Payment',
    new_payment: 'Payment',
    no_payments: 'No payments',
    amount_paid: 'Amount Paid',
    date_paid: 'Date Paid',
    remove_payment: 'Delete payment',
    name: 'Payment Name',
    notes: 'Payment Notes',
    service_type: 'Service Type',
    payee: 'Payee',
    amount: 'Amount',
    types: {
      name: 'Payment type',
      final: 'Final payment',
      partial: 'Partial amount',
    },
    paid_by_first_part: 'Select or',
    paid_by_second_part: 'create a new payer',
    invoice_photos: 'Invoice photos',
    save_payment: 'Save payment',
    zoom: 'Zoom',
    upload: 'Upload',
    upload_image: 'Upload image',
    edit_payment: 'Edit payment',
    payment_updated: 'Payment successfully updated',
    payment_added: 'Payment successfully added',
    preview_image: 'Preview image',
    all_payments: 'All payments',
    current_project: 'Project:',
    project_name: 'Project',
    date_upcoming: 'Date upcoming',
    delete_payment_img: {
      title: 'Delete payment photo',
      message: 'Are you sure you want to delete payment photo?',
    },
    payer: {
      payer_added: 'Added payer',
      add_payer: 'Add payer',
      update_payer: 'Update info',
      payer_name: "Payer's name",
      payer_label: 'Payer',
      edit_payer_info: 'Edit profile',
      edit_user_info_message: 'You can edit your info at the bottom of the sidebar',
      successfully_deleted: 'Successfully deleted',
      delete_payer_btn: 'Delete payer',
      delete_payer: {
        title: 'Delete payer',
        message: 'Are you sure you want to delete this payer?',
      },
    },
    payments_total: 'Payments total {value}',
    projects: 'Projects',
    categories: 'Categories',
    payers: 'Payers',
    modal_header_text: 'Filters',
    chart_label: 'Statistics',
    back_to_spendings: 'Spedings',
    download: 'Download',
    remove_payment_alert: {
      title: 'Delete payer',
      message: 'Are you sure you want to delete this payment?',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    clear_all_filters: 'Clear filters',
  },

  spendings: {
    user_price: 'User price',
    estimated_price: 'Estimated Price',
    remaining_budget: 'Remaining budget',
    overspent: 'Overspent',
    category_overspent_label: 'Overspent {number}',
    category_saved_label: 'Saved {number}',
    category_remaining_budget_label: '{number} remaining budget',
    category_quoted_label: '{number} quoted',
    category_estimated_cost_label: '{number} estimated cost',
    total_costs: 'Total costs',
    budget_spent: 'Budget spent',
    to_payments_page: 'Payments',
    materials_quality: 'Materials quality: {equipment_type}',
    record_payment: 'Record payment',
    payments: 'Payments',
    search_placeholder: 'Search by payment name, notes, payee',
    categories: 'Categories',
    chart_label: 'Statistics',
    payers: 'Payers',
    apply_filters_btn: 'Apply filters',
    payment: {
      name: 'Payment name',
      amount: 'Amount',
      notes: 'Notes',
      type: 'Type',
      date_paid: 'Date paid',
    },
    totals: {
      saved: 'Sum of all savings {savings}',
      overspent: 'Sum of all overspends {overspends}',
      deviation: 'Balance of cost deviation {deviation}',
    },
  },

  page_titles: {
    my_projects: 'My projects',
    dashboard: 'Dashboard',
    project_info: 'Project info',
    options: 'Options',
    budget: 'Lifestyle calculator',
    contact: 'Contact us',
    logout: 'Logout',
    profile: 'Profile',
    plan: 'Plan',
    my_budget: 'My Budget',
    forgot_password: 'Forgot your password',
    reset_password: 'Reset your password',
    loan: 'Financing',
    edit_budget: 'Adjust monthly expenses',
    sign_up: 'Sign up',
    login: 'Sign in',
    payments: 'Payments',
    spendings: 'Spendings',
    property_valuation: 'Property evaluation',
    debug_mode: 'Debug mode',
  },

  project_plan: {
    equipment_type: 'Equipment type',
    total_costs: 'Total costs',
    download_pdf: 'Download PDF',
    customize_project_plan: 'Customize project plan',
    adjust_project_details: 'Customize project details',
    personal_contribution: 'Your personal contribution fee',
    back_to_dashboard: 'Back to Dashboard',
    btn_label_to_budget: 'Budget calculation',
    complete_previous_step: `You don't have planning categories, try to plan`,
    complete_previous_step_btn: 'Calculate project details',
  },

  planning_categories: {
    stored_automatically: 'Please note, that changes are stored automatically!',
    financing: 'Financing',
    planning_and_construction_management: 'Planning & Construction Management',
    property_costs: 'Property costs',
    charges_and_taxes: 'Charges and taxes',
    development_costs: 'Development costs',
    demolition_costs: 'Demolition costs',
    tooltip_edit_button: 'You can update your project plan calculated values here',
    tooltip_download_button: 'You can download your lifestyle plan as a PDF here',
    tooltip_settings_button: 'You can update your project details here',
    reserves_for_unexpected_costs: 'Reserves for unexpected costs',
    reserves_for_unexpected_costs_description:
      'In the case of renovations in particular, we recommend a financial reserve of at least 10% for unforeseen additional expenses.',
    shell_construction: 'Shell construction',
    windows_and_doors: 'Windows & Doors',
    sanitary: 'Sanitary',
    electrical_installation: 'Electrical installation',
    heating_system: 'Heating system',
    interior_construction: 'Interior construction',
    interior_equipment: 'Interior equipment',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    pool: 'Pool',
    fence: 'Fence',
    carport: 'Carport',
    garage: 'Garage',
    garden_landscaping: 'Garden landscaping',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access',
    special_underground: 'Special underground',
    planning_and_construction_management_description: 'Costs for Architecture, Planning and Construction Management',
    property_costs_description: 'Set how much your property costs',
    charges_and_taxes_description: 'Charges for different services',
    development_costs_description: 'Charges for development',
    demolition_costs_description: 'Charges for old building demolition',
    sole_plate_or_basement_description: {
      basement: 'Price for basement includes brickwork, floors, windows and doors and electrical work',
      sole_plate: 'Price includes a soleplate with insulating',
    },
    sole_plate: 'Here are only the costs for the sole-plate - earth works are not included',
    shell_construction_description:
      'Includes earth works, channel work, masonry, concrete works, chimney, carpentry, roofing, facade and metal worker.',
    windows_and_doors_description: 'Charges on windows and their installation',
    sanitary_description: 'Includes plumbing and sanitary equipment',
    electrical_installation_description: 'Includes cable ducts, wiring, and electro-furniture',
    heating_system_description: 'Includes boiler, heating piping as well as radiators or underfloor heating',
    interior_construction_description: 'Includes interior plaster, screed, flooring, tiles, drywall and painting',
    interior_equipment_description:
      'Includes kitchen, furniture of all rooms, lighting, electrical devices and alarm system including installation',
    terrace_description:
      'Includes costs for the terrace with tiles, depending on the category without or with sun protection / roofing / additional equipment',
    winter_garden_description:
      'Includes conservatory, flooring, heated depending on the category and with additional equipment',
    pool_description:
      'Massive pool including earthworks, depending on the category with additional roofing / additional equipment',
    garden_landscaping_description: 'Includes lawns, plants and paths',
    fence_description: 'Includes base, fence, door / gate',
    carport_description: 'Includes massive canopy',
    garage_description: 'Includes massive garage with electric gate drive',
    electrical_installation_hint_description:
      'This calculation is based on the commonly required Energy-Check, the costs of planning your house (Application-Plan and Work-plan), and local construction supervision. You could save a lot of money if you are able to do the local supervision yourself.',
    domestic_ventilation: 'Domestic ventilation',
    domestic_ventilation_description: 'Electronically controlled, central ventilation system for the entire house',
    bus_system: 'Bus system, smart home',
    bus_system_description:
      'A bus system instead of the conventional electrical installation allows the central programming and control of all switches and sockets.',
    solar_heating: 'Solar heating',
    solar_heating_description: 'Solar system for hot water preparation or heating support',
    tile_stove: 'Tile Stove',
    tile_stove_description: 'Solid, tiled stove that is firmly connected to the house',
    solar_power_system: 'Solar power system',
    solar_power_system_description:
      'A PV system makes you somewhat independent. Often there is also public funding for this - find out more!',
    sole_plate_or_basement: 'Sole plate or Basement',
    interior_equipment_type_description:
      'The <b> Standard </b> category considers furnishings of average quality from furniture stores. In the higher categories, regionally produced, higher quality and often more durable and therefore more sustainable solutions are calculated',
    winter_garden_equipment_type_description:
      'Differences arise in the type of glazing, shading and heating / ventilation. A <b> "standard winter garden" </b> is more of a closed terrace, a winter garden in the "Exclusive" category is a full-fledged living space that can be used all year round.',
    pool_equipment_type_description:
      'Massive pool including earthworks, depending on the category with additional roofing / additional equipment',
    garden_landscaping_equipment_type_description:
      'The <b> "Standard" </b> category largely calculates lawns and inexpensive planting based on simple planning as offered in specialist stores.',
    fence_landscaping_equipment_type_description:
      'In the <b> Standard </b> version, a fence with a foundation and simple privacy protection is calculated. Higher quality designs and walls are considered in the higher categories.',
    tile_stove_equipment_type_description:
      'The basis is a tiled stove set by the stove. There are differences in size and design, including the water supply and integration into the heating system.',
    domestic_ventilation_equipment_type_description:
      'The basis are supply and exhaust air ducts for all rooms. In the higher categories, the design is such that each room can be controlled separately.',
    bus_system_equipment_type_description:
      'The basis is a continuous bus system with appropriate cabling and controls for lighting and electricity. There are differences in the bus system itself (important here to rely on a <b> standard </b> such as KNX) and in the type of control.',
    solar_heating_equipment_type_description:
      'Standard </b> is a system that largely covers the hot water requirement. In the higher categories, the system is also designed to support heating.',
    sole_plate_or_basement_form: {
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    wallbox_e_mobility: 'Wallbox E-Mobility',
    wallbox_e_mobility_description:
      'To be ready for the future you should consider installing a wallbox for charging electric cars. We estimate the price of a fix mounted wallbox which is installed by a professional',
    wallbox_e_mobility_equipment_type_description:
      'Standard includes a wallbox which is installed based on the existing building wiring which means that it normaly has only 4.6kw. In the higher categories new wiring and a wallbox with energy-management is calculated - this allows you loading up to 11kW and an integration into a photovoltaic system',
    other_investments: 'Other investments',
    other_investments_description:
      'You can enter other expenses in connection with your renovation project here individually.',
    additional_categories: 'Additional costs',
  },

  planning: {
    override_cost: 'Override cost, €',
    calculated_button: 'Calculated',
    quoted_button: 'Quoted',
    sole_plate_or_basement_form: {
      select_label: ' ',
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    sole_plate_or_basement: {
      basement: 'Basement',
      sole_plate: 'Sole plate',
    },
    plan_overview: 'Plan Overview',
    earthworks: 'Earthworks',
    channel: 'Channel work',
    masonry: 'Masonry, concrete and reinforced concrete works',
    chimney: 'Chimney',
    carpentry: 'Carpentry',
    roofing: 'Roofing and plumbing work (plumber)',
    facade: 'Facade - full thermal insulation',
    metal: 'Metal worker',
    plumbing: 'Plumbing',
    sanitary_equipment: 'Sanitary equipment',
    heating_installation: 'Heating installation total',
    radiator: 'Radiator or underfloor heating assembly',
    boiler: 'Boiler',
    electro_cable_ducts: 'Electro - cable ducts',
    electro_wiring: 'Electro - wiring',
    electro_finishing: 'Electro - finishing',
    windows_patio_balcony: 'Windows, patio and front door',
    front_door: 'Front door',
    interior_plastering: 'Interior plastering',
    floor_construction: 'Floor construction - screed',
    drywall_construction: 'Drywall construction',
    tiling: 'Tiling',
    interior_doors_doorstays: 'Interior doors - doorstays',
    interior_doors_door_panels: 'Interior doors - door panels and completion',
    flooring: 'Flooring',
    painting_work: 'Painting work (house painter, upholsterer)',
    energy_check: 'Energy Check',
    price_of_property: 'Price of property',
    development_costs: 'Development costs',
    charges_and_taxes: 'Charges and taxes',
    demolition_costs: 'Demolition costs',
    demolition_area: 'Demolition area (m²)',
    interior_equipment: 'Interior equipment',
    controlled_domestic_ventilation: 'Controlled domestic ventilation',
    pool: 'Pool',
    garden_design: 'Garden design',
    fence: 'Fence',
    carport: 'Carport',
    number_of_car_ports: 'Number of Car ports',
    number_of_garage_place: 'Number of Garage places',
    number_of_car_ports_format_value: {
      one: 'Car port: {{value}} place',
      other: 'Car port: {{value}} places',
    },
    number_of_garage_place_format_value: {
      one: 'Garage: {{value}} place',
      other: 'Garage: {{value}} places',
    },
    garage: 'Garage',
    terrace: 'Terrace',
    winter_garden: 'Winter garden',
    hillside_location: 'Hillside location',
    limited_access: 'Limited access',
    special_underground: 'Special underground',
    special_underground_form: {
      underground_type: 'Underground type',
      underground_type_options: {
        groundwater_level_high: 'Groundwater level high',
        rocky: 'Rocky',
        marshy: 'Marshy',
      },
    },
    cost_estimation: 'Preliminary Planning & cost estimation',
    permit_planning: 'Permit planning',
    execution_planning: 'Execution planning',
    bill_of_quantity: 'Bill of quantity & Offers',
    construction_management: 'Construction Management',
    bus_system: 'Bus system, smart home',
    tile_stove: 'Tile Stove',
    solar_power_system: 'Solar power system',
    solar_heating: 'Solar heating',
    taxes_and_notary_expenses: 'Taxes and notary expenses',
    other_investments_subitem: 'Other investments',
    other_investments_amount: 'Amount',
    other_investments_comment: 'Comment',
  },
  loan: {
    budget_shortage_chart: {
      shortage_label: 'Shortage',
      budget_label: 'Own funds',
    },
    overview: {
      title: 'Overview',
      budget: 'Budget:',
      total: 'Total:',
      shortage: 'Shortage:',
    },
    project_price_zero: `The estimated cost of your project is zero so you can't complete this step. You can start planning now`,
    start_planning: 'Start planning',
    interest_type: {
      variable: 'Variable',
      fixed: 'Fixed',
    },
    personal_financing_request: {
      title: 'Personal financing request',
      prefer_contact_by: 'I prefer making contact by',
      prefer_contact_by_email: 'Mail',
      prefer_contact_by_phone: 'Phone',
      phone_number: 'My phobe number',
      reachability_on_phone: 'Reachability on phone',
      message: 'Would you like to tell us something which could be usefull for the financing offer?',
      description: 'e.g additional funds, expected increase of your loan, Link to your desired real-estate',
      before: 'Before',
      after: 'or after',
      oclock: "o'clock ",
      day: 'Day',
      month: 'Month',
      year: 'Year',
      invalid_date: 'Invalid date',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
    },
    own_funds_ratio_not_successful:
      "Your own funds ratio {own_funds_ratio} could make the financing difficult. But let's talk maybe we find a solution!",
    max_loan_rate_ratio_not_successful:
      "The calculated loan rate exceeds {max_loan_rate_ratio} of your total income, this makes financing difficult but we'll look at it and help you to find a solution!",
    estimated_property_price: 'Estimated property price',
    already_requested: 'You have already requested a loan',
    request_sent: 'The request was successfully sent',
    tooltip_settings_button: 'You can update your financing details here',
    total_project_costs: 'Total project costs',
    loan_costs: 'Loan costs',
    affordable_loan: 'Affordable loan',
    budget_surplus: 'Calculated budget surplus',
    financing_gap: 'Financing gap',
    financing_reserve: 'Financing reserve',
    monthly_deficit: 'Monthly deficit',
    monthly_reserve: 'Monthly reserve',
    financing_deficit_message:
      '<p>Your total project costs are currently above your finacial capabilities. You can try to adjust the following parameters:</p><ul><li>Lower your project costs</li><li>Increase your own funds</li><li>Try to earn more money</li><li>Lower your monthly expenses</li><li>Increase the loan term</li></ul><p>We would be glad to support you with your financial planning!</p>',
    financing_deficit_button: 'Request Personal consulting',
    financing_reserve_message:
      '<p>Congratulations!</p> <p>It seems that your project can be financed! (your own funds ratio is {own_funds_ratio} and your loan rate ratio is {loan_rate_ratio})</p> <p>Please request your personal offer!</p>',
    financing_reserve_button: 'Request your personal offer',
    loan_input: 'Loan input',
    loan_details: 'Loan details',
    title: 'Loan on Project "{value}"',
    price: 'Total project costs (€)',
    project_link_description: 'According to your project:',
    budget: 'Own funds',
    period: 'Loan period in years',
    interest_terms: 'Interest terms',
    fixed_interest_period: 'Fixed interests in years',
    date: 'Loan date:',
    amount: 'Requested loan amount',
    own_funds_cash: 'Own funds cash',
    own_funds_value_of_property: 'Own funds - value of property',
    total_own_funds: 'Total owned funds',
    required_loan: 'Required loan',
    loan_end_date: 'I would need the loan till the',
    loan_term: 'I would prefer a loan term of',
    term_in_years: '{period} years',
    amount_with_loan_costs: 'Your loan amount including loan-costs would be',
    interest_rate: 'Expected interest bandwith (depending on your credit assment)',
    monthly_rate: 'This would be first monthly a rate of',
    fixed_interest_rate: 'I would like a fixed-interest-period of:',
    variable_interest_rate: 'I would like to have a variable rate based at the 6-month EURIBOR',
    no_need: "It looks like you have enough budget for your project and don't need a loan",
    edit: 'Edit',
    complete_previous_step: 'You must complete the Budget step first to have access to Financing',
    complete_previous_step_btn: 'Calculate Budget',
    rate_title: 'How is my rate calculated?',
    rate_btn_label: 'How is my rate calculated?',
    how_rate_calculated_first_part:
      '<b>How is the loan installment of your financing calculated? </b> \n The calculation is <b> based on your input </b> and serves as a guide. \n The <b> type of financing </b> is a mortgage secured loan.  n You can choose from 3 <b> financing variants </b>: <ul> <li>Variable</li> <li>Fix</li> <li>Fixed and variable combined</li> </ul> The <b> condition </b> from the <b> variable interest </b> results from the term, the surcharge - which is dependent on creditworthiness and security - and the current 6 month Euribor. \n Adjustment on 01.01. and 01.07. every year. \n The condition of the fixed interest rate is also dependent on the term, credit rating and collateral.\n',
    how_rate_calculated_second_part:
      '<b>Fixed interest terms </b> can be adjusted to your needs: 5, 10, 15 or 20 years are available.  n The <b> total term </b> of the financing is max. 35 years.  n The <b> maximum age </b> at the end of the loan is 80 years.  n <b> Ancillary credit costs:  n These include: </b> <ul> <li><b>Processing fee</b></li> <li><b>Issuing fee</b> (KSV expenses, Land register archiving fee, land register entry fee, land register extract, certification of pledge deed, estimated costs per property)</li> <li><b>Land register entry fee</b> (1.2% of the maximum amount mortgage entered in the land register)</li></ul> The <b> loan amount </b> paid to you will be reduced by the sum of the fees mentioned.  n The <b> credit account management fee </b> is charged to the credit period per quarter.',
  },

  budget: {
    titles: {
      monthly_net_income: 'Monthly net income',
      monthly_expenses: 'Monthly expenses',
      total_monthly_income: 'Total monthly income (12 times a year)',
      total_monthly_costs: 'Total monthly costs',
    },
    affordable_loan_info: {
      congratulations: `Congratulations - you're creditworthy!`,
      affordable_amount_of_loan:
        'would be the amount of loan you can afford (loan term 30 years) - the monthly repayments would be ',
      potential_amount_of_loan:
        'You could afford more if you would change your lifestyle and save more money - in that case you could afford:',
      amount_of_loan: 'based on 30 years loan-term and a monthly repayment of ',
    },
    btn_label_to_financing: 'Go to financing',
    btn_label_to_budget_edit: 'Adjusting househole costs',
    tooltip_download_button: 'You can download budget estimation in pdf here',
    tooltip_edit_button: 'You can update your budget calculated values here',
    tooltip_settings_button: 'You can update your budget here',
    show_more_text: 'Read more',
    show_less_text: 'Show less',
    user_name: 'My name is',
    alone: 'I live alone',
    with_partner: 'I live together with my partner',
    number_children: 'Number of children I have',
    number_of_cars: 'Number of cars in the household',
    monthly_net_salary: 'My salary',
    monthly_net_income: 'My monthly net income (excluding special payments) is',
    monthly_net_income_partner: 'The net income of my partner is',
    monthly_net_salary_partner: 'Monthly net salary of partner',
    other_regular_income: 'Other regular income',
    monthly_alimony: 'Monthly alimony to pay',
    monthly_repayments_loans: 'Monthly repayments for existing loans',
    monthly_expenses: 'Monthly expenses for me',
    monthly_expenses_description: 'This includes the essential expenses for your basic needs',
    monthly_expenses_partner: 'Monthly expenses for my partner',
    monthly_expenses_partner_description: 'This includes the essential expenses for your basic needs',
    monthly_expenses_children: 'Monthly expenses for my children',
    monthly_expenses_children_description: 'This includes the essential expenses for your basic needs',
    operational_costs_home: 'Operational costs for our home',
    operational_costs_home_description: 'Such as repair and maintenance costs.',
    technology: 'Technology',
    technology_description: 'Such as smartphones, computers, TV, and Internet.',
    luxuries: 'Luxuries',
    luxuries_description: 'Such as fashion, beauty or retail therapy.',
    socializing: 'Socializing',
    socializing_description: 'Such as going out for drinks or a meal, or entertaining at your home.',
    hobbies: 'Hobbies',
    hobbies_description:
      'This includes costs for equipment/instruments and equipment, membership fees for clubs, courses, etc.',
    taking_a_break: 'Taking a break',
    taking_a_break_description: 'Such as holidays and travel.',
    cars: 'Cars: {number}',
    cars_description:
      'The total costs of each car (depreciation, insurance, refueling, etc.) are taken into account here.',
    private_pension_and_insurances: 'Private pension and insurances',
    private_pension_and_insurances_description:
      'Supplementary insurance for illness and accident, legal protection, old-age provision',
    savings: 'Savings',
    new_car: 'That new car smell',
    lifestyle: 'Lifestyle',
    select_values: {
      not_important: 'Not important',
      important: 'Important',
      very_important: 'Very important',
    },
    monthly_expenses_chart: {
      central_label: 'Monthly Expenses for Your lifestyle',
      options: {
        basic_expenses: 'Basic expenses',
        hobbies: 'Hobbies',
        taking_a_break: 'Vacations and trips',
        cars: 'Car',
        socializing: 'Culinary, guests, celebrations and gifts',
        savings: 'Saving',
        pension_and_insurances: 'Insurance and retirement provision',
        technology: 'Smartphone, Notebook, TV, Internet',
        luxuries: 'Clothing, beauty, shopping',
      },
    },
    monthly_income_chart: {
      central_label: 'Monthly income',
      options: {
        expenses: 'Expenditure',
        credit_rate: 'Credit rate',
      },
    },
  },

  budget_calculator: {
    not_important_option_label: 'Not important',
    important_option_label: 'Important',
    very_important_option_label: 'Very important',
    loan_impossible_description:
      'Oops, it seems that your income doesn’t fit your lifestyle - please adjust income or expenses!',
    missing_monthly_value: "You're missing at least {value} per month",
    budget_calculator_description:
      "Most budget calculators use average spending figures to calculate a budget - but honestly, who is really average? Our budget should be based on what we like to do - if you love to read books, go out for walks and like to cook at home then your budget should be different from someone who is a skiing fanatic, never eats at home and has an inclination for fast cars. Our calculator uses these factors to help you find out what you can afford and still enjoy your life. Maybe it's better to have a slightly small home and still have the money to spend on the things you love to do. MeinBau's goal is to help people find that balance and be happier for it :)",
    start: 'Start',
    first_step: {
      title: 'You and Your Family',
      partner_option_label: 'Partner',
      alone_option_label: 'Alone',
      alone_or_partner: 'Do you live alone or with partner?',
      kids_quantity: 'How many kids do you have?',
      kids_quantity_description: "Please also enter kid 'in planning' because a loan is a long-lasting commitment",
      age: 'How old are you?',
      age_units: 'years',
      age_description: 'We need the age to calculate the possible loan',
    },
    second_step: {
      title: 'Your Income',
      other_regular_revenues: 'Other regular revenues? (€)',
      other_regular_revenues_description: 'e.g. revenues of renting a flat',
      monthly_net_salary: 'How much is your monthly salary? (€)',
      monthly_net_salary_description:
        'Please enter your monthly net salary excluding diets and mileage allowance. If you receive income from self-employment, please enter your last annual net salary divided by 14 here',
      monthly_net_income_partner: 'How much is the monthly net income of your partner? (€)',
    },
    third_step: {
      title: 'Expenses',
      how_many_cars: 'How many cars do you have?',
      amount_of_alimony: 'How much alimony do you have to pay? (€)',
      monthly_loan: 'Monthly repayments for any loans (€)',
    },
    fourth_step: {
      title: 'Costs & Funding',
      project_type_finance: 'What kind of project are you undertaking?',
      building_new_house: 'Building new house',
      buying_house: 'Buying a house',
      buying_a_flat: 'Buying a flat',
      renovation_house: 'Renovating a house',
      renovating_a_flat: 'Renovating a flat',
      square_meters: 'How many square meters does your house or flat have?',
      square_meters_description: "We'll use this to calculate the running costs.",
    },
    fifth_step: {
      title: 'Lifestyle',
      socializing: 'Socializing',
      socializing_descriptions:
        'How important is it for you to go out for drinks or a meal, or entertain at your home?',
      luxuries: 'Luxuries',
      luxuries_description:
        'How important is it for you to spend on non-necessities such as fashion, beauty or retail therapy.',
      new_car: 'That new car smell',
      new_car_description: 'How important is it for you to have a new, fresh or powerful car?',
      taking_a_break: 'Taking a break',
      taking_a_break_description: 'How important is it for you to take holidays or to travel?',
      hobbies: 'Hobbies',
      hobbies_description:
        'How important is it for you to have money to spend on potentially costly hobbies? E.g. Golf, Skiing, Hunting, Motorsports etc.',
      technology: 'Technology',
      technology_description:
        'How important is it for you to have the latest technology? E.g. smartphones, tablets, wearables, TVs, or digital subscriptions.',
    },
    sixth_step: {
      title: 'Saving & Pensions',
      pension_and_insurances: 'Private pensions and insurances',
      savings: 'Monthly savings',
      savings_description: 'Do you have any regular outgoing to savings accounts?',
    },
    edit_values: {
      monthly_expenses: 'Monthly expenses for me',
      monthly_expenses_partner: 'Monthly expenses for my partner',
      monthly_expenses_kids: 'Monthly expenses for my children',
      costs_for_repair: 'Operational costs for our home.',
      cars: 'Car(s)',
    },
  },

  dashboard: {
    title: 'Dashboard',
    complete_project_details_step: 'You must complete the project details step first',
    complete_project_details_step_btn: 'Project details Projekt berechnen',
    can_not_complete_step: `Currently, you can't complete this step for the new calculator for CO2, energy costs, and renovation costs. Development in progress`,
    downloads: {
      title: 'Downloads',
      project_pdf: 'Project estimation',
      budget_not_available_tooltip: 'You must complete the Budget planning first',
      budget_pdf: 'Budget overview',
    },
    overview: {
      project_price: 'My project costs',
      budget: 'My own funds',
      monthly_affordable_repayments: 'My monthly affordable repayments',
      loan_amount: 'My loan amount for this project',
      monthly_rate: 'My monthly rate',
      budget_monthly_rate_loan: 'You must to complete {link} to get this value',
      budget_monthly_rate_loan_link_label: 'budget step',
    },
    getting_started: {
      step_cta_button: 'Proceed',
      project_details: {
        title: 'Dream home planning',
        description:
          'Choose from numerous parameters and use them to determine your anticipated project costs. As a registered user, you also benefit from the determination of the market value of your property.',
      },
      adjust_plan: {
        title: 'Adopt costs',
        description:
          'If you already have offer prices or do some activities yourself, you can overwrite the calculated prices in order to adapt the project costs individually to your situation.',
      },
      budget_planning: {
        title: 'Lifestyle budget planning',
        description: 'Playfully grasp your financial possibilities based on your personal lifestyle.',
      },
      adjust_budget_plan: {
        title: 'Adopt budget plan',
        description: 'Correct the previously recorded weighting and values to your personal lifestyle',
      },
      loan_calculator: {
        title: 'Plan financing',
        description:
          'Determine the loan term and the fixed interest rate and immediately receive a non-binding loan approval including additional information (monthly installment, expected interest rate, etc.)',
        disabled_proceed_btn_tooltip: 'You must complete the Budget step first to have access to Financing',
      },
      loan: {
        title: 'Digital financing commitment',
        description: 'Almost there! You will receive our assessment of the feasibility of your financing!',
      },
      request_personal_offer: {
        title: 'Request personal offer',
        description:
          'Do you have anymore questions? Send your data to a housing expert at HYPO NOE with one click. They will contact you immediately and advise you comprehensively on individual questions!',
      },
    },
  },
  disclaimers: {
    guest_user: 'You are a guest. In order to save your data you need to register',
    registration:
      '<b>DISCLAIMER:</b>\n\n The values given are non-binding estimates, which were calculated on the basis of average market values in Austria and are used only as a guide. \n Please keep in mind that these values vary more or less from the actual actual costs depending on the market situation, negotiation situation and execution.\n Talo IT assumes no liability for the correctness of these values, nor for any malfunctions.',
    budget:
      '<b>DISCLAIMER:</b>\n\n Talo IT GmbH (short: Talo IT) does not recommend a specific loan. However, based on your answers to some of the questions, Talo IT will provide you with information about financing so that you can make your own decision.\n This list is not a legal offer, but is only for internal processing of the loan request.',
    loan_calculator:
      '<b>DISCLAIMER:</b>\n\n This result was calculated based on your input, serves only as a guide and does not constitute a binding offer.\n For the calculation, the cheapest interest rate with mortgage collateral and very good creditworthiness of the borrower without taking into account expenses and incidental fees was used.\n Talo IT is ready to make you a non-binding offer in accordance with the Mortgage and Real Estate Loan Act (HIKrG) in the event of a specific interest.\n Please use the "Request a personal offer" option and one of our housing experts will contact you in this regard set.',
    immazing_not_available: 'Unfortunately there is no ImmAzing rating available for the address entered!',
    immazing_estimation:
      'Note:\n\n A maximum of 10 property reviews are available to each user. Reviews you have already carried out: {requestsCount}',
    land_immazing_not_available: 'Unfortunately there is no ImmAzing rating available for the address entered!',
    land_immazing_estimation:
      'Note:\n\n A maximum of 10 land area reviews are available to each user. Reviews you have already carried out: {requestsCount}',
  },
  legend: {
    total_label: 'Your price {value}',
    vw_lwr: 'Estimate lower limit',
    vw: 'Estimate',
    vw_upr: 'Upper bound estimate',
  },
  IE_not_supported:
    'Unfortunately IE11 is not supported. It is recommended to view the website using the latest version of Google Chrome',
  countries: {
    AT: 'Austria',
    CH: 'Switzerland',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DK: 'Denmark',
    ES: 'Spain',
    FI: 'Finland',
    FR: 'France',
    GB: 'United Kingdom',
    HR: 'Croatia',
    HU: 'Hungary',
    IE: 'Ireland',
    IT: 'Italy',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    NL: 'Netherlands',
    NO: 'Norway',
    PL: 'Poland',
    PT: 'Portugal',
    SI: 'Slovenia',
    SK: 'Slovakia',
  },
  debug_mode: {
    co2_values: {
      columns: {
        option_name: 'Option name',
        actual_scenario_input: 'Actual-Scenario Input Parameter',
        potential_scenario_input: 'Potential-Scenario Input Parameter',
        planned_scenario_input: 'Planned-Scenario Input Parameter',
        actual_scenario_output: 'Actual-Scenario Output Parameter',
        potential_scenario_output: 'Potential-Scenario Output Parameter',
        planned_scenario_output: 'Planned-Scenario Output Parameter',
      },
      form_values: {
        amount_of_hot_water: 'Amount of hot water',
        energy_standard: 'Energy standard',
        heated_living_area: 'Heated living area',
        heating_system: 'Heating system',
        hot_water_producer: 'Hot water producer',
        age_of_heating_system: 'How old is heating system',
        is_devices_younger_than_10_years: 'Is devices younger than 10 years',
        is_solar_heating_system: 'Is solar heating system',
        is_solar_power_system: 'Is solar power system',
        kind_of_house: 'Kind of house',
        number_of_people: 'Number of people',
        power_consumers: 'Power consumers',
        renovations: 'Renovations',
        solar_heating_system_type: 'Solar heating system type',
        solar_power_system_consuming_percentage: 'Solar power system consuming percentage',
        solar_power_system_size: 'Solar power system size',
        temperature: 'Temperature',
      },
      result: {
        specific_heating_demand: 'Specific heating demand',
        heating_factor_kea: 'Heating factor kea',
        heating_factor_ghg: 'Heating factor ghg',
        total_heating_demand: 'Total heating demand',
        primary_energy_for_heating: 'Primary energy for heating',
        heating_ghg_emission: 'Heating ghg emission',
        heating_ghg_equivalent_car: 'Heating ghg equivalent_car',
        heating_ghg_equivalent_plane: 'Heating ghg equivalent plane',
        heating_ghg_equivalent_trees: 'Heating ghg equivalent trees',
        total_heating_costs: 'Total heating costs',
        primary_energy_for_hot_water: 'Primary energy for hot water',
        hot_water_ghg_emission: 'Hot water ghg emission',
        hot_water_ghg_equivalent_car: 'Hot water ghg equivalent car',
        hot_water_ghg_equivalent_plane: 'Hot water ghg equivalent plane',
        hot_water_ghg_equivalent_trees: 'Hot water ghg equivalent trees',
        total_hot_water_costs: 'Total hot water costs',
        total_power_consumption_per_year: 'Total power consumption per year',
        total_power_production_per_year: 'Total power production per year',
        own_consumption: 'Yourself consumption',
        power_consumption_from_energy: 'Power_consumption_from_energy',
        electrical_feed_in_to_grid: 'Electrical feed in to grid',
        ghg_savings_solar_power_system: 'Ghg savings solar power system',
        ghg_balance_of_power_consumption: 'Ghg balance of power consumption',
        electricity_ghg_equivalent_car: 'Electricity ghg equivalent car',
        electricity_ghg_equivalent_plane: 'Electricity ghg equivalent plane',
        electricity_ghg_equivalent_trees: 'Electricity ghg equivalent trees',
        solar_power_system_helps_to_save: 'Solar power system helps to save',
        total_electricity_costs: 'Total electricity costs',
        total_energy_balance_per_year: 'Total energy balance per year',
        energy_ghg_balance_per_year: 'Energy ghg balance per year',
        energy_ghg_equivalent_car: 'Energy ghg equivalent car',
        energy_ghg_equivalent_plane: 'Energy ghg equivalent plane',
        energy_ghg_equivalent_trees: 'Energy ghg equivalent trees',
        total_energy_costs: 'Total energy costs',
      },
    },
  },
  alt_text: {
    btn_select_with_icon: 'Image {btnLabel}',
    quick_calculator: {
      renovation_house: 'Image renovation of a single-family house (including Co2 balance)',
      buying_house: 'Image single-family house (including renovation)',
      new_building: 'Image new building single-family house',
      new_apartment: 'Image new apartment',
      lifestyle_calculator: 'Image lifestyle calculator',
      property_valuation: 'Image property valuation',
    },
    project_details_page: {
      new_apartment: {
        first_step: 'Image the first step new apartment project type',
        third_step: 'Image the third step new apartment project type',
        fourth_step: 'Image the fourth step new apartment project type',
        fifth_step: 'Image the fifth step new apartment project type',
      },
      new_building: {
        first_step: 'Image the first step new building project type',
        third_step: 'Image the third step new building project type',
        fourth_step: 'Image the fourth step new building project type',
        fifth_step: 'Image the fifth step new building project type',
        sixth_step: 'Image the sixth_step step new building project type',
      },
      buying_house: {
        first_step: 'Image the first step buying house project type',
        third_step: 'Image the third step buying house project type',
        fourth_step: 'Image the fourth step buying house project type',
        fifth_step: 'Image the fifth step buying house project type',
        sixth_step: 'Image the sixth_step step buying house project type',
      },
      renovation_house: {
        first_step: 'Image object data - house renovation',
        third_step: 'Image apartments - general information',
        fourth_step: 'Image CO2 optimization - wind turbines, solar plant',
        fifth_step: 'Interior graphics - woman sitting at computer',
        sixth_step: 'Image exterior - house with solar panels',
        seventh_step: 'Image apartments - general information',
      },
    },
    project_plan_page: {
      img: 'Image plan project costs - calculator',
    },
    my_projects: {
      delete_project: 'Icon delete project',
    },
    auth_pages: {
      login: 'Image login page',
      sign_up: 'Image sign-up page',
      forgot_password: 'Image forgot password page',
      reset_password: 'Image reset password page',
    },
    renovation_house: {
      energy_scale_house: 'Image Energy balance display for your home',
      co2_icon: 'Icon Co2 savings potential',
      euro_icon: 'Icon energy costs savings potential',
      lightning_icon: 'Icon energy consumption potential savings',
      plane_icon: 'Icon air travel',
      car_icon: 'Icon car kilometers',
      tree_icon: 'Icon trees',
      renovation_house_img: 'Image house renovation - old becomes new',
      number_of_people: {
        one_person: 'Image one person',
        two_people: 'Image two people',
        three_people: 'Image three people',
        four_people: 'Image four people',
        five_people: 'Image five people',
        six_people: 'Image six people',
      },
    },
    new_building: {
      number_of_floors: {
        one_floor: 'Image one-storey house',
        two_floors: 'Image two-storey house',
        three_floors: 'Image three-storey house',
      },
      equipment_house_types: {
        standard: 'Icon standard type of house equipment',
        premium: 'Icon premium type of house equipment',
        superior: 'Icon superior type of house equipment',
      },
    },
    invite_user_page_img: 'Image invite user',
    contact_us_img: 'Image contact us',
    budget_overview_img: 'Image budget overview',
    budget_edit_monthly_expenses_img: 'Image budget edit monthly expenses',
    budget_calculator: {
      first_step: 'Image Living situation - couple with stroller',
      second_step: 'Image Income - man with euro coin',
      third_step: 'Image Expenses - woman with purse',
      fourth_step: 'Image Lifestyle - woman with shopping bags',
      fifth_step: 'Image Lifestyle - man and woman using smartphone',
      sixth_step: 'Image Saving & Provisioning - Woman with Coin and Piggy Bank',

      cars: {
        one_car: 'Icon one car',
        two_cars: 'Icon two cars',
        three_cars: 'Icon three cars',
        four_cars: 'Icon four or more cars',
      },
      kids_quantity: {
        one_child: 'Icon one child',
        two_children: 'Icon two children',
        three_children: 'Icon three children',
        four_children: 'Icon four or more children',
      },
    },
    icons: {
      info: 'Icon info',
      plus: 'Icon plus',
      minus: 'Icon minus',
      lightning: 'Icon lightning',
      has_none: 'Icon has none',
      close: 'Icon close',
      check_circle: 'Icon check circle',

      vertical_split: 'Icon vertical split',
      assignment: 'Icon assignment',
      euro: 'Icon euro',
      wallet: 'Icon wallet',
      document: 'Icon document',
      dashboard: 'Icon dashboard',
      message: 'Icon message',
      subject: 'Icon subject',
      local_offer: 'Icon local offer',
      supervisor_account: 'Icon supervisor account',
      wrench: 'Icon wrench',
      arrow_right: 'Icon arrow right',
    },
    navbar: {
      logo: 'Logo',
      dropdown_user_icon: 'Icon user',
    },
  },
};

export const backendErrors = {
  access_denied: 'Access denied',
  something_went_wrong: 'Something went wrong',
  form_data_is_missing: 'Form data is missing',
  create_project_first: 'You cannot log in until you register and create a project',
  auth: {
    login_failed: 'Login failed. Please try again',
    email_exists: 'User with this email is already registered',
    verify_account: 'You have to verify your email before login.',
    password_is_required: 'Password is required',
    not_authorized: 'Not authorized',
    temporary_password_is_required: 'Your first login must have a temporary password',
    wrong_password: 'Wrong password',
    email_is_required: 'Email is required',
    account_has_no_password:
      'Your account does not have a password because you signed up with a social services (Google or Facebook)',
  },
  user: {
    user_not_found: 'User not found',
    cannot_remove_yourself: 'You cannot remove yourself',
  },
  immazingEstimation: {
    not_permissions: 'No permissions for get immazing estimation',
    requests_limit: 'You have already used the service limit',
  },
  project: {
    limit_of_projects: 'You cannot create more than 10 projects',
    last_project: 'You cannot delete the last project',
    not_found: 'Project not found',
    cannot_remove_project_payments_recorded: 'You cannot remove this project because payments have been recorded',
    has_already_owner: 'Project has already an owner',
  },
  budget: {
    no_budget_calculation: 'You have no budget calculation',
    need_to_transfer_project_with_budget:
      'As the financing step in the project is completed, you will also have to transfer budget calculations',
  },
  financing: {
    request_sent: 'Request has already been sent',
  },
  invitation: {
    email_exists: '@Such an email already exists in the system',
    cannot_resend_temp_password: `@You can't send a temporary password because the user has already changed it`,
    cannot_delete_invited_user: `@You can't delete a user account because he has changed the temporary password`,
  },
  image_uploading: {
    fetch_fail: 'Failed to fetch file',
    fetch_owner_fail: 'Failed to fetch file uploader',
    wrong_owner: 'No permissions to remove this file',
  },
  payments: {
    limit_of_payers: 'You cannot add more than 5 payers',
    failed_to_remove_payer:
      'You cannot remove this payer at this time. Please reassign payments when this payer is assigned.',
  },
  admin: {
    disable_2_fa: 'Disable 2FA before initiating it again.',
    otp_is_invalid: 'One-time password is not valid. Please try again',
    last_admin: 'You last admin',
  },
  pdf: {
    export_error: 'Something went wrong while exporting pdf',
  },
};
