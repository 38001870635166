import * as Sentry from '@sentry/react';
import settings from 'config/settings';
import initCookies from './init-cookies';

export default function startup() {
  initCookies();
  if (settings.isProduction)
    Sentry.init({
      dsn: settings.dsn,
      ignoreErrors: [
        "Cannot read property 'readyState' of null",
        "null is not an object (evaluating 'g.readyState')",
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        'fbq is not defined',
        'XMLHttpRequest.g.onreadystatechange(analytics)',
        'CookieConsent is undefined',
        /analytics/,
        /fbq/,
      ],
    });
}
