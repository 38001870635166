import Modal from 'components/common/Modal';
import PaymentFormContainer from 'components/payments/PaymentForm';
import { useAddPaymentHandler } from 'hooks/payments/useAddPayment';
import useProjectsPayersQuery from 'hooks/payments/useProjectsPayersQuery';
import React, { useMemo } from 'react';

const PaymentsFormModal = ({ hideModal, t, projectId, isVisible, modalProps, countOfPayments }) => {
  const defaultFilters = { searchQuery: '', categories: [], projects: [projectId], payers: [] };

  const onAddPayment = useAddPaymentHandler({ hideModal, countOfPayments });

  const { projects, paymentsTotalByPayers, loading } = useProjectsPayersQuery(defaultFilters);
  const payers = useMemo(() => paymentsTotalByPayers.map(({ payer }) => ({ payer })), [paymentsTotalByPayers]);
  const formattedProjects = useMemo(() => projects.map((project) => ({ label: project.name, value: project._id })), [
    projects,
  ]);

  if (loading) return <div />;

  const defaultInitialValues = {
    name: '',
    notes: '',
    category: '',
    payee: '',
    amount: null,
    payment_type: '',
    paid_by: '',
    project_id: projectId,
    attachments: [],
    date_paid: new Date(),
    ...modalProps,
  };

  return (
    <Modal headerText={t({ id: 'payments.new_payment' })} isVisible={isVisible} close={hideModal} size="md">
      <PaymentFormContainer
        initialValues={defaultInitialValues}
        projects={formattedProjects}
        onSubmit={onAddPayment}
        payers={payers}
      />
    </Modal>
  );
};

export default PaymentsFormModal;
