import { Pencil, Plus } from 'assets/icons';
import CircleButton from 'components/common/CircleButton';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import s from './SpendingsTable.module.scss';

const ActionButtons = ({ category: { name }, showModal }) => {
  const { id: projectId } = useParams();

  return (
    <div className={s.buttonsWrapper}>
      <Link to={{ pathname: routePatterns.planForm.stringify({ id: projectId }), search: `?category=${name}` }}>
        <CircleButton color="secondary">
          <Pencil />
        </CircleButton>
      </Link>
      <CircleButton
        className={s.plusIcon}
        onClick={(e) => {
          e.stopPropagation();
          showModal({ category: name });
        }}
        color="secondary"
      >
        <Plus />
      </CircleButton>
    </div>
  );
};

export default ActionButtons;
