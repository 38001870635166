import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import useProjectPlan from 'hooks/useProjectPlan';
import LoadingOverlay from 'components/common/LoadingOverlay';
import PlanForm from 'components/plan/PlanForm';
import isNil from 'lodash/isNil';

function getInitialValuesFromProjectPlan(categories) {
  return categories.reduce((acc, category) => {
    const { name: categoryName, user_price, calculated_price, subitems } = category;
    acc[categoryName] = !isNil(user_price) ? user_price : calculated_price;

    subitems.forEach(({ name: subItemName, equipment_type: subItemEquipmentType, user_price: subItemUserPrice }) => {
      acc[subItemName] = !isNil(subItemUserPrice) ? subItemUserPrice : subItemEquipmentType;
    });

    return acc;
  }, {});
}

const Plan = () => {
  const { id: projectId } = useParams();
  const { projectPlan, loading } = useProjectPlan(projectId);
  const planningCategories = get(projectPlan, 'planningCategories', []);
  const initialValues = useMemo(() => getInitialValuesFromProjectPlan(planningCategories), [planningCategories]);

  const categories = useMemo(() => planningCategories.filter((c) => c.calculated_price), [planningCategories]);

  if (loading) return <LoadingOverlay />;

  return <PlanForm {...{ projectId, categories, initialValues }} />;
};

export default Plan;
