/* eslint-disable import/prefer-default-export */
import React, { memo, useMemo } from 'react';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import Alert from 'components/common/Alert';
import { useIntl } from 'react-intl';
import equal from 'fast-deep-equal/es6/react';
import { RENOVATION_HOUSE } from 'constants/variables';
import { BudgetStep2 } from 'assets/icons/budget';

const formatPercent = (percent) =>
  new Intl.NumberFormat('de-DE', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
    percent,
  );

const FinancingMessages = ({ loan, monthlyFinancing, projectType }) => {
  const { formatMessage: t } = useIntl();
  const ownFundsRatioMessageFlag = useMemo(
    () => !projectType === RENOVATION_HOUSE && !loan.is_own_funds_ratio_successful,
    [loan.is_own_funds_ratio_successful, projectType],
  );
  const maxLoanRateRatioMessageFlag = !loan.is_max_loan_rate_ratio_successful;
  const monthlyFinancingMessageFlag = useMemo(() => monthlyFinancing.value < 0, [monthlyFinancing.value]);
  const financingReserveMessageFlag = useMemo(
    () => monthlyFinancing.value > 0 && loan.is_max_loan_rate_ratio_successful && loan.is_own_funds_ratio_successful,
    [monthlyFinancing.value, loan.is_max_loan_rate_ratio_successful, loan.is_own_funds_ratio_successful],
  );

  const hasNoMessages =
    !ownFundsRatioMessageFlag &&
    !maxLoanRateRatioMessageFlag &&
    !monthlyFinancingMessageFlag &&
    !financingReserveMessageFlag;

  if (hasNoMessages) {
    return <BudgetStep2 width="100%" />;
  }

  return (
    <>
      {ownFundsRatioMessageFlag ? (
        <Alert color="danger">
          {t({ id: 'loan.own_funds_ratio_not_successful' }, { own_funds_ratio: formatPercent(loan.own_funds_ratio) })}
        </Alert>
      ) : null}

      {maxLoanRateRatioMessageFlag ? (
        <Alert color="danger">
          {t(
            { id: 'loan.max_loan_rate_ratio_not_successful' },
            { max_loan_rate_ratio: formatPercent(loan.max_loan_rate_ratio) },
          )}
        </Alert>
      ) : null}

      {monthlyFinancingMessageFlag ? (
        <Alert color="danger">
          <FormattedHTMLMessage id="loan.financing_deficit_message" />
        </Alert>
      ) : null}

      {financingReserveMessageFlag ? (
        <Alert color="primary">
          <FormattedHTMLMessage
            id="loan.financing_reserve_message"
            values={{
              own_funds_ratio: formatPercent(loan.own_funds_ratio),
              loan_rate_ratio: formatPercent(loan.max_loan_rate_ratio),
            }}
          />
        </Alert>
      ) : null}
    </>
  );
};

export default memo(FinancingMessages, equal);
