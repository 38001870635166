import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';

export const FINANCING_QUERY = gql`
  query financingQuery($id: ID!, $locale: String) {
    myBudget {
      _id
      calculation {
        amount_of_loan {
          calculated_value
        }
        monthly_rate_loan {
          calculated_value
        }
      }
    }
    project(id: $id) {
      _id
      pdf(locale: $locale)
      type
      name
      price
      budget
      financing_rate
      estimated_property_price
      loan {
        amount
        period
        loan_costs
        interest_type
        fixed_interest_period
        amount_with_loan_costs
        interest_rate
        monthly_rate
        own_funds_ratio
        max_loan_rate_ratio
        interest_bandwith
        is_own_funds_ratio_successful
        is_max_loan_rate_ratio_successful
      }
      progress {
        loan_viewed
        loan_request
      }
    }
  }
`;

const useGetFinancing = (projectId) => {
  const { locale } = useIntl();
  const { loading, data } = useQuery(FINANCING_QUERY, {
    variables: { id: projectId, locale },
    fetchPolicy: 'cache-and-network',
  });

  const budget = get(data, 'myBudget', null);
  const project = get(data, 'project', null);

  const financing = { project, budget };

  return [financing, loading];
};

export default useGetFinancing;
