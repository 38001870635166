/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useMemo } from 'react';
import { COLORS } from 'constants/variables';
import { User } from 'assets/icons/sidebar';
import { formatCurrency } from 'utils/helpers';
import { useModal } from 'components/common/Modal';
import useEditPayerInfo from 'hooks/payments/useEditPayerInfo';
import { useIntl } from 'react-intl';
import Tooltip from 'components/common/Tooltip';
import useDeletePayer from 'hooks/payments/useDeletePayer';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import PayerFormModal from './PayerFormModal';
import s from './PayersList.module.scss';

const useDeletePayerHandler = (payerId, hideModal) => {
  const { formatMessage: t } = useIntl();
  const deletePayer = useDeletePayer();

  const onDeletePayer = useCallback(async () => {
    try {
      await deletePayer(payerId);
      hideModal();
      toast.success(t({ id: 'payments.payer.successfully_deleted' }));
    } catch (error) {
      toast.error(error.message);
    }
  }, [deletePayer, hideModal, payerId, t]);

  const handleDelete = useCallback(async () => {
    confirmAlert({
      title: t({ id: 'payments.payer.delete_payer.title' }),
      message: t({ id: 'payments.payer.delete_payer.message' }),
      buttons: [{ label: t({ id: 'app.cancel' }) }, { label: t({ id: 'app.confirm' }), onClick: onDeletePayer }],
    });
  }, [onDeletePayer, t]);
  return handleDelete;
};

function getButtons({ onDeletePayer }) {
  return [
    {
      id: 1,
      type: 'button',
      color: 'danger',
      label: 'payments.payer.delete_payer_btn',
      onClick: onDeletePayer,
    },
    {
      id: 2,
      type: 'submit',
      color: 'primary',
      label: 'payments.payer.update_payer',
    },
  ];
}

const ListItem = ({ onEditInfo = () => {}, _id, name, avatar, total, index }) => (
  <button type="button" className={s.listItemBtn} onClick={() => onEditInfo({ id: _id, name, avatar })}>
    <div className={s.payerAvatar}>
      <div className={s.imgBox} style={{ border: `2px solid ${COLORS[index]}` }}>
        {avatar?.url ? <img src={avatar.url} alt="avatar" /> : <User />}
      </div>
      <span className={s.name}>{name}</span>
    </div>
    <div>{formatCurrency(total)}</div>
  </button>
);

const PayersList = ({ paymentsTotalByPayers }) => {
  const { showModal, hideModal, isVisible, modalProps } = useModal();
  const { formatMessage: t } = useIntl();
  const onEditPayerInfo = useEditPayerInfo(modalProps?.payerId, hideModal);
  const onDeletePayer = useDeletePayerHandler(modalProps?.payerId, hideModal);
  const buttons = useMemo(() => getButtons({ onDeletePayer }), [onDeletePayer]);

  const initialValues = {
    name: modalProps?.name,
    avatar: modalProps?.avatar?.file_name,
  };

  const onEditInfo = useCallback(
    ({ id, name, avatar }) => {
      showModal({ payerId: id, name, avatar });
    },
    [showModal],
  );

  return (
    <>
      <div className={s.payersList}>
        {paymentsTotalByPayers.map(({ total, payer: { _id, profile } }, index) => (
          <div key={_id}>
            {_id === 'me' ? (
              <Tooltip placement="top" overlay={<p>{t({ id: 'payments.payer.edit_user_info_message' })}</p>}>
                <div>
                  <ListItem {...{ total, _id, ...profile, index }} />
                </div>
              </Tooltip>
            ) : (
              <ListItem onEditInfo={onEditInfo} {...{ total, _id, ...profile, index }} />
            )}
          </div>
        ))}
      </div>
      <PayerFormModal
        onSubmit={onEditPayerInfo}
        initialValues={initialValues}
        hideModal={hideModal}
        isVisible={isVisible}
        headerText={t({ id: 'payments.payer.edit_payer_info' })}
        buttons={buttons}
        avatar={modalProps?.avatar}
      />
    </>
  );
};

export default PayersList;
