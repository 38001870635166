import React, { memo, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import NumberFormat from 'react-number-format';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import cn from 'classnames';
import clamp from 'lodash/clamp';
import { areEqualFields, onKeyDown } from 'utils/form';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

const NumberInput = ({
  field,
  form: { touched, errors, setFieldValue },
  icon,
  onChange,
  bigLabel = false,
  label,
  style,
  disabled,
  description,
  withoutSeparators,
  sideEffectOnChange,
  className,
  defaultValue,
  min,
  max = 5000000,
  skipScrollingToField,
  required,
  ...props
}) => {
  const { locale } = useIntl();
  const inputRef = useRef();
  const didMountRef = useRef(false);
  const [, forceRerender] = useState(false);
  const { value, name, onBlur } = field;
  const fieldWrapperRef = useRef();

  const handleChange = useCallback(
    (values) => {
      let v;
      if (isNil(values.floatValue)) {
        v = null;
      } else {
        v = clamp(values.floatValue, min, max);
      }
      setFieldValue(name, v);
      forceRerender((b) => !b);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ name, value: v });
    },
    [max, min, name, setFieldValue, sideEffectOnChange],
  );

  const getClassNameForInput =
    touched[field.name] && errors[field.name] ? 'default-input error-input' : 'default-input';

  useEffect(() => {
    if (!didMountRef.current) {
      if (!Number.isFinite(value) && !isNil(defaultValue)) {
        setFieldValue(name, defaultValue);
      }
      didMountRef.current = true;
    }
  }, [name, value, defaultValue, setFieldValue, min]);

  const fieldWrapperClassnames = useMemo(() => {
    let classes = 'field-wrapper';
    if (bigLabel) {
      classes += ' field-with-big-label';
    }
    if (!skipScrollingToField) {
      classes += ' scroll-to-field';
    }
    return cn(classes, className);
  }, [skipScrollingToField, bigLabel, className]);

  const handleKeyDown = useCallback(
    (e) => {
      if (Number.isFinite(value)) onKeyDown(e, fieldWrapperRef);
    },
    [value],
  );

  return (
    <div className={fieldWrapperClassnames} style={style} ref={fieldWrapperRef}>
      {icon && <div style={{ position: 'absolute', bottom: '30%', left: '2%' }}>{icon}</div>}
      <NumberFormat
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...(withoutSeparators ? {} : config[locale])}
        onValueChange={onChange || handleChange}
        name={name}
        value={value}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        autoComplete="off"
        id={name}
        className={cn(getClassNameForInput, disabled && 'disabled-input')}
        disabled={disabled}
        style={icon && { paddingLeft: '50px' }}
        getInputRef={(el) => {
          inputRef.current = el;
        }}
        {...props}
        type="text"
      />

      <label htmlFor={name} id={`label-${name}`} className="text-left">
        {label}
      </label>

      {description ? (
        <div className="text-left">
          <small className="muted">{description}</small>
        </div>
      ) : null}

      {get(touched, name) && get(errors, name) && <div className="error-text text-left">{get(errors, name)}</div>}
    </div>
  );
};

export default memo(NumberInput, areEqualFields);
