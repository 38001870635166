/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { __ } from './form';

export const createEquipmentTypeValidator = (featureName) =>
  yup
    .mixed()
    .notRequired()
    .when(`features.${featureName}`, {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(__('errors.choose_one')),
    });

export const createConditionalValidator = (featureName, type = 'string') => {
  return yup
    .mixed()
    .notRequired()
    .when(`features.${featureName}`, {
      is: true,
      then:
        type === 'string'
          ? yup
              .string()
              .nullable()
              .required(__('errors.fill_field'))
          : yup
              .number()
              .nullable()
              .required(__('errors.fill_field')),
    });
};
