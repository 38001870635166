import React, { useState } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import get from 'lodash/get';
import useUser from 'hooks/user/useUser';
import { Formik, Form, Field } from 'formik';
import { User } from 'assets/icons/sidebar';
import Button from 'components/common/Button';
import { ReactComponent as ArrowDropUp } from 'assets/icons/arrow-drop-up.svg';
import { Link } from 'react-router-dom';
import routePaths, { routePatterns } from 'router/route-paths';
import { useIntl } from 'react-intl';
import TextInput from 'components/inputs/TextInput';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import AvatarField from 'components/common/Avatar/AvatarField';
import useEditUserInfo from 'hooks/user/useEditUserInfo';
import Modal, { useModal } from 'components/common/Modal';
import * as yup from 'yup';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import { GUEST } from 'constants/variables';
import ScrollToError from 'components/common/ScrollToError';
import { ReactComponent as Save } from 'assets/icons/save.svg';
import { __ } from 'utils/form';
import { useHandleDeleteMyAccount, useLogoutHandler } from './Dropup.utils';
import s from './Dropup.module.scss';

const validationSchema = yup.object().shape({
  firstName: yup.string().required(__('errors.fill_field')),
  lastName: yup.string().required(__('errors.fill_field')),
});

const EditUserInfoForm = ({ hideModal, isVisible, onSubmit, user: { profile } }) => {
  const { firstName, lastName, avatar } = profile;
  const { formatMessage: t } = useIntl();
  const avatarFileName = get(avatar, 'file_name', null);
  const initialValues = { firstName, lastName, avatar: avatarFileName };

  return (
    <Modal headerText={t({ id: 'profile.edit_info' })} close={hideModal} isVisible={isVisible} size="sm">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting }) => (
          <Form>
            <ScrollToError inModal />
            <Field name="avatar" component={AvatarField} avatar={avatar} />
            <Field name="firstName" component={TextInput} label={t({ id: 'profile.first_name' })} />
            <Field name="lastName" component={TextInput} label={t({ id: 'profile.last_name' })} />
            <div className={s.btnWrapper}>
              <Button loading={isSubmitting} disabled={isSubmitting} type="submit" endIcon={<Save />}>
                {t({ id: 'app.save' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const DropdownMenu = (props) => {
  const { email, onDeleteAccount, onEditClick, setVisible, hideModal, isVisible, user, onSubmit, projectId } = props;
  const isGuest = useHasRequiredRole(GUEST);
  const onLogout = useLogoutHandler();
  const { formatMessage: t } = useIntl();

  return (
    <Menu className={s.menu}>
      <MenuItem key="1" className={s.menuItemImg}>
        {user.profile?.avatar ? (
          <img src={user.profile.avatar.url} alt="avatar" />
        ) : (
          <User width="100%" height="100%" />
        )}
      </MenuItem>
      {!isGuest ? (
        <button
          type="button"
          className={s.editButton}
          onClick={() => {
            setVisible(false);
            onEditClick();
          }}
        >
          <EditIcon />
        </button>
      ) : null}
      <MenuItem key="2" className={s.name}>{`${user.profile.firstName} ${user.profile.lastName}`}</MenuItem>
      {!isGuest ? (
        <MenuItem key="3" className={s.email}>
          {email}
        </MenuItem>
      ) : null}
      {isGuest ? (
        <MenuItem key="7" className={s.btnMenuItem} onClick={() => setVisible(false)}>
          <Link to={routePatterns.guestSignUp.stringify({ id: projectId })}>
            <Button color="outline">{t({ id: 'auth.sign_up_submit' })}</Button>
          </Link>
        </MenuItem>
      ) : null}
      <MenuItem key="4" className={s.btnMenuItem} onClick={() => setVisible(false)}>
        <Link to={routePaths.contact}>
          <Button color="outline">{t({ id: 'page_titles.contact' })}</Button>
        </Link>
      </MenuItem>
      <MenuItem key="5" className={s.btnMenuItem} onClick={() => setVisible(false)}>
        <Button color="outline" onClick={() => onLogout(setVisible)}>
          {t({ id: 'page_titles.logout' })}
        </Button>
      </MenuItem>
      <div className={s.divider} />
      <MenuItem key="6" className={s.btnDeleteAccount} onClick={() => setVisible(false)}>
        <Button color="danger" onClick={onDeleteAccount}>
          {t({ id: 'profile.delete_my_account.title' })}
        </Button>
      </MenuItem>
      <EditUserInfoForm {...{ hideModal, isVisible, user, onSubmit }} />
    </Menu>
  );
};

const DropdownContent = ({ projectId }) => {
  const [user] = useUser();
  const { formatMessage: t } = useIntl();
  const profile = get(user, 'profile', {});
  const email = get(user, 'emails[0].address', null);
  const avatar = get(user, 'profile.avatar', '');
  const deleteMyAccount = useHandleDeleteMyAccount();
  const { showModal, hideModal, isVisible } = useModal();
  const [visible, setVisible] = useState(false);
  const onSubmit = useEditUserInfo(hideModal);

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <DropdownMenu
          onDeleteAccount={deleteMyAccount}
          onEditClick={showModal}
          {...{ user, projectId, onSubmit, isVisible, hideModal, setVisible, email }}
        />
      }
      animation="slide-up"
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
      placement="topLeft"
    >
      <button type="button" className={s.triggeredContent}>
        <div className={s.imgPlaceholder}>
          {avatar ? (
            <img src={avatar.url} alt={t({ id: 'alt_text.navbar.dropdown_user_icon' })} />
          ) : (
            <User width="100%" height="100%" />
          )}
        </div>
        <div className={s.fullName}>
          <span>{profile.firstName}</span>&nbsp;<span>{profile.lastName}</span>
        </div>
        <ArrowDropUp className={s.arrowDropUp} />
      </button>
    </Dropdown>
  );
};

export default DropdownContent;
