import React from 'react';
import { BudgetStep3 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import ScrollToError from 'components/common/ScrollToError';
import { Field, Form, Formik } from 'formik';
import PageTitle from 'components/common/PageTitle';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as DiagonalLine } from 'assets/icons/diagonal-line.svg';
import { ReactComponent as OneCarIcon } from 'assets/icons/budget/1-car.svg';
import { ReactComponent as TwoCarsIcon } from 'assets/icons/budget/2-cars.svg';
import { ReactComponent as ThreeCarsIcon } from 'assets/icons/budget/3-cars.svg';
import { ReactComponent as FourCarsIcon } from 'assets/icons/budget/4-cars.svg';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import * as yup from 'yup';
import Heading from 'components/inputs/Heading';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';

const validationSchema = yup.object().shape({
  cars: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  amount_of_alimony: yup.string().required(__('errors.fill_field')),
  monthly_loan: yup.string().required(__('errors.fill_field')),
});

const ThirdStep = ({
  initialValues,
  isEditMode,
  onSubmit,
  formValuesRef,
  onSubmitStep,
  nextStep,
  onBack,
  previousStep,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.third_step.title' })}</PageTitle>

        <Formik
          initialValues={isEditMode ? initialValues : formValuesRef.current}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions);
            } else {
              await onSubmitStep(values, actions);
              nextStep();
            }
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />
              <Field
                name="cars"
                label={t({ id: 'budget_calculator.third_step.how_many_cars' })}
                nullable={false}
                contentInColumn
                inOneRow={false}
                component={ButtonSelect}
                options={[
                  {
                    icon: ({ altText }) => <DiagonalLine className="stroke" title={altText} />,
                    altText: t({ id: 'alt_text.icons.has_none' }),
                    value: 0,
                    label: '',
                  },
                  {
                    icon: ({ altText }) => <OneCarIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.one_car' }),
                    value: 1,
                    label: '1',
                  },
                  {
                    icon: ({ altText }) => <TwoCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.two_cars' }),
                    value: 2,
                    label: '2',
                  },
                  {
                    icon: ({ altText }) => <ThreeCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.three_cars' }),
                    value: 3,
                    label: '3',
                  },
                  {
                    icon: ({ altText }) => <FourCarsIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.cars.four_cars' }),
                    value: 4,
                    label: '4+',
                  },
                ]}
              />

              <Field
                name="amount_of_alimony"
                label={t({ id: 'budget_calculator.third_step.amount_of_alimony' })}
                component={SliderWithTooltip}
                min={0}
                max={5000}
                defaultValue={0}
                step={100}
                units="€"
              />

              <Heading label={t({ id: 'budget_calculator.third_step.monthly_loan' })} color="primary" />
              <Field
                name="monthly_loan"
                component={SliderWithTooltip}
                min={0}
                max={2000}
                defaultValue={0}
                step={100}
                units="€"
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep3 title={t({ id: 'alt_text.budget_calculator.third_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default ThirdStep;
