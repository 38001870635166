export default {
  Query: {
    filters: () => {
      const defaultProject = window.location.pathname.split('project/')[1].split('/')[0];
      return {
        projects: [defaultProject],
        categories: [],
        payers: [],
        searchQuery: '',
        __typename: 'Filters',
      };
    },
  },
};
