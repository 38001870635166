import React from 'react';
import colors from 'config/theme';
import s from './Heading.module.scss';

const colorToStyle = {
  primary: colors.primaryColor,
  gray: colors.grayColor,
};

const Heading = ({ label, description, color = 'gray' }) => (
  <div className={s.heading}>
    <p className={s.label} style={{ color: colorToStyle[color] }}>
      {label}
    </p>
    {description ? <p className={s.description}>{description}</p> : null}
  </div>
);

export default Heading;
