import React, { useCallback, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as FilterList } from 'assets/icons/filter-list.svg';
import useGetCategories from 'hooks/payments/useGetCategories';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useModal } from 'components/common/Modal';
import intersectionBy from 'lodash/intersectionBy';
import { User } from 'assets/icons/sidebar';
import { Close } from 'assets/icons';
import { useApolloClient } from '@apollo/client';
import { GET_FILTERS } from 'hooks/payments/useGetFilters';
import s from './SearchPaymentsSection.module.scss';
import FilterPaymentsModal from './FilterPaymentsModal';

const Filters = ({ filters, projects, categories, payers, onDeleteFilterClick }) => {
  const { formatMessage: t } = useIntl();

  const filteredProjects = useMemo(
    () => intersectionBy([...projects], [...filters.projects.map((projectId) => ({ value: projectId }))], 'value'),
    [filters.projects, projects],
  );

  const filteredPayers = useMemo(
    () => intersectionBy([...payers], [...filters.payers.map((payerId) => ({ payer: { _id: payerId } }))], 'payer._id'),
    [filters.payers, payers],
  );

  const filteredCategories = useMemo(
    () =>
      intersectionBy([...categories], [...filters.categories.map((categoryName) => ({ name: categoryName }))], 'name'),
    [categories, filters.categories],
  );

  return (
    <div className={s.filtersContainer}>
      {filteredProjects.length ? (
        <div>
          <p>{t({ id: 'payments.projects' })}</p>
          <div className={s.filter}>
            {filteredProjects.map(({ label, value }) => (
              <div className={s.filterItemBox} key={value}>
                {label}
                <Close className={s.deleteFilter} onClick={() => onDeleteFilterClick('projects', value)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {filteredCategories.length ? (
        <div>
          <p>{t({ id: 'payments.categories' })}</p>
          <div className={s.filter}>
            {filteredCategories.map(({ label, name }) => (
              <div className={s.filterItemBox} key={label}>
                {t({ id: label })}
                <Close className={s.deleteFilter} onClick={() => onDeleteFilterClick('categories', name)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {filteredPayers.length ? (
        <div>
          <p>{t({ id: 'payments.payers' })}</p>
          <div className={s.filter}>
            {filteredPayers.map(({ payer: { _id, profile: { name, avatar } } }) => (
              <div className={s.filterItemBox} key={_id}>
                <div className={s.optionWrapper}>
                  {avatar ? (
                    <img src={avatar?.url} alt="avatar" className={s.avatar} />
                  ) : (
                    <User className={s.defaultAvatar} />
                  )}
                  <div>{name}</div>
                </div>
                <Close className={s.deleteFilter} onClick={() => onDeleteFilterClick('payers', _id)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const SearchPaymentsSection = ({ projects, payers, filters }) => {
  const [searchValue, setSearchValue] = useState(filters.searchQuery);
  const { formatMessage: t } = useIntl();
  const { showModal, hideModal, isVisible } = useModal();
  const { categories, loading } = useGetCategories(filters.projects);
  const client = useApolloClient();

  const initialValues = {
    projects: filters.projects,
    categories: filters.categories,
    payers: filters.payers,
  };

  const onSubmit = useCallback(
    async (values) => {
      client.writeQuery({
        query: GET_FILTERS,
        data: { filters: { ...filters, ...values, searchQuery: searchValue, __typename: 'Filters' } },
      });
      hideModal();
    },
    [client, filters, hideModal, searchValue],
  );

  const searchPayments = useCallback(
    debounce(async (values) => {
      client.writeQuery({ query: GET_FILTERS, data: { filters: { ...values, __typename: 'Filters' } } });
    }, 1000),
    [],
  );

  const handleChange = useCallback(
    async ({ target: { value } }) => {
      setSearchValue(value);
      await searchPayments({ ...filters, searchQuery: value });
    },
    [filters, searchPayments],
  );

  const onDeleteFilterClick = useCallback(
    async (key, value) => {
      client.writeQuery({
        query: GET_FILTERS,
        data: { filters: { ...filters, [key]: [...filters[key].filter((id) => id !== value)], __typename: 'Filters' } },
      });
    },
    [client, filters],
  );

  const onClearFiltersClick = useCallback(async () => {
    const { searchQuery } = filters;
    client.writeQuery({
      query: GET_FILTERS,
      data: { filters: { searchQuery, projects: [], categories: [], payers: [], __typename: 'Filters' } },
    });
  }, [client, filters]);

  if (loading) return <LoadingOverlay />;

  return (
    <div className={s.container}>
      <div className={s.inputWithBtn}>
        <div className={cn('field-wrapper', s.searchFieldWrapper)}>
          <span className={s.icon}>
            <SearchIcon />
          </span>
          <input
            name="searchQuery"
            type="text"
            autoComplete="off"
            id="searchQuery"
            value={searchValue}
            onChange={handleChange}
            style={{ paddingLeft: '40px' }}
            placeholder={t({ id: 'spendings.search_placeholder' })}
          />
        </div>
        <button
          type="button"
          className={s.filtersButton}
          onClick={(e) => {
            e.persist();
            showModal();
          }}
        >
          <FilterList />
        </button>
        {filters.projects.length || filters.categories.length || filters.payers.length ? (
          <button type="button" className={s.btnClearAllFilters} onClick={onClearFiltersClick}>
            {t({ id: 'payments.clear_all_filters' })}
          </button>
        ) : null}
      </div>

      <Filters {...{ filters, projects, categories, payers, onDeleteFilterClick }} />
      <FilterPaymentsModal {...{ hideModal, isVisible, onSubmit, initialValues, projects, payers }} />
    </div>
  );
};
export default SearchPaymentsSection;
