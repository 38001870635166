import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import WarningMessage from 'components/common/WarningMessage';
import { PROPERTY_VALUATION } from 'constants/variables';
import useGetSpendings from 'hooks/spendings/useGetSpendings';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { formatCurrency } from 'utils/helpers';
import Spendings from './Spendings';
import s from './Spendings.module.scss';

function isRecordedAtLeastOnePayment({ payments, categoryName }) {
  return payments.some(({ category }) => category.name === categoryName);
}

export default () => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const { projectPlan, payments, project, loading } = useGetSpendings(projectId);

  const planningCategories = useMemo(
    () =>
      get(projectPlan, 'planningCategories', []).filter(({ calculated_price, user_price, name: categoryName }) => {
        const paymentsIsRecorded = isRecordedAtLeastOnePayment({ payments, categoryName });
        return user_price || calculated_price || paymentsIsRecorded;
      }),
    [payments, projectPlan],
  );

  const paymentsTotal = get(project, 'payments_total', 0);
  const price = get(projectPlan, 'price', 0);

  const percentage = useMemo(
    () => (Number.isFinite(paymentsTotal / price) ? Math.round((paymentsTotal / price) * 100) : 0),
    [paymentsTotal, price],
  );

  const paymentsTotalByCategories = useMemo(
    () =>
      planningCategories.reduce((res, category) => {
        const paymentsInTheCategory = payments.filter(
          ({ category: { name: categoryName } }) => categoryName === category.name,
        );

        const isFinalPayment = paymentsInTheCategory.some(({ payment_type }) => payment_type === 'final');
        const total = paymentsInTheCategory.reduce((acc, { amount }) => acc + amount, 0);

        return [
          ...res,
          {
            category,
            paymentsTotalByCategory: { paymentsTotal: total, isFinalPayment, payments: paymentsInTheCategory },
          },
        ];
      }, []),
    [payments, planningCategories],
  );

  const totalSaved = useMemo(() => {
    const numbers = [];
    paymentsTotalByCategories.forEach(
      ({ category, paymentsTotalByCategory: { paymentsTotal: paymentsTotalByCategory, isFinalPayment } }) => {
        const categoryTotal = Number.isFinite(category.user_price) ? category.user_price : category.calculated_price;
        const percentageByCategory = Number.isFinite(paymentsTotalByCategory / categoryTotal)
          ? Math.round((paymentsTotalByCategory / categoryTotal) * 100)
          : 0;
        // The first check when category "other investments" isn't chosen but payments were recorded
        if (categoryTotal !== 0 && percentage !== 0 && percentageByCategory <= 100 && isFinalPayment) {
          numbers.push(Math.abs(categoryTotal - paymentsTotalByCategory));
        }
      },
    );
    return numbers.length ? numbers.reduce((acc, current) => acc + current) : null;
  }, [paymentsTotalByCategories, percentage]);

  const totalOverspent = useMemo(() => {
    const numbers = [];
    paymentsTotalByCategories.forEach(
      ({ category, paymentsTotalByCategory: { paymentsTotal: paymentsTotalByCategory } }) => {
        const categoryTotal = Number.isFinite(category.user_price) ? category.user_price : category.calculated_price;
        const percentageByCategory = Number.isFinite(paymentsTotalByCategory / categoryTotal)
          ? Math.round((paymentsTotalByCategory / categoryTotal) * 100)
          : 0;
        // The second check when category "other investments" isn't chosen but payments were recorded
        if (percentageByCategory > 100 || (categoryTotal === 0 && percentageByCategory === 0)) {
          numbers.push(Math.abs(categoryTotal - paymentsTotalByCategory));
        }
      },
    );
    return numbers.length ? numbers.reduce((acc, current) => acc + current) : null;
  }, [paymentsTotalByCategories]);

  const deviation = useMemo(() => formatCurrency(Math.abs(totalSaved - totalOverspent)), [totalOverspent, totalSaved]);

  const deviationStyle = useMemo(() => {
    const saved = Math.sign(totalSaved - totalOverspent);
    if (saved === -1) return s.totalOverspent;
    if (saved === 1) return s.totalSaved;
    return null;
  }, [totalOverspent, totalSaved]);

  if (loading) return <LoadingOverlay />;

  if (!project || !projectPlan) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  if (project.type === PROPERTY_VALUATION) return <StartProjectPlanning />;

  if (planningCategories.length === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.dashboard' })}
        message={t({ id: 'dashboard.complete_project_details_step' })}
        btnLabel={t({ id: 'dashboard.complete_project_details_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  return (
    <Spendings
      {...{
        t,
        projectId,
        totalSaved,
        totalOverspent,
        deviation,
        deviationStyle,
        percentage,
        price,
        paymentsTotal,
        paymentsTotalByCategories,
        isPayments: !!payments.length,
        countOfPayments: payments.length,
      }}
    />
  );
};
