/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import ErrorMessage from 'components/common/ErrorMessage';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import CDatePicker from 'components/inputs/DatePicker';
import Checkbox from 'components/inputs/Checkbox';
import TextArea from 'components/inputs/TextArea';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import useRequestPersonalOffer from 'hooks/financing/useRequestPersonalOffer';
import { Visible, Hidden } from 'components/common/Grid';
import useUser from 'hooks/user/useUser';
import get from 'lodash/get';
import TextInput from 'components/inputs/TextInput';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import { GUEST } from 'constants/variables';
import ScrollToError from 'components/common/ScrollToError';
import { __ } from 'utils/form';
import s from './PersonalFinancingRequest.module.scss';

const useRequestPersonalOfferHandler = (hideModal) => {
  const params = useParams();
  const [requestPersonalOffer] = useRequestPersonalOffer();

  const handleSubmit = useCallback(
    async (variables, { setSubmitting }) => {
      const { first_name, last_name, email, year, month, day, ...rest } = variables;
      const profile = { first_name, last_name, email };
      await requestPersonalOffer(params.id, { ...rest, profile });
      hideModal();
      setSubmitting(false);
    },
    [hideModal, params, requestPersonalOffer],
  );

  return handleSubmit;
};

const ReachabilityOnPhoneBefore = ({ t }) => (
  <>
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.before' })}</span>
    <Field
      name="reachability_on_phone_before"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      timeFormat="HH:mm"
      dateFormat="HH:mm"
      component={CDatePicker}
      fieldWrapperClassName={s.fieldWrapper}
    />
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.oclock' })}</span>
  </>
);

const ReachabilityOnPhoneAfter = ({ t }) => (
  <>
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.after' })}</span>
    <Field
      name="reachability_on_phone_after"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={30}
      timeCaption="Time"
      timeFormat="HH:mm"
      dateFormat="HH:mm"
      component={CDatePicker}
      fieldWrapperClassName={s.fieldWrapper}
    />
    <span className={s.text}>{t({ id: 'loan.personal_financing_request.oclock' })}</span>
  </>
);

const personalFinancingRequestShape = yup.object().shape({
  phone_number: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  first_name: yup.string().required(__('errors.fill_field')),
  last_name: yup.string().required(__('errors.fill_field')),
  email: yup.string().required(__('errors.fill_field')),
});

function getTime(hours, minutes) {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

function getInitialValues({ me, isGuest }) {
  return {
    reachability_on_phone_before: getTime(9, 0),
    reachability_on_phone_after: getTime(16, 0),
    day: null,
    month: null,
    year: null,
    prefer_contact_by_email: null,
    prefer_contact_by_phone: null,
    phone_number: null,
    first_name: !isGuest ? get(me, 'profile.firstName', '') : '',
    last_name: !isGuest ? get(me, 'profile.lastName', '') : '',
    email: get(me, 'emails[0].address', ''),
  };
}

const PersonalFinancingRequest = ({ hideModal, buttonLabelId }) => {
  const { formatMessage: t } = useIntl();
  const onSubmit = useRequestPersonalOfferHandler(hideModal);
  const [me] = useUser();
  const isGuest = useHasRequiredRole(GUEST);
  const initialValues = useMemo(() => getInitialValues({ me, isGuest }), [isGuest, me]);

  return (
    <div className={s.container}>
      <Formik {...{ initialValues, onSubmit }} validationSchema={personalFinancingRequestShape}>
        {({ isSubmitting, errors, values }) => (
          <>
            <Form>
              <ScrollToError inModal />
              <Field
                name="first_name"
                component={TextInput}
                label={t({ id: 'loan.personal_financing_request.first_name' })}
              />
              <Field
                name="last_name"
                component={TextInput}
                label={t({ id: 'loan.personal_financing_request.last_name' })}
              />
              <Field
                name="email"
                component={TextInput}
                label={t({ id: 'loan.personal_financing_request.email' })}
                type="email"
                lowerCase
              />

              <label>{t({ id: 'loan.personal_financing_request.prefer_contact_by' })}</label>

              <div className={s.contactByCheckboxesWrapper}>
                <Field
                  name="prefer_contact_by_email"
                  label={t({ id: 'loan.personal_financing_request.prefer_contact_by_email' })}
                  skipScrollingOnChange
                  component={Checkbox}
                />
                <Field
                  name="prefer_contact_by_phone"
                  label={t({ id: 'loan.personal_financing_request.prefer_contact_by_phone' })}
                  skipScrollingOnChange
                  component={Checkbox}
                />
              </div>
              <Field
                name="phone_number"
                component={PhoneNumberInput}
                label={t({ id: 'loan.personal_financing_request.phone_number' })}
              />
              {values.phone_number ? (
                <>
                  <label>{t({ id: 'loan.personal_financing_request.prefer_contact_by' })}</label>
                  {/* desktop */}
                  <Hidden xs sm>
                    <div className={s.inputsWrapper}>
                      <ReachabilityOnPhoneBefore t={t} />
                      <ReachabilityOnPhoneAfter t={t} />
                    </div>
                  </Hidden>
                  {/* mobile */}
                  <Visible xs sm>
                    <div className={s.inputsWrapper}>
                      <div className={s.group}>
                        <ReachabilityOnPhoneBefore t={t} />
                      </div>
                      <div className={s.group}>
                        <ReachabilityOnPhoneAfter t={t} />
                      </div>
                    </div>
                  </Visible>
                </>
              ) : null}
              <Field
                name="additional_information"
                component={TextArea}
                minRows={8}
                label={<FormattedMessage id="loan.personal_financing_request.message" />}
                labelClassName={s.labelTextarea}
                className={s.additionalInformation}
                description={<FormattedMessage id="loan.personal_financing_request.description" />}
              />
              {errors.form && <ErrorMessage message={errors.form} />}

              <div className={s.btnWrapper}>
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                  {t({ id: buttonLabelId })}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PersonalFinancingRequest;
