import React from 'react';
import { ReactComponent as DistrictStandard } from 'assets/icons/co2-calculator/heating-system/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/co2-calculator/heating-system/natural-gas.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/co2-calculator/heating-system/heating-pump.svg';
import { ReactComponent as WoodPellets } from 'assets/icons/co2-calculator/heating-system/wood-pellets.svg';

export const handleOnChangeRoofRenewalType = ({ value, form }) => {
  const { values, setFieldValue } = form;
  const getRoofArea = (roofType) => {
    let area = 0;
    if (roofType === 'gable') {
      area = (values.house_area / values.number_of_floors) * 1.35 * 1.4;
    } else if (roofType === 'hipped') {
      area = (values.house_area / values.number_of_floors) * 1.35 * 1.5;
    } else if (roofType === 'flat') {
      area = (values.house_area / values.number_of_floors) * 1.35;
    }
    return Math.ceil(area);
  };
  setFieldValue('roof_renewal_area', getRoofArea(value));
};

export const handleOnChangeFacadeInsulationType = ({ value, form }) => {
  const { values, setFieldValue } = form;
  const getFacadeArea = (facadeType) => {
    let area = 0;
    if (facadeType === 'single') {
      area = Math.sqrt(values.house_area / values.number_of_floors) * 3.5 * 4 * values.number_of_floors * 1.3;
    } else if (facadeType === 'double') {
      area = Math.sqrt(values.house_area / values.number_of_floors) * 3.5 * 3 * values.number_of_floors * 1.3;
    } else if (facadeType === 'middle') {
      area = Math.sqrt(values.house_area / values.number_of_floors) * 3.5 * 2 * values.number_of_floors * 1.3;
    }
    return Math.ceil(area);
  };
  setFieldValue('facade_insulation_area', getFacadeArea(value));
};

function createEquipmentTypeSelect(options) {
  return {
    label: 'project_wizard.equipment_type',
    type: 'button_select',
    name: options.name,
    options: [
      {
        value: 'standard',
        label: 'equipment_types.standard',
      },
      {
        value: 'premium',
        label: 'equipment_types.premium',
      },
      {
        value: 'superior',
        label: 'equipment_types.superior',
      },
    ],
    required: true,
    nullable: false,
    ...options,
  };
}
export default [
  {
    _id: 'de43b036-6779-4864-8e68-9c60842e1d4f',
    name: 'facade_insulation',
    label: 'buying_house_features.facade_insulation',
    description: 'buying_house_features.facade_insulation_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.facade_insulation.type.name',
        type: 'inline_select',
        sideEffectOnChange: handleOnChangeFacadeInsulationType,
        name: 'facade_insulation_type',
        options: [
          {
            imageSrc: require('assets/images/house-types/single.png'),
            label: 'buying_house_wizard.facade_insulation.type.single_house',
            description: 'buying_house_wizard.facade_insulation.type.single_house_description',
            value: 'single',
          },
          {
            imageSrc: require('assets/images/house-types/double.png'),
            label: 'buying_house_wizard.facade_insulation.type.double_house',
            description: 'buying_house_wizard.facade_insulation.type.double_house_description',
            value: 'double',
          },
          {
            imageSrc: require('assets/images/house-types/middle.png'),
            label: 'buying_house_wizard.facade_insulation.type.middle_house',
            description: 'buying_house_wizard.facade_insulation.type.middle_house_description',
            value: 'middle',
          },
        ],
        required: true,
        nullable: false,
      },
      {
        label: 'buying_house_wizard.facade_insulation.area',
        type: 'slider_with_tooltip',
        name: 'facade_insulation_area',
        bigLabel: true,
        required: true,
        step: 10,
        min: 20,
        max: 500,
        units: 'm²',
        forciblyReplaceInputValue: true,
      },
      createEquipmentTypeSelect({
        name: 'facade_insulation_equipment_type',
        description: 'buying_house_features.facade_insulation_equipment_type_description',
      }),
    ],
  },
  {
    _id: '7adaaa8a-bb7e-428e-879d-2c7233a87c63',
    name: 'buying_house_new_windows',
    label: 'buying_house_features.new_windows',
    description: 'buying_house_features.new_windows_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.new_windows_number',
        type: 'counter',
        name: 'new_windows_number',
        required: true,
        min: 1,
        max: 20,
      },
      {
        label: 'buying_house_wizard.number_of_extra_large_windows',
        type: 'counter',
        name: 'number_of_extra_large_windows',
        defaultValue: 0,
        max: 20,
      },
      {
        label: 'buying_house_wizard.number_of_lift_and_slide_doors',
        name: 'number_of_lift_and_slide_doors',
        type: 'counter',
        required: true,
        defaultValue: 0,
        min: 0,
        max: 5,
      },
      createEquipmentTypeSelect({
        name: 'new_windows_equipment_type',
        description: 'buying_house_features.new_windows_equipment_type_description',
      }),
    ],
  },
  {
    id: '235bc892-a296-4795-bb06-666cdfaa9dc',
    name: 'roof_renewal',
    label: 'buying_house_features.roof_renewal',
    description: 'buying_house_features.roof_renewal_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.roof_renewal.type.name',
        type: 'inline_select',
        sideEffectOnChange: handleOnChangeRoofRenewalType,
        name: 'roof_renewal_type',
        options: [
          {
            imageSrc: require('assets/images/roof/gable.png'),
            label: 'buying_house_wizard.roof_renewal.type.gable',
            description: 'buying_house_wizard.roof_renewal.type.gable_description',
            value: 'gable',
          },
          {
            imageSrc: require('assets/images/roof/flat.png'),
            label: 'buying_house_wizard.roof_renewal.type.flat',
            description: 'buying_house_wizard.roof_renewal.type.flat_description',
            value: 'flat',
          },
          {
            imageSrc: require('assets/images/roof/hipped.png'),
            label: 'buying_house_wizard.roof_renewal.type.hipped',
            description: 'buying_house_wizard.roof_renewal.type.hipped_description',
            value: 'hipped',
          },
        ],
        required: true,
        nullable: false,
      },
      {
        label: 'buying_house_wizard.roof_renewal.area',
        type: 'slider_with_tooltip',
        name: 'roof_renewal_area',
        bigLabel: true,
        required: true,
        min: 30,
        max: 300,
        step: 10,
        units: 'm²',
        forciblyReplaceInputValue: true,
      },
      createEquipmentTypeSelect({
        name: 'roof_renewal_equipment_type',
        description: 'buying_house_features.roof_renewal_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'dcc72e7d-d445-46ea-983e-5145dae4348d',
    name: 'insulation_top_ceiling',
    label: 'buying_house_features.insulation_top_ceiling',
    description: 'buying_house_features.insulation_top_ceiling_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.insulation_top_ceiling_area',
        type: 'slider_with_tooltip',
        name: 'insulation_top_ceiling_area',
        required: true,
        default_value_formula: 'Math.ceil(values.house_area / values.number_of_floors)',
        min: 25,
        max: 300,
        step: 5,
        units: 'm²',
      },
    ],
  },
  {
    _id: 'e9b709ac-78f4-4732-bc95-dc41255f4071',
    name: 'insulation_basement_ceiling',
    label: 'buying_house_features.insulation_basement_ceiling',
    description: 'buying_house_features.insulation_basement_ceiling_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.insulation_basement_ceiling_area',
        type: 'slider_with_tooltip',
        name: 'insulation_basement_ceiling_area',
        default_value_formula: 'Math.ceil(values.house_area / values.number_of_floors)',
        required: true,
        min: 27,
        max: 300,
        step: 3,
        units: 'm²',
      },
    ],
  },
  {
    _id: '53c6335f-349c-4375-b688-5e712097bb7d',
    name: 'buying_house_renewal_of_heating_system',
    label: 'buying_house_features.renewal_of_heating_system',
    description: 'buying_house_features.renewal_of_heating_system_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.heating_system_for_renovation.name',
        type: 'button_select',
        name: 'heating_system_for_renovation',
        bigLabel: true,
        contentInColumn: true,
        inOneRow: false,
        options: [
          {
            icon: ({ altText }) => <HeatingPump className="fill" title={altText} />,
            value: 'heat_pump',
            label: 'buying_house_wizard.heating_system_for_renovation.heat_pump',
          },
          {
            icon: ({ altText }) => <DistrictStandard className="fill" title={altText} />,
            value: 'district',
            label: 'buying_house_wizard.heating_system_for_renovation.district',
          },
          {
            icon: ({ altText }) => <WoodPellets className="fill" title={altText} />,
            value: 'pellet',
            label: 'buying_house_wizard.heating_system_for_renovation.pellet',
          },
          {
            icon: ({ altText }) => <NaturalGas className="fill" title={altText} />,
            value: 'gas',
            label: 'buying_house_wizard.heating_system_for_renovation.gas',
          },
        ],
        required: true,
        nullable: false,
      },
      createEquipmentTypeSelect({
        name: 'renewal_of_heating_system_equipment_type',
        description: 'buying_house_features.renewal_of_heating_system_equipment_type_description',
      }),
    ],
  },
  {
    id: '16d4b0eb-596c-4be2-87eb-b613a96c268d',
    name: 'buying_house_solar_power_system',
    label: 'buying_house_features.solar_power_system',
    description: 'buying_house_features.solar_power_system_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.solar_power_size_kwp',
        type: 'counter',
        name: 'solar_power_size_kwp',
        min: 3,
        max: 15,
        description: 'buying_house_wizard.solar_power_size_kwp_description',
        required: true,
      },
      {
        type: 'inline_select',
        name: 'solar_power_equipment_type',
        label: 'buying_house_wizard.solar_power_equipment_type.name',
        options: [
          {
            imageSrc: require('assets/images/pv_standard.png'),
            label: 'equipment_types.standard',
            description: 'buying_house_wizard.solar_power_equipment_type.standard_description',
            value: 'standard',
          },
          {
            imageSrc: require('assets/images/pv_premium.png'),
            label: 'equipment_types.premium',
            description: 'buying_house_wizard.solar_power_equipment_type.premium_description',
            value: 'premium',
          },
          {
            imageSrc: require('assets/images/pv_superior.png'),
            label: 'equipment_types.superior',
            description: 'buying_house_wizard.solar_power_equipment_type.superior_description',
            value: 'superior',
          },
        ],
        required: true,
      },
    ],
  },
  {
    _id: '77d30980-c195-4ece-a6a2-76aff351dcb1',
    name: 'buying_house_solar_heating',
    label: 'buying_house_features.solar_heating',
    description: 'buying_house_features.solar_heating_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [],
  },
  {
    _id: 'b90f5301-a816-48d8-ad84-85b3a2eba0d4',
    name: 'buying_house_domestic_ventilation',
    label: 'buying_house_features.domestic_ventilation',
    description: 'buying_house_features.domestic_ventilation_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'domestic_ventilation_equipment_type',
        description: 'buying_house_features.domestic_ventilation_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'ba05c7b3-05ec-4c5d-acd9-f95d6bd70a7c',
    name: 'renewal_of_front_door',
    label: 'buying_house_features.renewal_of_front_door',
    description: 'buying_house_features.renewal_of_front_door_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'renewal_of_front_door_equipment_type',
        description: 'buying_house_features.renewal_of_front_door_equipment_type_description',
      }),
    ],
  },
  {
    id: 'f5226dae-1fb5-4d1b-9ae5-343f8ebb93c6',
    name: 'buying_house_wallbox_e_mobility',
    label: 'buying_house_features.wallbox_e_mobility',
    description: 'buying_house_features.wallbox_e_mobility_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [],
  },
  {
    _id: 'a7a23bba-58c6-427c-b05e-be875334daf9',
    name: 'energy_certificate',
    label: 'buying_house_features.energy_certificate',
    description: 'buying_house_features.energy_certificate_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [],
  },
  {
    id: '65b38f2d-a27b-4dc6-9b6c-9f173384be05',
    name: 'buying_house_carport',
    label: 'buying_house_features.carport',
    description: 'buying_house_features.carport_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.number_of_car_ports',
        type: 'counter',
        name: 'number_of_car_ports',
        min: 1,
        max: 5,
        description: 'buying_house_wizard.number_of_car_ports_description',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'carport_equipment_type',
        description: 'buying_house_features.carport_equipment_type_description',
      }),
    ],
  },
  {
    id: '30863c8e-ee5f-49fb-97fa-55176bcbd22a',
    name: 'buying_house_garage',
    label: 'buying_house_features.garage',
    description: 'buying_house_features.garage_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.number_of_garage_place',
        type: 'counter',
        name: 'number_of_garage_places',
        min: 1,
        max: 5,
        description: 'buying_house_wizard.number_of_garage_place_description',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'garage_equipment_type',
        description: 'buying_house_features.garage_equipment_type_description',
      }),
    ],
  },
  {
    _id: '1d7edb2f-1ac3-42df-89a1-953ef258a729',
    name: 'buying_house_kitchen',
    label: 'buying_house_features.kitchen',
    description: 'buying_house_features.kitchen_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'kitchen_equipment_type',
        description: 'buying_house_features.kitchen_equipment_type_description',
      }),
    ],
  },
  {
    _id: '93a51629-4f9b-46a9-9035-8b522e760a5c',
    name: 'air_condition',
    label: 'buying_house_features.air_condition',
    description: 'buying_house_features.air_condition_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'air_condition_equipment_type',
        description: 'buying_house_features.air_condition_equipment_type_description',
      }),
    ],
  },
  {
    _id: '5dd76c60-2a8a-4f0f-acaa-5f9961121257',
    name: 'buying_house_sanitary_renovation',
    label: 'buying_house_features.sanitary_renovation',
    description: 'buying_house_features.sanitary_renovation_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.sanitary_renovation_area',
        type: 'slider_with_tooltip',
        name: 'sanitary_renovation_area',
        bigLabel: true,
        required: true,
        min: 10,
        max: 300,
        defaultValue: 25,
        step: 5,
        units: 'm²',
      },
      {
        label: 'buying_house_wizard.number_of_bathrooms_for_renovation',
        type: 'counter',
        name: 'number_of_bathrooms_for_renovation',
        min: 1,
        max: 5,
        default_value_formula: 'values.number_of_bathrooms',
        required: true,
      },
      {
        label: 'buying_house_wizard.number_of_toilets_for_renovation',
        type: 'counter',
        name: 'number_of_toilets_for_renovation',
        min: 1,
        max: 5,
        default_value_formula: 'values.number_of_toilets',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'sanitary_renovation_equipment_type',
        description: 'buying_house_features.sanitary_renovation_equipment_type_description',
      }),
    ],
  },
  {
    _id: '89ac755c-0c8d-4bf3-afcc-918da5c8711f',
    name: 'buying_house_new_flooring',
    label: 'buying_house_features.new_flooring',
    description: 'buying_house_features.new_flooring_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.new_flooring_area',
        type: 'slider_with_tooltip',
        name: 'new_flooring_area',
        required: true,
        min: 10,
        max: 300,
        defaultValue: 25,
        step: 5,
        units: 'm²',
      },
      createEquipmentTypeSelect({
        name: 'new_flooring_equipment_type',
        description: 'buying_house_features.new_flooring_equipment_type_description',
      }),
    ],
  },
  {
    _id: '0281c053-a2bc-4deb-a56e-c1507f1f80ae',
    name: 'buying_house_renovation_walls_and_interior_doors',
    label: 'buying_house_features.renovation_walls_and_interior_doors',
    description: 'buying_house_features.renovation_walls_and_interior_doors_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'buying_house_wizard.number_of_interior_doors_for_renovation',
        type: 'counter',
        name: 'number_of_interior_doors_for_renovation',
        required: true,
        min: 1,
        max: 20,
      },
      {
        label: 'buying_house_wizard.renovation_walls_and_interior_doors_area',
        type: 'slider_with_tooltip',
        name: 'renovation_walls_and_interior_doors_area',
        required: true,
        min: 10,
        max: 300,
        defaultValue: 50,
        step: 10,
        units: 'm²',
      },
      createEquipmentTypeSelect({
        name: 'renovation_walls_and_interior_doors_equipment_type',
        description: 'buying_house_features.renovation_walls_and_interior_doors_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'f9ec18d5-32bc-4fbf-bfd8-5839fe9dd322',
    name: 'buying_house_renewal_of_electric_installation',
    label: 'buying_house_features.renewal_of_electric_installation',
    description: 'buying_house_features.renewal_of_electric_installation_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'renewal_of_electric_installation_equipment_type',
        description: 'buying_house_features.renewal_of_electric_installation_equipment_type_description',
      }),
    ],
  },
  {
    _id: '95fdad29-828a-4694-bf06-0b9044c52a36',
    name: 'alarm_system',
    label: 'buying_house_features.alarm_system',
    description: 'buying_house_features.alarm_system_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'alarm_system_equipment_type',
        description: 'buying_house_features.alarm_system_equipment_type_description',
      }),
    ],
  },
  {
    _id: '6d6a1982-bf39-402c-96f5-2e00cf61c0c9',
    name: 'buying_house_pool',
    label: 'buying_house_features.pool',
    description: 'buying_house_features.pool_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'pool_equipment_type',
        description: 'buying_house_features.pool_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'a02b8d07-d00c-4a9d-b89c-8559d547d2be',
    name: 'buying_house_winter_garden',
    label: 'buying_house_features.winter_garden',
    description: 'buying_house_features.winter_garden_description',
    autoselect: false,
    group: 'outdoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'winter_garden_equipment_type',
        description: 'buying_house_features.winter_garden_equipment_type_description',
      }),
    ],
  },
  {
    id: '25a97a51-dad5-4b65-b436-4ca7c044a96f',
    name: 'reserves_for_unexpected_costs',
    label: 'buying_house_features.reserves_for_unexpected_costs',
    description: 'buying_house_features.reserves_for_unexpected_costs_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    form: [],
  },
  {
    _id: 'c7bbe36f-255b-433c-853a-2628fec9d9c1',
    name: 'other_investments',
    label: 'planning_categories.other_investments',
    description: 'planning_categories.other_investments_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    form: [
      {
        label: 'planning.other_investments_amount',
        type: 'number',
        name: 'other_investments_amount',
        required: true,
      },
      {
        label: 'planning.other_investments_comment',
        type: 'textarea',
        name: 'other_investments_comment',
        required: true,
        minRows: 8,
      },
    ],
  },
];
