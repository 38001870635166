import React, { useMemo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Hidden, Visible } from 'components/common/Grid';
import Modal from 'components/common/Modal';
import PaymentFormContainer from 'components/payments/PaymentForm';
import { useAddPaymentHandler } from 'hooks/payments/useAddPayment';
import FilteredPayments from 'components/payments/PaymentsList/FilteredPayments';
import useProjectsPayersQuery from 'hooks/payments/useProjectsPayersQuery';
import PaymentsCostsByPayerChart from 'components/payments/PaymentsList/PaymentsCostsByPayerChart';
import PayersList from 'components/payments/Payers/PayersList';
import SearchPaymentsSection from 'components/payments/PaymentsList/SearchPaymentsSection';
import Loading from 'components/common/Loading';
import useGetFilters from 'hooks/payments/useGetFilters';
import useGetPayments from 'hooks/payments/useGetPayments';

export default ({ hideModal, isVisible, projectId, t }) => {
  const { filters } = useGetFilters();
  const { payments, loading: paymentsLoading, fetchMore } = useGetPayments({ filters });
  const onAddPayment = useAddPaymentHandler({ hideModal, countOfPayments: payments.length });
  const { projects, paymentsTotalByPayers, loading } = useProjectsPayersQuery();
  const formattedProjects = useMemo(() => projects.map((project) => ({ label: project.name, value: project._id })), [
    projects,
  ]);

  const payers = useMemo(() => paymentsTotalByPayers.map(({ payer }) => ({ payer })), [paymentsTotalByPayers]);

  const initialValues = {
    name: '',
    notes: '',
    category: '',
    payee: '',
    amount: null,
    payment_type: '',
    paid_by: '',
    project_id: projectId,
    attachments: [],
    date_paid: new Date(),
  };

  return (
    <>
      <Row>
        <Col md={7} lg={8}>
          <Row>
            <Col xs={12}>
              <SearchPaymentsSection {...{ payers, filters, projects: formattedProjects }} />
              {!loading ? (
                <>
                  <Visible xs sm>
                    <PaymentsCostsByPayerChart {...{ paymentsTotalByPayers }} height={300} thickness={50} />
                    <PayersList {...{ paymentsTotalByPayers }} />
                  </Visible>
                  <FilteredPayments
                    {...{ payers, payments, fetchMore, projects: formattedProjects, loading: paymentsLoading }}
                  />
                </>
              ) : (
                <Loading size={30} />
              )}
            </Col>
          </Row>
        </Col>
        {!loading ? (
          <Hidden xs sm>
            <Col md={5} lg={4}>
              <div style={{ position: 'sticky', top: '30px' }}>
                <PaymentsCostsByPayerChart {...{ paymentsTotalByPayers }} />
                <PayersList {...{ paymentsTotalByPayers }} />
              </div>
            </Col>
          </Hidden>
        ) : null}
      </Row>

      <Modal headerText={t({ id: 'payments.new_payment' })} isVisible={isVisible} close={hideModal} size="lg">
        <PaymentFormContainer {...{ initialValues, payers, projects: formattedProjects, onSubmit: onAddPayment }} />
      </Modal>
    </>
  );
};
