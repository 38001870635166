/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import { useAppContext } from 'contexts/AppContext';
import s from './CircleButton.module.scss';
import Loading from './Loading';

const buttonThemes = {
  primary: {
    default: s.default,
    primary: s.primary,
    secondary: s.secondary,
  },
  green: {
    default: s.defaultSuccess,
    primary: s.primarySuccess,
    secondary: s.secondarySuccess,
  },
};

export default function CircleButton({
  children,
  className,
  color = 'default',
  disabled,
  loading,
  type = 'button',
  onClick,
  ...rest
}) {
  const { theme } = useAppContext();
  return (
    <button
      className={cn(s.circleButton, buttonThemes[theme][color], className)}
      type={type}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {loading ? <Loading size={25} /> : children}
    </button>
  );
}
