/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import { NavLink, useParams, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Euro, Wallet, LocalOffer, Document, Dashboard, Subject } from 'assets/icons/sidebar';
import { ReactComponent as Assignment } from 'assets/icons/sidebar/assignment.svg';
import { ReactComponent as VerticalSplit } from 'assets/icons/sidebar/vertical-split.svg';
import { Visible, Hidden } from 'components/common/Grid';
import useLastProjectId, { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';
import { useApolloClient } from '@apollo/client';
import { useAppContext } from 'contexts/AppContext';
import routePaths, { routePatterns } from 'router/route-paths';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import Dropup from 'components/layout/Dropup';
import { DEVELOPER } from 'constants/variables';
import { ReactComponent as WrenchIcon } from 'assets/icons/wrench.svg';
import useUser from 'hooks/user/useUser';
import { get } from 'lodash';
import s from './Sidebar.module.scss';
import LanguageSwitcher from './LanguageSwitcher';

export const sidebarItems = [
  {
    id: '0',
    getPath: () => routePaths.myProjects,
    title: 'page_titles.my_projects',
    hideWhenNoProject: false,
    getIcon: ({ t }) => <Subject title={t({ id: 'alt_text.icons.subject' })} />,
  },
  {
    id: '1',
    getPath: ({ projectId }) => routePatterns.dashboard.stringify({ id: projectId }),
    title: 'page_titles.dashboard',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <Dashboard title={t({ id: 'alt_text.icons.dashboard' })} />,
  },
  {
    id: '2',
    getPath: ({ projectId }) => routePatterns.projectDetailsCalculator.stringify({ id: projectId }),
    title: 'page_titles.project_info',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <Document title={t({ id: 'alt_text.icons.document' })} />,
  },
  {
    id: '4',
    getPath: ({ projectId }) => routePatterns.plan.stringify({ id: projectId }),
    title: 'page_titles.plan',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <LocalOffer title={t({ id: 'alt_text.icons.local_offer' })} />,
  },
  {
    id: '5',
    getPath: ({ projectId }) =>
      projectId
        ? routePatterns.lifestyleOverviewWithProject.stringify({ id: projectId })
        : routePaths.lifestyleOverview,
    title: 'page_titles.budget',
    hideWhenNoProject: false,
    getIcon: ({ t }) => <Wallet title={t({ id: 'alt_text.icons.wallet' })} />,
  },
  {
    id: '6',
    getPath: ({ projectId }) => routePatterns.loan.stringify({ id: projectId }),
    title: 'page_titles.loan',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <Euro title={t({ id: 'alt_text.icons.euro' })} />,
  },
  {
    id: '7',
    getPath: ({ projectId }) => routePatterns.payments.stringify({ id: projectId }),
    title: 'page_titles.payments',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <VerticalSplit title={t({ id: 'alt_text.icons.vertical_split' })} />,
  },
  {
    id: '8',
    getPath: ({ projectId }) => routePatterns.spendings.stringify({ id: projectId }),
    title: 'page_titles.spendings',
    hideWhenNoProject: true,
    getIcon: ({ t }) => <Assignment title={t({ id: 'alt_text.icons.assignment' })} />,
  },
  {
    id: '9',
    getPath: ({ projectId }) => routePatterns.debugMode.stringify({ id: projectId }),
    title: 'page_titles.debug_mode',
    showFor: DEVELOPER,
    getIcon: () => <WrenchIcon />,
  },
];

export function SidebarMenuItem({ path, title, icon, onClick, hideWhenNoProject, projectId, t, theme }) {
  const sidebarItemClassNames = {
    primary: {
      className: s.sidebarMenuItem,
      activeClassName: s.sidebarMenuItemActive,
    },
    green: {
      className: s.greenSidebarMenuItem,
      activeClassName: s.greenSidebarMenuItemActive,
    },
  };

  if (!projectId && hideWhenNoProject) return null;

  return (
    <li>
      <NavLink
        to={path}
        className={sidebarItemClassNames[theme].className}
        onClick={onClick}
        activeClassName={sidebarItemClassNames[theme].activeClassName}
        exact
      >
        <span className={s.sidebarMenuItemIcon}>{icon}</span>
        {t({ id: title })}
      </NavLink>
    </li>
  );
}

const useLastProjectIdMemo = () => {
  const { lastProjectId } = useLastProjectId();
  const [projectId, setProjectId] = React.useState('');
  const { id: paramsId } = useParams();
  const client = useApolloClient();

  useEffect(() => {
    if (lastProjectId) {
      if (paramsId && lastProjectId !== paramsId) {
        client.writeQuery({
          query: LAST_PROJECT_ID_QUERY,
          data: { lastProject: { _id: paramsId, __typename: 'Project' } },
        });
      } else {
        setProjectId(lastProjectId);
      }
    }
  }, [client, lastProjectId, paramsId, setProjectId]);
  return projectId;
};

export default function Sidebar() {
  const projectId = useLastProjectIdMemo();
  const { sidebarOpen, toggleSidebar, theme } = useAppContext();
  const { formatMessage: t } = useIntl();
  const [me] = useUser();
  const roles = get(me, 'roles', []);
  const hideSidebar = useCallback(() => toggleSidebar(false), [toggleSidebar]);

  return (
    <>
      <div className={cn(s.sidebar, { [s.sidebarOpen]: sidebarOpen })}>
        <Hidden xs sm>
          <div className={s.logoContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={routePaths.myProjects}>
                <Logo className={s.logo} />
              </Link>
              <LanguageSwitcher />
            </div>
            <div className={s.divider} />
          </div>
        </Hidden>
        <div className={s.sidebarContent}>
          <ul className={cn(s.sidebarMenu, s.mainMenu)}>
            {sidebarItems.map((item) => {
              if (item.showFor && !roles.includes(item.showFor)) return null;
              return (
                <SidebarMenuItem
                  key={item.id}
                  onClick={hideSidebar}
                  path={item.getPath({ projectId })}
                  icon={item.getIcon({ t })}
                  {...{ ...item, t, theme, projectId }}
                />
              );
            })}
          </ul>
          <div className={s.footerMenu}>
            <div className={s.divider} />
            <Dropup projectId={projectId} />
          </div>
        </div>
      </div>
      {sidebarOpen ? (
        <Visible xs sm>
          <div onClick={hideSidebar} className={s.sidebarOverlay} />
        </Visible>
      ) : null}
    </>
  );
}
