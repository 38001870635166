import ApartmentValuationForm from 'components/calculators/PropertyValuation/ApartmentValuationForm';
import BuyingHouseValuationForm from 'components/calculators/PropertyValuation/BuyingHouseValuationForm';
import LandValuationForm from 'components/calculators/PropertyValuation/LandValuationForm';
import Alert from 'components/common/Alert';
import PageTitle from 'components/common/PageTitle';
import { BUYING_HOUSE, GUEST, LAND_ESTIMATION, NEW_APARTMENT } from 'constants/variables';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import useUpdateProjectPropertyValuation from 'hooks/project-details/useUpdateProjectPropertyValuation';
import useGetProjectCalculations from 'hooks/useGetProjectCalculations';
import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

const projectTypeToPropertyValuationFormMap = {
  [NEW_APARTMENT]: ApartmentValuationForm,
  [BUYING_HOUSE]: BuyingHouseValuationForm,
  [LAND_ESTIMATION]: LandValuationForm,
};

const useUpdateProjectPropertyValuationHandler = (projectId) => {
  const onSubmit = useUpdateProjectPropertyValuation(projectId);

  const handleSubmit = useCallback(
    async (values, { setSubmitting }, changeProjectType = true) => {
      setSubmitting(true);
      try {
        await onSubmit({ projectId, formData: { ...values, change_project_type: changeProjectType } });
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, projectId],
  );
  return handleSubmit;
};

const plannedProjectTypeToImmazingType = {
  [NEW_APARTMENT]: 'immazing',
  [BUYING_HOUSE]: 'immazing',
  [LAND_ESTIMATION]: 'immazing_land',
};

const PropertyValuationCalculator = ({ project }) => {
  const { formatMessage: t } = useIntl();
  const { calculations, immazingRequestsCount: requestsCount, loading } = useGetProjectCalculations(project._id);
  const onSubmit = useUpdateProjectPropertyValuationHandler(project._id);
  const isGuest = useHasRequiredRole(GUEST);

  const immazingType = plannedProjectTypeToImmazingType[project.planned_project_type];

  const estimationRequestCompleted = useMemo(() => {
    const requestCompleted = get(calculations, `${immazingType}.request_completed`, null);
    return requestCompleted || requestsCount >= 10;
  }, [calculations, immazingType, requestsCount]);

  const PropertyValuationForm = projectTypeToPropertyValuationFormMap[project.planned_project_type];

  if (!PropertyValuationForm) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  return (
    <Grid>
      <Row between="lg" center="md">
        <Col md={9} lg={12}>
          <PageTitle>{t({ id: 'page_titles.property_valuation' })}</PageTitle>
        </Col>
      </Row>
      <PropertyValuationForm
        {...{ project, calculations, requestsCount, loading, onSubmit, isGuest, estimationRequestCompleted }}
      />
    </Grid>
  );
};

export default PropertyValuationCalculator;
