import React from 'react';
import CandleChart from 'components/CandleChart';
import { formatCurrency } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { redColor } from 'styles/variables.scss';
import s from './TopCard.module.scss';
import { getBodyPriceTextForTopCard } from '../helpers';

const TopCard = ({ percentage, price, paymentsTotal, totalSaved, totalOverspent, deviation, deviationStyle }) => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <div className={s.card}>
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' }}>
          <h4>{t({ id: 'spendings.total_costs' })}</h4>
          <div>
            <div style={{ color: percentage > 100 ? redColor : '#40C2C7' }}>
              {getBodyPriceTextForTopCard({
                percentage,
                price,
                paymentsTotal,
                t,
              })}
            </div>
            <small>
              {formatCurrency(paymentsTotal)} / {formatCurrency(price)} ({percentage}%){' '}
              {t({ id: 'spendings.budget_spent' })}
            </small>
          </div>
          <div className={s.totals}>
            <div className={s.saved}>
              {t({ id: 'spendings.totals.saved' }, { savings: formatCurrency(totalSaved) })}
            </div>
            <div className={s.overspent}>
              {t({ id: 'spendings.totals.overspent' }, { overspends: formatCurrency(totalOverspent) })}
            </div>
            <div className={deviationStyle}>{t({ id: 'spendings.totals.deviation' }, { deviation })}</div>
          </div>
        </div>
        <CandleChart vertical value={percentage} complete={false} />
      </div>
    </>
  );
};

export default TopCard;
