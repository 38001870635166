import React, { memo, useCallback } from 'react';
import get from 'lodash/get';
import PhoneInput from 'react-phone-number-input';
import cn from 'classnames';
import { areEqualFields } from 'utils/form';
import 'react-phone-number-input/style.css';
import s from './PhoneNumberInput.module.scss';

const PhoneNumberInput = ({ field, form, label, ...props }) => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const handleChange = useCallback(
    (v) => {
      setFieldValue(name, v);
    },
    [name, setFieldValue],
  );

  const handleOnBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <div className={cn('field-wrapper', s.phoneFieldWrapper)}>
      <label htmlFor={name} className={s.label} id={`label-${name}`}>
        {label}
      </label>
      <PhoneInput
        {...props}
        autoComplete="none"
        limitMaxLength
        smartCaret
        international
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        className={s.numberInput}
      />
      {get(touched, name) && get(errors, name) && <div className="error-text">{get(errors, name)}</div>}
    </div>
  );
};

export default memo(PhoneNumberInput, areEqualFields);
