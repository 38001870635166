import React from 'react';
import classnames from 'classnames';
import styles from './Footer.module.scss';

export default function Footer({ fluid }) {
  return (
    <footer className={styles.footer}>
      <div className={classnames({ 'container-fluid': fluid, 'root-container': !fluid })}>
        Copyright © {new Date().getFullYear()} Talo IT GmbH
      </div>
    </footer>
  );
}
