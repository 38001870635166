/* eslint-disable jsx-a11y/control-has-associated-label */
import { Header } from 'components/common/heap';
import { get, isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import EnergyReductionItems from './EnergyReductionItems/EnergyReductionItems';
import { EnergyScaleClasses } from './EnergyClassesAndIndicators/EnergyClassesAndIndicators';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import EnuPartner from './EnuPartner';
import s from './CO2StatusProjectPlanSection.module.scss';

const CO2StatusProjectPlanSection = ({ project }) => {
  const { formatMessage: t } = useIntl();
  const { calculations } = project;
  const before = get(calculations, 'co2_emissions_before.result', null);
  const after = get(calculations, 'co2_emissions_after.result', null);

  const equivalentValues = useMemo(() => {
    const values = { energyGhgEquivalentPlane: 0, energyGhgEquivalentCar: 0, energyGhgEquivalentTrees: 0 };
    if (before && after) {
      values.energyGhgEquivalentPlane = before.energy_ghg_equivalent_plane - after.energy_ghg_equivalent_plane;
      values.energyGhgEquivalentCar = before.energy_ghg_equivalent_car - after.energy_ghg_equivalent_car;
      values.energyGhgEquivalentTrees = before.energy_ghg_equivalent_trees - after.energy_ghg_equivalent_trees;
    }
    return values;
  }, [before, after]);

  if (!before || !after || isEqual(before, after)) return null;

  return (
    <Row>
      <Col xs={12}>
        <Header>
          <h3>{t({ id: 'renovation_house_wizard.house_energy_balance.before_and_after_header' })}</h3>
        </Header>
      </Col>
      <Col xl={7}>
        <EnergyScaleClasses {...{ calculations, t, costOverviewTab: true }} />
      </Col>
      <Col xl={5}>
        <div className={s.energyReductionItems}>
          <EnergyReductionItems {...{ t, calculations, type: 'after' }} />
        </div>
      </Col>
      <Col xs={12}>
        <div className="my-4">
          <EnergyGhgEquivalents {...{ equivalentValues, costOverviewTab: true }} />
        </div>
      </Col>
      <Col xs={12}>
        <EnuPartner />
      </Col>
    </Row>
  );
};

export default CO2StatusProjectPlanSection;
