import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import ScrollToError from 'components/common/ScrollToError';
import { PROPERTY_VALUATION } from 'constants/variables';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Col } from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { ReactComponent as BuyingHouse } from 'assets/images/quick-calculator/buying-house.svg';
import { ReactComponent as LandImg } from 'assets/images/quick-calculator/land.svg';
import { ReactComponent as BuyingApartment } from 'assets/images/quick-calculator/buying-apartment.svg';
import InlineSelect from 'components/inputs/InlineSelect';
import Checkbox from 'components/inputs/Checkbox';
import settings from 'config/settings';
import { __ } from 'utils/form';
import s from './QuickCalculator.module.scss';

const validationSchema = yup.object().shape({
  planned_project_type: yup
    .mixed()
    .notRequired()
    .when('type', {
      is: (type) => type === PROPERTY_VALUATION,
      then: yup
        .string()
        .nullable()
        .required(__('errors.choose_one')),
    }),
});

export const ProjectCard = ({ handleSelect, t, type, label, isSubmitting, altText, img: Image }) => (
  <Col xs={12} md={6} xl={3} className="mb-4">
    <div className={s.projectCard}>
      <Image title={t({ id: altText })} />
      <h3>{t({ id: label })}</h3>
      <div className={s.startBtnWrapper}>
        <Button disabled={isSubmitting} onClick={() => handleSelect(type)} endIcon={<ArrowForward />}>
          {t({ id: 'app.start' })}
        </Button>
      </div>
    </div>
  </Col>
);

export const QuickCalculatorModal = ({
  hideModal,
  isVisible,
  t,
  isSubmitting,
  me,
  projectType,
  onSubmit,
  initialValues,
}) => {
  return (
    <Modal
      close={hideModal}
      isVisible={isVisible}
      size="md"
      headerText={t({ id: !me ? 'auth.modal_header' : 'project_types.property_valuation' })}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (args, formikActions) => {
          const newValues = { ...args, type: projectType };
          await onSubmit(newValues, formikActions);
        }}
      >
        {() => (
          <Form>
            <ScrollToError inModal />
            {projectType === PROPERTY_VALUATION ? (
              <Field
                name="planned_project_type"
                label={t({ id: 'property_valuation.planned_project_types.label' })}
                options={[
                  {
                    img: BuyingApartment,
                    label: t({ id: 'property_valuation.planned_project_types.new_apartment' }),
                    description: t({ id: 'property_valuation.planned_project_types.new_apartment_description' }),
                    value: 'new_apartment',
                  },
                  {
                    img: BuyingHouse,
                    label: t({ id: 'property_valuation.planned_project_types.buying_house' }),
                    description: t({ id: 'property_valuation.planned_project_types.buying_house_description' }),
                    value: 'buying_house',
                  },
                  {
                    img: LandImg,
                    label: t({ id: 'property_valuation.planned_project_types.land_estimation' }),
                    description: t({ id: 'property_valuation.planned_project_types.land_estimation_description' }),
                    value: 'land_estimation',
                  },
                ]}
                component={InlineSelect}
              />
            ) : null}

            {!me ? (
              <>
                <p className="mt-4 mb-0">{t({ id: 'auth.terms_of_use_text' })}</p>

                <Field
                  name="terms_of_use_consent"
                  component={Checkbox}
                  validate={(v) => (!v ? <FormattedMessage id="auth.terms_of_use_required" /> : null)}
                  skipScrollingOnChange
                  label={
                    <FormattedMessage
                      id="auth.terms_of_use"
                      values={{
                        link: (
                          <a href={settings.termsOfUseUrl} rel="noopener noreferrer" target="_blank">
                            {t({ id: 'auth.terms_of_use_link_label' })}
                          </a>
                        ),
                      }}
                    />
                  }
                />
                <Field
                  name="privacy_policy_consent"
                  component={Checkbox}
                  validate={(v) => (!v ? <FormattedMessage id="auth.privacy_policy_required" /> : null)}
                  skipScrollingOnChange
                  label={
                    <FormattedMessage
                      id="auth.privacy_policy"
                      values={{
                        link: (
                          <a href={settings.privacyPolicyUrl} rel="noopener noreferrer" target="_blank">
                            {t({ id: 'auth.privacy_policy_link_label' })}
                          </a>
                        ),
                      }}
                    />
                  }
                />
              </>
            ) : null}

            <div className={s.submitBtn}>
              <Button loading={isSubmitting} disabled={isSubmitting} endIcon={<ArrowForward />} type="submit">
                {t({ id: 'app.continue' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
