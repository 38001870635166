import React, { useState, useCallback } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import Button from 'components/common/Button';
import { ReactComponent as MoreActions } from 'assets/icons/more-actions.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Trash } from 'assets/icons';
import { toast } from 'react-toastify';
import useArchivePayment from 'hooks/payments/useArchivePayment';
import { confirmAlert } from 'react-confirm-alert';
import useGetFilters from 'hooks/payments/useGetFilters';
import s from './PaymentsList.module.scss';

const useArchivePaymentHandler = () => {
  const { filters } = useGetFilters();
  const onArchivePayment = useArchivePayment({ filters });
  const { formatMessage: t } = useIntl();

  const handleArchive = useCallback(
    async (paymentId) => {
      try {
        confirmAlert({
          title: t({ id: 'payments.remove_payment_alert.title' }),
          message: t({ id: 'payments.remove_payment_alert.message' }),
          buttons: [
            {
              label: t({ id: 'payments.remove_payment_alert.cancel' }),
            },
            {
              label: t({ id: 'payments.remove_payment_alert.confirm' }),
              onClick: async () => {
                await onArchivePayment(paymentId);
              },
            },
          ],
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
    [onArchivePayment, t],
  );
  return handleArchive;
};

const DropdownMenu = ({ paymentId, onArchivePayment, setVisible }) => (
  <Menu className="dropdown-menu">
    <MenuItem key="1" className="menu-item">
      <Button
        color="outline"
        className={s.menuItem}
        startIcon={<Trash />}
        onClick={(e) => {
          e.stopPropagation();
          onArchivePayment(paymentId);
          setVisible(false);
        }}
      >
        <FormattedMessage id="payments.remove_payment" />
      </Button>
    </MenuItem>
  </Menu>
);

const DropdownMoreActions = ({ paymentId }) => {
  const onArchivePayment = useArchivePaymentHandler();
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <DropdownMenu alignPoint paymentId={paymentId} setVisible={setVisible} onArchivePayment={onArchivePayment} />
      }
      placement="bottomRight"
      onVisibleChange={setVisible}
      visible={visible}
    >
      <div className={s.triggeredContent}>
        <MoreActions />
      </div>
    </Dropdown>
  );
};

export default DropdownMoreActions;
