import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';

export const PROJECT_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useProject = (projectId) => {
  const { loading, data } = useQuery(PROJECT_QUERY, { variables: { id: projectId } });

  return [data?.project, loading];
};

export default useProject;
