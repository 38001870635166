import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useParams } from 'react-router-dom';
import PageTitle from 'components/common/PageTitle';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useProjectPlan from 'hooks/useProjectPlan';
import Alert from 'components/common/Alert';
import ProjectPlanTables from 'components/plan/ProjectPlanTables';
import ActionButtons from 'components/plan/ActionButtons';
import { routePatterns } from 'router/route-paths';
import WarningMessage from 'components/common/WarningMessage';
import { PROPERTY_VALUATION } from 'constants/variables';
import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import styles from './Plan.module.scss';

export default function Plan() {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const { projectPlan, project, loading } = useProjectPlan(projectId);

  if (loading) return <LoadingOverlay />;

  if (!project) {
    return <Alert color="danger">{t({ id: 'errors.project_not_found' })}</Alert>;
  }

  if (project.type === PROPERTY_VALUATION) {
    return <StartProjectPlanning />;
  }

  if (!projectPlan?.planningCategories) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  if (projectPlan?.price === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.plan' })}
        message={t({ id: 'project_plan.complete_previous_step' })}
        btnLabel={t({ id: 'project_plan.complete_previous_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  return (
    <Grid className={styles.container}>
      <Row between="xs">
        <Col md={12}>
          <div className={styles.header}>
            <PageTitle>{t({ id: 'page_titles.plan' })}</PageTitle>
            <ActionButtons projectId={project._id} pdf={projectPlan.pdf} />
          </div>
        </Col>
      </Row>
      <ProjectPlanTables {...{ project, projectPlan }} />
    </Grid>
  );
}
