import React, { useEffect, useState, memo, useRef, useMemo } from 'react';
import { areEqualFields } from 'utils/form';
import cn from 'classnames';
import Tooltip from './Tooltip';
import Marks from './Marks';
import s from './SliderWithTooltip.module.scss';
import Range from './Range';

const SliderWithTooltip = ({
  field,
  form,
  sideEffect,
  sideEffectOnChange,
  description,
  defaultValue,
  label,
  withAllMarks,
  thousandSeparator,
  skipScrollingToField,
  bigLabel,
  forciblyReplaceInputValue,
  units = '',
  min = 0,
  step = 1,
  max = 100,
}) => {
  const { name, value } = field;
  const [stepValue, setStepValue] = useState(step);
  const [changeableStep, setChangeableStep] = useState(true);
  const defaultNumberInputValue = useMemo(() => [value, defaultValue, min].find((v) => Number.isFinite(v)), [
    defaultValue,
    min,
    value,
  ]);
  const [numberInputValue, setNumberInputValue] = useState(defaultNumberInputValue);
  const fieldWrapperRef = useRef();

  useEffect(() => {
    const stepForBigValues = 10000;
    if (step !== 1 && !changeableStep) {
      setStepValue(1);
    }
    if (changeableStep) {
      if (value >= 100000 && stepValue !== stepForBigValues) {
        setStepValue(10000);
      }
      if (value < 100000 && stepValue !== step) {
        setStepValue(step);
      }
    }
  }, [value, step, max, stepValue, changeableStep]);

  useEffect(() => {
    if (!Number.isFinite(value)) {
      form.setFieldValue(name, defaultValue || min);
    }
    if (Number.isFinite(value)) {
      if (value <= min) {
        form.setFieldValue(name, min);
      }
      if (value >= max) {
        form.setFieldValue(name, max);
      }
    }
  }, [name, value, defaultValue, min, form, max, field.name]);

  useEffect(() => {
    if (typeof sideEffect === 'function') sideEffect();
  }, [max, min, sideEffect, step, value]);

  const fieldWrapperClassnames = useMemo(() => {
    const classes = [s.fieldWrapper];
    if (bigLabel) classes.push(s.fieldWrapperBigLabel);
    if (!skipScrollingToField) classes.push('scroll-to-field');
    return cn(...classes);
  }, [bigLabel, skipScrollingToField]);

  return (
    <div className={fieldWrapperClassnames} ref={fieldWrapperRef}>
      <div className={s.sliderWrapper}>
        <label htmlFor={name} className={s.inputLabel} id={`label-${name}`}>
          {label}
        </label>
        <Range
          {...{
            field,
            form,
            stepValue,
            min,
            max,
            changeableStep,
            setChangeableStep,
            setNumberInputValue,
            sideEffectOnChange,
          }}
        />
        <Marks {...{ max, min, step, units, withAllMarks, thousandSeparator }} />
      </div>
      <Tooltip
        {...{
          sliderValue: value,
          min,
          max,
          name,
          units,
          form,
          thousandSeparator,
          numberInputValue,
          sideEffectOnChange,
          setStepValue,
          setNumberInputValue,
          changeableStep,
          setChangeableStep,
          fieldWrapperRef,
          forciblyReplaceInputValue,
        }}
      />
      {description ? (
        <div className="text-left">
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {form.errors[name] && form.touched[name] && <div className="error-text">{form.errors[name]}</div>}
    </div>
  );
};

export default memo(SliderWithTooltip, areEqualFields);
