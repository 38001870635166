/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import {
  NEW_BUILDING_FORM_VALUES,
  NEW_APARTMENT_FORM_VALUES,
  BUYING_HOUSE_FORM_VALUES,
  RENOVATION_HOUSE_FORM_VALUES,
  PROPERTY_VALUATION_FORM_VALUES,
} from 'graphql/fragments/project-form-values';

export const PROJECT_FRAGMENT = gql`
  fragment project on Project {
    _id
    pdf
    zip
    name
    type
    price
    budget
    country
    features
    equipment_type
    estimated_property_price
    planned_project_type
    form_values {
      ... on NewBuildingFormValues {
        ...newBuildingFormValues
      }
      ... on NewApartmentFormValues {
        ...newApartmentFormValues
      }
      ... on BuyingHouseFormValues {
        ...buyingHouseFormValues
      }
      ... on RenovationHouseFormValues {
        ...renovationHouseFormValues
      }
      ... on PropertyValuationFormValues {
        ...propertyValuationFormValues
      }
    }
    progress {
      project_details
      step
    }
  }
  ${NEW_BUILDING_FORM_VALUES}
  ${NEW_APARTMENT_FORM_VALUES}
  ${BUYING_HOUSE_FORM_VALUES}
  ${RENOVATION_HOUSE_FORM_VALUES}
  ${PROPERTY_VALUATION_FORM_VALUES}
`;
