import React from 'react';
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import TextArea from 'components/inputs/TextArea';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import ErrorMessage from 'components/common/ErrorMessage';
import { ArrowForward } from 'assets/icons';
import { __ } from 'utils/form';
import styles from './ContactForm.module.scss';

const initialValue = { message: '' };

const contactParamsSchema = yup.object().shape({
  message: yup.string().required(__('errors.fill_field')),
});

const ContactForm = ({ onSubmit }) => {
  const { formatMessage: t } = useIntl();
  return (
    <Formik initialValues={initialValue} validationSchema={contactParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) => (
        <>
          <div className={styles.container}>
            <Form>
              <Field
                className={styles.messageField}
                name="message"
                component={TextArea}
                minRows={15}
                label={t({ id: 'contact.note' })}
              />
              {errors.form && <ErrorMessage message={errors.form} />}
              <div className={styles.buttonContainer}>
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                  {t({ id: 'app.send' })}
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ContactForm;
