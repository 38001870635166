/* eslint-disable react/no-danger */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/common/Button';
import { routePatterns } from 'router/route-paths';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { ReactComponent as Emoji } from 'assets/icons/emoji.svg';
import { ReactComponent as PasteIcon } from 'assets/icons/paste.svg';
import { ReactComponent as FinishFlag } from 'assets/icons/finish-flag.svg';
import { ArrowForward } from 'assets/icons';
import PageTitle from 'components/common/PageTitle';
import Tooltip from 'components/common/Tooltip';
import Modal, { useModal } from 'components/common/Modal';
import PersonalFinancingRequest from 'components/loan/PersonalFinancingRequest';
import FinancingMessages from 'components/loan/FinancingMessages';
import LoanDetails from 'components/loan/LoanDetails';
import CircleButton from 'components/common/CircleButton';
import useCompleteProjectStep from 'hooks/useCompleteProjectStep';
import get from 'lodash/get';
import Collapsible from 'react-collapsible';
import { Header } from 'components/common/heap';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import s from './Loan.module.scss';

const FinancingRequestButton = ({ disabled, onClick = () => {}, monthlyFinancing, loan, t }) => {
  const btnLabelCondition = useMemo(
    () => monthlyFinancing.value > 0 && loan.is_max_loan_rate_ratio_successful && loan.is_own_funds_ratio_successful,
    [loan.is_max_loan_rate_ratio_successful, loan.is_own_funds_ratio_successful, monthlyFinancing.value],
  );

  return (
    <div className={s.financingBtnWrapper}>
      <Button disabled={disabled} onClick={onClick} endIcon={<Emoji />} className={s.financingRequestButton}>
        {t({ id: btnLabelCondition ? 'loan.financing_reserve_button' : 'loan.financing_deficit_button' })}
      </Button>

      <FinishFlag className={s.finishFlagIcon} />
    </div>
  );
};

const InfoContainer = ({ t }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Collapsible
      open={isOpen}
      handleTriggerClick={() => setIsOpen(!isOpen)}
      transitionTime={800}
      trigger={
        <Header className={s.trigger} endIcon={<ArrowForward className={isOpen ? s.activeChevronSvg : s.chevronSvg} />}>
          <h3>{t({ id: 'loan.rate_btn_label' })}</h3>
        </Header>
      }
    >
      <div className={s.infoContainer}>
        <h2>{t({ id: 'loan.rate_title' })}</h2>
        <FormattedHTMLMessage id="loan.how_rate_calculated_first_part" />
        <FormattedHTMLMessage id="loan.how_rate_calculated_second_part" />
      </div>
    </Collapsible>
  );
};

export default function Loan({ project, projectId, monthlyRateLoan, monthlyFinancing, t }) {
  const { showModal, hideModal, isVisible } = useModal();
  const completeProjectStep = useCompleteProjectStep(projectId);
  const { name, loan } = project;
  const location = useLocation();
  const loanRequest = get(project, 'progress.loan_request', true);
  const shouldShowModal = get(location, 'state.showModalAfterRedirect') && !loanRequest;

  useEffect(() => {
    if (!project.progress.loan_viewed) {
      completeProjectStep('loan_viewed');
    }
  }, [completeProjectStep, project.progress.loan_viewed]);

  useEffect(() => {
    if (shouldShowModal) showModal();
  }, [shouldShowModal, showModal]);

  return (
    <Grid>
      <Row>
        <Col lg={6}>
          <div className={s.header}>
            <PageTitle className={s.title}>{t({ id: 'loan.title' }, { value: name })}</PageTitle>
            <Tooltip overlay={<p>{t({ id: 'loan.tooltip_settings_button' })}</p>} placement="top">
              <Link to={routePatterns.loanCalculator.stringify({ id: projectId })}>
                <CircleButton color="primary">
                  <PasteIcon />
                </CircleButton>
              </Link>
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <LoanDetails {...{ ...project, monthlyFinancing, monthlyRateLoan }} />

          <Tooltip overlay={<p>{t({ id: 'loan.already_requested' })}</p>} placement="top" hide={!loanRequest}>
            <div>
              <FinancingRequestButton {...{ t, loan, monthlyFinancing, disabled: loanRequest, onClick: showModal }} />
            </div>
          </Tooltip>

          <InfoContainer t={t} />
        </Col>

        <Col lg={6} first="xs" last="lg">
          <FinancingMessages {...{ loan, monthlyFinancing, projectType: project.type }} />

          <Modal
            isVisible={isVisible}
            close={hideModal}
            headerText={t({ id: 'loan.personal_financing_request.title' })}
          >
            <PersonalFinancingRequest
              buttonLabelId={
                monthlyFinancing.value < 0 ? 'loan.financing_deficit_button' : 'loan.financing_reserve_button'
              }
              hideModal={hideModal}
            />
          </Modal>
        </Col>
      </Row>
    </Grid>
  );
}
