/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import TextInput from 'components/inputs/TextInput';
import TextArea from 'components/inputs/TextArea';
import Select from 'components/inputs/Select';
import NumberInput from 'components/inputs/NumberInput';
import DatePicker from 'components/inputs/DatePicker';
import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import useGetCategories from 'hooks/payments/useGetCategories';
import { useParams } from 'react-router-dom';
import pick from 'lodash/pick';
import useAddPayer from 'hooks/payments/useAddPayer';
import { useModal } from 'components/common/Modal';
import { User } from 'assets/icons/sidebar';
import ScrollToError from 'components/common/ScrollToError';
import { __ } from 'utils/form';
import ImagesField from './PaymentImagesField';
import s from './PaymentForm.module.scss';
import PayerFormModal from '../Payers/PayerFormModal';

const validationSchema = yup.object().shape({
  name: yup.string().required(__('errors.fill_field')),
  category: yup.string().required(__('errors.choose_one')),
  amount: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  payment_type: yup.string().required(__('errors.choose_one')),
  project_id: yup.string().required(__('errors.choose_one')),
  paid_by: yup.string().required(__('errors.choose_one')),
});

const buttons = [
  {
    id: 1,
    type: 'submit',
    color: 'primary',
    label: 'payments.payer.add_payer',
  },
];

export const PaymentForm = ({
  initialValues,
  projects,
  onSubmit,
  payers,
  onAddPayer,
  isVisible,
  hideModal,
  showModal,
  t,
}) => {
  const [imageLoading, setImageLoading] = useState(false);
  const { id: projectId } = useParams();
  const { categories, refetch } = useGetCategories([projectId]);

  const handleChangeProject = useCallback(
    (selectedOption, setFieldValue) => {
      const value = selectedOption ? selectedOption.value : null;
      setFieldValue('project_id', value);
      refetch({ projects: [value] });
    },
    [refetch],
  );

  const memoCategories = useMemo(
    () => categories.map((category) => ({ label: t({ id: category.label }), value: category.name })),
    [categories, t],
  );

  const memoPayers = useMemo(
    () =>
      payers.map(({ payer: { _id, profile: { name, avatar } } }) => ({
        label: (
          <div className={s.optionWrapper}>
            {avatar ? (
              <img src={avatar?.url} alt="avatar" className={s.avatar} />
            ) : (
              <User className={s.defaultAvatar} />
            )}
            <span>{name}</span>
          </div>
        ),
        value: _id,
      })),
    [payers],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ isSubmitting, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <ScrollToError inModal />
          <Field name="name" component={TextInput} label={t({ id: 'payments.name' })} />
          <Field name="notes" component={TextArea} minRows={6} label={t({ id: 'payments.notes' })} />
          <Field
            name="project_id"
            component={Select}
            options={projects}
            label={t({ id: 'payments.project_name' })}
            onChange={(option) => handleChangeProject(option, setFieldValue)}
          />
          <Field
            name="category"
            defaultValue="new_building"
            type="text"
            component={Select}
            options={memoCategories}
            label={t({ id: 'payments.service_type' })}
            required
          />
          <Field name="payee" component={TextInput} label={t({ id: 'payments.payee' })} />
          <Field name="amount" component={NumberInput} label={t({ id: 'payments.amount' })} max={2000000} required />
          <Field name="date_paid" component={DatePicker} label={t({ id: 'payments.date_paid' })} />
          <Field
            name="payment_type"
            defaultValue="new_building"
            type="text"
            component={Select}
            options={[
              {
                label: t({ id: 'payments.types.final' }),
                value: 'final',
              },
              {
                label: t({ id: 'payments.types.partial' }),
                value: 'partial',
              },
            ]}
            label={t({ id: 'payments.types.name' })}
          />
          <Field
            name="paid_by"
            component={Select}
            options={memoPayers}
            label={
              <div className={s.selectPayerLabel}>
                <span>{t({ id: 'payments.paid_by_first_part' })}</span>{' '}
                <button type="button" onClick={showModal} className={s.createNewPayerLabel}>
                  {t({ id: 'payments.paid_by_second_part' })}
                </button>
              </div>
            }
            className={s.selectPayer}
          />
          <Field
            name="attachments"
            imageLoading={imageLoading}
            setImageLoading={setImageLoading}
            component={ImagesField}
            label={t({ id: 'payments.invoice_photos' })}
          />

          <div className={s.buttonWrapper}>
            <Button
              loading={isSubmitting}
              disabled={isSubmitting || imageLoading}
              type="submit"
              endIcon={<ArrowForward />}
            >
              {t({ id: 'payments.save_payment' })}
            </Button>
          </div>
          <PayerFormModal
            onSubmit={onAddPayer}
            headerText={t({ id: 'payments.payer.payer_label' })}
            {...{ isVisible, hideModal, buttons }}
          />
        </Form>
      )}
    </Formik>
  );
};

const PaymentFormContainer = ({ initialValues: values, onSubmit, projects, payers }) => {
  const { formatMessage: t } = useIntl();
  const { isVisible, showModal, hideModal } = useModal();
  const onAddPayer = useAddPayer(hideModal);

  const initialValues = pick(values, [
    'amount',
    'attachments',
    'category',
    'date_paid',
    'name',
    'notes',
    'paid_by',
    'payee',
    'payment_type',
    'project_id',
  ]);

  return (
    <PaymentForm {...{ initialValues, projects, onSubmit, payers, onAddPayer, isVisible, hideModal, showModal, t }} />
  );
};

export default PaymentFormContainer;
