import React from 'react';
import { Formik, Form } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { useIntl } from 'react-intl';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { Col, Row } from 'react-flexbox-grid';
import { ReactComponent as IndoorImg } from 'assets/images/quick-calculator/buying-apartment.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ScrollToTop from 'components/common/ScrollToTop';
import { NEW_APARTMENT } from 'constants/variables';
import validationSchema from '../yupSchemas/apartmentFourthStepSchema';
import Features from '../../Features';
import s from '../../calculators.module.scss';

const ApartmentFourthStep = ({
  isEditMode,
  lastStep,
  currentStep,
  onBack,
  onSubmit,
  previousStep,
  nextStep,
  formValuesRef,
  onSubmitStep,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Row between="lg" start="lg" center="md">
      <ScrollToTop />
      <Col xs={12} md={8} lg={6} xl={5}>
        <Formik
          initialValues={formValuesRef?.current}
          onSubmit={async (values, formikActions) => {
            onSubmitStep(values);
            await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
          }}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <ScrollToError />
              <Features group="indoor" projectType={NEW_APARTMENT} />

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <IndoorImg title={t({ id: 'alt_text.project_details_page.new_apartment.fourth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default ApartmentFourthStep;
