import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECTS_PAYERS_QUERY } from './useProjectsPayersQuery';
import useGetFilters from './useGetFilters';

const ADD_PAYER_MUTATION = gql`
  mutation addPayer($formData: PayerInput!) {
    addPayer(formData: $formData) {
      _id
      profile {
        name
        avatar {
          url
          file_name
        }
      }
    }
  }
`;

const useAddPayer = (hideModal) => {
  const [addPayerMutation] = useMutation(ADD_PAYER_MUTATION);
  const { formatMessage: t } = useIntl();
  const { filters } = useGetFilters();

  const handleAddPayer = useCallback(
    async (variables, { setSubmitting }) => {
      try {
        await addPayerMutation({
          variables: { formData: { ...variables } },
          refetchQueries: [{ query: PROJECTS_PAYERS_QUERY, variables: { filters } }],
          awaitRefetchQueries: true,
        });
        hideModal();
        toast.success(t({ id: 'payments.payer.payer_added' }));
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      } finally {
        setSubmitting(false);
      }
    },
    [addPayerMutation, filters, hideModal, t],
  );
  return handleAddPayer;
};

export default useAddPayer;
