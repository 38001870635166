import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { __ } from 'utils/form';

const validationSchema = yup.object().shape({
  heated_living_area: yup
    .number()
    .required(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
  kind_of_house: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  energy_standard: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  solar_heating_system_type: createConditionalValidator('is_solar_heating_system', 'string'),
  number_of_people: yup
    .number()
    .nullable()
    .required(__('errors.choose_one')),
  heating_system: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  age_of_heating_system: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  temperature: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  hot_water_producer: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  amount_of_hot_water: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  solar_power_system_size: createConditionalValidator('is_solar_power_system', 'number'),
  solar_power_system_consuming_percentage: createConditionalValidator('is_solar_power_system', 'number'),
});

export default validationSchema;
