import React from 'react';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import * as yup from 'yup';
import AvatarField from 'components/common/Avatar/AvatarField';
import Modal from 'components/common/Modal';
import ScrollToError from 'components/common/ScrollToError';
import { __ } from 'utils/form';
import s from './PayerForm.module.scss';

const validationSchema = yup.object().shape({
  name: yup.string().required(__('errors.fill_field')),
});

const defaultInitialValues = {
  name: '',
  avatar: '',
};

const PayerFormModal = ({
  onSubmit,
  isVisible,
  hideModal,
  headerText,
  buttons,
  avatar,
  initialValues = defaultInitialValues,
}) => {
  const { formatMessage: t } = useIntl();

  return (
    <Modal isVisible={isVisible} close={hideModal} size="sm" headerText={headerText}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            <ScrollToError inModal />
            <Field name="avatar" component={AvatarField} avatar={avatar} />
            <Field name="name" component={TextInput} label={t({ id: 'payments.payer.payer_name' })} />
            <div className={s.btnWrapper}>
              {buttons.map(({ type, onClick, label, color }) => (
                <Button key={label} disabled={isSubmitting} type={type} onClick={onClick} color={color}>
                  {t({ id: label })}
                </Button>
              ))}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PayerFormModal;
