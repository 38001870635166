import { ArrowBack, Plus } from 'assets/icons';
import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import Button from 'components/common/Button';
import CircleButton from 'components/common/CircleButton';
import { Hidden, Visible } from 'components/common/Grid';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useModal } from 'components/common/Modal';
import PageTitle from 'components/common/PageTitle';
import { PROPERTY_VALUATION } from 'constants/variables';
import useGetProjectTypes from 'hooks/project/useGetProjectTypes';
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import Payments from './Payments';
import s from './Payments.module.scss';

export default () => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const { showModal, hideModal, isVisible } = useModal();
  const { type, loading } = useGetProjectTypes(projectId);

  if (loading) return <LoadingOverlay />;

  if (type === PROPERTY_VALUATION) return <StartProjectPlanning />;

  return (
    <Grid>
      <Row className={s.pageHeader}>
        <Col xs={7} className={s.firstCol}>
          <PageTitle>{t({ id: 'page_titles.payments' })}</PageTitle>
        </Col>
        <Col xs={5} className={s.secondCol}>
          <Link to={routePatterns.spendings.stringify({ id: projectId })}>
            <Hidden xs sm md>
              <Button className={s.btn} startIcon={<ArrowBack />}>
                {t({ id: 'payments.back_to_spendings' })}
              </Button>
            </Hidden>
            <Visible xs sm md>
              <CircleButton className={s.btn} color="primary">
                <ArrowBack />
              </CircleButton>
            </Visible>
          </Link>
          <Hidden xs sm md>
            <Button className={s.btn} onClick={showModal} endIcon={<Plus />}>
              {t({ id: 'payments.add_payment' })}
            </Button>
          </Hidden>
          <Visible xs sm md>
            <CircleButton onClick={showModal} className={s.btn} color="primary">
              <Plus />
            </CircleButton>
          </Visible>
        </Col>
      </Row>
      <Payments {...{ t, hideModal, isVisible, projectId }} />
    </Grid>
  );
};
