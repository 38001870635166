import { useParams } from 'react-router-dom';
import React, { useCallback } from 'react';
import ProjectPlanTables from 'components/plan/ProjectPlanTables';
import useProjectPlan from 'hooks/useProjectPlan';
import ScrollToTop from 'components/common/ScrollToTop';
import Loading from 'components/common/Loading';
import ActionButtons from 'components/plan/ActionButtons';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { useIntl } from 'react-intl';
import Alert from 'components/common/Alert';
import WarningMessage from 'components/common/WarningMessage';
import { routePatterns } from 'router/route-paths';

const CostOverview = ({ goToStep }) => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const { projectPlan, project, loading } = useProjectPlan(projectId);

  const onSettingsButtonClick = useCallback(() => goToStep(1), [goToStep]);

  if (loading) {
    return <Loading size={50} />;
  }

  if (!project) {
    return <Alert color="danger">{t({ id: 'errors.project_not_found' })}</Alert>;
  }

  if (projectPlan?.price === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.plan' })}
        message={t({ id: 'project_plan.complete_previous_step' })}
        btnLabel={t({ id: 'project_plan.complete_previous_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  return (
    <>
      <ScrollToTop />
      <Row middle="xs">
        <Col xs={8} md={10}>
          <PageTitle className="text-left">{t({ id: 'page_titles.plan' })}</PageTitle>
        </Col>
        <Col xs={4} md={2}>
          <ActionButtons projectId={project._id} pdf={projectPlan.pdf} {...{ onSettingsButtonClick }} />
        </Col>
      </Row>
      <ProjectPlanTables {...{ project, projectPlan, goToStep }} />
    </>
  );
};

export default CostOverview;
