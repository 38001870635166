import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';

export const REMOVE_IMAGE = gql`
  mutation removeImage($fileName: String!) {
    removeImage(fileName: $fileName)
  }
`;

const useRemoveImage = () => {
  const [removeImageMutation] = useMutation(REMOVE_IMAGE);

  const removeImage = useCallback(
    async (fileName) => {
      try {
        await removeImageMutation({
          variables: { fileName },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [removeImageMutation],
  );

  return removeImage;
};

export default useRemoveImage;
