import { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { CURRENT_USER_FRAGMENT, ME_QUERY } from 'hooks/user/useUser';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { formatGraphqlErrors } from 'utils/helpers';

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: HashedPassword, $newPasswordPlain: String, $token: String!) {
    resetPassword(newPassword: $newPassword, newPasswordPlain: $newPasswordPlain, token: $token) {
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useResetPassword = () => {
  const client = useApolloClient();

  const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
    onCompleted: (res) => {
      const me = get(res, 'resetPassword.user', null);
      client.writeQuery({
        query: ME_QUERY,
        data: { me },
      });
    },
  });

  const resetPassword = useCallback(
    async ({ password, token }) => {
      try {
        await resetPasswordMutation({
          variables: { token, newPasswordPlain: password },
        });
        return;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [resetPasswordMutation],
  );

  return resetPassword;
};

export default useResetPassword;
