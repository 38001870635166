/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, memo } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { areEqualFields } from 'utils/form';

const CDatePicker = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  max,
  label,
  className,
  dateFormat,
  defaultValue,
  fieldWrapperClassName,
  ...props
}) => {
  const { name, value: fieldValue } = field;
  const [value, setValue] = useState(new Date(field.value));

  const handleOnChange = useCallback(
    (date) => {
      setValue(date);
      setFieldValue(name, date);
    },
    [setFieldValue, name],
  );

  const getClassNameForInput =
    touched[field.name] && errors[field.name] ? 'error-input default-input' : 'default-input';

  return (
    <div className={cn('field-wrapper', fieldWrapperClassName)}>
      <label className="label-when-focused-input">{label}</label>
      <DatePicker
        {...field}
        {...props}
        value={new Date(fieldValue)}
        selected={value}
        onChange={handleOnChange}
        autoComplete="none"
        className={cn(getClassNameForInput, className)}
        dateFormat={dateFormat || 'dd.MM.yyyy'}
      />
      {touched[name] && errors[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(CDatePicker, areEqualFields);
