import { useAppContext } from 'contexts/AppContext';
import React, { useCallback } from 'react';
import cn from 'classnames';
import s from './SliderWithTooltip.module.scss';

const Range = ({
  field,
  min,
  max,
  form,
  stepValue,
  changeableStep,
  setChangeableStep,
  setNumberInputValue,
  sideEffectOnChange,
}) => {
  const { name, value } = field;
  const { theme } = useAppContext();

  const handleChange = useCallback(
    (e) => {
      const v = Number(e.target.value);
      form.setFieldValue(name, v);
      setNumberInputValue(v);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange(v);
    },
    [form, name, setNumberInputValue, sideEffectOnChange],
  );

  const handleFocus = useCallback(
    (e) => {
      e.target.focus();
      if (!changeableStep) setChangeableStep(true);
    },
    [changeableStep, setChangeableStep],
  );

  return (
    <input
      type="range"
      id={name}
      value={Number.isFinite(value) ? value : 0}
      name={name}
      min={min}
      max={max}
      onChange={handleChange}
      onFocus={handleFocus}
      step={stepValue}
      className={cn(s.slider, theme === 'green' ? s.greenSlider : '')}
    />
  );
};

export default Range;
