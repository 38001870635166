import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import client from 'graphql/apollo';
import { useIntl } from 'react-intl';
import { PROJECT_PLAN_FRAGMENT, PROJECT_PLAN_QUERY } from '../useProjectPlan';

const SET_PLAN_CATEGORY_QUOTE_MUTATION = gql`
  mutation setPlanCategoryQuote($projectId: ID!, $categoryName: String!, $value: Float, $locale: String) {
    setPlanCategoryQuote(projectId: $projectId, categoryName: $categoryName, value: $value) {
      ...projectPlan
      personal_contribution_value
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useSetPlanCategoryQuote = () => {
  const [setPlanCategoryQuoteMutation, { loading }] = useMutation(SET_PLAN_CATEGORY_QUOTE_MUTATION);
  const { locale } = useIntl();

  const planCategoryQuote = useCallback(
    async ({ projectId, categoryName, value }) => {
      try {
        const {
          data: { setPlanCategoryQuote },
        } = await setPlanCategoryQuoteMutation({ variables: { projectId, categoryName, value } });

        const { planningCategories, price, personal_contribution_value } = setPlanCategoryQuote;
        const { project, projectPlan } = client.readQuery({
          query: PROJECT_PLAN_QUERY,
          variables: { locale, projectId },
        });

        client.writeQuery({
          query: PROJECT_PLAN_QUERY,
          variables: { locale, projectId },
          data: {
            project: { ...project, price, personal_contribution_value },
            projectPlan: { ...projectPlan, price, planningCategories },
          },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [locale, setPlanCategoryQuoteMutation],
  );
  return [planCategoryQuote, loading];
};

export default useSetPlanCategoryQuote;
