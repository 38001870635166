import { Hidden, Visible } from 'components/common/Grid';
import React from 'react';
import { useIntl } from 'react-intl';
import { useModal } from 'components/common/Modal';
import { useParams } from 'react-router-dom';
import SpendingsPlanningCard from './cards';
import SpendingsTable from './table';
import s from './SpendingsPlanning.module.scss';
import PaymentsFormModal from './PaymentsFormModal';

const SpendingsPlanning = ({ paymentsTotalByCategories, countOfPayments }) => {
  const { showModal, isVisible, hideModal, modalProps } = useModal();
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();

  return (
    <>
      {/* desktop start */}
      <Hidden xs sm>
        <div className={s.tableWrapper}>
          <SpendingsTable {...{ paymentsTotalByCategories, showModal }} />
        </div>
      </Hidden>
      {/* desktop end */}

      {/* mobile start */}
      <Visible xs sm>
        <div>
          {paymentsTotalByCategories.map(({ category, paymentsTotalByCategory }) => (
            <SpendingsPlanningCard key={category.name} {...{ showModal, category, paymentsTotalByCategory }} />
          ))}
        </div>
      </Visible>
      <PaymentsFormModal {...{ hideModal, t, isVisible, projectId, modalProps, countOfPayments }} />
      {/* mobile end */}
    </>
  );
};

export default SpendingsPlanning;
