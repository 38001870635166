import CandleChart from 'components/CandleChart';
import Table from 'rc-table';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import cn from 'classnames';
import { redColor } from 'styles/variables.scss';
import { getBodyPriceTextForTopCard } from '../helpers';
import s from './SpendingsTable.module.scss';

const useGetColumns = () => {
  const { formatMessage: t } = useIntl();

  const columns = useMemo(
    () => [
      {
        title: 'category',
        dataIndex: 'category',
        key: 'category',
        render: ({ totalSaved }) => (
          <div className={s.totalsContainer}>
            <h4 style={{ fontWeight: 'bold' }}>{t({ id: 'spendings.total_costs' })}</h4>
            <div className={s.saved}>
              {t({ id: 'spendings.totals.saved' }, { savings: formatCurrency(totalSaved) })}
            </div>
          </div>
        ),
        width: '35%',
      },
      {
        title: 'calculation',
        dataIndex: 'calculation',
        key: 'calculation',
        width: '30%',
        render: ({ percentage, price, paymentsTotal, totalOverspent }) => (
          <div className={s.calculation}>
            <div style={{ color: percentage > 100 ? redColor : '#40C2C7' }}>
              {getBodyPriceTextForTopCard({
                percentage,
                price,
                paymentsTotal,
                t,
              })}
            </div>
            <small>
              {formatCurrency(paymentsTotal)} / {formatCurrency(price)} ({percentage}%){' '}
              {t({ id: 'spendings.budget_spent' })}
            </small>
            <div className={s.overspent}>
              {t({ id: 'spendings.totals.overspent' }, { overspends: formatCurrency(totalOverspent) })}
            </div>
          </div>
        ),
      },
      {
        title: 'chart',
        dataIndex: 'chart',
        key: 'chart',
        width: '35%',
        render: ({ percentage, deviation, deviationStyle }) => (
          <div>
            <CandleChart value={percentage} complete={false} />
            <div className={cn(deviationStyle, s.deviation)}>
              {t({ id: 'spendings.totals.deviation' }, { deviation })}
            </div>
          </div>
        ),
      },
    ],
    [t],
  );
  return columns;
};

const TopTable = ({
  percentage,
  price,
  paymentsTotal,
  totalSaved,
  totalOverspent,
  deviation,
  deviationStyle,
  isPayments,
}) => {
  const columns = useGetColumns();
  const data = [
    {
      key: percentage,
      category: { totalSaved },
      calculation: { percentage, price, paymentsTotal, totalOverspent },
      chart: { percentage, deviation, deviationStyle },
    },
  ];

  return (
    <div className={s.topTableWrapper}>
      <Table data={data} columns={columns} showHeader={false} style={isPayments ? { paddingLeft: '35px' } : {}} />
    </div>
  );
};

export default TopTable;
