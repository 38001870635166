import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { BUDGET_CALCULATION_FRAGMENT, BUDGET_FORM_DATA_FRAGMENT } from 'graphql/fragments/budget';
import { useIntl } from 'react-intl';

export const MY_BUDGET_QUERY = gql`
  query myBudget($locale: String) {
    myBudget {
      _id
      pdf(locale: $locale)
      ...budgetCalculation
      ...budgetFormData
    }
  }
  ${BUDGET_CALCULATION_FRAGMENT}
  ${BUDGET_FORM_DATA_FRAGMENT}
`;

const useMyBudget = () => {
  const { locale } = useIntl();
  const { data, loading } = useQuery(MY_BUDGET_QUERY, { variables: { locale } });

  return [data?.myBudget, loading];
};

export default useMyBudget;
