import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import { getBodyPriceText, getCategoryStage, getColor, getData } from '../helpers';
import s from './SpendingsTable.module.scss';

const CalculationCell = ({ category, paymentsTotalByCategory: { isFinalPayment, paymentsTotal } }) => {
  const { formatMessage: t } = useIntl();
  const { subitems, user_price } = category;
  const { percentage, categoryTotal } = getData({ paymentsTotal, category });
  const hasPayments = !!paymentsTotal;

  const stage = useMemo(
    () =>
      getCategoryStage({
        paymentsTotal,
        user_price,
        subitems,
        t,
      }),
    [paymentsTotal, subitems, t, user_price],
  );

  const cardAccentColor = useMemo(
    () =>
      getColor({
        categoryTotal,
        percentage,
        hasPayments,
        stage,
        isFinalPayment,
      }),
    [categoryTotal, hasPayments, isFinalPayment, percentage, stage],
  );

  return (
    <>
      <div className={s.calculation} style={{ color: cardAccentColor }}>
        {getBodyPriceText({
          stage,
          percentage,
          hasPayments,
          categoryTotal,
          paymentsTotal,
          isFinalPayment,
          t,
        })}
      </div>
      {hasPayments ? (
        <small>
          {formatCurrency(paymentsTotal)} / {formatCurrency(categoryTotal)}{' '}
          {categoryTotal !== 0 ? `(${percentage}%)` : ''}
          {t({ id: 'spendings.budget_spent' })}
        </small>
      ) : null}
    </>
  );
};

export default CalculationCell;
