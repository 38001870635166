import { useCallback } from 'react';
import get from 'lodash/get';
import { useHistory, useParams } from 'react-router-dom';
import routePaths, { routePatterns } from 'router/route-paths';

const useAuthFormSubmit = (auth, { onSuccess } = {}) => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  return useCallback(
    async ({ firstName, lastName, ...rest }, formikActions, onError) => {
      const { setSubmitting, setErrors } = formikActions;
      setSubmitting(true);
      const args = { profile: { projectId: id, firstName, lastName }, projectId: id, ...rest };
      try {
        const data = await auth(args);
        const emailVerified = get(data, 'user.emails[0].verified');
        if (emailVerified) {
          if (id) {
            history.push(routePatterns.dashboard.stringify({ id }));
          } else {
            history.push(routePaths.myProjects);
          }
        }
        if (typeof onSuccess === 'function') onSuccess();
        setSubmitting(false);
      } catch (error) {
        if (typeof onError === 'function') onError(error, auth, args, formikActions);
        if (error.message === 'AUTH_OTP_REQUIRED_ERROR') return;
        setErrors({ form: error.message });
      } finally {
        setSubmitting(false);
      }
    },
    [auth, history, id, onSuccess],
  );
};

export default useAuthFormSubmit;
