import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { formatGraphqlErrors } from 'utils/helpers';
import get from 'lodash/get';
import { PROJECT_QUERY } from '../project-details/useProject';
import { GET_PROJECT_TYPES_QUERY } from './useGetProjectTypes';

const CHANGE_PROJECT_TYPE_MUTATION = gql`
  mutation changeProjectType($projectId: ID!, $type: ProjectType!) {
    changeProjectType(projectId: $projectId, type: $type) {
      success
    }
  }
`;

const useChangeProjectType = () => {
  const [changeProjectTypeMutation] = useMutation(CHANGE_PROJECT_TYPE_MUTATION);
  const client = useApolloClient();

  const mutate = useCallback(
    async ({ projectId, type }) => {
      try {
        const data = client.readQuery({ query: PROJECT_QUERY, variables: { id: projectId } });
        const project = get(data, 'project', null);

        await changeProjectTypeMutation({
          variables: { projectId, type },
          refetchQueries: [{ query: GET_PROJECT_TYPES_QUERY, variables: { projectId } }],
        });

        if (!project) return;

        client.writeQuery({
          query: PROJECT_QUERY,
          variables: { id: projectId },
          data: { project: { ...project, type } },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [changeProjectTypeMutation, client],
  );

  return mutate;
};

export default useChangeProjectType;
