import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { CO2_CALCULATOR_RESULT, CO2_CALCULATOR_FORM_VALUES } from 'graphql/fragments/co2-calculator';

export const PROJECT_DASHBOARD_QUERY = gql`
  query projectDashboard($id: ID!, $locale: String) {
    project(id: $id) {
      _id
      pdf(locale: $locale)
      type
      name
      price
      budget
      financing_rate
      loan_amount
      form_values {
        ... on NewApartmentFormValues {
          cost_apartment
        }
        ... on BuyingHouseFormValues {
          cost_house
        }
      }
      calculations {
        ... on NewApartmentCalculations {
          immazing(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            error
          }
        }
      }
      calculations {
        ... on BuyingHouseCalculations {
          immazing(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            error
          }
        }
      }
      calculations {
        ... on RenovationHouseCalculations {
          co2_emissions_before {
            form_values {
              ...co2CalculatorFormValues
            }
            result {
              ...co2CalculatorResult
            }
          }
          co2_emissions_potential {
            result {
              heating_ghg_emission
              hot_water_ghg_emission
              ghg_balance_of_power_consumption
              specific_heating_demand

              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
          co2_emissions_after {
            result {
              specific_heating_demand
              total_energy_balance_per_year
              energy_ghg_balance_per_year
              total_energy_costs
            }
          }
        }
      }
      progress {
        project_details
        plan_adjusted
        budget_planning
        budget_planning_adjusted
        loan_viewed
        loan_calculator
        loan_request
        step
      }
      loan {
        monthly_rate
        amount
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
  ${CO2_CALCULATOR_FORM_VALUES}
`;

const useProjectDashboardQuery = (projectId) => {
  const { locale } = useIntl();
  const { loading, data } = useQuery(PROJECT_DASHBOARD_QUERY, {
    variables: { id: projectId, locale },
  });

  return [data?.project, loading];
};

export default useProjectDashboardQuery;
