import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ErrorMessage from 'components/common/ErrorMessage';
import Button from 'components/common/Button';
import Slider from 'components/inputs/Slider';
import { routePatterns } from 'router/route-paths';
import Toggler from 'components/inputs/Toggler';
import NumberInput from 'components/inputs/NumberInput';
import { ArrowForward } from 'assets/icons';
import { formatCurrency } from 'utils/helpers';
import Alert from 'components/common/Alert';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import { __ } from 'utils/form';
import s from './LoanCalculatorForm.module.scss';

const loanShape = {
  budget: yup
    .number()
    .required(__('errors.fill_field'))
    .max(2000000, __('errors.max_value', { max: formatCurrency(2000000) })),
  period: yup.number().required(__('errors.choose_one')),
  interest_type: yup.string().required(__('errors.choose_one')),
  fixed_interest_period: yup
    .mixed()
    .notRequired()
    .when('interest_type', {
      is: (interestType) => interestType === 'fixed',
      then: yup.string().required(__('errors.choose_one')),
    }),
};

const computeTotalOwnFunds = (budget = 0, estimatedPropertyPrice) => budget + estimatedPropertyPrice;

const loanParamsSchema = yup.object().shape(loanShape);

const LoanCalculatorForm = ({
  initialValues,
  onSubmit,
  projectName,
  projectId,
  onChangeBudget,
  onChangeEstimatedPropertyPrice,
}) => {
  const { formatMessage: t } = useIntl();
  return (
    <Formik initialValues={initialValues} validationSchema={loanParamsSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors, values }) => (
        <>
          <Form>
            <Field
              name="price"
              component={NumberInput}
              disabled
              label={t({ id: 'loan.price' })}
              description={
                <>
                  {t({ id: 'loan.project_link_description' })}{' '}
                  <Link to={routePatterns.plan.stringify({ id: projectId })} className="link">
                    {projectName}
                  </Link>
                </>
              }
            />
            <Field
              name="budget"
              component={SliderWithTooltip}
              label={t({ id: 'loan.budget' })}
              min={0}
              step={1000}
              max={2000000}
              units="€"
              sideEffectOnChange={onChangeBudget}
            />
            {initialValues.estimated_property_price ? (
              <>
                <Field
                  name="estimated_property_price"
                  component={NumberInput}
                  sideEffectOnChange={onChangeEstimatedPropertyPrice}
                  label={t({ id: 'loan.estimated_property_price' })}
                />
                <p className={s.totalCost}>
                  <span>{t({ id: 'project_wizard.total_own_funds' })}</span>
                  <b>{formatCurrency(computeTotalOwnFunds(values?.budget, values.estimated_property_price))}</b>
                </p>
              </>
            ) : null}
            <Field name="period" component={Slider} min={10} max={35} step={5} label={t({ id: 'loan.period' })} />
            <Field
              name="interest_type"
              required
              component={Toggler}
              options={[
                {
                  label: t({ id: 'loan.interest_type.variable' }),
                  value: 'variable',
                },
                {
                  label: t({ id: 'loan.interest_type.fixed' }),
                  value: 'fixed',
                },
              ]}
              label={t({ id: 'loan.interest_terms' })}
            />
            {values.interest_type === 'fixed' && (
              <Field
                name="fixed_interest_period"
                component={Slider}
                required
                min={10}
                max={20}
                step={5}
                label={t({ id: 'loan.fixed_interest_period' })}
              />
            )}
            {errors.form && <ErrorMessage message={errors.form} />}

            <div className={s.btnWrapper}>
              <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                {t({ id: 'app.submit_form_final' })}
              </Button>
            </div>
            <Alert className="mt-4">
              <FormattedHTMLMessage id="disclaimers.loan_calculator" />
            </Alert>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default LoanCalculatorForm;
