import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { routePatterns } from 'router/route-paths';
import { Link, useLocation } from 'react-router-dom';
import { ArrowForward } from 'assets/icons';
import { animateScroll } from 'react-scroll';
import { Grid, Row, Col } from 'react-flexbox-grid';
import WarningMessage from 'components/common/WarningMessage';
import s from './PlanForm.module.scss';
import Category from './Category';

const PlanForm = ({ categories = [], initialValues, projectId }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeCategoryName, setActiveCategoryName] = useState(searchParams.get('category') || categories[0]?.name);
  const { formatMessage: t } = useIntl();
  const cardRef = useRef();

  const activeCategory = useMemo(() => {
    const currentActiveCategory = categories.find((c) => c.name === activeCategoryName);
    return currentActiveCategory || categories[0];
  }, [activeCategoryName, categories]);

  const onCategoryTabClick = useCallback((categoryName) => {
    if (cardRef.current) {
      const coordinates = cardRef.current.getBoundingClientRect();
      animateScroll.scrollTo(coordinates.top + window.pageYOffset - 300);
    }
    setActiveCategoryName(categoryName);
  }, []);

  if (!activeCategoryName) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.plan' })}
        message={t({ id: 'project_plan.complete_previous_step' })}
        btnLabel={t({ id: 'project_plan.complete_previous_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  return (
    <Grid>
      <Row>
        <Col md={12}>
          <div className={s.card}>
            <div className={s.categoryTabs}>
              {categories.map((category) => (
                <Button
                  key={category.name}
                  className={s.categoryTab}
                  onClick={() => onCategoryTabClick(category.name)}
                  color={category.name === activeCategory.name ? 'primary' : 'outline'}
                >
                  {t({ id: category.label })}
                </Button>
              ))}
            </div>
            <Alert style={{ marginTop: '10px' }} color="primary">
              {t({ id: 'planning_categories.stored_automatically' })}
            </Alert>
            <div className={s.planOverviewBtn}>
              <Link to={routePatterns.plan.stringify({ id: projectId })}>
                <Button endIcon={<ArrowForward />}>{t({ id: 'planning.plan_overview' })}</Button>
              </Link>
            </div>
          </div>
          {activeCategoryName ? (
            <div className={s.categoriesContainer}>
              <Category key={activeCategory.name} {...{ initialValues, ...activeCategory, cardRef }} />
            </div>
          ) : null}
        </Col>
      </Row>
    </Grid>
  );
};

export default PlanForm;
