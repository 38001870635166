import React, { useCallback, useMemo } from 'react';
import Table from 'rc-table';
import { useIntl } from 'react-intl';
import { ArrowForward } from 'assets/icons';
import { formatCurrency } from 'utils/helpers';
import cn from 'classnames';
import get from 'lodash/get';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import s from './SpendingsTable.module.scss';
import getColumns from './getColumns';

const SpendingsTable = ({ paymentsTotalByCategories, showModal }) => {
  const { formatMessage: t } = useIntl();
  const columns = getColumns();

  const data = useMemo(
    () =>
      paymentsTotalByCategories.map(({ category, paymentsTotalByCategory }) => {
        return {
          key: category.name,
          category,
          calculation: { category, paymentsTotalByCategory },
          chart: { category, paymentsTotalByCategory },
          actions: { category, showModal },
        };
      }),
    [paymentsTotalByCategories, showModal],
  );

  const expandedRowRender = useCallback(
    ({ calculation: { paymentsTotalByCategory } }) => {
      const payments = get(paymentsTotalByCategory, 'payments', []);
      return (
        <SlideDown>
          <table className={s.paymentsTable}>
            <thead>
              <tr>
                <th>{t({ id: 'spendings.payment.name' })}</th>
                <th>{t({ id: 'spendings.payment.amount' })}</th>
                <th>{t({ id: 'spendings.payment.notes' })}</th>
                <th>{t({ id: 'spendings.payment.type' })}</th>
                <th>{t({ id: 'spendings.payment.date_paid' })}</th>
              </tr>
            </thead>
            <tbody>
              {payments.map(({ _id, amount, name, notes, payment_type, date_paid }) => (
                <tr key={_id}>
                  <td>{name}</td>
                  <td>{formatCurrency(amount)}</td>
                  <td>{notes}</td>
                  <td>{t({ id: `payments.types.${payment_type}` })}</td>
                  <td>{format(new Date(date_paid), 'do MMMM yyyy', { locale: de })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </SlideDown>
      );
    },
    [t],
  );

  const rowExpandable = useCallback(({ calculation: { paymentsTotalByCategory } }) => {
    const payments = get(paymentsTotalByCategory, 'payments', []);
    return !!payments.length;
  }, []);

  function CustomExpandIcon({ expanded, expandable }) {
    return expandable ? <ArrowForward className={cn(s.chevronIcon, expanded ? s.activeChevronIcon : '')} /> : null;
  }

  return (
    <Table
      expandable={{
        expandRowByClick: true,
        expandedRowRender,
        rowExpandable,
        expandIcon: CustomExpandIcon,
      }}
      className={s.spendingsTable}
      data={data}
      columns={columns}
      showHeader={false}
    />
  );
};

export default SpendingsTable;
