import CandleChart from 'components/CandleChart';
import React from 'react';
import { getData } from '../helpers';
import ActionButtons from './ActionButtons';
import CalculationCell from './CalculationCell';
import CategoryCell from './CategoryCell';

const Chart = ({ category, paymentsTotalByCategory: { paymentsTotal, isFinalPayment } }) => {
  const { percentage } = getData({ paymentsTotal, category });
  return <CandleChart complete={isFinalPayment} value={percentage} />;
};

const getColumns = () => {
  const columns = [
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
      render: CategoryCell,
      width: '35%',
    },
    {
      title: 'calculation',
      dataIndex: 'calculation',
      key: 'calculation',
      width: '30%',
      render: CalculationCell,
    },
    {
      title: 'chart',
      dataIndex: 'chart',
      key: 'chart',
      width: '25%',
      render: Chart,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: ActionButtons,
    },
  ];

  return columns;
};

export default getColumns;
