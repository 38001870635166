import { debounce, isNil } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { areEqualFields, onKeyDown } from 'utils/form';
import s from './SliderWithTooltip.module.scss';

const config = {
  en: {
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  de: {
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
};

const Input = ({
  form,
  name,
  min,
  max,
  units,
  sliderValue,
  // we use this prop when change slider value not by handleChange function to also change the number input value
  forciblyReplaceInputValue = false,
  value,
  setValue,
  thousandSeparator,
  sideEffectOnChange,
  changeableStep,
  setChangeableStep,
  fieldWrapperRef,
}) => {
  const { locale } = useIntl();
  const [, forceRerender] = useState(false);
  const [shouldInputValueBeEqualsSlider, setInputValueBeEqualsSlider] = useState(false);

  const setSliderValue = useCallback(
    debounce((inputValue) => {
      form.setFieldValue(name, inputValue);
    }, 800),
    [form, name],
  );

  useEffect(() => {
    if (shouldInputValueBeEqualsSlider && sliderValue !== value) {
      setValue(sliderValue);
    }
  }, [setValue, shouldInputValueBeEqualsSlider, sliderValue, value]);

  const handleChange = useCallback(
    debounce((values) => {
      setInputValueBeEqualsSlider(false);
      let v;
      if (isNil(values.floatValue)) {
        v = null;
      } else {
        v = values.floatValue >= max ? max : values.floatValue;
      }
      setValue(v);

      if (typeof sideEffectOnChange === 'function') sideEffectOnChange(v);
      if (Number.isFinite(v) && v >= min && v <= max) setSliderValue(v);

      forceRerender((b) => !b);
    }, 400),
    [max],
  );

  const handleFocus = useCallback(
    (e) => {
      e.target.focus();
      if (changeableStep) {
        setChangeableStep(false);
      }
      if (forciblyReplaceInputValue) {
        setInputValueBeEqualsSlider(true);
      }
    },
    [changeableStep, forciblyReplaceInputValue, setChangeableStep],
  );

  const handleBlur = useCallback(() => {
    form.setFieldTouched(name, true);
    if (forciblyReplaceInputValue) {
      setInputValueBeEqualsSlider(true);
    }
    if (!changeableStep) {
      setChangeableStep(true);
    }
  }, [changeableStep, forciblyReplaceInputValue, form, name, setChangeableStep]);

  const handleKeyDown = useCallback(
    (e) => {
      onKeyDown(e, fieldWrapperRef);
    },
    [fieldWrapperRef],
  );

  return (
    <div className={s.inputWrapper}>
      <NumberFormat
        max={max}
        value={value}
        allowNegative={false}
        allowLeadingZeros={false}
        allowEmptyFormatting={false}
        {...(!isNil(thousandSeparator) ? { thousandSeparator } : config[locale])}
        name={`${name}-number-input`}
        autoComplete="off"
        id={`${name}-number-input`}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        className={s.numberInput}
        type="text"
        onValueChange={handleChange}
        onBlur={handleBlur}
      />
      <div className={s.units}>{units}</div>
    </div>
  );
};

export default memo(Input, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['value', 'sliderValue']));
