/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useInjectImmazingValues from 'hooks/useInjectImmazingValues';
import Button from 'components/common/Button';
import { formatCurrency } from 'utils/helpers';
import { BUYING_HOUSE, GUEST, LAND_ESTIMATION, NEW_BUILDING } from 'constants/variables';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import Alert from 'components/common/Alert';
import Loading from 'components/common/Loading';
import { get, pick } from 'lodash';
import equal from 'fast-deep-equal/es6/react';
import { toast } from 'react-toastify';
import { whiteListBuyingHouseValues } from './ImmazingEstimation';
import { EstimationNotAvailableForGuest } from './ImmazingEstimation.components';
import s from './ImmazingLandEstimation.module.scss';

const EstimatedImmazingValues = ({ estimatedPropertyPrice, isActualData, landArea, showNote }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Alert>
      {estimatedPropertyPrice && isActualData ? (
        <div className={s.immazing}>
          <h2>{t({ id: 'immazing_estimation.immazing_land.title' })}</h2>
          <p>{t({ id: 'project_wizard.land_estimation' })}</p>
          <b>
            {formatCurrency(estimatedPropertyPrice)}{' '}
            {t(
              { id: 'project_wizard.land_estimation_per_square_meter' },
              { value: parseFloat(estimatedPropertyPrice / landArea).toFixed(2) },
            )}
          </b>
          {showNote ? <p className={s.note}>{t({ id: 'project_wizard.land_estimation_note' })}</p> : null}
        </div>
      ) : null}
    </Alert>
  );
};

const whiteListLandEstimationValues = ['coordinates.lat', 'coordinates.lng', 'land_area'];

const whiteListFormValuesByProjectType = {
  [NEW_BUILDING]: whiteListLandEstimationValues,
  [BUYING_HOUSE]: whiteListBuyingHouseValues,
};

const useInjectImmazingValuesHandler = ({ projectId, type, values, sideEffectOnRequest }) => {
  const { formatMessage: t } = useIntl();
  const getImmazingValues = useInjectImmazingValues({ projectId, type: LAND_ESTIMATION });
  const [isSubmitting, setSubmitting] = useState(false);

  const handleInject = useCallback(async () => {
    setSubmitting(true);
    try {
      const formData = pick(values, whiteListFormValuesByProjectType[type]);
      const isFormInvalid = Object.values(formData).some((value) => value === null || typeof value === 'undefined');
      if (isFormInvalid || !values.full_address) {
        toast.error(t({ id: 'errors.fill_all_fields' }));
      } else {
        await sideEffectOnRequest();
        await getImmazingValues();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }, [getImmazingValues, sideEffectOnRequest, t, type, values]);

  return [handleInject, isSubmitting];
};

const ImmazingLandEstimation = ({ values, projectType, requestsCount, loading, calculations, sideEffectOnRequest }) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const isGuest = useHasRequiredRole(GUEST);

  const [getImmazingValues, isSubmitting] = useInjectImmazingValuesHandler({
    projectId,
    values,
    sideEffectOnRequest,
    type: projectType,
  });

  const immazing = get(calculations, 'immazing_land', {});
  const immazingFromValues = get(calculations, 'immazing_land.form_values', {});

  const isActualData = useMemo(
    () => equal(pick(immazingFromValues, whiteListLandEstimationValues), pick(values, whiteListLandEstimationValues)),
    [immazingFromValues, values],
  );

  const isAvailableRequests = requestsCount < 10;

  if (isGuest) {
    return <EstimationNotAvailableForGuest immazingLand t={t} />;
  }

  if (loading) {
    return <Loading size={50} />;
  }

  if (isActualData && immazing?.vw) {
    return (
      <EstimatedImmazingValues
        isActualData={isActualData}
        showNote={values.has_property}
        estimatedPropertyPrice={immazing?.vw}
        landArea={values.land_area}
      />
    );
  }

  if (isActualData && immazing?.error) {
    return (
      <Alert>
        <h2 className="mt-0">{t({ id: 'immazing_estimation.immazing_land.title' })}</h2>
        <div>{t({ id: 'disclaimers.land_immazing_not_available' })}</div>
      </Alert>
    );
  }

  if (!isAvailableRequests) {
    return (
      <Alert>
        <h2 className="mt-0">{t({ id: 'immazing_estimation.immazing_land.title' })}</h2>
        <div>{t({ id: 'immazing_estimation.not_available_request' }, { requestsCount })}</div>
      </Alert>
    );
  }

  return (
    <div className="my-4">
      <Alert>
        <h2 className="mt-0">{t({ id: 'immazing_estimation.immazing_land.title' })}</h2>
        <div>{t({ id: 'disclaimers.land_immazing_estimation' }, { requestsCount: requestsCount ?? 0 })}</div>
      </Alert>
      <div className={s.btnWrapper}>
        <Button loading={isSubmitting} disabled={isSubmitting} onClick={getImmazingValues}>
          {t({ id: 'immazing_estimation.btn_label' })}
        </Button>
      </div>
    </div>
  );
};

export default memo(ImmazingLandEstimation, equal);
