import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { BUDGET_CALCULATION_FRAGMENT, BUDGET_FORM_DATA_FRAGMENT } from 'graphql/fragments/budget';
import { get } from 'lodash';
import { useIntl } from 'react-intl';

export const PROJECT_BUDGET_QUERY = gql`
  query projectBudgetQuery($projectId: ID!, $locale: String) {
    myBudget {
      _id
      pdf(locale: $locale)
      ...budgetCalculation
      ...budgetFormData
    }
    project(id: $projectId) {
      _id
      budget
      type
      estimated_property_price
      form_values {
        ... on NewBuildingFormValues {
          living_space
        }
        ... on NewApartmentFormValues {
          apartment_area
        }
        ... on BuyingHouseFormValues {
          house_area
        }
        ... on RenovationHouseFormValues {
          house_area
        }
      }
    }
  }
  ${BUDGET_CALCULATION_FRAGMENT}
  ${BUDGET_FORM_DATA_FRAGMENT}
`;

const useProjectBudgetQuery = (projectId) => {
  const { locale } = useIntl();
  const { data, loading } = useQuery(PROJECT_BUDGET_QUERY, { variables: { projectId, locale } });

  const project = get(data, 'project', null);
  const myBudget = get(data, 'myBudget', null);

  return { project, myBudget, loading };
};

export default useProjectBudgetQuery;
