import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

export const GET_PAYMENTS_BY_PROJECT_ID = gql`
  query paymentsByProjectId($projectId: ID!) {
    paymentsByProjectId(projectId: $projectId) {
      _id
      name
      notes
      payee
      amount
      payment_type
      category {
        name
        label
      }
      date_paid
    }
    project(id: $projectId) {
      _id
      payments_total
    }
  }
`;

export const SPENDINGS_QUERY = gql`
  query spendings($projectId: ID!) {
    projectPlan(projectId: $projectId) {
      price
      planningCategories {
        name
        label
        description
        calculated_price
        user_price
        equipment_type
        avg_equipment_type
        has_mixed_equipment_type
        subitems {
          name
          label
          description
          calculated_price
          user_price
          equipment_type
        }
      }
    }
    paymentsByProjectId(projectId: $projectId) {
      _id
      name
      notes
      payee
      amount
      payment_type
      category {
        name
        label
      }
      date_paid
    }
    project(id: $projectId) {
      _id
      type
      payments_total
    }
  }
`;

const useGetSpendings = (projectId) => {
  const { data, loading } = useQuery(SPENDINGS_QUERY, { variables: { projectId } });

  const projectPlan = get(data, 'projectPlan', []);
  const payments = get(data, 'paymentsByProjectId', []);
  const project = get(data, 'project', null);

  return { projectPlan, payments, project, loading };
};

export default useGetSpendings;
