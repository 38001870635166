/* eslint-disable react/jsx-no-target-blank */
import settings from 'config/settings';
import React from 'react';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import { ArrowForward } from 'assets/icons';
import s from './EnuPartner.module.scss';

export default function EnuPartner() {
  const { formatMessage: t } = useIntl();

  return (
    <div className={s.enuPartner}>
      <img src={require('assets/images/logo-enu.jpeg')} alt="Enu partner" />
      <div className={s.secondCol}>
        <p>{t({ id: 'renovation_house_wizard.enu_partner.description' })}</p>
        <a href={settings.enuPartnerLink} target="_blank" rel="noreferrer">
          <Button color="primary" endIcon={<ArrowForward />}>
            {t({ id: 'renovation_house_wizard.enu_partner.request_btn' })}
          </Button>
        </a>
      </div>
    </div>
  );
}
