import Button from 'components/common/Button';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useGetCO2Values from 'hooks/co2-calculator/useGetCO2Values';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Table from 'rc-table';
import { useIntl } from 'react-intl';
import { isArray, omit, pick } from 'lodash';
import { formatValue } from 'utils/helpers';
import s from './CO2Values.module.scss';

function getInputParametersRows(data) {
  const co2FormValuesBefore = data.co2_emissions_before.form_values;
  const co2FormValuesPotential = data.co2_emissions_potential.form_values;
  const co2FormValuesAfter = data?.co2_emissions_after?.form_values || {};
  const keys = Object.keys(omit(co2FormValuesBefore, '__typename'));

  const format = (value) => {
    if (!value) return '';
    return isArray(value) ? value.join(', ') : String(value);
  };

  const rows = keys.map((key) => ({
    key,
    optionName: `debug_mode.co2_values.form_values.${key}`,
    actualScenarioInput: format(co2FormValuesBefore[key]),
    potentialScenarioInput: format(co2FormValuesPotential[key]),
    plannedScenarioInput: format(co2FormValuesAfter[key]),
  }));
  return rows;
}

const inputParametersColumns = [
  {
    title: 'debug_mode.co2_values.columns.option_name',
    key: 'optionName',
    dataIndex: 'optionName',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.actual_scenario_input',
    key: 'actualScenarioInput',
    dataIndex: 'actualScenarioInput',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.potential_scenario_input',
    key: 'potentialScenarioInput',
    dataIndex: 'potentialScenarioInput',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.planned_scenario_input',
    key: 'plannedScenarioInput',
    dataIndex: 'plannedScenarioInput',
    width: '25%',
  },
];

const CO2InputParametersTable = ({ values, t }) => {
  const localizedColumns = useMemo(
    () => inputParametersColumns.map(({ title, ...rest }) => ({ ...rest, title: t({ id: title }) })),
    [t],
  );

  const rows = useMemo(() => getInputParametersRows(values), [values]);
  const localizedRows = useMemo(
    () => rows.map(({ optionName, ...rest }) => ({ ...rest, optionName: t({ id: optionName }) })),
    [rows, t],
  );

  return (
    <Table
      className={s.co2ParametersTable}
      style={{ width: '100%' }}
      scroll={{ x: '100%' }}
      direction="ltr"
      columns={localizedColumns}
      data={localizedRows}
      emptyText="No data"
    />
  );
};

const outputParametersColumns = [
  {
    title: 'debug_mode.co2_values.columns.option_name',
    key: 'optionName',
    dataIndex: 'optionName',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.actual_scenario_output',
    key: 'actualScenarioOutput',
    dataIndex: 'actualScenarioOutput',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.actual_scenario_output',
    key: 'potentialScenarioOutput',
    dataIndex: 'potentialScenarioOutput',
    width: '25%',
  },
  {
    title: 'debug_mode.co2_values.columns.actual_scenario_output',
    key: 'plannedScenarioOutput',
    dataIndex: 'plannedScenarioOutput',
    width: '25%',
  },
];

function getOutputParametersRows(data) {
  const co2ResultBefore = data.co2_emissions_before.result;
  const co2PotentialResult = data.co2_emissions_potential.result;
  const co2ResultAfter = data?.co2_emissions_after?.result || {};
  const keys = Object.keys(omit(co2ResultBefore, '__typename'));

  const format = (key, value) => {
    const editableFields = [
      'primary_energy_for_heating',
      'primary_energy_for_hot_water',
      'total_power_consumption_per_year',
    ];
    if (!value) return '';

    if (editableFields.includes(key)) {
      return formatValue(value.calculated_value);
    }
    return formatValue(value);
  };

  const rows = keys.map((key) => ({
    key,
    optionName: `debug_mode.co2_values.result.${key}`,
    actualScenarioOutput: format(key, co2ResultBefore[key]),
    potentialScenarioOutput: format(key, co2PotentialResult[key]),
    plannedScenarioOutput: format(key, co2ResultAfter[key]),
  }));
  return rows;
}

const CO2OutputParametersTable = ({ t, values }) => {
  const localizedColumns = useMemo(
    () => outputParametersColumns.map(({ title, ...rest }) => ({ ...rest, title: t({ id: title }) })),
    [t],
  );
  const rows = useMemo(() => getOutputParametersRows(values), [values]);
  const localizedRows = useMemo(
    () => rows.map(({ optionName, ...rest }) => ({ ...rest, optionName: t({ id: optionName }) })),
    [rows, t],
  );

  return (
    <Table
      className={s.co2ParametersTable}
      style={{ width: '100%' }}
      scroll={{ x: '100%' }}
      direction="ltr"
      columns={localizedColumns}
      data={localizedRows}
      emptyText="No data"
    />
  );
};

export default () => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const { getCO2Values, loading, co2Values, called } = useGetCO2Values(projectId);

  const co2InputParameters = pick(co2Values, [
    'co2_emissions_before.form_values',
    'co2_emissions_potential.form_values',
    'co2_emissions_after.form_values',
  ]);

  const co2OutputParameters = pick(co2Values, [
    'co2_emissions_before.result',
    'co2_emissions_potential.result',
    'co2_emissions_after.result',
  ]);

  const handleGetValuesClick = useCallback(async () => getCO2Values(), [getCO2Values]);

  if (loading) return <LoadingOverlay />;
  if (called && !co2Values) return <div>Complete project details step at first</div>;

  return (
    <>
      <Button onClick={handleGetValuesClick} loading={loading} disabled={loading} className="mb-4">
        Get CO₂ values
      </Button>
      {!loading ? (
        <>
          {co2Values ? (
            <>
              <CO2InputParametersTable values={co2InputParameters} t={t} />
              <CO2OutputParametersTable values={co2OutputParameters} t={t} />
            </>
          ) : null}
        </>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};
