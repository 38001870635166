import * as yup from 'yup';
// import { validZipCodes } from 'constants/variables';
import { __ } from 'utils/form';

const validationSchema = yup.object().shape({
  construction_year: yup
    .number()
    .nullable()
    .required(__('errors.fill_field'))
    .min(1900, __('errors.values_within_range', { min: 1900, max: new Date().getFullYear() }))
    .max(new Date().getFullYear(), __('errors.values_within_range', { min: 1900, max: new Date().getFullYear() })),
  full_address: yup
    .string()
    .nullable()
    .required(__('errors.full_address')),
  zip: yup
    .string()
    .nullable()
    .required(__('errors.fill_field')),
  // .when('country', (val, schema) =>
  //   validZipCodes[val] ? schema.matches(validZipCodes[val], { message: __('errors.wrong_zip_code') }) : schema,
  // ),
  country: yup.string().required(__('errors.choose_one')),
  floor_number: yup
    .number()
    .nullable()
    .required(__('errors.fill_field')),
  apartment_area: yup
    .number()
    .nullable()
    .required(__('errors.fill_field'))
    .min(25, __('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, __('errors.values_within_range', { min: 25, max: 300 })),
  condition_of_apartment: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  loggia_area: yup
    .mixed()
    .notRequired()
    .when('loggia', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  terrace_area: yup
    .mixed()
    .notRequired()
    .when('terrace', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  garden_area: yup
    .mixed()
    .notRequired()
    .when('garden', {
      is: true,
      then: yup
        .number()
        .nullable()
        .required(__('errors.fill_field')),
    }),
  parking: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  heating_system: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

export default validationSchema;
