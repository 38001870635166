import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { formatGraphqlErrors } from 'utils/helpers';
import { BUDGET_CALCULATION_FRAGMENT, BUDGET_FORM_DATA_FRAGMENT } from 'graphql/fragments/budget';

const UPDATE_BUDGET_MUTATION = gql`
  mutation updateBudget($formData: UpdateBudgetInput!) {
    updateBudget(formData: $formData) {
      _id
      pdf
      ...budgetCalculation
      ...budgetFormData
    }
  }
  ${BUDGET_CALCULATION_FRAGMENT}
  ${BUDGET_FORM_DATA_FRAGMENT}
`;

const useUpdateBudget = () => {
  const [updateBudgetMutation] = useMutation(UPDATE_BUDGET_MUTATION);

  const budgetCalculator = useCallback(
    async (formData) => {
      try {
        const {
          data: { updateBudget: result },
        } = await updateBudgetMutation({
          variables: { formData },
          refetchQueries: ['myBudget'],
          awaitRefetchQueries: true,
        });

        return result;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [updateBudgetMutation],
  );
  return budgetCalculator;
};

export default useUpdateBudget;
