import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import routePaths, { routePatterns } from 'router/route-paths';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { CURRENT_USER_FRAGMENT } from 'hooks/user/useUser';
import { useIntl } from 'react-intl';
import useLastProjectId from 'hooks/project/useLastProjectId';
import { formatGraphqlErrors } from 'utils/helpers';

export const VERIFY_EMAIL_MUTATION = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      token
      user {
        ...currentUser
      }
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

const useVerifyEmail = () => {
  const history = useHistory();
  const { formatMessage: __ } = useIntl();
  const { lastProjectId } = useLastProjectId();

  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION, {
    refetchQueries: ['me'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const token = get(data, 'verifyEmail.token', null);
      if (token) {
        toast.success(__({ id: 'verify_account.email_verified' }));
        if (lastProjectId) {
          history.replace(routePatterns.dashboard.stringify({ id: lastProjectId }));
        } else {
          history.replace(routePaths.myProjects);
        }
      }
    },
  });

  const handleVerifyEmail = useCallback(
    async (token) => {
      try {
        await verifyEmail({ variables: { token } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
        history.replace(routePaths.login);
      }
    },
    [history, verifyEmail],
  );

  return handleVerifyEmail;
};

export default useVerifyEmail;
