/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useMemo, useRef } from 'react';
import RCCheckbox from 'rc-checkbox';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { useAppContext } from 'contexts/AppContext';
import s from './Checkbox.module.scss';
import 'rc-checkbox/assets/index.css';

const Checkbox = ({
  field: { name, value, onBlur },
  form: { touched, errors, setFieldValue },
  icon,
  color,
  skipScrollingToField,
  skipScrollingOnChange,
  sideEffectOnChange,
  ...props
}) => {
  const { label, className, style, description } = props;
  const fieldWrapperRef = useRef();
  const { theme } = useAppContext();

  const handleChange = React.useCallback(
    (e) => {
      const v = e.target.checked;
      setFieldValue(name, v);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange(v);
      if (v && !skipScrollingOnChange) scrollToNextMatchingSibling(fieldWrapperRef);
    },
    [name, setFieldValue, sideEffectOnChange, skipScrollingOnChange],
  );

  useEffect(() => {
    if (isNil(value)) setFieldValue(name, false);
  }, [name, setFieldValue, value]);

  const fieldWrapperClassnames = useMemo(() => {
    let classes = '';
    if (!skipScrollingToField) {
      classes += 'scroll-to-field';
    }
    return cn(s.checkboxWrapper, className, classes);
  }, [skipScrollingToField, className]);

  return (
    <div className={fieldWrapperClassnames} style={style} ref={fieldWrapperRef}>
      <div className={cn(s.checkbox, theme === 'green' ? s.greenCheckbox : '')}>
        <RCCheckbox
          name={name}
          id={name}
          type="checkbox"
          checked={value || false}
          onChange={handleChange}
          onBlur={onBlur}
          className={s.checkboxInput}
        />
        <label className={s.label} htmlFor={name}>
          <div style={{ color }}>{label}</div>
          {icon ? <div className={s.icon}>{icon}</div> : null}
        </label>
      </div>

      {description ? (
        <div className={s.note}>
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {errors[name] && touched[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(Checkbox, areEqualFields);
