import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PAYMENTS_QUERY } from './useGetPayments';
import { PROJECTS_PAYERS_QUERY } from './useProjectsPayersQuery';
import useGetFilters from './useGetFilters';

const ARCHIVE_PAYMENT_MUTATION = gql`
  mutation archiveProjectPayment($paymentId: ID!) {
    archiveProjectPayment(paymentId: $paymentId)
  }
`;

const useArchivePayment = () => {
  const [archivePaymentMutation] = useMutation(ARCHIVE_PAYMENT_MUTATION);
  const { filters } = useGetFilters();

  const handleArchivePayment = useCallback(
    async (paymentId) => {
      try {
        await archivePaymentMutation({
          variables: { paymentId },
          refetchQueries: [
            { query: GET_PAYMENTS_QUERY, variables: { filters, limit: 10, offset: 0 } },
            { query: PROJECTS_PAYERS_QUERY, variables: { filters } },
          ],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [archivePaymentMutation, filters],
  );
  return handleArchivePayment;
};

export default useArchivePayment;
