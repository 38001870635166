import React from 'react';
import cn from 'classnames';
import { useLocaleContext } from 'contexts/LocaleContext';
import { locales } from 'constants/variables';
import s from './LanguageSwitcher.module.scss';

function LanguageSwitcher() {
  const { changeLocale, locale } = useLocaleContext();

  return (
    <div className={s.localesContainer}>
      {locales.map((l) => {
        return (
          <button
            key={l}
            type="button"
            onClick={() => changeLocale(l)}
            className={cn(s.localeItem, locale === l ? s.activeLocaleItem : '')}
          >
            {l}
          </button>
        );
      })}
    </div>
  );
}

export default LanguageSwitcher;
