import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ReactComponent as RedevelopmentHouse } from 'assets/images/quick-calculator/renovation-house.svg';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward } from 'assets/icons';
import get from 'lodash/get';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import EnergyReductionItems from './EnergyReductionItems/EnergyReductionItems';
import s from './EnergyReductionAndGhgEquivalents.module.scss';

const EnergyReductionAndGhgEquivalents = ({ calculations, t, nextStep, previousStep }) => {
  const { result } = calculations.co2_emissions_before;
  const equivalentValues = useMemo(
    () => ({
      energyGhgEquivalentPlane: get(result, 'energy_ghg_equivalent_plane', 0),
      energyGhgEquivalentCar: get(result, 'energy_ghg_equivalent_car', 0),
      energyGhgEquivalentTrees: get(result, 'energy_ghg_equivalent_trees', 0),
    }),
    [result],
  );

  return (
    <>
      <Row className="mb-4">
        <Col lg={6}>
          <EnergyReductionItems {...{ calculations, t }} />
        </Col>
        <Col lg={6}>
          <h3 className="mt-0">
            {t({ id: 'renovation_house_wizard.potential_energy_balance.redevelopment_house_label' })}
          </h3>
          <div className={s.buttonsWrapper}>
            <Button onClick={previousStep} startIcon={<ArrowBack />} color="outline">
              {t({ id: 'app.back' })}
            </Button>
            <Button onClick={nextStep} endIcon={<ArrowForward />}>
              {t({ id: 'renovation_house_wizard.improve_house_btn_label' })}
            </Button>
          </div>
          <div>
            <RedevelopmentHouse title={t({ id: 'alt_text.renovation_house.renovation_house_img' })} />
          </div>
        </Col>
      </Row>
      <EnergyGhgEquivalents {...{ equivalentValues }} />
    </>
  );
};

export default EnergyReductionAndGhgEquivalents;
