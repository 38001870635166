import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { GET_PAYMENTS_BY_PROJECT_ID } from 'hooks/spendings/useGetSpendings';
import { useParams } from 'react-router-dom';
import { formatGraphqlErrors } from 'utils/helpers';
import client from 'graphql/apollo';
import { get } from 'lodash';
import { GET_PAYMENTS_QUERY } from './useGetPayments';
import { PAYMENT_FRAGMENT } from './useGetPaymentById';
import { PROJECTS_PAYERS_QUERY } from './useProjectsPayersQuery';
import useGetFilters from './useGetFilters';

const ADD_PAYMENT_MUTATION = gql`
  mutation insertProjectPayment($formData: ProjectPaymentInput!) {
    insertProjectPayment(formData: $formData) {
      ...payment
      category {
        label
      }
    }
  }
  ${PAYMENT_FRAGMENT}
`;

export const useAddPaymentHandler = ({ hideModal, countOfPayments }) => {
  const addPayment = useAddPayment(countOfPayments);
  const { formatMessage: t } = useIntl();

  const handleAddPayment = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await addPayment({ formData: { ...values } });
        hideModal();
        toast.success(t({ id: 'payments.payment_added' }));
      } catch (error) {
        toast(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [addPayment, hideModal, t],
  );
  return handleAddPayment;
};

const useAddPayment = (countOfPayments) => {
  const [addPaymentMutation] = useMutation(ADD_PAYMENT_MUTATION);
  const { id: projectId } = useParams();
  const { filters } = useGetFilters();

  const handleAddPayment = useCallback(
    async (variables) => {
      try {
        const paymentsQueryData = client.readQuery({
          query: GET_PAYMENTS_QUERY,
          variables: { filters, offset: 0, limit: countOfPayments || 10 },
        });
        const paymentsWithTotal = get(paymentsQueryData, 'paymentsWithTotal', {});

        const {
          data: { insertProjectPayment: newPayment },
        } = await addPaymentMutation({
          variables,
          refetchQueries: [
            { query: GET_PAYMENTS_BY_PROJECT_ID, variables: { projectId } },
            { query: PROJECTS_PAYERS_QUERY, variables: { filters } },
          ],
          awaitRefetchQueries: true,
        });

        const newPayments = [...(paymentsWithTotal.payments || []), newPayment];

        const paymentsTotalByCategories = newPayments.reduce((sum, payment) => sum + payment.amount, 0);

        client.writeQuery({
          query: GET_PAYMENTS_QUERY,
          variables: { filters, offset: 0, limit: countOfPayments || 10 },
          data: {
            paymentsWithTotal: { ...paymentsWithTotal, total: paymentsTotalByCategories, payments: newPayments },
          },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [addPaymentMutation, countOfPayments, filters, projectId],
  );
  return handleAddPayment;
};

export default useAddPayment;
