import {
  secondPrimaryColor,
  primaryColor,
  yellowColor,
  primaryLightColor,
  successColor,
  dangerColor,
} from 'styles/variables.scss';

export const NEW_BUILDING = 'new_building';
export const NEW_APARTMENT = 'new_apartment';
export const BUYING_HOUSE = 'buying_house';
export const RENOVATION_HOUSE = 'renovation_house';
export const PROPERTY_VALUATION = 'property_valuation';
export const LIFESTYLE_CALCULATOR = 'lifestyle_calculator';

export const PROJECT_TYPES = [
  NEW_BUILDING,
  NEW_APARTMENT,
  BUYING_HOUSE,
  RENOVATION_HOUSE,
  PROPERTY_VALUATION,
  LIFESTYLE_CALCULATOR,
];

export const GOOGLE_SERVICE = 'google_service';
export const FACEBOOK_SERVICE = 'facebook_service';

export const RECORD_PAYMENTS = 'RECORD_PAYMENTS';
export const ROUGH_ESTIMATION = 'ROUGH_ESTIMATION';

export const LAND_ESTIMATION = 'land_estimation';

export const COLORS = [secondPrimaryColor, yellowColor, primaryColor, primaryLightColor, successColor, dangerColor];
export const GUEST = 'guest';
export const DEVELOPER = 'developer';

export const countries = [
  'AT',
  'CH',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FI',
  'FR',
  'GB',
  'HR',
  'HU',
  'IE',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'NL',
  'NO',
  'PL',
  'PT',
  'SI',
  'SK',
];

// export const validZipCodes = {
//   DE: /^[0-9]{5}$/,
//   AT: /^[1-9]{1}[0-9]{3}$/,
// };

export const locales = ['de', 'en'];
