import React, { useMemo } from 'react';
import { BudgetStep6 } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import PageTitle from 'components/common/PageTitle';
import { Field, Form, Formik } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import Button from 'components/common/Button';
import { ArrowBack, ArrowForward, SaveIcon } from 'assets/icons';
import * as yup from 'yup';
import ButtonSelect from 'components/inputs/ButtonSelect';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';
import { OPTIONS } from './FifthStep';

const validationSchema = yup.object().shape({
  pension_and_insurances: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  savings: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

const SixthStep = ({ initialValues, isEditMode, onSubmit, formValuesRef, onBack, previousStep }) => {
  const { formatMessage: t } = useIntl();

  const localizedOptions = useMemo(() => OPTIONS.map(({ label, ...rest }) => ({ label: t({ id: label }), ...rest })), [
    t,
  ]);

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.sixth_step.title' })}</PageTitle>

        <Formik initialValues={isEditMode ? initialValues : formValuesRef.current} {...{ onSubmit, validationSchema }}>
          {({ isSubmitting, values, errors }) => (
            <Form>
              <ScrollToError />
              <Field
                name="pension_and_insurances"
                label={t({ id: 'budget_calculator.sixth_step.pension_and_insurances' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />
              <Field
                name="savings"
                label={t({ id: 'budget_calculator.sixth_step.savings' })}
                description={t({ id: 'budget_calculator.sixth_step.savings_description' })}
                options={localizedOptions}
                component={ButtonSelect}
                nullable={false}
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep6 title={t({ id: 'alt_text.budget_calculator.sixth_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default SixthStep;
