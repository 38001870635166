import { ArrowBack, ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import ScrollToError from 'components/common/ScrollToError';
import NumberInput from 'components/inputs/NumberInput';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import MapContainer from 'components/calculators/GoogleMap/MapContainer';
import LocationSearchInput from 'components/calculators/GoogleMap/LocationSearchInput';
import SwitchInput from 'components/inputs/Switch';
import { get, pick } from 'lodash';
import useGetProjectCalculations from 'hooks/useGetProjectCalculations';
import { useParams } from 'react-router-dom';
import equal from 'fast-deep-equal/es6/react';
import { Visible } from 'components/common/Grid';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import TextInput from 'components/inputs/TextInput';
import cn from 'classnames';
import ImmazingLandEstimation from 'components/calculators/ImmazingEstimation/ImmazingLandEstimation';
import Select from 'components/inputs/Select';
import { countries, NEW_BUILDING } from 'constants/variables';
import EstimationReminderModal from 'components/calculators/ImmazingEstimation/EstimationReminderModal';
import { useModal } from 'components/common/Modal';
import validationSchema from '../yupSchemas/buildHouseSecondStepSchema';
import s from '../../calculators.module.scss';

const BuildHouseSecondStep = ({
  initialValues,
  formValuesRef,
  isEditMode,
  lastStep,
  onSubmit,
  onSubmitStep,
  nextStep,
  previousStep,
  currentStep,
  onBack,
}) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const formikRef = useRef();
  const allCountries = useMemo(() => countries.map((cc) => ({ label: t({ id: `countries.${cc}` }), value: cc })), [t]);
  const { calculations, immazingRequestsCount: requestsCount, loading } = useGetProjectCalculations(projectId);
  const estimatedPropertyPrice = get(calculations, 'immazing_land.vw', 0);
  const { showModal, hideModal, isVisible } = useModal();
  const estimationRequestCompleted = useMemo(
    () => calculations?.immazing_land?.request_completed || requestsCount >= 10,
    [calculations, requestsCount],
  );

  const onHasPropertyChange = useCallback(
    (value, name, setFieldValue) => {
      setFieldValue(name, value);
      setFieldValue('estimated_property_price', value ? estimatedPropertyPrice : null);
    },
    [estimatedPropertyPrice],
  );

  const onImmazingValuesChange = useCallback(() => {
    if (formikRef.current) {
      const prev = pick(initialValues, ['full_address', 'land_area']);
      const current = pick(formikRef.current.values, ['full_address', 'land_area']);
      if (!initialValues.estimated_property_price || !equal(prev, current)) {
        formikRef.current.setFieldValue(
          'estimated_property_price',
          estimatedPropertyPrice ? Math.round(estimatedPropertyPrice) : null,
        );
      }
    }
  }, [estimatedPropertyPrice, initialValues]);

  useEffect(() => {
    onImmazingValuesChange();
  }, [onImmazingValuesChange]);

  return (
    <Formik
      initialValues={formValuesRef?.current}
      onSubmit={async (values, formikActions) => {
        onSubmitStep(values);
        await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
      }}
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {({ values, isSubmitting, setSubmitting, setFieldValue }) => (
        <Row center="md" between="lg">
          <Col md={8} lg={6} xl={5}>
            <Form>
              <ScrollToError />
              <Field
                name="full_address"
                latLngName="coordinates"
                component={LocationSearchInput}
                label={t({ id: 'project_wizard.full_address' })}
                projectType={NEW_BUILDING}
              />
              {values.coordinates ? (
                <div className="my-4">
                  <MapContainer position={values.coordinates} />
                </div>
              ) : null}
              <Field
                name="country"
                component={Select}
                options={allCountries}
                label={t({ id: 'project_wizard.country' })}
              />
              <Field name="zip" component={TextInput} label={t({ id: 'project_wizard.zip_code' })} />
              <Field
                name="land_area"
                component={SliderWithTooltip}
                min={200}
                step={50}
                max={3000}
                units="m²"
                label={t({ id: 'project_wizard.land_area' })}
              />
              <Field
                name="has_property"
                component={SwitchInput}
                label={t({ id: 'project_wizard.has_property' })}
                onChange={(v) => onHasPropertyChange(v, 'has_property', setFieldValue)}
              />
              {values.has_property ? (
                <Field
                  name="estimated_property_price"
                  component={NumberInput}
                  type="number"
                  min={0}
                  max={2000000}
                  label={t({ id: 'project_wizard.estimated_property_price' })}
                />
              ) : (
                <Field
                  label={t({ id: 'project_wizard.avg_property_price_sq_m' })}
                  name="avg_property_price_sq_m"
                  type="number"
                  component={NumberInput}
                  max={10000}
                />
              )}

              <EstimationReminderModal {...{ isVisible, hideModal, isSubmitting, t }} />

              <Visible xs sm md>
                <div className="my-4">
                  <ImmazingLandEstimation
                    {...{ values, requestsCount, loading, projectType: NEW_BUILDING, calculations }}
                    sideEffectOnRequest={async () => {
                      await onSubmit({
                        values,
                        currentStep,
                        isEditMode,
                        changeStep: false,
                        formikActions: { setSubmitting },
                      });
                    }}
                  />
                </div>
              </Visible>

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button onClick={() => onBack(values, previousStep)} startIcon={<ArrowBack />} color="outline">
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  {...(!estimationRequestCompleted ? { onClick: showModal, type: 'button' } : { type: 'submit' })}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          </Col>

          <Col xs={0} sm={0} md={0} lg={6} first="xs" last="lg">
            <div className={cn(s.imgBox, 'my-4')}>
              <ImmazingLandEstimation
                {...{ values, requestsCount, loading, projectType: NEW_BUILDING, calculations }}
                sideEffectOnRequest={async () => {
                  await onSubmit({
                    values,
                    currentStep,
                    isEditMode,
                    changeStep: false,
                    formikActions: { setSubmitting },
                  });
                }}
              />
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default BuildHouseSecondStep;
