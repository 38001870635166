import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'components/common/Button';
import routePaths from 'router/route-paths';
import Navbar from './Navbar';
import s from './Navbar.module.scss';
import LanguageSwitcher from './LanguageSwitcher';

const AuthNavbar = () => (
  <Navbar hideHamburger fluid auth>
    <div className="mr-4">
      <LanguageSwitcher />
    </div>
    <Link to={routePaths.login} className="link">
      <Button color="danger" className={s.loginBtn}>
        <FormattedMessage id="auth.sign_in_submit" />
      </Button>
    </Link>
  </Navbar>
);

export default AuthNavbar;
