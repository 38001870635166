import React from 'react';
import { navbarHeight } from 'styles/variables.scss';
import Footer from '../layout/Footer';
import AuthNavbar from '../layout/AuthNavbar';

export default function AuthLayout({ children }) {
  return (
    <>
      <div className="content root-container container-narrow" style={{ paddingTop: navbarHeight }}>
        <div>{children}</div>
      </div>
      <AuthNavbar />
      <Footer />
    </>
  );
}
