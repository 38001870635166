import React, { useMemo } from 'react';
import Alert from 'components/common/Alert';
import { FormattedMessage } from 'react-intl';
import { yellowColor, successColor, grayDarkColor, whisperGrey } from 'styles/variables.scss';
import { Col, Row } from 'react-flexbox-grid';
import Tooltip from 'rc-tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { formatCurrency } from 'utils/helpers';
import ReactSpeedometer from '@uplab/react-d3-speedometer';
import Button from 'components/common/Button';
import { ArrowForward } from 'assets/icons';
import s from './ImmazingEstimation.module.scss';

export const NoEstimationRequests = ({ requestsCount, t }) => (
  <Alert>
    <h2 className="mt-0">
      <FormattedMessage id="immazing_estimation.immazing.title" />
    </h2>
    <div>{t({ id: 'immazing_estimation.not_available_request' }, { requestsCount })}</div>
  </Alert>
);

export const EstimationNotAvailable = ({ t }) => (
  <Alert>
    <h2 className="mt-0">
      <FormattedMessage id="immazing_estimation.immazing.title" />
    </h2>
    <div>{t({ id: 'disclaimers.immazing_not_available' })}</div>
  </Alert>
);

export const NoActualEstimationInfo = ({ t, requestsCount, isSubmitting, getImmazingValues }) => (
  <div>
    <Alert>
      <h2 className="mt-0">{t({ id: 'immazing_estimation.immazing.title' })}</h2>
      {t({ id: 'disclaimers.immazing_estimation' }, { requestsCount: requestsCount ?? 0 })}
    </Alert>

    <div className={s.btnWrapper}>
      <Button loading={isSubmitting} disabled={isSubmitting} onClick={getImmazingValues} endIcon={<ArrowForward />}>
        {t({ id: 'immazing_estimation.btn_label' })}
      </Button>
    </div>
  </div>
);

export const EstimationNotAvailableForGuest = ({ t, immazingLand }) => (
  <Alert>
    <h2 className="mt-0">{t({ id: `immazing_estimation.${immazingLand ? 'immazing_land' : 'immazing'}.title` })}</h2>
    <div>{t({ id: `immazing_estimation.${immazingLand ? 'immazing_land' : 'immazing'}.not_available_for_guest` })}</div>
  </Alert>
);

function getDataForChart(immazing) {
  if (!immazing?.vw) return [];
  const { vw_lwr, vw_upr } = immazing;
  const startValue = vw_lwr - (vw_upr - vw_lwr);
  const endValue = vw_lwr - startValue + vw_upr;

  return [startValue, vw_lwr, vw_upr, endValue];
}

export const EstimationInfo = ({ t, immazing, projectPrice }) => {
  const [startValue, vw_lwr, vw_upr, endValue] = useMemo(() => getDataForChart(immazing), [immazing]);
  // eslint-disable-next-line no-nested-ternary
  const price = projectPrice > endValue ? endValue : projectPrice < startValue ? startValue : projectPrice;

  return (
    <div className={s.card}>
      <Row>
        <Col xs={12}>
          <h2 className="mt-0">
            <FormattedMessage id="immazing_estimation.immazing.title" />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <div className={s.immazing}>
            <p className={s.subTitle}>
              <Tooltip overlay={<p>{t({ id: 'immazing_estimation.immazing.tooltip_description' })}</p>} placement="top">
                <span style={{ cursor: 'pointer' }}>
                  {t({ id: 'immazing_estimation.immazing.tooltip_label' })}
                  <AiOutlineQuestionCircle size={17} />
                </span>
              </Tooltip>
            </p>
            <b className={s.vw}>{formatCurrency(immazing.vw)}</b>
            <p>{t({ id: 'immazing_estimation.estimated_range' })}</p>
            <span>
              <b className={s.vw_lwr}>{formatCurrency(immazing.vw_lwr)}</b> -{' '}
              <b className={s.vw_upr}>{formatCurrency(immazing.vw_upr)}</b>
            </span>
          </div>
        </Col>
        <Col xl={6} className={s.chartContainer}>
          <ReactSpeedometer
            value={parseInt(price, 10)}
            width={250}
            height={200}
            minValue={startValue}
            maxValue={endValue}
            valueFormat={(v) => formatCurrency(v)}
            needleColor={grayDarkColor}
            segmentColors={[successColor, whisperGrey, yellowColor]}
            segments={3}
            currentValueText={t({ id: 'legend.total_label' }, { value: formatCurrency(projectPrice) })}
            customSegmentStops={[startValue, vw_lwr, vw_upr, endValue]}
            textColor={grayDarkColor}
            paddingVertical={5}
          />
        </Col>
      </Row>
    </div>
  );
};
