import NumberInput from 'components/inputs/NumberInput';
import { Field } from 'formik';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import inRange from 'lodash/inRange';
import { pick } from 'lodash';
import s from './BirthdayInputs.module.scss';

const BirthdayInputs = ({ form, label, required }) => {
  const { formatMessage: t } = useIntl();
  const { values, errors, setFieldValue, setFieldError } = form;

  const handleSetDate = useCallback(
    ({ name, value: v }) => {
      const date = pick(values, ['day', 'month', 'year']);
      date[name] = v;
      const selectedDate = new Date(date.year, date.month - 1, date.day);
      setFieldValue('date_of_birth', selectedDate);
    },
    [setFieldValue, values],
  );

  const validateDay = useCallback((day) => (required && !day ? t({ id: 'errors.fill_field' }) : null), [t, required]);

  const validateMonth = useCallback(
    (month) => {
      const year = get(values, 'year', null);
      const day = get(values, 'day', null);
      if (day && month && year) {
        const daysInMonth = new Date(year, month, 0).getDate();
        const validDate = inRange(day, 1, daysInMonth + 1);
        if (!errors.day && !validDate) {
          setFieldError('day', t({ id: 'errors.invalid_date' }));
        }
      }
      return required && !month ? t({ id: 'errors.fill_field' }) : null;
    },
    [values, t, required, errors, setFieldError],
  );

  const validateYear = useCallback(
    (year) => {
      const currentYear = new Date().getFullYear();
      if (year && year < 1940) return t({ id: 'errors.min_value' }, { min: 1940 });
      if (year && year > currentYear) return t({ id: 'errors.max_value' }, { max: currentYear });
      const res = required && !year ? t({ id: 'errors.fill_field' }) : null;
      return res;
    },
    [t, required],
  );

  return (
    <div style={{ marginTop: '50px' }}>
      {label ? <h3 className={s.dateOfBirth}>{t({ id: label })}</h3> : null}
      <Row className={s.birthWrapper}>
        <Col xs={4} md={3}>
          <Field
            name="day"
            component={NumberInput}
            max={31}
            label={t({ id: 'loan.personal_financing_request.day' })}
            className={s.numberInputWrapper}
            validate={validateDay}
            sideEffectOnChange={handleSetDate}
          />
        </Col>
        <Col xs={4} md={3}>
          <Field
            name="month"
            component={NumberInput}
            max={12}
            label={t({ id: 'loan.personal_financing_request.month' })}
            className={s.numberInputWrapper}
            validate={validateMonth}
            sideEffectOnChange={handleSetDate}
          />
        </Col>
        <Col xs={4} md={3}>
          <Field
            name="year"
            component={NumberInput}
            thousandSeparator=""
            max={new Date().getFullYear()}
            label={t({ id: 'loan.personal_financing_request.year' })}
            className={s.numberInputWrapper}
            validate={validateYear}
            sideEffectOnChange={handleSetDate}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BirthdayInputs;
