import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/client';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import { formatGraphqlErrors } from 'utils/helpers';
import { cloneDeep } from 'lodash';
import { FINANCING_QUERY } from './useFinancing';

const REQUEST_PERSONAL_OFFER_MUTATION = gql`
  mutation requestPersonalOffer($projectId: ID!, $formData: RequestPersonalOffer!) {
    requestPersonalOffer(projectId: $projectId, formData: $formData) {
      success
    }
  }
`;

const useRequestPersonalOffer = () => {
  const { formatMessage, locale } = useIntl();
  const client = useApolloClient();

  const [requestPersonalOfferMutation, { loading }] = useMutation(REQUEST_PERSONAL_OFFER_MUTATION, {
    onCompleted: () => toast.success(formatMessage({ id: 'loan.request_sent' })),
    onError: (error) => {
      const e = formatGraphqlErrors(error);
      toast.error(e.message);
    },
  });

  const handleRequestPersonalOffer = useCallback(
    async (projectId, formData) => {
      try {
        await requestPersonalOfferMutation({ variables: { projectId, formData } });
        const financingQuery = client.readQuery({ query: FINANCING_QUERY, variables: { id: projectId, locale } });
        if (!financingQuery) return;
        const clonedData = cloneDeep(financingQuery);
        clonedData.project.progress.loan_request = true;
        client.writeQuery({ query: FINANCING_QUERY, variables: { id: projectId, locale }, data: { ...clonedData } });
      } catch (error) {
        toast.error(get(error, 'graphQLErrors[0].message'));
      }
    },
    [client, locale, requestPersonalOfferMutation],
  );
  return [handleRequestPersonalOffer, loading];
};

export default useRequestPersonalOffer;
