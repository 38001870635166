import React from 'react';
import cn from 'classnames';
import { Hidden, Visible } from 'components/common/Grid';
import { useIntl } from 'react-intl';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import Button from 'components/common/Button';
import { Link } from 'react-router-dom';
import routePaths from 'router/route-paths';
import { GUEST } from 'constants/variables';
import Alert from 'components/common/Alert';
import settings from 'config/settings';
import { ArrowForward, Settings } from 'assets/icons';
import { useAppContext } from 'contexts/AppContext';
import usePopulatePayments from 'hooks/payments/usePopulatePayments';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import AppFooter from '../layout/AppFooter';
import AppNavbar from '../layout/AppNavbar';
import Sidebar from '../layout/Sidebar';
import SidebarAwareContainer from '../layout/SidebarAwareContainer';
import s from './AppLayout.module.scss';

const DevMenu = ({ toggleDevMenu, devMenuOpen }) => {
  const [onPopulatePayments, isSubmitting] = usePopulatePayments();
  return (
    <div className={cn(s.devMenu, devMenuOpen ? s.devMenuOpen : s.devMenuHidden)}>
      <Button onClick={onPopulatePayments} loading={isSubmitting} disabled={isSubmitting}>
        Populate payments
      </Button>
      <ArrowForward onClick={toggleDevMenu} className={s.closeIcon} />
    </div>
  );
};

export default function AppLayout({ children, noCardWrapper, fluid }) {
  const { formatMessage: t } = useIntl();
  const isGuest = useHasRequiredRole(GUEST);
  const { devMenuOpen, toggleDevMenu } = useAppContext();

  return (
    <>
      <Sidebar />
      <SidebarAwareContainer>
        <div className={cn('content', 'root-container', !fluid && 'container-narrow', fluid && 'container-fluid')}>
          {isGuest ? (
            <Alert withContainer className={s.disclaimer}>
              <div className={s.disclaimerContent}>
                <InfoIcon className={s.infoIcon} title={t({ id: 'alt_text.icons.info' })} />
                <p>{t({ id: 'disclaimers.guest_user' })}</p>
                <div className={s.btnWrapper}>
                  <Link to={routePaths.guestSignUp}>
                    <Button endIcon={<ArrowForward />}>{t({ id: 'auth.sign_up_submit' })}</Button>
                  </Link>
                  <Link to={routePaths.guestLogin}>
                    <Button endIcon={<ArrowForward />}>{t({ id: 'auth.sign_in_submit' })}</Button>
                  </Link>
                </div>
              </div>
            </Alert>
          ) : null}
          {noCardWrapper ? children : <div>{children}</div>}
        </div>
        {settings.isDevelopment && !devMenuOpen ? (
          <Hidden xs sm>
            <Settings className={s.settingsIcon} onClick={toggleDevMenu} />
          </Hidden>
        ) : null}
        {settings.isDevelopment ? <DevMenu {...{ toggleDevMenu, devMenuOpen }} /> : null}
      </SidebarAwareContainer>
      <Visible xs sm>
        <AppNavbar />
      </Visible>
      <AppFooter />
    </>
  );
}
