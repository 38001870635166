import React, { useCallback } from 'react';
import Alert from 'components/common/Alert';
import { useIntl } from 'react-intl';
import throttle from 'lodash/throttle';
import InfiniteScrollComponent from 'components/common/InfiniteScrollComponent';
import useLoadingData from 'hooks/useLoadingData';
import useGetFilters from 'hooks/payments/useGetFilters';
import PaymentsList from './PaymentsList';
import PaymentsLoader from './PaymentsLoader';

const FilteredPayments = ({ payers, loading, projects, fetchMore, payments }) => {
  const { filters } = useGetFilters();
  const { formatMessage: t } = useIntl();

  const throttledFetchMore = useCallback(throttle(fetchMore, 200), []);
  const { hasMoreData, fetchMoreData } = useLoadingData({
    key: 'paymentsWithTotal.payments',
    variables: { filters },
    length: payments.length,
    fetchMore: throttledFetchMore,
  });

  if (loading) return <PaymentsLoader count={4} />;

  if (payments.length === 0) {
    return <Alert color="primary">{t({ id: 'payments.no_payments' })}</Alert>;
  }

  return (
    <InfiniteScrollComponent length={payments.length} {...{ hasMoreData, fetchMoreData }}>
      <PaymentsList {...{ payments, payers, projects }} />
    </InfiniteScrollComponent>
  );
};

export default FilteredPayments;
