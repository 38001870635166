/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import cn from 'classnames';
import Button from 'components/common/Button';
import { PROPERTY_VALUATION } from 'constants/variables';
import { ReactComponent as CloudDownloadIcon } from 'assets/icons/cloud-download.svg';
import { routePatterns } from 'router/route-paths';
import { Link } from 'react-router-dom';
import { ArrowForward, Trash } from 'assets/icons';
import Tooltip from 'components/common/Tooltip';
import CircleButton from 'components/common/CircleButton';
import s from './ProjectsList.module.scss';

const themeToActiveProjectClass = {
  primary: s.activeProject,
  green: s.greenActiveProject,
};

export default function ProjectCard({
  _id,
  name,
  type,
  price,
  pdf,
  theme,
  isLastProject,
  isActive,
  removeProject,
  onAdjustProject,
  onCardClick,
}) {
  const { formatMessage: t } = useIntl();
  const isPropertyValuationProject = useMemo(() => type === PROPERTY_VALUATION, [type]);

  return (
    <Col lg={5} xl={4} md={6}>
      <div
        className={cn(
          s.projectCard,
          theme === 'green' ? s.greenProjectCard : '',
          isActive ? themeToActiveProjectClass[theme] : null,
        )}
        role="link"
        onClick={(e) => onCardClick(e, _id)}
      >
        <div className={s.headerWrapper} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className={s.projectName}>{name}</h3>
          <Tooltip
            overlay={<p>{t({ id: 'my_projects.delete_project.tooltip_max_count_of_projects' })}</p>}
            hide={!isLastProject}
          >
            <span>
              <CircleButton
                className={isLastProject ? s.disabledBtn : {}}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isLastProject) removeProject(_id);
                }}
              >
                <Trash title={t({ id: 'alt_text.my_projects.delete_project' })} />
              </CircleButton>
            </span>
          </Tooltip>
        </div>
        <hr className={s.divider} />
        <div className={s.projectInfo}>
          <Row className={s.row}>
            <Col xs={12}>
              <span>{t({ id: `project_types.${type}` })}</span>
            </Col>
          </Row>
          <Row className={s.row}>
            <Col xs={5} md={5}>
              <span className={s.item}>{t({ id: 'my_projects.price' })}</span>
            </Col>
            <Col xs={7} md={7} style={{ textAlign: 'right' }}>
              <span className={s.currency}>{formatCurrency(price)}</span>
            </Col>
          </Row>
        </div>
        <div className={s.buttonsWrapper}>
          <Link
            to={routePatterns.projectDetailsCalculator.stringify({ id: _id })}
            onClick={(e) => {
              e.stopPropagation();
              onAdjustProject(_id);
            }}
          >
            <Button endIcon={<ArrowForward />}>{t({ id: 'my_projects.adjust' })}</Button>
          </Link>
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            disabled={isPropertyValuationProject}
            className={s.pdfLink}
            onClick={(e) => e.stopPropagation()}
          >
            <Button endIcon={<CloudDownloadIcon />} disabled={isPropertyValuationProject}>
              {t({ id: 'my_projects.download' })}
            </Button>
          </a>
        </div>
      </div>
    </Col>
  );
}
