import ButtonLink from 'components/common/ButtonLink';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import s from './LoginForm.module.scss';

export default function Switcher({ isSignUp, setIsSignUp }) {
  return isSignUp ? (
    <p className={s.switch}>
      <FormattedMessage id="auth.already_have_account" />
      <ButtonLink onClick={() => setIsSignUp(false)} className={s.switchBtn}>
        <FormattedMessage id="auth.already_have_account_sign_in_link" />
      </ButtonLink>
    </p>
  ) : (
    <p className={s.switch}>
      <FormattedMessage id="auth.do_not_have_account" />{' '}
      <ButtonLink onClick={() => setIsSignUp(true)} className={s.switchBtn}>
        <FormattedMessage id="auth.do_not_have_account_sign_up_link" />
      </ButtonLink>
    </p>
  );
}
