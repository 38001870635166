import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import { PAYMENT_FRAGMENT } from './useGetPaymentById';
import { PROJECTS_PAYERS_QUERY } from './useProjectsPayersQuery';
import useGetFilters from './useGetFilters';

const UPDATE_PAYMENT_MUTATION = gql`
  mutation updateProjectPayment($paymentId: ID!, $formData: ProjectPaymentInput!) {
    updateProjectPayment(paymentId: $paymentId, formData: $formData) {
      ...payment
    }
  }
  ${PAYMENT_FRAGMENT}
`;

const useUpdatePayment = () => {
  const [updatePaymentMutation] = useMutation(UPDATE_PAYMENT_MUTATION);
  const { filters } = useGetFilters();

  const updatePayment = useCallback(
    async ({ paymentId, ...formData }) => {
      try {
        await updatePaymentMutation({
          variables: { paymentId, ...formData },
          refetchQueries: [{ query: PROJECTS_PAYERS_QUERY, variables: { filters } }],
          awaitRefetchQueries: true,
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [filters, updatePaymentMutation],
  );
  return updatePayment;
};

export default useUpdatePayment;
