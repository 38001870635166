import React from 'react';
import { Formik, Field, Form } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ErrorMessage from 'components/common/ErrorMessage';
import routePaths from 'router/route-paths';
import OtpModalField from 'components/inputs/OtpModalField';
import { ArrowForward } from 'assets/icons';
import ScrollToError from 'components/common/ScrollToError';
import settings from 'config/settings';
import TextInput from 'components/inputs/TextInput';
import Checkbox from 'components/inputs/Checkbox';
import Button from 'components/common/Button';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import PasswordInput from 'components/inputs/PasswordInput';
import { GUEST } from 'constants/variables';
import { __ } from 'utils/form';
import s from './LoginForm.module.scss';
import SocialButtons from './SocialButtons';
import Switcher from './Switcher';

const loginShape = {
  email: yup
    .string()
    .required(__('errors.email_required'))
    .email(__('errors.email_invalid')),
  plainPassword: yup
    .string()
    .required(__('errors.password_required'))
    .min(8, __('errors.password_too_small')),
};
const signUpShape = {
  firstName: yup.string().required(__('errors.first_name_required')),
  lastName: yup.string().required(__('errors.last_name_required')),
  privacy_policy_consent: yup.bool().oneOf([true], __('auth.privacy_policy_required')),
  terms_of_use_consent: yup.bool().oneOf([true], __('auth.terms_of_use_required')),
  passwordRepeat: yup.string().oneOf([yup.ref('plainPassword'), null], __('errors.passwords_must_match')),
  ...loginShape,
};

const loginParamsSchema = yup.object().shape(loginShape);
const signUpParamsSchema = yup.object().shape(signUpShape);

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  plainPassword: '',
  passwordRepeat: '',
  consent: false,
  otp: '',
};

export default function LoginForm({
  onSubmit,
  initialValues,
  signUpEnabled = true,
  isLoginTwoFAWithService,
  isSignUp,
  setIsSignUp,
  showModal,
  isAdmin,
  hideModal,
  isVisible,
  onSubmitAdminLogin,
}) {
  const { formatMessage: t } = useIntl();
  const isGuest = useHasRequiredRole(GUEST);
  const schema = React.useMemo(() => {
    if (isSignUp) {
      return signUpParamsSchema;
    }
    return loginParamsSchema;
  }, [isSignUp]);

  return (
    <Formik initialValues={initialValues || defaultInitialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ handleSubmit, errors, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className={s.form}>
          <ScrollToError />
          <div className={s.socialLoginContainer}>
            <p className={s.label}>{t({ id: isSignUp ? 'auth.create_account_using' : 'auth.sign_in_with' })}</p>
            <SocialButtons showModal={showModal} isSubmitting={isSubmitting} />
            <div className={s.divider}>
              <span className={s.line} />
              <span className={s.text}>{t({ id: 'auth.divider' })}</span>
              <span className={s.line} />
            </div>
          </div>

          {isSignUp ? (
            <div>
              <Field name="firstName" component={TextInput} label={t({ id: 'auth.first_name' })} />
              <Field name="lastName" component={TextInput} label={t({ id: 'auth.last_name' })} />
            </div>
          ) : null}

          {!isLoginTwoFAWithService ? (
            <>
              <Field name="email" lowerCase component={TextInput} label={t({ id: 'auth.email' })} type="email" />
              <Field
                name="plainPassword"
                component={PasswordInput}
                label={t({ id: 'auth.password' })}
                type="password"
              />
            </>
          ) : null}

          {isSignUp ? (
            <>
              <Field
                name="passwordRepeat"
                component={PasswordInput}
                label={t({ id: 'auth.repeat_password' })}
                type="password"
              />
              <Field
                name="terms_of_use_consent"
                component={Checkbox}
                label={
                  <FormattedMessage
                    id="auth.terms_of_use"
                    values={{
                      link: (
                        <a
                          href={settings.termsOfUseUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          {t({ id: 'auth.terms_of_use_link_label' })}
                        </a>
                      ),
                    }}
                  />
                }
              />
              <Field
                name="privacy_policy_consent"
                component={Checkbox}
                label={
                  <FormattedMessage
                    id="auth.privacy_policy"
                    values={{
                      link: (
                        <a
                          href={settings.privacyPolicyUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          {t({ id: 'auth.privacy_policy_link_label' })}
                        </a>
                      ),
                    }}
                  />
                }
              />
            </>
          ) : null}

          {!isSignUp && !isAdmin ? (
            <p className="text-left">
              <Link to={isGuest ? routePaths.guestForgotPassword : routePaths.forgotPassword} className="link">
                {t({ id: 'auth.forgot_password.note' })}
              </Link>
            </p>
          ) : null}

          {signUpEnabled && !isGuest ? <Switcher {...{ setIsSignUp, isSignUp }} /> : null}

          {errors.form && <ErrorMessage message={errors.form} />}

          <Field
            name="otp"
            component={OtpModalField}
            label={t({ id: 'auth.verification_code_tip' })}
            {...{ hideModal, isVisible, onSubmitAdminLogin }}
          />

          <div className={s.btnWrapper}>
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting} endIcon={<ArrowForward />}>
              {t({ id: isSignUp ? 'auth.sign_up_submit' : 'auth.sign_in_submit' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
