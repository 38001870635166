import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

export const GET_FILTERS = gql`
  query filters {
    filters @client {
      searchQuery
      projects
      categories
      payers
    }
  }
`;

const useGetFilters = () => {
  const { id } = useParams();
  const { data } = useQuery(GET_FILTERS);
  const filters = get(data, 'filters', { projects: [id], categories: [], payers: [], searchQuery: '' });
  const { projects, categories, payers, searchQuery } = filters;
  return { filters: { projects, categories, payers, searchQuery } };
};

export default useGetFilters;
