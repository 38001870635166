import React, { memo, useEffect } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { areEqualFields } from 'utils/form';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useAppContext } from 'contexts/AppContext';
import s from './Counter.module.scss';

const Counter = ({
  field: { name, value },
  form: { setFieldValue, touched, errors, values },
  label,
  defaultValue,
  min = 0,
  max,
  description,
  callbackOnChange,
}) => {
  const { formatMessage: t } = useIntl();
  const { theme } = useAppContext();

  const handleOnChangeNumber = React.useCallback(
    (condition) => {
      let newValue = value;
      if (condition === 'increment' && newValue < max) newValue += 1;
      if (condition === 'decrement' && newValue > min) newValue -= 1;
      if (value < min) newValue = min;
      setFieldValue(name, newValue);
      if (typeof callbackOnChange === 'function') callbackOnChange(newValue, values, setFieldValue);
    },
    [value, max, min, setFieldValue, name, callbackOnChange, values],
  );

  useEffect(() => {
    if (!Number.isFinite(value)) {
      setFieldValue(name, defaultValue || min);
    }
    if (Number.isFinite(value) && value < min) setFieldValue(name, min);
  }, [name, value, defaultValue, setFieldValue, min]);

  return (
    <div className="field-wrapper">
      <label htmlFor={name}>{label}</label>
      <div className={s.counter}>
        <button
          type="button"
          onClick={() => handleOnChangeNumber('decrement')}
          className={cn(s.counterBtn, s.ripple, theme === 'green' ? s.greenCounterBtn : '')}
        >
          <Minus title={t({ id: 'alt_text.icons.minus' })} />
        </button>
        <div className={s.value}>{value}</div>
        <button
          type="button"
          onClick={() => handleOnChangeNumber('increment')}
          className={cn(s.counterBtn, s.ripple, theme === 'green' ? s.greenCounterBtn : '')}
        >
          <Plus title={t({ id: 'alt_text.icons.plus' })} />
        </button>
      </div>
      {description ? <div className={s.description}>{description}</div> : null}
      {touched[name] && errors[name] && <div className="error-text">{errors[name]}</div>}
    </div>
  );
};

export default memo(Counter, areEqualFields);
