/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, memo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import format from 'date-fns/format';
import equal from 'fast-deep-equal/es6/react';
import Modal, { useModal } from 'components/common/Modal';
import { useIntl } from 'react-intl';
import { de } from 'date-fns/locale';
import isAfter from 'date-fns/isAfter';
import useUpdatePayment from 'hooks/payments/useUpdatePayment';
import { formatCurrency } from 'utils/helpers';
import { toast } from 'react-toastify';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import useGetPaymentById from 'hooks/payments/useGetPaymentById';
import omitDeep from 'omit-deep-lodash';
import LoadingOverlay from 'components/common/LoadingOverlay';
import get from 'lodash/get';
import DropdownMoreActions from './DropdownMoreActions';
import PaymentFormContainer from '../PaymentForm';
import s from './PaymentsList.module.scss';

const useUpdatePaymentHandler = ({ paymentId, hideModal }) => {
  const updatePayment = useUpdatePayment();
  const { formatMessage: t } = useIntl();

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const formData = omitDeep(values, '__typename', 'url');
        await updatePayment({ paymentId, formData });
        hideModal();
        toast.success(t({ id: 'payments.payment_updated' }));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [hideModal, paymentId, t, updatePayment],
  );
  return handleSubmit;
};

function getFormattedData(payments, t) {
  let sections = [];
  if (payments.length) {
    const sortFormat = 'yyyy-MM';
    const displayFormat = 'MMMM yyyy';

    const sectionsMap = groupBy(payments, ({ date_paid: datePaid, created_at: createdAt }) => {
      const date = new Date(datePaid || createdAt);
      if (isAfter(date, new Date())) {
        return 'upcoming';
      }
      return format(date, sortFormat);
    });
    const sectionKeys = Object.keys(sectionsMap)
      .sort()
      .reverse();

    sections = sectionKeys.map((YYYY_MM) => {
      const title =
        YYYY_MM === 'upcoming' ? t({ id: 'payments.date_upcoming' }) : format(new Date(YYYY_MM), displayFormat);
      const newPayments = sectionsMap[YYYY_MM];
      const sortedPayments = sortBy(
        newPayments,
        ({ created_at: createdAt, date_paid: datePaid }) => -1 * Number(new Date(datePaid || createdAt)),
      );
      return { title, data: sortedPayments };
    });
  }
  return sections;
}

export const PaymentCard = ({
  name,
  payee,
  amount,
  category,
  date_paid,
  notes,
  paymentId,
  showModal,
  withoutClick = false,
  t,
}) => (
  <div
    className={s.card}
    onClick={() => {
      if (!withoutClick) showModal({ paymentId });
    }}
  >
    <div className={s.cardHeader}>
      <h3 className={s.paymentName}>{name}</h3>
      <Row>
        <Col xs={5}>
          <small>{payee}</small>
        </Col>
        <Col xs={7}>
          <small>{t({ id: category.label })}</small>
        </Col>
      </Row>
      {!withoutClick ? (
        <div onClick={(e) => e.stopPropagation()} className={s.moreActionsBtn}>
          <DropdownMoreActions paymentId={paymentId} />
        </div>
      ) : null}
    </div>
    <div className={s.cardBody}>
      <Row>
        <Col xs={5} className={s.cardBodyCol}>
          <small>{t({ id: 'payments.amount_paid' })}</small>
          <span>{formatCurrency(amount)}</span>
        </Col>
        <Col xs={7} className={s.cardBodyCol}>
          <small>{t({ id: 'payments.date_paid' })}</small>
          <span>{format(new Date(date_paid), 'do MMMM yyyy', { locale: de })}</span>
        </Col>
      </Row>
      <p className={s.notes}>{notes}</p>
    </div>
  </div>
);

const PaymentCardMemo = memo(PaymentCard, equal);

const Section = ({ title, data, showModal, t }) => (
  <>
    <Col xs={12}>
      <h2>{title}</h2>
    </Col>
    {data.map(({ _id, ...rest }) => (
      <Col xs={12} lg={6} key={_id}>
        <PaymentCardMemo {...rest} paymentId={_id} showModal={showModal} t={t} />
      </Col>
    ))}
  </>
);

const SectionMemo = memo(Section, equal);

const Payment = ({ onSubmit, payers, projects, paymentId }) => {
  const { payment, loading } = useGetPaymentById(paymentId);

  if (loading) return <LoadingOverlay />;

  const initialValues = { ...payment, category: payment.categoryName };

  return <PaymentFormContainer {...{ paymentId, onSubmit, payers, projects, initialValues }} />;
};

export default ({ payments, projects, payers }) => {
  const { formatMessage: t } = useIntl();
  const { isVisible, hideModal, showModal, modalProps } = useModal();
  const paymentId = get(modalProps, 'paymentId');
  const onSubmit = useUpdatePaymentHandler({ paymentId, hideModal });
  const sections = getFormattedData(payments, t);

  return (
    <Row className={s.list}>
      {sections.map((section) => (
        <SectionMemo {...section} showModal={showModal} t={t} key={section.title} />
      ))}
      {modalProps?.paymentId ? (
        <Modal isVisible={isVisible} close={hideModal} headerText={t({ id: 'payments.edit_payment' })} size="lg">
          <Payment {...{ onSubmit, payers, projects, paymentId }} />
        </Modal>
      ) : null}
    </Row>
  );
};
