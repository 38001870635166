import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECT_CALCULATIONS } from './useGetProjectCalculations';

const INJECT_IMMAZING_VALUES = gql`
  mutation injectImmazingValues($projectId: ID!, $type: String!) {
    injectImmazingValues(projectId: $projectId, type: $type) {
      success
    }
  }
`;

export default function useInjectImmazingValues({ projectId, type }) {
  const [injectImmazingValues] = useMutation(INJECT_IMMAZING_VALUES);

  const getImmazingValues = useCallback(async () => {
    try {
      await injectImmazingValues({
        variables: { projectId, type },
        refetchQueries: [{ query: PROJECT_CALCULATIONS, variables: { id: projectId } }],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      const e = formatGraphqlErrors(error);
      throw e;
    }
  }, [injectImmazingValues, projectId, type]);

  return getImmazingValues;
}
