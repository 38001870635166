import { useApolloClient } from '@apollo/client';
import useUpdateCO2CalculatedValues from 'hooks/co2-calculator/useUpdateCO2CalculatedValues';
import { cloneDeep } from 'lodash';
import Collapsible from 'react-collapsible';
import React, { useState, useCallback } from 'react';
import { SectionWrapper } from 'components/common/heap';
import { RENOVATION_HOUSE_CALCULATIONS } from 'hooks/co2-calculator/useRenovationHouseCalculations';
import HeatingResult from './HeatingResult';
import HotWaterResult from './HotWaterResult';
import ElectricityResult from './ElectricityResult';
import s from '../CO2CalculatorResult.module.scss';

const AllCalculations = ({ project, t }) => {
  const client = useApolloClient();
  const [isOpen, setIsOpen] = useState(false);
  const updateCalculatedValue = useUpdateCO2CalculatedValues();

  const onUpdateCalculatedValue = useCallback(
    async ({ callback, fieldName, fieldValue }) => {
      const data = client.readQuery({ query: RENOVATION_HOUSE_CALCULATIONS, variables: { id: project._id } });
      const newData = cloneDeep(data);
      newData.project.calculations.co2_emissions_before.result[fieldName].user_input = fieldValue;
      client.writeQuery({
        query: RENOVATION_HOUSE_CALCULATIONS,
        variables: { id: project._id },
        data: { ...newData },
      });
      callback();
      await updateCalculatedValue(project._id, { [fieldName]: fieldValue });
    },
    [client, project._id, updateCalculatedValue],
  );

  return (
    <div className={s.tablesWrapper}>
      <Collapsible
        triggerClassName="collapsible-trigger"
        triggerOpenedClassName="collapsible-trigger"
        open={isOpen}
        transitionTime={800}
        handleTriggerClick={() => setIsOpen(!isOpen)}
        trigger={
          <div className={s.showAllCalculations}>
            <div>{t({ id: 'renovation_house_wizard.should_show_all_calculations' })}</div>
            <div>{t({ id: `renovation_house_wizard.${isOpen ? 'hide_calculations' : 'show_calculations'}` })}</div>
          </div>
        }
      >
        <SectionWrapper>
          <SectionWrapper>
            <HeatingResult {...{ project, onSaveButtonClick: onUpdateCalculatedValue }} />
          </SectionWrapper>
          <SectionWrapper>
            <HotWaterResult {...{ project, onSaveButtonClick: onUpdateCalculatedValue }} />
          </SectionWrapper>
          <SectionWrapper>
            <ElectricityResult {...{ project, onSaveButtonClick: onUpdateCalculatedValue }} />
          </SectionWrapper>
        </SectionWrapper>
      </Collapsible>
    </div>
  );
};

export default AllCalculations;
