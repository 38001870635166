/* eslint-disable react/no-danger */
import PageTitle from 'components/common/PageTitle';
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Downloads from 'components/dashboard/Downloads';
import Overview from 'components/dashboard/Overview';
import GettingStarted from 'components/dashboard/GettingStarted';
import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useProjectDashboardQuery from 'hooks/useProjectDashboardQuery';
import { PROPERTY_VALUATION } from 'constants/variables';
import StartProjectPlanning from 'components/calculators/PropertyValuation/StartProjectPlanning';
import WarningMessage from 'components/common/WarningMessage';
import { routePatterns } from 'router/route-paths';

const Dashboard = () => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const [project, loading] = useProjectDashboardQuery(projectId);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  if (project.type === PROPERTY_VALUATION) return <StartProjectPlanning />;

  if (!project.progress.project_details) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.dashboard' })}
        message={t({ id: 'dashboard.complete_project_details_step' })}
        btnLabel={t({ id: 'dashboard.complete_project_details_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <PageTitle>{t({ id: 'dashboard.title' })}</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <GettingStarted {...{ project }} />
        </Col>
        <Col lg={4} first="xs" last="lg">
          <Overview {...{ project }} />
          <Downloads {...{ project }} />
        </Col>
      </Row>
    </Grid>
  );
};

export default Dashboard;
