import React from 'react';
import { Field } from 'formik';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { ReactComponent as DistrictHeatingStandard } from 'assets/icons/co2-calculator/heating-system/district-heating-standard.svg';
import { ReactComponent as NaturalGas } from 'assets/icons/co2-calculator/heating-system/natural-gas.svg';
import { ReactComponent as Electric } from 'assets/icons/co2-calculator/heating-system/electric.svg';
import { ReactComponent as Oil } from 'assets/icons/co2-calculator/heating-system/oil.svg';
import { ReactComponent as WoodChip } from 'assets/icons/co2-calculator/heating-system/wood-chip.svg';
import { ReactComponent as Coal } from 'assets/icons/co2-calculator/heating-system/coal.svg';
import { ReactComponent as Firewood } from 'assets/icons/co2-calculator/heating-system/firewood.svg';
import { ReactComponent as DistrictHeatingBiomass } from 'assets/icons/co2-calculator/heating-system/district-heating-biomass.svg';
import { ReactComponent as HeatingPump } from 'assets/icons/co2-calculator/heating-system/heating-pump.svg';
import { ReactComponent as WoodPellets } from 'assets/icons/co2-calculator/heating-system/wood-pellets.svg';
import Switch from 'components/inputs/Switch';

const SecondSection = ({ t, values }) => (
  <>
    <h2 className="text-primary text-left">{t({ id: 'renovation_house_wizard.tell_about_your_heating' })}</h2>
    <Field
      label={t({ id: 'renovation_house_wizard.heating_system.name' })}
      name="heating_system"
      contentInColumn
      inOneRow={false}
      component={ButtonSelect}
      options={[
        {
          icon: ({ altText }) => <HeatingPump className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.heat_pump' }),
          value: 'heat_pump',
        },
        {
          icon: ({ altText }) => <DistrictHeatingBiomass className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.district_heating_biomass' }),
          value: 'district_heating_biomass',
        },
        {
          icon: ({ altText }) => <DistrictHeatingStandard className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.district_heating_standard' }),
          value: 'district_heating_standard',
        },
        {
          icon: ({ altText }) => <WoodChip className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.wood_chip' }),
          value: 'wood_chip',
        },
        {
          icon: ({ altText }) => <WoodPellets className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.wood_pellets' }),
          value: 'wood_pellets',
        },
        {
          icon: ({ altText }) => <Firewood className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.firewood' }),
          value: 'firewood',
        },
        {
          icon: ({ altText }) => <Coal className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.coal' }),
          value: 'coal',
        },
        {
          icon: ({ altText }) => <Oil className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.heating_oil' }),
          value: 'heating_oil',
        },
        {
          icon: ({ altText }) => <Electric className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.electric' }),
          value: 'electric',
        },
        {
          icon: ({ altText }) => <NaturalGas className="fill" title={altText} />,
          label: t({ id: 'renovation_house_wizard.heating_system.natural_gas' }),
          value: 'natural_gas',
        },
      ]}
    />
    <Field
      label={t({ id: 'renovation_house_wizard.is_solar_heating_system' })}
      name="is_solar_heating_system"
      component={Switch}
    />
    {values.is_solar_heating_system ? (
      <Field
        label={t({ id: 'renovation_house_wizard.solar_heating_system_type.name' })}
        name="solar_heating_system_type"
        component={ButtonSelect}
        inOneRow
        options={[
          {
            label: t({ id: 'renovation_house_wizard.solar_heating_system_type.heating_and_hot_water' }),
            value: 'heating_and_hot_water',
          },
          {
            label: t({ id: 'renovation_house_wizard.solar_heating_system_type.only_hot_water' }),
            value: 'only_hot_water',
          },
        ]}
      />
    ) : null}
    <Field
      label={t({ id: 'renovation_house_wizard.age_of_heating_system.name' })}
      name="age_of_heating_system"
      description={t({ id: 'renovation_house_wizard.age_of_heating_system.description' })}
      component={ButtonSelect}
      inOneRow
      options={[
        {
          label: t({ id: 'renovation_house_wizard.age_of_heating_system.more_than_20_years' }),
          value: 'older_than_20_years',
        },
        {
          label: t({ id: 'renovation_house_wizard.age_of_heating_system.between_10_and_20_years' }),
          value: 'between_10_and_20_years',
        },
        {
          label: t({ id: 'renovation_house_wizard.age_of_heating_system.less_than_10_years' }),
          value: 'less_than_10_years',
        },
      ]}
    />
    <Field
      label={t({ id: 'renovation_house_wizard.temperature.name' })}
      description={t({ id: 'renovation_house_wizard.temperature.description' })}
      name="temperature"
      component={ButtonSelect}
      inOneRow
      options={[
        {
          label: t({ id: 'renovation_house_wizard.temperature.less_than_21' }),
          value: 'less_than_21',
        },
        {
          label: t({ id: 'renovation_house_wizard.temperature.between_21_and_23' }),
          value: 'between_21_and_23',
        },
        {
          label: t({ id: 'renovation_house_wizard.temperature.more_than_23' }),
          value: 'more_than_23',
        },
      ]}
    />
  </>
);

export default SecondSection;
