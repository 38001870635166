import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { useAuthWithThirdPartyServiceHandler } from 'hooks/auth/useAuthWithThirdPartyService';
import Loading from 'components/common/Loading';
import FacebookLogin from 'react-facebook-login';
import { toast } from 'react-toastify';
import { FACEBOOK_SERVICE, GOOGLE_SERVICE } from 'constants/variables';
import settings from 'config/settings';
import s from './LoginForm.module.scss';

const SocialButtons = ({ showModal, isSubmitting }) => {
  const params = useParams();

  const [onSuccessAuthWithGoogle, googleLoading] = useAuthWithThirdPartyServiceHandler({
    projectId: params.id,
    service: GOOGLE_SERVICE,
    showModal,
  });
  const [onSuccessAuthWithFacebook, facebookLoading] = useAuthWithThirdPartyServiceHandler({
    projectId: params.id,
    service: FACEBOOK_SERVICE,
    showModal,
  });
  const onFailureAuth = useCallback((error) => toast.error(error.message), []);

  return (
    <div className={s.socialButtonsWrapper}>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText=""
        onSuccess={onSuccessAuthWithGoogle}
        onFailure={onFailureAuth}
        cookiePolicy="single_host_origin"
        className={s.googleLoginBtn}
        disabled={googleLoading || isSubmitting}
      />
      <FacebookLogin
        appId={settings.facebookAppId}
        textButton
        icon={facebookLoading ? <Loading size={25} /> : 'fa-facebook'}
        cssClass={s.facebookLoginBtn}
        fields="id,first_name,last_name,email"
        callback={onSuccessAuthWithFacebook}
        onFailure={onFailureAuth}
        isDisabled={facebookLoading || isSubmitting}
        disableMobileRedirect
      />
    </div>
  );
};

export default SocialButtons;
