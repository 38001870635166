import DonutChart from 'components/common/DonutChart';
import { get, orderBy, round } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatValue } from 'utils/helpers';
import useBreakpoint from 'use-breakpoint';
import { screenSizeConfig } from 'components/common/Grid';
import { DEFAULT_COLORS } from 'components/common/DonutChart/DonutChart';

const monthlyExpensesChartOptions = [
  {
    label: 'budget.monthly_expenses_chart.options.basic_expenses',
    pathsToValues: [
      'costs_for_repair.calculated_value',
      'monthly_expenses.calculated_value',
      'monthly_expenses_partner.calculated_value',
      'monthly_expenses_kids.calculated_value',
      'formData.amount_of_alimony',
      'formData.monthly_loan',
    ],
  },
  {
    label: 'budget.monthly_expenses_chart.options.hobbies',
    pathsToValues: ['hobbies.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.taking_a_break',
    pathsToValues: ['taking_a_break.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.cars',
    pathsToValues: ['cars.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.socializing',
    pathsToValues: ['socializing.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.savings',
    pathsToValues: ['savings.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.pension_and_insurances',
    pathsToValues: ['pension_and_insurances.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.technology',
    pathsToValues: ['technology.calculated_value'],
  },
  {
    label: 'budget.monthly_expenses_chart.options.luxuries',
    pathsToValues: ['luxuries.calculated_value'],
  },
];

export function getDataForChart({ calculations, options, total, t }) {
  const data = options.reduce((acc, { label, pathsToValues }, idx) => {
    const optionPrice = pathsToValues.reduce((sum, pathToValue) => sum + get(calculations, pathToValue), 0);
    const percentage = round((optionPrice * 100) / total, 1);

    const currentItem = {
      value: optionPrice,
      percentage,
      fill: DEFAULT_COLORS[idx],
      name: `${t({ id: label })} (${formatValue(percentage)} %)`,
    };
    if (!optionPrice) return acc;
    return [...acc, currentItem];
  }, []);
  return orderBy(data, ['percentage'], ['desc']);
}

const MonthlyExpensesChart = ({ sortedOptions, labelInsideChart, breakpoint }) => (
  <div className="card">
    <DonutChart
      {...{ data: sortedOptions, labelInsideChart }}
      containerId="monthlyExpenses"
      height={220}
      thickness={35}
      legendLayout={['md', 'lg', 'xxl'].includes(breakpoint) ? 'vertical' : 'horizontal'}
    />
  </div>
);

export default ({ budget }) => {
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'xxl');
  const { formatMessage: t } = useIntl();
  const monthlyExpensesTotal = budget.calculation.monthly_costs.calculated_value;
  const lifestyleCalculations = { ...budget.calculation, formData: budget.formData };

  const labelInsideChart = t({ id: 'budget.monthly_expenses_chart.central_label' });

  const sortedOptions = useMemo(
    () =>
      getDataForChart({
        t,
        calculations: lifestyleCalculations,
        total: monthlyExpensesTotal,
        options: monthlyExpensesChartOptions,
      }),
    [lifestyleCalculations, monthlyExpensesTotal, t],
  );

  return <MonthlyExpensesChart {...{ sortedOptions, labelInsideChart, breakpoint }} />;
};
