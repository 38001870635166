/* eslint-disable no-nested-ternary */
import { secondPrimaryColor, primaryColor, primaryLightColor } from 'styles/variables.scss';

const SelectCustomStyles = {
  container: (style) => ({
    ...style,
    outline: 'none',
    border: '1px solid #eaeaea',
    borderRadius: '6px',
  }),
  control: (style) => ({
    ...style,
    border: 'none',
    outline: 'none',
    borderRadius: '6px',
    backgroundColor: 'white',
    width: '100%',
    padding: '5px 10px 5px 15px',
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: secondPrimaryColor,
  }),
  valueContainer: (style) => ({
    ...style,
    padding: 0,
    height: '30px',
  }),
  singleValue: (style) => ({
    ...style,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: primaryColor,
    margin: 0,
  }),
  option: (style, { isFocused, isSelected }) => ({
    ...style,
    backgroundColor: isSelected ? secondPrimaryColor : isFocused ? primaryLightColor : null,
    color: isSelected ? 'white' : primaryColor,
    textAlign: 'left',
  }),
};

const featuresInputStyle = {
  display: 'inlineBlock',
  width: '90%',
};

export { featuresInputStyle, SelectCustomStyles };
