import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/client';
import { ME_QUERY } from 'hooks/user/useUser';
import { TOKEN_COOKIE } from 'constants/cookies';
import Cookies from 'js-cookie';
import { useAppContext } from 'contexts/AppContext';

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

const useLogout = () => {
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const { toggleSidebar } = useAppContext();
  const logout = useCallback(async () => {
    Cookies.remove(TOKEN_COOKIE);
    await logoutMutation();
    client.writeQuery({ query: ME_QUERY, data: { me: null } });
    client.resetStore();
    toggleSidebar(false);
  }, [client, logoutMutation, toggleSidebar]);

  return logout;
};

export default useLogout;
