/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import max from 'lodash/max';

const DEFAULT_LOCALE = 'de-DE';

export const wait = (timeout = 3000) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

export const isSmallScreen = () => {
  if (window.innerWidth <= 748) {
    return true;
  }
  return false;
};

export const spliceLabelOnMobile = (value) => {
  if (!isSmallScreen()) return value;
  if (typeof value !== 'string') return value;
  // if (value.length > 38) {
  //   return `${value.slice(0, 33)}...`;
  // }
  return value;
};

export const formatCurrency = (() => {
  const locale = localStorage.getItem('language') || DEFAULT_LOCALE;
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' });
  return (number) => {
    const parts = formatter.formatToParts(number);
    const strArray = [];
    // filter out the decimal part
    parts.forEach((part) => {
      if (['decimal', 'fraction'].includes(part.type)) return;
      strArray.push(part.value);
    });
    return strArray.join('');
  };
})();

export const formatValue = (value) => {
  const locale = localStorage.getItem('language') || DEFAULT_LOCALE;
  return Intl.NumberFormat(locale).format(value);
};

export const localizeWizard = (object, options = {}) => {
  const {
    formatter,
    keys = [
      'units',
      'title',
      'label',
      'subtitle',
      'description',
      'fieldLabel',
      'summaryTitle',
      'submitButtonLabel',
      'text',
      'note',
    ],
  } = options;
  const result = cloneDeep(object);
  function traverse(subtree) {
    if (!subtree) return;
    Object.entries(subtree).forEach(([key, value]) => {
      if (keys.includes(key)) {
        if (subtree.skipI18n) return;
        // eslint-disable-next-line no-param-reassign
        subtree[key] = formatter(value);
      } else if (typeof value === 'object') {
        traverse(value);
      }
    });
  }
  traverse(result);
  return result;
};

// adds "^" symbol to query string where error exist
export const formatQueryByErrorPositions = ({ queryString, errorPositions }) => {
  if (!errorPositions) return queryString;
  try {
    const columnsByLine = groupBy(errorPositions, 'line');
    const lineNumbers = Object.keys(columnsByLine).sort((a, b) => b - a);
    const queryStringLines = queryString.split('\n');
    lineNumbers.forEach((lineIndex) => {
      const columns = columnsByLine[lineIndex].map((object) => object.column);
      const maxColumn = max(columns);
      queryStringLines.splice(lineIndex, 0, '');
      queryStringLines[lineIndex] = new Array(maxColumn).fill(' ');
      columns.forEach((column) => {
        queryStringLines[lineIndex][column - 1] = '^';
      });
      queryStringLines[lineIndex] = queryStringLines[lineIndex].join('');
    });
    return queryStringLines.join('\n').trim();
  } catch (e) {
    console.error(e);
    return queryString;
  }
};

export const formatGraphqlErrors = (error) => {
  const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
  e.raw = error || '';
  return e;
};
