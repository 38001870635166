/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import ApartmentDetails from 'components/calculators/ApartmentDetails';
import apartmentFeaturesSchema from 'constants/wizards/apartment-features-schema';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import useUpdateProjectNewApartmentMutation from 'hooks/project-details/useUpdateProjectNewApartmentMutation';
import { intersection, isEmpty } from 'lodash';

const defaultFeatures = ['cost_apartment', 'taxes_and_fees'];

const handleSpecificCategories = (_formData) => {
  const formData = cloneDeep(_formData);

  delete formData.additional_features;
  delete formData.type;

  if (!formData.features.includes('other_investments')) {
    formData.other_investments_amount = null;
    formData.other_investments_comment = '';
  }

  return formData;
};

const useSubmitApartmentDetailsCalculator = (projectId) => {
  const updateProjectNewApartmentMutation = useUpdateProjectNewApartmentMutation();

  const onSubmit = useCallback(
    async ({ values, formikActions, isEditMode, changeStep = true, currentStep, nextStep, lastStep }) => {
      const { setSubmitting } = formikActions;
      // eslint-disable-next-line no-param-reassign
      const features = Object.entries(values.features).reduce((arr, [feature, isEnabled]) => {
        if (isEnabled) arr.push(feature);
        return arr;
      }, []);

      const formData = cloneDeep(values);
      formData.features = uniq([...defaultFeatures, ...features]);

      const step = isEditMode ? null : changeStep ? currentStep + 1 : currentStep;
      formData.step = step;

      const _formData = handleSpecificCategories(formData);
      try {
        await updateProjectNewApartmentMutation(projectId, _formData);
        if (isEditMode && typeof lastStep === 'function') {
          lastStep();
        } else if (typeof nextStep === 'function') {
          nextStep();
        }
        if (changeStep) window.scrollTo(0, 0);
      } catch (err) {
        const error = err.raw?.networkError ? <FormattedMessage id="errors.no_server_response" /> : err.message;
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [projectId, updateProjectNewApartmentMutation],
  );

  return onSubmit;
};

const whitelistedProps = [
  'name',
  'zip',
  'country',
  'form_values.full_address',
  'form_values.coordinates.lat',
  'form_values.coordinates.lng',
  'form_values.floor_number',
  'form_values.is_rooftop',
  'form_values.apartment_area',
  'form_values.condition_of_apartment',
  'form_values.construction_year',
  'form_values.loggia',
  'form_values.loggia_area',
  'form_values.terrace',
  'form_values.terrace_area',
  'form_values.garden',
  'form_values.garden_area',
  'form_values.balcony',
  'form_values.elevator',
  'form_values.basement',
  'form_values.parking',
  'form_values.heating_system',
  'form_values.cost_apartment',
  'form_values.property_transfer_tax',
  'form_values.land_register_fee',
  'form_values.notary_fee',
  'form_values.broker_commission',

  'form_values.other_investments_amount',
  'form_values.other_investments_comment',

  ...apartmentFeaturesSchema.flatMap((feature) =>
    Array.isArray(feature.form) ? feature.form.map(({ name }) => `form_values.${name}`) : [],
  ),
];

const getInitialValues = (project) => {
  // the category of other investments is by default
  const restFeatures = {};
  if (project.progress.step !== null) restFeatures.reserves_for_unexpected_costs = true;
  restFeatures.other_investments = !!project.form_values?.other_investments_amount;

  const { form_values, ...whitelisted } = pick(project, whitelistedProps);
  const features = { ...project.features.reduce((acc, name) => ({ ...acc, [name]: true }), {}), ...restFeatures };

  const initialValues = { ...whitelisted, ...form_values, features };
  return initialValues;
};

const ApartmentDetailsCalculator = ({ project }) => {
  const onSubmit = useSubmitApartmentDetailsCalculator(project._id);
  const initialValues = getInitialValues(project);

  const renovationFeatures = apartmentFeaturesSchema.map((feature) => feature.name);
  const enabledFeatures = Object.keys(initialValues.features).filter(
    (key) => key !== 'reserves_for_unexpected_costs' && initialValues.features[key],
  );

  const hasAdditionalFeatures = !isEmpty(intersection(renovationFeatures, enabledFeatures));
  initialValues.additional_features = !hasAdditionalFeatures;

  return (
    <ApartmentDetails
      onSubmit={onSubmit}
      initialValues={initialValues}
      isEditMode={project.progress.step === null}
      step={project.progress.step}
      hasAdditionalFeatures={hasAdditionalFeatures}
      projectPrice={project.price}
    />
  );
};

export default ApartmentDetailsCalculator;
