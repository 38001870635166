import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatGraphqlErrors } from 'utils/helpers';

const EDIT_PAYER_INFO_MUTATION = gql`
  mutation editPayerInfo($payerId: ID!, $input: PayerInput!) {
    editPayerInfo(payerId: $payerId, input: $input) {
      _id
      profile {
        name
        avatar {
          url
          file_name
        }
      }
    }
  }
`;

const useEditPayerInfo = (payerId, hideModal) => {
  const [editPayerInfoMutation] = useMutation(EDIT_PAYER_INFO_MUTATION);

  const handleEditPayerInfo = useCallback(
    async (variables) => {
      try {
        await editPayerInfoMutation({
          variables: { payerId, input: { ...variables } },
        });
        hideModal();
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    },
    [editPayerInfoMutation, hideModal, payerId],
  );
  return handleEditPayerInfo;
};

export default useEditPayerInfo;
