/* eslint-disable no-param-reassign */
import React, { useCallback, useState, useMemo } from 'react';
import LoanCalculatorForm from 'components/loan/LoanCalculatorForm';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Hidden, Visible } from 'components/common/Grid';
import { formatCurrency } from 'utils/helpers';
import { debounce, get } from 'lodash';
import Downloads from 'components/dashboard/Downloads';
import Overview from 'components/loan/Overview';
import DonutChart from 'components/common/DonutChart';
import theme from 'config/theme';
import s from './LoanCalculator.module.scss';

function getDataForChart({ project, t }) {
  return [
    {
      name: t({ id: 'loan.budget_shortage_chart.shortage_label' }),
      value: Math.max(0, project.price - project.budget),
      fill: theme.secondPrimaryColor,
    },
    {
      name: t({ id: 'loan.budget_shortage_chart.budget_label' }),
      value: project.budget,
      fill: theme.yellowColor,
    },
  ];
}

export default function LoanCalculator({ project, t, initialValues, onSubmit }) {
  const [budget, setBudget] = useState(project.budget - project.estimated_property_price);
  const [estimatedPropertyPrice, setEstimatedPropertyPrice] = useState(project.estimated_property_price);
  const ownFunds = useMemo(() => budget + estimatedPropertyPrice, [budget, estimatedPropertyPrice]);
  const projectPrice = get(project, 'price', 0);

  const monthlyRate = project.loan?.monthly_rate || project?.financing_rate;
  const labelInsideChart = useMemo(
    () => t({ id: 'quick_calc_result.monthly_rate' }, { value: formatCurrency(monthlyRate) }),
    [monthlyRate, t],
  );

  const onChangeBudget = useCallback(
    debounce((v) => setBudget(v), 1000),
    [],
  );

  const onChangeEstimatedPropertyPrice = useCallback(
    debounce(({ value }) => setEstimatedPropertyPrice(value), 1000),
    [],
  );

  const dataForChart = useMemo(() => getDataForChart({ project: { budget: ownFunds, price: projectPrice }, t }), [
    ownFunds,
    projectPrice,
    t,
  ]);

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <PageTitle>{t({ id: 'page_titles.loan' })}</PageTitle>
        </Col>
      </Row>
      <Row between="xs">
        <Col md={7}>
          <Hidden xs sm>
            <Overview project={{ budget: ownFunds, price: project?.price }} />
          </Hidden>

          {/* mobile start */}
          <Visible xs sm>
            <Row>
              <Col xs={6}>
                <DonutChart
                  {...{ labelInsideChart, data: dataForChart }}
                  height={100}
                  thickness={20}
                  mobileFontSize="0.6rem"
                  containerId="financingCalculator"
                />
              </Col>
              <Col xs={6}>
                <Overview project={{ budget: ownFunds, price: project?.price }} />
              </Col>
              <Col xs={12}>
                <Downloads {...{ project }} />
              </Col>
            </Row>
          </Visible>
          {/* mobile end */}

          <LoanCalculatorForm
            projectName={project.name}
            projectId={project._id}
            {...{ initialValues, onSubmit, onChangeBudget, onChangeEstimatedPropertyPrice }}
          />
        </Col>

        {/* desktop start */}
        <Hidden xs sm>
          <Col md={5} xl={4}>
            <DonutChart
              containerId="financingCalculator"
              {...{ labelInsideChart, data: dataForChart, thickness: 40 }}
            />
            <hr className={s.divider} />
            <Downloads {...{ project }} />
          </Col>
        </Hidden>
        {/* desktop end */}
      </Row>
    </Grid>
  );
}
