import { ArrowForward } from 'assets/icons';
import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import React from 'react';
import { Hidden, Visible } from 'components/common/Grid';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import CircleButton from 'components/common/CircleButton';
import TopCard from 'components/spendings/cards/TopCard';
import TopTable from 'components/spendings/table/TopTable';
import SpendingsPlanning from 'components/spendings/SpendingsPlanning';
import s from './Spendings.module.scss';

export default ({
  t,
  projectId,
  totalSaved,
  totalOverspent,
  deviation,
  deviationStyle,
  percentage,
  price,
  paymentsTotal,
  paymentsTotalByCategories,
  isPayments,
  countOfPayments,
}) => {
  return (
    <Grid className={s.container}>
      <Row>
        <Col md={12}>
          <div className={s.header}>
            <div className={s.row}>
              <PageTitle>{t({ id: 'page_titles.spendings' })}</PageTitle>
              <Link to={routePatterns.payments.stringify({ id: projectId })}>
                <Hidden xs sm>
                  <Button endIcon={<ArrowForward />}>{t({ id: 'spendings.to_payments_page' })}</Button>
                </Hidden>
                <Visible xs sm>
                  <CircleButton color="primary">
                    <ArrowForward />
                  </CircleButton>
                </Visible>
              </Link>
            </div>
            <Hidden xs sm>
              <TopTable
                {...{
                  totalSaved,
                  totalOverspent,
                  deviation,
                  deviationStyle,
                  percentage,
                  price,
                  paymentsTotal,
                  isPayments,
                }}
              />
            </Hidden>
            <Visible xs sm>
              <TopCard
                {...{ totalSaved, totalOverspent, deviation, deviationStyle, percentage, price, paymentsTotal }}
              />
            </Visible>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className={s.secondCol}>
          <SpendingsPlanning {...{ paymentsTotalByCategories, countOfPayments }} />
        </Col>
      </Row>
    </Grid>
  );
};
