import theme from 'config/theme';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import s from './LoanDetails.module.scss';

const LoanDetails = ({ loan, price, budget, monthlyRateLoan, monthlyFinancing, estimated_property_price = null }) => {
  const { formatMessage: t } = useIntl();
  const ownFundsCash = useMemo(() => budget - estimated_property_price, [budget, estimated_property_price]);

  return (
    <section className={s.section}>
      <div className={s.tableWrapper}>
        <table className="table last-col-text-right" style={{ wordBreak: 'break-word' }}>
          <thead>
            <tr>
              <td colSpan="2">
                <h4>{t({ id: 'loan.loan_details' })}</h4>
              </td>
              <td />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t({ id: 'loan.loan_term' })}</td>
              <td>{t({ id: 'loan.term_in_years' }, { period: loan.period })}</td>
            </tr>
            <tr>
              {loan.interest_type === 'fixed' ? (
                <>
                  <td>{t({ id: 'loan.fixed_interest_rate' })}</td>
                  <td>{t({ id: 'loan.term_in_years' }, { period: loan.fixed_interest_period })}</td>
                </>
              ) : (
                <>
                  <td>{t({ id: 'loan.variable_interest_rate' })}</td>
                  <td />
                </>
              )}
            </tr>
            <tr>
              <td>{t({ id: 'loan.interest_rate' })}</td>
              <td>{`${parseFloat((loan.interest_bandwith * 100).toFixed(3))} - ${parseFloat(
                (loan.interest_rate * 100).toFixed(3),
              )} %`}</td>
            </tr>

            <tr>
              <td colSpan="2">
                <div className={s.divider} />
              </td>
            </tr>

            <tr>
              <td className="pb-0">{t({ id: 'loan.total_project_costs' })}</td>
              <td className="pb-0">
                <b>{formatCurrency(price)}</b>
              </td>
            </tr>
            <tr>
              <td className="pb-0">{t({ id: 'loan.own_funds_cash' })}</td>
              <td className="pb-0">
                <b>{formatCurrency(ownFundsCash)}</b>
              </td>
            </tr>
            {estimated_property_price ? (
              <>
                <tr>
                  <td className="pb-0">{t({ id: 'loan.own_funds_value_of_property' })}</td>
                  <td className="pb-0">
                    <b>{formatCurrency(estimated_property_price)}</b>
                  </td>
                </tr>
                <tr>
                  <td className="pb-0">{t({ id: 'loan.total_own_funds' })}</td>
                  <td className="pb-0">
                    <b>{formatCurrency(ownFundsCash + estimated_property_price)}</b>
                  </td>
                </tr>
              </>
            ) : null}

            <tr>
              <td colSpan="2" className="py-0">
                <div className={s.divider} />
              </td>
            </tr>

            <tr>
              <td className="py-0">{t({ id: 'loan.required_loan' })}</td>
              <td className="py-0">
                <b>{formatCurrency(price - ownFundsCash)}</b>
              </td>
            </tr>

            <tr>
              <td className="pb-0">{t({ id: 'loan.loan_costs' })}</td>
              <td className="pb-0">
                <b>{formatCurrency(loan.loan_costs)}</b>
              </td>
            </tr>

            <tr>
              <td colSpan="2" />
            </tr>

            <tr className="bold" style={{ backgroundColor: theme.secondPrimaryColor }}>
              <td className="text-white">{t({ id: 'loan.amount_with_loan_costs' })}</td>
              <td className="text-white">
                <b>{formatCurrency(loan.amount_with_loan_costs)}</b>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <div className={s.divider} />
              </td>
            </tr>

            <tr className="bold">
              <td>{t({ id: 'loan.monthly_rate' })}</td>
              <td>{formatCurrency(loan.monthly_rate)}</td>
            </tr>
            <tr>
              <td>{t({ id: 'loan.budget_surplus' })}</td>
              <td>{formatCurrency(monthlyRateLoan.calculated_value)}</td>
            </tr>
            <tr>
              <td>{monthlyFinancing.label}</td>
              <td>{formatCurrency(monthlyFinancing.value)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default LoanDetails;
