import React from 'react';
import cn from 'classnames';
import { redColor, greenColor } from 'styles/variables.scss';
import s from './CandleChart.module.scss';

const getColor = (complete, value) => {
  let color;
  if (value === 100) {
    color = '#6CC740';
  } else if (value > 100) {
    color = redColor;
  } else if (complete && value < 100) {
    color = greenColor;
  } else if (!complete && value < 100) {
    color = '#40C2C7';
  }
  return color;
};

const HorizontalChart = ({ complete = true, value = 0 }) => (
  <div className={s.horizontalChartContainer}>
    <div className={s.wrapper}>
      <div
        className={value >= 100 ? s.absoluteElement : s.absoluteLeftElement}
        style={value < 100 ? { width: `${value}%` } : {}}
      >
        <div className={cn(s.colorFilling)} style={{ backgroundColor: getColor(complete, value) }} />
      </div>
      <div
        className={value <= 100 ? s.absoluteElement : s.absoluteLeftElement}
        style={value > 100 ? { width: `${(100 / value) * 100}%` } : {}}
      >
        <div className={s.centerStick} />
        <div className={s.topStick} />
      </div>
    </div>
  </div>
);

const VerticalChart = ({ complete = true, value = 0 }) => (
  <div className={s.verticalChartContainer}>
    <div className={s.wrapper}>
      <div
        className={value >= 100 ? s.absoluteElement : s.absoluteBottomElement}
        style={value < 100 ? { height: `${value}%` } : {}}
      >
        <div className={cn(s.colorFilling)} style={{ backgroundColor: getColor(complete, value) }} />
      </div>
      <div
        className={value <= 100 ? s.absoluteElement : s.absoluteBottomElement}
        style={value > 100 ? { height: `${(100 / value) * 100}%` } : {}}
      >
        <div className={s.topStick} />
        <div className={s.centerStick} />
      </div>
    </div>
  </div>
);

const CandleChart = ({ vertical = false, ...rest }) => {
  return vertical ? <VerticalChart {...rest} /> : <HorizontalChart {...rest} />;
};

export default CandleChart;
