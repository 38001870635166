import * as yup from 'yup';
import { createConditionalValidator } from 'utils/yup';
import { NEW_APARTMENT } from 'constants/variables';
import { getPartOfValidationSchema } from '../../helpers';

const validationSchema = yup.object().shape({
  ...getPartOfValidationSchema({ projectType: NEW_APARTMENT, group: 'energy' }),
  new_windows_number: createConditionalValidator('new_windows', 'number'),
});

export default validationSchema;
