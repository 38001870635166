import useRenovationHouseCalculations from 'hooks/co2-calculator/useRenovationHouseCalculations';
import { useParams } from 'react-router-dom';
import React from 'react';
import Loading from 'components/common/Loading';
import CO2CalculatorResult from 'components/calculators/CO2CalculatorResult';

const CO2StatusSecondStep = ({ nextStep, previousStep, currentStep }) => {
  const { id: projectId } = useParams();
  const [project, loading] = useRenovationHouseCalculations({ projectId });

  if (loading) {
    return <Loading size={50} />;
  }

  return <CO2CalculatorResult {...{ previousStep, nextStep, project, currentStep }} />;
};

export default CO2StatusSecondStep;
