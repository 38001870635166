/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useRef } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import ScreenLink from 'components/common/ScreenLink';
import Button from 'components/common/Button';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import { useAppContext } from 'contexts/AppContext';
import { useIntl } from 'react-intl';
import s from './ButtonSelect.module.scss';

function getIconWithAltText({ altText, icon, t }) {
  return icon({ altText: t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: altText }) });
}

function getIcon({ icon, altText, label, t }) {
  if (typeof icon === 'function') {
    return getIconWithAltText({ icon, altText: altText || label, t });
  }
  return icon;
}

const SButton = ({ name, option, handleToggle, onBlur, theme, value, contentInColumn }) => {
  const { formatMessage: t } = useIntl();
  const { icon, value: optionValue, label, altText } = option;

  const themeToButtonColor = {
    primary: {
      selected: s.selected,
      notSelected: s.notSelected,
    },
    green: {
      selected: s.greenSelected,
      notSelected: s.greenNotSelected,
    },
  };

  const isSelected = React.useCallback(
    (o) => {
      return Array.isArray(value) ? value.includes(o.value) : o.value === value;
    },
    [value],
  );

  return (
    <Button
      startIcon={getIcon({ icon, t, label, altText })}
      name={name}
      key={optionValue}
      onClick={(e) => handleToggle(option, e)}
      onBlur={onBlur}
      className={cn(
        s.button,
        isSelected(option) ? themeToButtonColor[theme].selected : themeToButtonColor[theme].notSelected,
        contentInColumn && s.contentInColumn,
        icon && s.buttonWithIcon,
      )}
    >
      {label}
    </Button>
  );
};

const InlineSelect = ({
  label,
  onChange,
  onBlur,
  value,
  options,
  multi,
  nullable,
  error,
  description,
  moreDetails,
  contentInColumn,
  inOneRow = true,
  theme,
  skipScrollingToField,
  name,
}) => {
  const fieldWrapperRef = useRef();

  const handleToggle = React.useCallback(
    async (option, e) => {
      async function triggerChange() {
        if (multi) {
          let result = null;
          if (Array.isArray(value)) {
            result = value;
            if (value.includes(option.value)) result = result.filter((item) => item !== option.value);
            else result = [...result, option.value];
          } else {
            result = [value];
          }
          await onChange(result);
        } else if (value === option.value) {
          if (nullable !== false) {
            await onChange(null);
          }
        } else {
          await onChange(option.value);
        }
      }
      e.persist();
      await triggerChange();
      e.target.blur();
      if (!multi) {
        scrollToNextMatchingSibling(fieldWrapperRef);
      }
    },
    [multi, value, onChange, nullable],
  );

  return (
    <div className={`field-wrapper ${!skipScrollingToField ? 'scroll-to-field' : ''}`} ref={fieldWrapperRef}>
      <label className={s.label} style={moreDetails && { pointerEvents: 'initial' }}>
        {label} {moreDetails && <ScreenLink linkData={moreDetails} />}
      </label>
      <div className={cn(s.buttonsContainer, inOneRow ? s.buttonsInOneRow : '')}>
        {options.map((option) => (
          <SButton
            key={`${name}_${option.value}`}
            {...{ name, option, handleToggle, onBlur, theme, value, contentInColumn }}
          />
        ))}
      </div>
      <div>{error ? <div className="error-text">{error}</div> : null}</div>
      {description ? (
        <div className={s.note}>
          <small className="muted">{description}</small>
        </div>
      ) : null}
    </div>
  );
};

function InlineSelectContainer({ form, field, ...rest }) {
  const { name } = field;
  const { errors, touched } = form;
  const { theme } = useAppContext();

  const onChange = React.useCallback(
    (v) => {
      form.setFieldValue(name, v);
    },
    [name, form],
  );

  const error = get(errors, name) && get(touched, name) ? get(errors, name) : null;

  return <InlineSelect {...{ ...rest, ...field, onChange, error, theme }} />;
}

export default memo(InlineSelectContainer, areEqualFields);
