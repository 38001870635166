import { Document, LocalOffer, Wallet, Euro } from 'assets/icons/sidebar';
import { ArrowForward, IsCompleted } from 'assets/icons';
import Button from 'components/common/Button';
import Tooltip from 'components/common/Tooltip';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import routePaths, { routePatterns } from 'router/route-paths';
import useCompleteProjectStep from 'hooks/useCompleteProjectStep';
import s from './GettingStarted.module.scss';

function localizeSteps(steps, t) {
  return steps.map(({ title, description, projectObjectPath, disabledProceedBtnTooltip, ...rest }) => ({
    ...rest,
    title: t({ id: title, defaultMessage: ' ' }),
    description: t({ id: description, defaultMessage: ' ' }),
    ...(disabledProceedBtnTooltip ? { disabledProceedBtnTooltip: t({ id: disabledProceedBtnTooltip }) } : {}),
  }));
}

const SkipStepButton = ({ step, disabled }) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const completeStep = useCompleteProjectStep(projectId);
  const [submitting, setSubmitting] = useState(false);

  const handleCompleteStep = useCallback(async () => {
    setSubmitting(true);
    try {
      await completeStep(step);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }, [completeStep, step]);

  return (
    <Button
      disabled={submitting || disabled}
      loading={submitting}
      endIcon={<ArrowForward />}
      onClick={handleCompleteStep}
    >
      {t({ id: 'app.skip' })}
    </Button>
  );
};

function getSteps(project) {
  const { _id: id, progress } = project;
  const steps = [
    {
      index: 0,
      title: 'dashboard.getting_started.project_details.title',
      description: 'dashboard.getting_started.project_details.description',
      isCompleted: progress.step === null,
      link: routePatterns.projectDetailsCalculator.stringify({ id }),
      icon: Document,
      iconAltText: 'alt_text.icons.document',
    },
    {
      index: 1,
      title: 'dashboard.getting_started.adjust_plan.title',
      description: 'dashboard.getting_started.adjust_plan.description',
      isCompleted: progress.plan_adjusted,
      link: routePatterns.planForm.stringify({ id }),
      icon: LocalOffer,
      iconAltText: 'alt_text.icons.local_offer',
      disabledProceedBtn: !progress.project_details,
      skipButton: <SkipStepButton step="plan_adjusted" disabled={progress.plan_adjusted} />,
    },
    {
      index: 2,
      title: 'dashboard.getting_started.budget_planning.title',
      description: 'dashboard.getting_started.budget_planning.description',
      isCompleted: progress.budget_planning,
      link: routePatterns.lifestyleOverviewWithProject.stringify({ id }),
      icon: Wallet,
      iconAltText: 'alt_text.icons.wallet',
    },
    {
      index: 3,
      title: 'dashboard.getting_started.adjust_budget_plan.title',
      description: 'dashboard.getting_started.adjust_budget_plan.description',
      isCompleted: progress.budget_planning_adjusted,
      link: routePaths.lifestyleCalculatorEditValues,
      icon: Wallet,
      iconAltText: 'alt_text.icons.wallet',
      disabledProceedBtn: !progress.budget_planning,
      skipButton: (
        <SkipStepButton
          step="budget_planning_adjusted"
          disabled={progress.budget_planning_adjusted || !progress.budget_planning}
        />
      ),
    },
    {
      index: 4,
      title: 'dashboard.getting_started.loan_calculator.title',
      description: 'dashboard.getting_started.loan_calculator.description',
      isCompleted: progress.loan_calculator,
      link: routePatterns.loanCalculator.stringify({ id }),
      icon: Euro,
      iconAltText: 'alt_text.icons.euro',
      showTooltip: !progress.budget_planning,
      disabledProceedBtn: !progress.budget_planning,
      disabledProceedBtnTooltip: 'dashboard.getting_started.loan_calculator.disabled_proceed_btn_tooltip',
    },
    {
      index: 5,
      title: 'dashboard.getting_started.loan.title',
      description: 'dashboard.getting_started.loan.description',
      isCompleted: progress.loan_viewed,
      link: routePatterns.loan.stringify({ id }),
      icon: Euro,
      iconAltText: 'alt_text.icons.euro',
      disabledProceedBtn: !progress.loan_calculator,
    },
    {
      index: 6,
      title: 'dashboard.getting_started.request_personal_offer.title',
      description: 'dashboard.getting_started.request_personal_offer.description',
      isCompleted: progress.loan_request,
      link: routePatterns.loan.stringify({ id }),
      icon: Euro,
      iconAltText: 'alt_text.icons.euro',
      disabledProceedBtn: !progress.loan_calculator || progress.loan_request,
      linkState: { showModalAfterRedirect: true },
    },
  ];
  return steps;
}

const Step = ({
  t,
  link,
  title,
  skipButton,
  icon: Icon,
  iconAltText,
  isCompleted,
  description,
  showTooltip = false,
  disabledProceedBtn,
  linkState,
  disabledProceedBtnTooltip,
}) => (
  <div className={s.step}>
    <div className={s.header}>
      <div className={s.headerItem}>
        <Icon width={35} height={35} title={t({ id: iconAltText })} />
        <h3>{title}</h3>
      </div>
      <IsCompleted
        width={30}
        height={30}
        className={!isCompleted ? s.notCompletedIcon : null}
        title={t({ id: 'alt_text.icons.check_circle' })}
      />
    </div>
    <div className={s.body}>
      <p className={s.description}>{description}</p>
      <div className={s.buttonsWrapper}>
        <Tooltip overlay={<p>{disabledProceedBtnTooltip}</p>} hide={!showTooltip}>
          <div>
            <Link to={{ pathname: link, state: linkState || {} }} className={disabledProceedBtn ? s.disabledLink : ''}>
              <Button endIcon={<ArrowForward />} disabled={disabledProceedBtn} className={s.proceedBtn}>
                {t({ id: 'dashboard.getting_started.step_cta_button' })}
              </Button>
            </Link>
          </div>
        </Tooltip>
        {skipButton}
      </div>
    </div>
  </div>
);

export default function GettingStarted({ project }) {
  const { formatMessage: t } = useIntl();
  const steps = useMemo(() => getSteps(project, t), [project, t]);
  const localizedSteps = localizeSteps(steps, t);

  return (
    <div className={s.steps}>
      {localizedSteps.map((step) => {
        return <Step key={step.title} {...{ ...step, t }} />;
      })}
    </div>
  );
}
