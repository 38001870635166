import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/common/Button';
import get from 'lodash/get';
import useSendVerificationEmail from 'hooks/auth/useSendVerificationEmail';
import { useIntl } from 'react-intl';
import useLogout from 'hooks/auth/useLogout';
import ButtonLink from 'components/common/ButtonLink';
import useUser from 'hooks/user/useUser';
import useLastProjectId from 'hooks/project/useLastProjectId';
import { toast } from 'react-toastify';
import PageTitle from 'components/common/PageTitle';
import { routePatterns } from 'router/route-paths';
import Modal, { useModal } from 'components/common/Modal';
import ContactForm from 'components/ContactForm/ContactForm';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useContactHandler } from 'pages/Contact';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import styles from './ActivateAccount.module.scss';

const useRefreshMe = () => {
  const [, , , refetch] = useUser();
  const { lastProjectId } = useLastProjectId();
  const { formatMessage: t } = useIntl();
  const history = useHistory();

  const handleRefresh = React.useCallback(async () => {
    try {
      const refreshedData = await refetch();
      const emailVerified = get(refreshedData, 'data.me.emails[0].verified', null);
      if (emailVerified) {
        history.push(routePatterns.dashboard.stringify({ id: lastProjectId }));
      } else {
        toast.error(t({ id: 'verify_account.email_not_verified_message' }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [history, lastProjectId, refetch, t]);
  return handleRefresh;
};

const ActivateAccount = () => {
  const [handleSendVerificationEmail, loading] = useSendVerificationEmail();
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: __ } = useIntl();
  const logout = useLogout();
  const refreshMe = useRefreshMe();
  const onSubmitContact = useContactHandler(hideModal);

  const onLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.tick} />
          <PageTitle className={styles.title} auth>
            {__({ id: 'verify_account.title' })}
          </PageTitle>
          <div className={styles.description}>{__({ id: 'verify_account.message' })}</div>
          <div className={styles.btnContainer}>
            <Button color="outline" loading={loading} onClick={handleSendVerificationEmail}>
              {__({ id: 'verify_account.resend_link' })}
            </Button>
            <Button color="outline" onClick={onLogout}>
              {__({ id: 'verify_account.logout' })}
            </Button>
          </div>
          <ButtonLink className={styles.message} onClick={refreshMe}>
            {__({ id: 'verify_account.email_confirmed' })}
          </ButtonLink>
          <ButtonLink className={styles.message} onClick={showModal}>
            {__({ id: 'verify_account.contact_support' })}
          </ButtonLink>

          <Modal headerText={__({ id: 'page_titles.contact' })} isVisible={isVisible} close={hideModal}>
            <Grid>
              <Row>
                <Col md={12}>
                  <ContactForm onSubmit={onSubmitContact} />
                </Col>
              </Row>
            </Grid>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccount;
