import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatCurrency } from 'utils/helpers';
import PageTitle from 'components/common/PageTitle';
import { COLORS } from 'constants/variables';
import DonutChart from 'components/common/DonutChart';
import s from './PaymentsCostsByPayerChart.module.scss';

export default function PaymentsCostsByPayerChart({ paymentsTotalByPayers }) {
  const { formatMessage: t } = useIntl();

  const data = useMemo(() => {
    return paymentsTotalByPayers.map(({ total, payer: { _id } }, i) => ({
      _id,
      value: total,
      fill: COLORS[i],
    }));
  }, [paymentsTotalByPayers]);

  const paymentsTotal = useMemo(() => paymentsTotalByPayers.reduce((sum, { total }) => sum + total, 0), [
    paymentsTotalByPayers,
  ]);

  if (paymentsTotal === 0) return null;

  const labelInsideChart = t({ id: 'payments.payments_total' }, { value: formatCurrency(paymentsTotal) });

  return (
    <div className={s.chartContainer}>
      <PageTitle>{t({ id: 'payments.chart_label' })}</PageTitle>
      <DonutChart
        {...{ labelInsideChart, data, legend: false, thickness: 40, height: 240 }}
        containerId="PaymentsCostsByPayer"
      />
    </div>
  );
}
