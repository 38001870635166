export default [
  {
    index: 1,
    label: '+',
    max: 14,
    color: '#6b3fdc',
  },
  {
    index: 2,
    label: 'A',
    min: 15,
    max: 25,
    color: '#3caa38',
  },
  {
    index: 3,
    label: 'B',
    min: 26,
    max: 50,
    color: '#51dd02',
  },
  {
    index: 4,
    label: 'C',
    min: 51,
    max: 100,
    color: '#c4ef0b',
  },
  {
    index: 5,
    label: 'D',
    min: 101,
    max: 150,
    color: '#fad500',
  },
  {
    index: 6,
    label: 'E',
    min: 151,
    max: 200,
    color: '#f9bd05',
  },
  {
    index: 7,
    label: 'F',
    min: 201,
    max: 250,
    color: '#f78b02',
  },
  {
    index: 8,
    label: 'G',
    min: 251,
    color: '#f40a05',
  },
];
