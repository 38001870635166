import DonutChart from 'components/common/DonutChart';
import { DEFAULT_COLORS } from 'components/common/DonutChart/DonutChart';
import { screenSizeConfig } from 'components/common/Grid';
import { orderBy, round } from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useBreakpoint from 'use-breakpoint';
import { formatCurrency, formatValue } from 'utils/helpers';

const isAdditionalOptionsCategory = (group) => ['options'].includes(group);

function getTotalForCategories(categories) {
  return categories.reduce(
    (sum, { user_price, calculated_price }) => (sum + Number.isFinite(user_price) ? user_price : calculated_price),
    0,
  );
}

function getDataForChart(options, total, t) {
  const data = options.reduce((acc, { label, calculated_price, user_price }, idx) => {
    const price = Number.isFinite(user_price) ? user_price : calculated_price;
    const percentage = round((price * 100) / total, 2);
    const currentItem = {
      value: price,
      percentage,
      fill: DEFAULT_COLORS[idx],
      name: `${t({ id: label })} (${formatValue(percentage)} %)`,
    };
    return [...acc, currentItem];
  }, []);

  return orderBy(data, ['percentage'], ['desc']);
}

const PlanningCategoriesChart = ({ project, plan, legendLayout, chartHeight }) => {
  const { formatMessage: t } = useIntl();
  const totalPrice = project.price;
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'xxl');

  const labelInsideChart = useMemo(() => `${t({ id: 'project_plan.total_costs' })} ${formatCurrency(project.price)}`, [
    project.price,
    t,
  ]);

  const additionalOptionsCategories = useMemo(
    () => plan.planningCategories.filter(({ group }) => isAdditionalOptionsCategory(group)),
    [plan.planningCategories],
  );
  const additionalCostsCategoryTotal = useMemo(() => getTotalForCategories(additionalOptionsCategories), [
    additionalOptionsCategories,
  ]);

  const mainCategories = useMemo(
    () =>
      plan.planningCategories.filter(
        ({ group, calculated_price }) => !isAdditionalOptionsCategory(group) && calculated_price,
      ),
    [plan.planningCategories],
  );

  if (additionalOptionsCategories.length !== 0 && additionalCostsCategoryTotal) {
    mainCategories.push({
      label: 'planning_categories.additional_categories',
      calculated_price: additionalCostsCategoryTotal,
    });
  }

  const data = useMemo(() => getDataForChart(mainCategories, totalPrice, t), [mainCategories, t, totalPrice]);

  return (
    <DonutChart
      {...{ data, labelInsideChart, legendLayout }}
      height={chartHeight}
      thickness={35}
      containerId="planningCategories"
      legendLayout={['xxl'].includes(breakpoint) ? 'vertical' : 'horizontal'}
    />
  );
};

export default PlanningCategoriesChart;
