import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useProject from 'hooks/project-details/useProject';
import get from 'lodash/get';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { BUYING_HOUSE, NEW_APARTMENT, NEW_BUILDING, RENOVATION_HOUSE, PROPERTY_VALUATION } from 'constants/variables';
import { useIntl } from 'react-intl';
import Alert from 'components/common/Alert';
import BuildingHouseCalculator from './BuildingHouseCalculator';
import ApartmentDetailsCalculator from './ApartmentDetailsCalculator';
import BuyingHouseDetailsCalculator from './BuyingHouseDetailsCalculator';
import RenovationHouseCalculator from './RenovationHouseCalculator';
import PropertyValuationCalculator from './PropertyValuationCalculator';

const projectTypeToComponentMap = {
  [NEW_BUILDING]: BuildingHouseCalculator,
  [NEW_APARTMENT]: ApartmentDetailsCalculator,
  [BUYING_HOUSE]: BuyingHouseDetailsCalculator,
  [RENOVATION_HOUSE]: RenovationHouseCalculator,
  [PROPERTY_VALUATION]: PropertyValuationCalculator,
};

const ProjectDetailsCalculator = () => {
  const params = useParams();
  const { formatMessage: t } = useIntl();
  const [project, loading] = useProject(params.id);
  const type = get(project, 'type', '');

  const Calculator = useMemo(() => projectTypeToComponentMap[type], [type]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (type) return <Calculator {...{ project }} />;

  return (
    <Alert color="danger" withContainer>
      {t({ id: 'errors.project_not_found' })}
    </Alert>
  );
};

export default ProjectDetailsCalculator;
