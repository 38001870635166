import { ArrowForward } from 'assets/icons';
import Modal from 'components/common/Modal';
import ScrollToError from 'components/common/ScrollToError';
import InlineSelect from 'components/inputs/InlineSelect';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, memo, useState } from 'react';
import Button from 'components/common/Button';
import { ReactComponent as BuyingHouse } from 'assets/images/quick-calculator/buying-house.svg';
import { ReactComponent as BuildingHouse } from 'assets/images/quick-calculator/building-house.svg';
import { useHistory } from 'react-router-dom';
import { routePatterns } from 'router/route-paths';
import { toast } from 'react-toastify';
import useChangeProjectType from 'hooks/project/useChangeProjectType';
import equal from 'fast-deep-equal/es6/react';

export const useChangeProjectTypeHandler = (projectId) => {
  const changeProjectType = useChangeProjectType();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState();

  const handleChange = useCallback(
    async ({ type }) => {
      setSubmitting(true);
      try {
        await changeProjectType({ projectId, type });
        history.push(routePatterns.projectDetailsCalculator.stringify({ id: projectId }));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [changeProjectType, history, projectId],
  );

  return [handleChange, isSubmitting];
};

const LandValuationTypeModal = ({ hideModal, isVisible, t, formValuesRef = {}, onSubmit }) => (
  <Modal close={hideModal} isVisible={isVisible}>
    <Formik initialValues={{ type: null, ...(formValuesRef.current || {}) }} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <ScrollToError inModal />
          <Field
            name="type"
            label={t({ id: 'property_valuation.planning_project_type_label' })}
            validate={(type) => (!type ? t({ id: 'errors.fill_field' }) : null)}
            options={[
              {
                img: BuyingHouse,
                label: t({ id: 'property_valuation.planned_project_types.buying_house' }),
                description: t({
                  id: 'property_valuation.planned_project_types.buying_house_description',
                }),
                value: 'buying_house',
              },
              {
                img: BuildingHouse,
                label: t({ id: 'property_valuation.planned_project_types.new_building' }),
                description: t({ id: 'property_valuation.planned_project_types.new_building_description' }),
                value: 'new_building',
              },
            ]}
            component={InlineSelect}
          />
          <div className="text-center">
            <Button loading={isSubmitting} disabled={isSubmitting} type="submit" endIcon={<ArrowForward />}>
              {t({ id: 'property_valuation.plan_project_btn' })}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </Modal>
);

export default memo(LandValuationTypeModal, equal);
