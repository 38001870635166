import { RECORD_PAYMENTS, ROUGH_ESTIMATION } from 'constants/variables';
import { formatCurrency } from 'utils/helpers';
import { reduce, isNil } from 'lodash';
import { redColor, greenColor } from 'styles/variables.scss';

export const getBodyPriceText = ({ percentage, stage, categoryTotal, paymentsTotal, isFinalPayment, t }) => {
  const difference = Math.abs(categoryTotal - paymentsTotal);
  const formattedDifference = formatCurrency(difference);
  const formattedCategoryTotal = formatCurrency(categoryTotal);

  // check when category "other investments" isn't chosen but payments were recorded
  if (categoryTotal === 0 && percentage === 0 && difference !== 0) {
    return t({ id: 'spendings.category_overspent_label' }, { number: formattedDifference });
  }

  if (percentage > 100) {
    return t(
      { id: 'spendings.category_overspent_label' },
      {
        number: formattedDifference,
      },
    );
  }
  if (percentage <= 100) {
    if (stage === RECORD_PAYMENTS) {
      if (isFinalPayment) {
        return t(
          { id: 'spendings.category_saved_label' },
          {
            number: formattedDifference,
          },
        );
      }
      if (percentage > 0) {
        return t(
          { id: 'spendings.category_remaining_budget_label' },
          {
            number: formattedDifference,
          },
        );
      }
      return t(
        { id: 'spendings.category_quoted_label' },
        {
          number: formattedCategoryTotal,
        },
      );
    }
    if (stage === ROUGH_ESTIMATION) {
      return t(
        { id: 'spendings.category_estimated_cost_label' },
        {
          number: formattedCategoryTotal,
        },
      );
    }
  }
  return null;
};

export const getColor = ({ percentage, hasPayments, categoryTotal, isFinalPayment, stage }) => {
  if (percentage === 100) {
    return '#6CC740';
  }

  // The second check when category "other investments" isn't chosen but payments were recorded
  if (percentage > 100 || (hasPayments && categoryTotal === 0 && percentage === 0)) {
    return redColor;
  }

  // percentage < 100
  if (stage === ROUGH_ESTIMATION) {
    return '#ffca1d';
  }
  if (stage === RECORD_PAYMENTS) {
    if (!hasPayments) {
      return '#309af6';
    }
    if (isFinalPayment) {
      return greenColor;
    }
    return '#40C2C7';
  }
  return '';
};

export const getCategoryStage = ({ user_price, subitems, paymentsTotal }) => {
  let hasUserPrice = user_price;

  if (!hasUserPrice && subitems.length) {
    hasUserPrice = reduce(subitems, (accumulator, { user_price: price }) => accumulator && !isNil(price), true);
  }

  if (hasUserPrice || paymentsTotal) {
    return RECORD_PAYMENTS;
  }
  return ROUGH_ESTIMATION;
};

export const getData = ({ paymentsTotal, category }) => {
  const { user_price, calculated_price } = category;
  const categoryTotal = Number.isFinite(user_price) ? user_price : calculated_price;
  const percentage = Number.isFinite(paymentsTotal / categoryTotal)
    ? Math.round((paymentsTotal / categoryTotal) * 100)
    : 0;
  return { percentage, categoryTotal };
};

export const getBodyPriceTextForTopCard = ({ percentage, price, paymentsTotal, t }) => {
  const difference = Math.abs(price - paymentsTotal);
  const formattedDifference = formatCurrency(difference);

  return t(
    { id: percentage > 100 ? 'spendings.category_overspent_label' : 'spendings.category_remaining_budget_label' },
    { number: formattedDifference },
  );
};
