/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { ME_QUERY } from 'hooks/user/useUser';
import first from 'lodash/first';
import { formatGraphqlErrors } from 'utils/helpers';
import { GET_PROJECTS_QUERY } from 'hooks/project/useProjects';
import { LAST_PROJECT_ID_QUERY } from 'hooks/project/useLastProjectId';

const useAuth = (mutation) => {
  const [authMutation, { loading }] = useMutation(mutation, {
    refetchQueries: [{ query: GET_PROJECTS_QUERY }, { query: LAST_PROJECT_ID_QUERY }],
  });
  const client = useApolloClient();
  const auth = useCallback(
    async (variables) => {
      try {
        const res = await authMutation({ variables });
        const resultData = first(Object.values(res.data));
        client.writeQuery({
          query: ME_QUERY,
          data: { me: resultData.user },
        });
        return resultData;
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [authMutation, client],
  );

  return [auth, loading];
};

export default useAuth;
