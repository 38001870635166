import React, { useState } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import { ReactComponent as MoreActions } from 'assets/icons/more-actions.svg';
import { routePatterns } from 'router/route-paths';
import { FormattedMessage } from 'react-intl';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import s from './DropdownMoreActions.module.scss';

const DropdownMenu = ({ setVisible, onSettingsButtonClick, projectPlanPdf }) => {
  const params = useParams();
  return (
    <Menu className={s.menu}>
      <MenuItem key="1" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <a href={projectPlanPdf} target="_blank" rel="noopener noreferrer" className="link">
          <Button color="outline">
            <FormattedMessage id="project_plan.download_pdf" />
          </Button>
        </a>
      </MenuItem>
      <MenuItem key="2" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePatterns.planForm.stringify({ id: params.id })}>
          <Button color="outline">
            <FormattedMessage id="project_plan.customize_project_plan" />
          </Button>
        </Link>
      </MenuItem>

      <MenuItem key="3" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePatterns.projectDetailsCalculator.stringify({ id: params.id })} onClick={onSettingsButtonClick}>
          <Button color="outline">
            <FormattedHTMLMessage id="project_plan.adjust_project_details" />
          </Button>
        </Link>
      </MenuItem>
    </Menu>
  );
};

const DropdownMoreActions = ({ projectPlanPdf, onSettingsButtonClick }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      trigger={['click']}
      overlay={<DropdownMenu {...{ onSettingsButtonClick, projectPlanPdf, setVisible }} />}
      animation="slide-up"
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
      placement="bottomLeft"
    >
      <div className={s.triggeredContent}>
        <MoreActions />
      </div>
    </Dropdown>
  );
};

export default DropdownMoreActions;
