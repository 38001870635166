import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { formatGraphqlErrors } from 'utils/helpers';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_PLAN_QUERY } from '../useProjectPlan';
import { GET_CATEGORIES_QUERY } from '../payments/useGetCategories';
import { PROJECT_QUERY } from './useProject';

const UPDATE_PROJECT_BUYING_HOUSE_MUTATION = gql`
  mutation updateProjectBuyingHouse($projectId: ID!, $formData: UpdateProjectBuyingHouseInput!) {
    updateProjectBuyingHouse(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectBuyingHouseMutation = () => {
  const [updateProjectBuyingHouseMutation] = useMutation(UPDATE_PROJECT_BUYING_HOUSE_MUTATION);
  const client = useApolloClient();

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectBuyingHouse: updatedProject },
        } = await updateProjectBuyingHouseMutation({
          variables: { projectId, formData: { ...formData } },
          refetchQueries: [
            { query: PROJECT_PLAN_QUERY, variables: { projectId } },
            { query: GET_CATEGORIES_QUERY, variables: { projects: [projectId] } },
          ],
          awaitRefetchQueries: true,
        });

        client.writeQuery({
          query: PROJECT_QUERY,
          variables: { id: projectId },
          data: { project: { ...updatedProject } },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [updateProjectBuyingHouseMutation, client],
  );
  return mutate;
};

export default useUpdateProjectBuyingHouseMutation;
