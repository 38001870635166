import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Visible } from 'components/common/Grid';
import { ExclamationTriangle, ArrowForward } from 'assets/icons';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import s from './WarningMessage.module.scss';

const WarningMessage = ({ title, message, btnLabel, btnLink, onBtnClick, disabledBtn = false }) => (
  <Grid className={s.warningMessage}>
    <Visible sm xs>
      <Row>
        <Col xs={12}>
          <PageTitle className={s.title}>{title}</PageTitle>
        </Col>
      </Row>
    </Visible>
    <Row justify="center">
      <Col md={6} align="center" className={s.wrapper}>
        <ExclamationTriangle width="40px" height="40px" />
        <p className={s.message}>{message}</p>
        {btnLink ? (
          <Link to={btnLink}>
            <Button loading={disabledBtn} disabled={disabledBtn} endIcon={<ArrowForward />}>
              {btnLabel}
            </Button>
          </Link>
        ) : null}
        {typeof onBtnClick === 'function' ? (
          <Button loading={disabledBtn} disabled={disabledBtn} onClick={onBtnClick} endIcon={<ArrowForward />}>
            {btnLabel}
          </Button>
        ) : null}
      </Col>
    </Row>
  </Grid>
);

export default WarningMessage;
