import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

export const GET_PAYMENTS_QUERY = gql`
  query paymentsWithTotal($limit: Int, $offset: Int, $filters: PaymentFilters) {
    paymentsWithTotal(limit: $limit, offset: $offset, filters: $filters)
      @connection(key: "paymentsWithTotal", filter: ["filters"]) {
      total
      payments {
        _id
        name
        notes
        payee
        amount
        category {
          label
        }
        created_at
        date_paid
      }
    }
  }
`;

const useGetPayments = (variables) => {
  const { data, loading, fetchMore } = useQuery(GET_PAYMENTS_QUERY, {
    variables: { ...variables, limit: 10, offset: 0 },
  });

  const payments = useMemo(() => get(data, 'paymentsWithTotal.payments', []), [data]);

  return { payments, fetchMore, loading };
};

export default useGetPayments;
