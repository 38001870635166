import React from 'react';
import { useIntl } from 'react-intl';
import CircleButton from 'components/common/CircleButton';
import { Pencil, Settings, CloudDownloadIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import Tooltip from 'components/common/Tooltip';
import { routePatterns } from 'router/route-paths';
import { Hidden, Visible } from 'components/common/Grid';
import s from './ProjectPlanTables.module.scss';
import DropdownMoreActions from './DropdownMoreActions';

const ActionButtons = ({ projectId, pdf, onSettingsButtonClick = () => {} }) => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      {/* mobile */}
      <Visible xs sm>
        <DropdownMoreActions {...{ onSettingsButtonClick }} projectPlanPdf={pdf} />
      </Visible>
      {/* desktop */}
      <Hidden xs sm>
        <div className={s.actionBar}>
          <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_download_button' })}</p>} placement="top">
            <div>
              <a href={pdf} target="_blank" rel="noopener noreferrer" className="link">
                <CircleButton color="primary" className={s.actionButton}>
                  <CloudDownloadIcon />
                </CircleButton>
              </a>
            </div>
          </Tooltip>
          <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_edit_button' })}</p>} placement="top">
            <Link to={routePatterns.planForm.stringify({ id: projectId })}>
              <CircleButton color="primary" className={s.actionButton}>
                <Pencil />
              </CircleButton>
            </Link>
          </Tooltip>
          <Tooltip overlay={<p>{t({ id: 'planning_categories.tooltip_settings_button' })}</p>} placement="top">
            <Link
              to={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
              onClick={onSettingsButtonClick}
            >
              <CircleButton color="primary" className={s.actionButton}>
                <Settings />
              </CircleButton>
            </Link>
          </Tooltip>
        </div>
      </Hidden>
    </>
  );
};

export default ActionButtons;
