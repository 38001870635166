import gql from 'graphql-tag';
import { useMemo } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

export const GET_CATEGORIES_QUERY = gql`
  query filteredCategories($projects: [ID]!) {
    filteredCategories(projects: $projects) {
      label
      name
    }
  }
`;

const useGetCategories = (projectIds) => {
  const { data, loading, refetch } = useQuery(GET_CATEGORIES_QUERY, {
    variables: { projects: projectIds },
    fetchPolicy: 'cache-and-network',
  });
  const categories = useMemo(() => get(data, 'filteredCategories', []), [data]);

  return { categories, refetch, loading };
};

export default useGetCategories;
