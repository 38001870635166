import React from 'react';
import useProjects from 'hooks/project/useProjects';
import ProjectsList from 'components/ProjectList/ProjectsList';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { Link } from 'react-router-dom';
import { Plus } from 'assets/icons';
import { useIntl } from 'react-intl';
import routePaths from 'router/route-paths';
import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Hidden, Visible } from 'components/common/Grid';
import Tooltip from 'components/common/Tooltip';
import CircleButton from 'components/common/CircleButton';
import WarningMessage from 'components/common/WarningMessage';

const AddProjectButton = ({ isMaxCountOfProjects, t }) => (
  <Link to={routePaths.newProject} style={{ pointerEvents: isMaxCountOfProjects ? 'none' : 'initial' }}>
    <Hidden xs sm>
      <Button disabled={isMaxCountOfProjects} startIcon={<Plus />}>
        {t({ id: 'my_projects.new_project' })}
      </Button>
    </Hidden>
    <Visible xs sm>
      <CircleButton color="primary" disabled={isMaxCountOfProjects}>
        <Plus />
      </CircleButton>
    </Visible>
  </Link>
);

export default function MyProjects() {
  const [projects, loading] = useProjects();
  const { formatMessage: t } = useIntl();
  const isMaxCountOfProjects = projects.length >= 10;

  if (loading) {
    return <LoadingOverlay />;
  }

  if (projects.length === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.my_projects' })}
        message={t({ id: 'my_projects.create_project_message' })}
        btnLabel={t({ id: 'my_projects.new_project' })}
        btnLink={routePaths.newProject}
      />
    );
  }

  return (
    <Grid>
      <Row between="xs" middle="xs">
        <Col xs={8}>
          <PageTitle className="text-left">{t({ id: 'page_titles.my_projects' })}</PageTitle>
        </Col>
        <Col className="d-flex justify-content-end" xs={4}>
          <Tooltip
            hide={!isMaxCountOfProjects}
            overlay={<p>{t({ id: 'my_projects.delete_project.tooltip_max_count_of_projects' })}</p>}
            placement="top"
          >
            <div>
              <AddProjectButton {...{ isMaxCountOfProjects, t }} />
            </div>
          </Tooltip>
        </Col>
      </Row>
      {!loading ? <ProjectsList {...{ projects }} /> : null}
    </Grid>
  );
}
