import React, { useMemo, useRef } from 'react';
import { PieChart, Pie, Legend, ResponsiveContainer, Label, Text } from 'recharts';
import theme from 'config/theme';
import { isMobile } from 'react-device-detect';
import { max } from 'lodash';
import s from './DonutChart.module.scss';

export const DEFAULT_COLORS = [
  'rgb(107,63,220)',
  'rgb(250,205,55)',
  'rgb(35,101,186)',
  'rgb(136,204,238)',
  'rgb(74,170,153)',
  'rgb(102,153,204)',
  'rgb(153,153,51)',
  'rgb(221,203,119)',
  'rgb(204,102,119)',
  'rgb(54,120, 51)',
  'rgb(170,68,102)',
  'rgb(64,199,137)',
  'rgb(205, 24, 24)',
  'rgb(250,105,55)',
  'rgb(242,57,123)',
  'rgb(28, 20, 95)',
  'rgb(128,0,128)',
  'rgb(188,143,143)',
  'rgb(0,128,128)',
  'rgb(0,128,0)',
  'rgb(128,0,0)',
  'rgb(0,255,255)',
  'rgb(160,82,45)',
  'rgb(255,239,213)',
  'rgb(240,248,255)',
  'rgb(244,164,96)',
  'rgb(255,250,205)',
  'rgb(210,105,30)',
  'rgb(199,21,133)',
];

function calculateContainerHeight({ isHorizontalLegend, height, data }) {
  const coefficient = isMobile ? 38 : 33;
  return isHorizontalLegend ? height + data.length * coefficient : max([height, data.length * coefficient]);
}

export default function DonutChart({
  height = 250,
  thickness = 35,
  data,
  legend = true,
  labelInsideChart,
  legendLayout = 'horizontal',
  containerId = '',
  mobileFontSize = '1rem',
}) {
  const radius = height / 2;
  const labelFontSize = isMobile ? mobileFontSize : '1.1rem';
  const isHorizontalLegend = useMemo(() => legendLayout === 'horizontal', [legendLayout]);
  const containerRef = useRef();

  const containerHeight = useMemo(() => calculateContainerHeight({ isHorizontalLegend, height, data }), [
    data,
    height,
    isHorizontalLegend,
  ]);

  return (
    <ResponsiveContainer
      id={containerId}
      width="100%"
      height={containerHeight}
      className={s.donutChart}
      ref={containerRef}
    >
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          outerRadius={radius}
          innerRadius={radius - thickness}
          {...(!isHorizontalLegend ? { cx: height / 2 } : {})}
        >
          {labelInsideChart ? (
            <Label
              width={110}
              content={(props) => {
                const { viewBox, width } = props;
                const { cx, cy } = viewBox;
                const positioningProps = {
                  x: cx,
                  y: cy + 5,
                  width,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                  fill: theme.primaryColor,
                  fontSize: labelFontSize,
                  fontWeight: 'bold',
                };
                return <Text {...positioningProps}>{labelInsideChart}</Text>;
              }}
            />
          ) : null}
        </Pie>
        {legend ? (
          <Legend
            iconSize={24}
            iconType="circle"
            layout={legendLayout}
            wrapperStyle={!isHorizontalLegend ? { left: height + 25 } : {}}
            align={isHorizontalLegend ? 'left' : 'right'}
            verticalAlign={isHorizontalLegend ? 'bottom' : 'middle'}
          />
        ) : null}
      </PieChart>
    </ResponsiveContainer>
  );
}
