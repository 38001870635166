import { GUEST } from 'constants/variables';
import useUser from 'hooks/user/useUser';

const useHasRequiredRole = (role = GUEST) => {
  const [me] = useUser();
  if (!me) return false;
  const { roles = [] } = me;
  return roles.includes(role);
};

export default useHasRequiredRole;
