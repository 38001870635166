import React, { useCallback, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import TextInput from 'components/inputs/TextInput';
import NumberInput from 'components/inputs/NumberInput';
import { useIntl } from 'react-intl';
import ButtonSelect from 'components/inputs/ButtonSelect';
import { Floor1, Floor2, Floor3 } from 'assets/icons/QuickCalculator';
import { formatCurrency } from 'utils/helpers';
import { Col, Row } from 'react-flexbox-grid';
import { ArrowForward } from 'assets/icons';
import { ReactComponent as Img } from 'assets/images/buying-house/step-1.svg';
import { cloneDeep, debounce } from 'lodash';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import Button from 'components/common/Button';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import ScrollToError from 'components/common/ScrollToError';
import Switch from 'components/inputs/Switch';
import Slider from 'components/inputs/Slider';
import s from '../../calculators.module.scss';
import validationSchema from '../yupSchemas/buyingHouseFirstStepSchema';

const taxFields = [
  { key: 'property_transfer_tax', interest: 0.035 },
  { key: 'land_register_fee', interest: 0.011 },
  { key: 'notary_fee', interest: 0.01 },
  { key: 'broker_commission', interest: 0.036 },
];

const BuyingHouseFirstStep = ({
  onSubmit,
  lastStep,
  isEditMode,
  initialValues,
  nextStep,
  formValuesRef,
  currentStep,
  onSubmitStep,
}) => {
  const { formatMessage: t } = useIntl();
  const formikRef = useRef();

  const computeTotalCostBuyingHouse = useCallback((values) => {
    const { property_transfer_tax, land_register_fee, notary_fee, broker_commission, cost_house } = values;
    return formatCurrency(property_transfer_tax + land_register_fee + notary_fee + broker_commission + cost_house || 0);
  }, []);

  const recalculateFields = useCallback(
    debounce(
      () =>
        taxFields.forEach(({ key, interest }) => {
          if (formikRef.current) {
            const value = formikRef.current.values?.cost_house;
            formikRef.current.setFieldValue(key, Math.round(value * interest));
          }
        }),
      1000,
    ),
    [],
  );

  const onSubmitFormHandler = useCallback(
    async (values, formikActions) => {
      const newValues = cloneDeep(values);
      taxFields.forEach(({ key, interest }) => {
        newValues[key] = Math.round(newValues.cost_house * interest);
      });
      onSubmitStep(newValues);
      await onSubmit({ values: newValues, formikActions, currentStep, isEditMode, lastStep, nextStep });
    },
    [currentStep, isEditMode, lastStep, nextStep, onSubmit, onSubmitStep],
  );

  return (
    <Formik
      innerRef={formikRef}
      initialValues={isEditMode ? initialValues : formValuesRef?.current}
      onSubmit={onSubmitFormHandler}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => (
        <Row center="md" between="lg">
          <Col md={8} lg={6} xl={5}>
            <Form>
              <ScrollToError />
              <Field name="name" component={TextInput} type="text" label={t({ id: 'project_wizard.project_name' })} />
              <Field
                name="cost_house"
                component={SliderWithTooltip}
                step={1000}
                min={20000}
                max={3000000}
                units="€"
                sideEffect={recalculateFields}
                label={t({ id: 'buying_house_wizard.cost_house' })}
              />
              <p className="text-left">{t({ id: 'buying_house_wizard.additional_costs' })}</p>
              <Field name="main_residence" component={Switch} label={t({ id: 'buying_house_wizard.main_residence' })} />
              <Field
                name="property_transfer_tax"
                type="number"
                component={NumberInput}
                label={t({ id: 'buying_house_wizard.property_transfer_tax' })}
                skipScrollingToField
              />
              <Field
                name="land_register_fee"
                type="number"
                component={NumberInput}
                label={t({ id: 'buying_house_wizard.land_register_fee' })}
                skipScrollingToField
              />
              <Field
                name="notary_fee"
                type="number"
                component={NumberInput}
                label={t({ id: 'buying_house_wizard.notary_fee' })}
                skipScrollingToField
              />
              <Field
                name="broker_commission"
                type="number"
                component={NumberInput}
                label={t({ id: 'buying_house_wizard.broker_commission' })}
                skipScrollingToField
              />
              <p className={s.totalValue}>
                <span>{t({ id: 'buying_house_wizard.total_cost_house' })}</span>
                <b>{computeTotalCostBuyingHouse(values)}</b>
              </p>
              <div className={s.divider} />
              <Field
                name="land_area"
                component={SliderWithTooltip}
                min={200}
                step={50}
                max={3000}
                units="m²"
                label={t({ id: 'buying_house_wizard.land_area' })}
              />
              <Field
                name="house_area"
                component={SliderWithTooltip}
                min={25}
                step={5}
                max={300}
                units="m²"
                label={t({ id: 'buying_house_wizard.house_area' })}
              />
              <Field
                name="construction_year"
                component={NumberInput}
                label={t({ id: 'buying_house_wizard.construction_year' })}
                thousandSeparator=""
                format="####"
              />
              <Field
                name="house_type"
                component={ButtonSelect}
                options={[
                  {
                    label: t({ id: 'buying_house_wizard.house_type.solid_construction' }),
                    value: 'solid_construction',
                  },
                  {
                    label: t({ id: 'buying_house_wizard.house_type.prefabricated_house' }),
                    value: 'prefabricated_house',
                  },
                  {
                    label: t({ id: 'buying_house_wizard.house_type.timber_house' }),
                    value: 'timber_house',
                  },
                ]}
                label={t({ id: 'buying_house_wizard.house_type.name' })}
              />
              <Field
                name="number_of_floors"
                component={ButtonSelect}
                nullable={false}
                inline
                options={[
                  {
                    icon: ({ altText }) => <Floor1 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.one_floor' }),
                    label: 1,
                    value: 1,
                  },
                  {
                    icon: ({ altText }) => <Floor2 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.two_floors' }),
                    label: 2,
                    value: 2,
                  },
                  {
                    icon: ({ altText }) => <Floor3 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.three_floors' }),
                    label: 3,
                    value: 3,
                  },
                ]}
                label={t({ id: `buying_house_wizard.floors` })}
              />
              <Field
                name="number_of_toilets"
                component={Slider}
                step={1}
                min={1}
                max={5}
                label={t({ id: `buying_house_wizard.number_of_toilets` })}
              />
              <Field
                name="number_of_bathrooms"
                component={Slider}
                step={1}
                min={1}
                max={5}
                label={t({ id: `buying_house_wizard.number_of_bathrooms` })}
              />

              <div className={s.buttonsWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          </Col>
          <Col md={8} lg={6} xl={5} first="xs" last="lg">
            <div className="sticky-img-box">
              <Img title={t({ id: 'alt_text.project_details_page.buying_house.first_step' })} />
            </div>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default BuyingHouseFirstStep;
