import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { CO2_CALCULATOR_FORM_VALUES, CO2_CALCULATOR_RESULT } from 'graphql/fragments/co2-calculator';

const GET_CO2_VALUES = gql`
  query co2Values($projectId: ID!) {
    co2Values(projectId: $projectId) {
      co2_emissions_before {
        form_values {
          ...co2CalculatorFormValues
        }
        result {
          ...co2CalculatorResult
        }
      }
      co2_emissions_potential {
        form_values {
          ...co2CalculatorFormValues
        }
        result {
          ...co2CalculatorResult
        }
      }
      co2_emissions_after {
        form_values {
          ...co2CalculatorFormValues
        }
        result {
          ...co2CalculatorResult
        }
      }
    }
  }
  ${CO2_CALCULATOR_RESULT}
  ${CO2_CALCULATOR_FORM_VALUES}
`;

const useGetCO2Values = (projectId) => {
  const [getCO2Values, { loading, data, called }] = useLazyQuery(GET_CO2_VALUES, {
    variables: { projectId },
    fetchPolicy: 'cache-and-network',
  });
  return { getCO2Values, co2Values: data?.co2Values, loading, called };
};

export default useGetCO2Values;
