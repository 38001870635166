import React from 'react';
import SidebarAwareContainer from './SidebarAwareContainer';
import styles from './Footer.module.scss';

const AppFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className="container-fluid">
        <SidebarAwareContainer>Copyright © {new Date().getFullYear()} Talo IT GmbH</SidebarAwareContainer>
      </div>
    </footer>
  );
};

export default AppFooter;
