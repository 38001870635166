/* eslint-disable no-param-reassign */
import Alert from 'components/common/Alert';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useGetFinancing from 'hooks/financing/useFinancing';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import useSubmitLoan from 'hooks/financing/useSubmitLoan';
import { toast } from 'react-toastify';
import { routePatterns } from 'router/route-paths';
import WarningMessage from 'components/common/WarningMessage';
import LoanCalculator from './LoanCalculator';

export const useSubmitLoanHandler = () => {
  const loan = useSubmitLoan();
  const history = useHistory();
  const { id: projectId } = useParams();

  const handleSubmit = useCallback(
    async (variables, { setSubmitting }) => {
      try {
        const { price, interest_type, ...formData } = variables;
        if (interest_type !== 'fixed') {
          formData.fixed_interest_period = null;
        }
        await loan({ projectId, formData: { ...formData, interest_type } });
        history.push(routePatterns.loan.stringify({ id: projectId }));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [history, loan, projectId],
  );

  return handleSubmit;
};

export default () => {
  const { id: projectId } = useParams();
  const { formatMessage: t } = useIntl();
  const [{ project }, loading] = useGetFinancing(projectId);
  const onSubmit = useSubmitLoanHandler();

  if (loading) return <LoadingOverlay />;

  if (!project) {
    return (
      <Alert color="danger" withContainer>
        {t({ id: 'errors.project_not_found' })}
      </Alert>
    );
  }

  if (project.price === 0) {
    return (
      <WarningMessage
        title={t({ id: 'page_titles.loan' })}
        message={t({ id: 'project_plan.complete_previous_step' })}
        btnLabel={t({ id: 'project_plan.complete_previous_step_btn' })}
        btnLink={routePatterns.projectDetailsCalculator.stringify({ id: projectId })}
      />
    );
  }
  const initialValues = {
    price: project.price,
    budget: project.estimated_property_price ? project.budget - project.estimated_property_price : project.budget,
    period: project.loan?.period || 10,
    interest_type: project.loan?.interest_type || 'variable',
    fixed_interest_period: project.loan?.fixed_interest_period || 10,
    ...(Number.isFinite(project.estimated_property_price)
      ? { estimated_property_price: project.estimated_property_price }
      : {}),
  };

  return <LoanCalculator {...{ project, t, initialValues, onSubmit }} />;
};
