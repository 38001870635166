import React, { useMemo } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { ReactComponent as CarIcon } from 'assets/icons/co2-calculator/car.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/co2-calculator/plane.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/co2-calculator/tree.svg';
import { useIntl } from 'react-intl';
import { formatValue } from 'utils/helpers';
import { Visible } from 'components/common/Grid';
import s from './EnergyGhgEquivalents.module.scss';

const EnergyGhgEquivalent = ({ label, altText, icon: Icon, t, value }) => (
  <div className={s.equivalent}>
    <div className={s.circle}>
      <Icon title={t({ id: altText })} />
    </div>
    <p className={s.text}>{t({ id: label }, { value: formatValue(value) })}</p>
  </div>
);

function getEnergyEquivalents(values) {
  return [
    {
      key: 'energy_ghg_equivalent_plane',
      value: values.energyGhgEquivalentPlane,
      label: 'renovation_house_wizard.energy_ghg_equivalents.equivalent_plane',
      icon: PlaneIcon,
      altText: 'alt_text.renovation_house.plane_icon',
    },
    {
      key: 'energy_ghg_equivalent_car',
      value: values.energyGhgEquivalentCar,
      label: 'renovation_house_wizard.energy_ghg_equivalents.equivalent_car',
      icon: CarIcon,
      altText: 'alt_text.renovation_house.car_icon',
    },
    {
      key: 'energy_ghg_equivalent_trees',
      value: values.energyGhgEquivalentTrees,
      label: 'renovation_house_wizard.energy_ghg_equivalents.equivalent_trees',
      icon: TreeIcon,
      altText: 'alt_text.renovation_house.tree_icon',
    },
  ];
}

const EnergyGhgEquivalents = ({ equivalentValues, costOverviewTab = false }) => {
  const { formatMessage: t } = useIntl();
  const energyEquivalents = useMemo(() => getEnergyEquivalents(equivalentValues), [equivalentValues]);

  return (
    <Row between="md" start="md">
      <Visible md>
        <Col md={12}>
          <Row>
            <Col md={8}>
              <div className={s.almostRectangle}>
                {t({ id: `renovation_house_wizard.energy_ghg_equivalents.${costOverviewTab ? 'reduction' : 'label'}` })}
              </div>
            </Col>
          </Row>
        </Col>
      </Visible>

      <Col md={0} lg={5}>
        <div className={s.almostRectangle}>
          {t({ id: `renovation_house_wizard.energy_ghg_equivalents.${costOverviewTab ? 'reduction' : 'label'}` })}
        </div>
      </Col>

      {energyEquivalents.map((v) => (
        <Col key={v.key} md={4} lg={2}>
          <EnergyGhgEquivalent {...v} t={t} />
        </Col>
      ))}
    </Row>
  );
};

export default EnergyGhgEquivalents;
