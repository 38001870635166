import React, { useState } from 'react';
import { BudgetStep1, Alone, Partner } from 'assets/icons/budget';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-flexbox-grid';
import { Field, Form, Formik } from 'formik';
import ShowMoreText from 'react-show-more-text';
import ButtonSelect from 'components/inputs/ButtonSelect';
import PageTitle from 'components/common/PageTitle';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import { ReactComponent as DiagonalLine } from 'assets/icons/diagonal-line.svg';
import { ReactComponent as OneChildIcon } from 'assets/icons/budget/one-child.svg';
import { ReactComponent as TwoChildrenIcon } from 'assets/icons/budget/two-children.svg';
import { ReactComponent as ThreeChildrenIcon } from 'assets/icons/budget/three-children.svg';
import { ReactComponent as FourChildrenIcon } from 'assets/icons/budget/four-children.svg';
import Button from 'components/common/Button';
import { ArrowForward, SaveIcon } from 'assets/icons';
import * as yup from 'yup';
import ScrollToError from 'components/common/ScrollToError';
import ErrorMessage from 'components/common/ErrorMessage';
import { __ } from 'utils/form';
import s from './BudgetFormSteps.module.scss';

const validationSchema = yup.object().shape({
  alone_or_partner: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
  age: yup.string().required(__('errors.fill_field')),
  kids_quantity: yup
    .string()
    .nullable()
    .required(__('errors.choose_one')),
});

const FirstStep = ({ isEditMode, onSubmitStep, initialValues, formValuesRef, onSubmit, nextStep }) => {
  const { formatMessage: t } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Row between="lg" center="md">
      <Col md={8} lg={6} xl={5}>
        <PageTitle>{t({ id: 'budget_calculator.first_step.title' })}</PageTitle>
        <div className={s.showMoreText}>
          <ShowMoreText
            lines={2}
            more={t({ id: 'budget.show_more_text' })}
            less={t({ id: 'budget.show_less_text' })}
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-left"
          >
            {t({ id: 'budget_calculator.budget_calculator_description' })}
          </ShowMoreText>
        </div>

        <Formik
          initialValues={formValuesRef?.current || initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (isEditMode) {
              await onSubmit(values, actions);
            } else {
              await onSubmitStep(values, actions);
              nextStep();
            }
          }}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <ScrollToError />
              <Field
                name="alone_or_partner"
                label={t({ id: 'budget_calculator.first_step.alone_or_partner' })}
                component={ButtonSelect}
                options={[
                  {
                    display: 'inline',
                    icon: ({ altText }) => <Alone className="stroke" title={altText} />,
                    value: 'alone',
                    label: t({ id: 'budget_calculator.first_step.alone_option_label' }),
                  },
                  {
                    display: 'inline',
                    icon: ({ altText }) => <Partner className="stroke" title={altText} />,
                    value: 'partner',
                    label: t({ id: 'budget_calculator.first_step.partner_option_label' }),
                  },
                ]}
              />

              <Field
                name="age"
                label={t({ id: 'budget_calculator.first_step.age' })}
                component={SliderWithTooltip}
                description={t({ id: 'budget_calculator.first_step.age_description' })}
                min={18}
                max={70}
                defaultValue={30}
                units={t({ id: 'budget_calculator.first_step.age_units' })}
              />

              <Field
                name="kids_quantity"
                label={t({ id: 'budget_calculator.first_step.kids_quantity' })}
                component={ButtonSelect}
                description={t({ id: 'budget_calculator.first_step.kids_quantity_description' })}
                inOneRow={false}
                nullable={false}
                options={[
                  {
                    icon: ({ altText }) => <DiagonalLine className="stroke" title={altText} />,
                    altText: t({ id: 'alt_text.icons.has_none' }),
                    value: 0,
                    label: '',
                  },
                  {
                    icon: ({ altText }) => <OneChildIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.one_child' }),
                    value: 1,
                    label: '1',
                  },
                  {
                    icon: ({ altText }) => <TwoChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.two_children' }),
                    value: 2,
                    label: '2',
                  },
                  {
                    icon: ({ altText }) => <ThreeChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.three_children' }),
                    value: 3,
                    label: '3',
                  },
                  {
                    icon: ({ altText }) => <FourChildrenIcon className="fill" title={altText} />,
                    altText: t({ id: 'alt_text.budget_calculator.kids_quantity.four_children' }),
                    value: 4,
                    label: '4+',
                  },
                ]}
              />

              {errors.form ? <ErrorMessage message={errors.form} /> : null}

              <div className={s.buttonsWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className="sticky-img-box">
          <BudgetStep1 title={t({ id: 'alt_text.budget_calculator.first_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default FirstStep;
