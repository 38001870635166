import DonutChart from 'components/common/DonutChart';
import { screenSizeConfig } from 'components/common/Grid';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useBreakpoint from 'use-breakpoint';
import { getDataForChart } from './MonthlyExpensesChart';

const monthlyIncomeChartOptions = [
  {
    label: 'budget.monthly_income_chart.options.expenses',
    pathsToValues: ['monthly_costs.calculated_value'],
  },
  {
    label: 'budget.monthly_income_chart.options.credit_rate',
    pathsToValues: ['monthly_rate_loan.calculated_value'],
  },
];

const MonthlyIncomeChart = ({ sortedOptions, labelInsideChart, breakpoint }) => (
  <div className="card">
    <DonutChart
      {...{ data: sortedOptions, labelInsideChart }}
      containerId="monthlyIncome"
      legendLayout={['xs', 'sm'].includes(breakpoint) ? 'horizontal' : 'vertical'}
      height={220}
      thickness={35}
    />
  </div>
);

export default ({ budget }) => {
  const { formatMessage: t } = useIntl();
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'xxl');
  const lifestyleCalculations = { ...budget.calculation };
  const totalMonthlyIncome = budget.calculation.total_monthly_income.calculated_value;

  const labelInsideChart = t({ id: 'budget.monthly_income_chart.central_label' });

  const sortedOptions = useMemo(
    () =>
      getDataForChart({
        t,
        calculations: lifestyleCalculations,
        options: monthlyIncomeChartOptions,
        total: totalMonthlyIncome,
      }),
    [lifestyleCalculations, t, totalMonthlyIncome],
  );

  return <MonthlyIncomeChart {...{ sortedOptions, labelInsideChart, breakpoint }} />;
};
