import React from 'react';
import ContentLoader from 'react-content-loader';
import range from 'lodash/range';

const PaymentsLoader = ({ count = 1, ...rest }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      {range(0, count).map((i) => (
        <ContentLoader
          speed={2}
          width={350}
          height={200}
          viewBox="0 0 350 200"
          backgroundColor="#ededed"
          foregroundColor="#e9f3fb"
          key={i}
          {...rest}
        >
          <rect x="1" y="5" rx="3" ry="3" width="90" height="6" />
          <rect x="1" y="20" rx="3" ry="3" width="55" height="6" />
          <rect x="1" y="90" rx="3" ry="3" width="280" height="6" />
          <rect x="1" y="105" rx="3" ry="3" width="280" height="6" />
          <rect x="1" y="60" rx="5" ry="5" width="100" height="10" />
          <rect x="1" y="45" rx="3" ry="3" width="60" height="6" />
          <rect x="202" y="45" rx="5" ry="5" width="80" height="6" />
          <rect x="182" y="60" rx="5" ry="5" width="100" height="10" />
          <rect x="190" y="20" rx="3" ry="3" width="90" height="6" />
        </ContentLoader>
      ))}
    </div>
  );
};

export default PaymentsLoader;
