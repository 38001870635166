import React, { useMemo } from 'react';
import useMyBudget from 'hooks/budget/useMyBudget';
import pick from 'lodash/pick';
import map from 'lodash/map';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import useUpdateBudget from 'hooks/budget/useUpdateBudget';
import { toast } from 'react-toastify';
import routePaths, { routePatterns } from 'router/route-paths';
import LifestyleEditMonthlyExpensesForm from 'components/budget/LifestyleMonthlyExpensesEditForm';
import PageTitle from 'components/common/PageTitle';
import round from 'lodash/round';
import get from 'lodash/get';

const monthlyExpensesValues = [
  {
    key: 'monthly_expenses',
    label: 'budget_calculator.edit_values.monthly_expenses',
  },
  {
    key: 'monthly_expenses_partner',
    label: 'budget_calculator.edit_values.monthly_expenses_partner',
  },
  {
    key: 'monthly_expenses_kids',
    label: 'budget_calculator.edit_values.monthly_expenses_kids',
  },
  {
    key: 'costs_for_repair',
    label: 'budget_calculator.edit_values.costs_for_repair',
  },
  {
    key: 'technology',
    label: 'budget_calculator.fifth_step.technology',
  },
  {
    key: 'luxuries',
    label: 'budget_calculator.fifth_step.luxuries',
  },
  {
    key: 'socializing',
    label: 'budget_calculator.fifth_step.socializing',
  },
  {
    key: 'hobbies',
    label: 'budget_calculator.fifth_step.hobbies',
  },
  {
    key: 'taking_a_break',
    label: 'budget_calculator.fifth_step.taking_a_break',
  },
  {
    key: 'cars',
    label: 'budget_calculator.edit_values.cars',
  },
  {
    key: 'pension_and_insurances',
    label: 'budget_calculator.sixth_step.pension_and_insurances',
  },
  {
    key: 'savings',
    label: 'budget_calculator.sixth_step.savings',
  },
];

const useUpdateBudgetHandler = (myBudget) => {
  const updateBudget = useUpdateBudget();
  const history = useHistory();
  const { id } = useParams();

  const handleSubmit = React.useCallback(
    async (variables, { setSubmitting }, formik) => {
      setSubmitting(true);
      try {
        const changedVariables = Object.keys(variables).filter(
          (key) => round(get(myBudget, `calculation[${key}].calculated_value`)) !== variables[key],
        );

        if (formik.dirty) await updateBudget(pick(variables, changedVariables));

        const to = id ? routePatterns.lifestyleOverviewWithProject.stringify({ id }) : routePaths.lifestyleOverview;
        history.push(to);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [history, id, myBudget, updateBudget],
  );
  return handleSubmit;
};

function getInitialValues(budgetCalculation) {
  if (!budgetCalculation) return {};
  const allowedKeys = monthlyExpensesValues.map(({ key }) => key);
  const allowedValues = pick(budgetCalculation, allowedKeys);

  map(allowedValues, (item, key) => {
    if (item.calculated_value === 0 && !Number.isFinite(item.user_input)) {
      delete allowedValues[key];
    } else if (Number.isFinite(item.calculated_value)) {
      const value = Number.isFinite(item.user_input) ? item.user_input : item.calculated_value;
      allowedValues[key] = round(value);
    }
  });
  return allowedValues;
}

const LifestyleEditMonthlyExpenses = () => {
  const [myBudget, loading] = useMyBudget();
  const { formatMessage: t } = useIntl();
  const onSubmit = useUpdateBudgetHandler(myBudget);
  const budgetCalculation = get(myBudget, 'calculation');

  const initialValues = useMemo(() => getInitialValues(budgetCalculation), [budgetCalculation]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Grid>
      <Row center="md" start="lg">
        <Col md={8} lg={12}>
          <PageTitle>{t({ id: 'page_titles.edit_budget' })}</PageTitle>
        </Col>
      </Row>
      <LifestyleEditMonthlyExpensesForm {...{ onSubmit, initialValues, monthlyExpensesValues }} />
    </Grid>
  );
};

export default LifestyleEditMonthlyExpenses;
