import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { PROJECT_PLAN_QUERY } from '../useProjectPlan';
import { PROJECT_QUERY } from './useProject';

const UPDATE_PROJECT_RENOVATION_HOUSE_MUTATION = gql`
  mutation updateProjectRenovationHouse($projectId: ID!, $formData: UpdateProjectRenovationHouseInput!) {
    updateProjectRenovationHouse(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectRenovationHouseMutation = () => {
  const [updateProjectRenovationHouseMutation] = useMutation(UPDATE_PROJECT_RENOVATION_HOUSE_MUTATION);
  const client = useApolloClient();

  const mutate = useCallback(
    async (projectId, formData) => {
      try {
        const {
          data: { updateProjectRenovationHouse: updatedProject },
        } = await updateProjectRenovationHouseMutation({
          variables: { projectId, formData: { ...formData } },
          refetchQueries: [{ query: PROJECT_PLAN_QUERY, variables: { projectId } }],
          awaitRefetchQueries: true,
        });

        client.writeQuery({
          query: PROJECT_QUERY,
          variables: { id: projectId },
          data: { project: { ...updatedProject } },
        });
      } catch (error) {
        const e = error.graphQLErrors ? new Error(error.graphQLErrors.map((err) => err.message)) : error;
        e.raw = error || '';
        throw e;
      }
    },
    [updateProjectRenovationHouseMutation, client],
  );
  return mutate;
};

export default useUpdateProjectRenovationHouseMutation;
