import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrors } from 'utils/helpers';
import client from 'graphql/apollo';
import { useIntl } from 'react-intl';
import { PROJECT_PLAN_FRAGMENT, PROJECT_PLAN_QUERY } from '../useProjectPlan';

const UPDATE_PLAN_ITEM_MUTATION = gql`
  mutation updatePlanItem(
    $projectId: ID!
    $categoryName: String!
    $planItemName: String!
    $formData: PlanItemInput!
    $locale: String
  ) {
    updatePlanItem(
      projectId: $projectId
      categoryName: $categoryName
      planItemName: $planItemName
      formData: $formData
    ) {
      ...projectPlan
      personal_contribution_value
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useUpdatePlanItem = () => {
  const [updatePlanItemMutation] = useMutation(UPDATE_PLAN_ITEM_MUTATION);
  const { locale } = useIntl();

  const handleUpdatePlanItem = useCallback(
    async ({ projectId, categoryName, planItemName, formData }) => {
      try {
        const { project, projectPlan } = client.readQuery({
          query: PROJECT_PLAN_QUERY,
          variables: { locale, projectId },
        });

        const {
          data: { updatePlanItem },
        } = await updatePlanItemMutation({ variables: { projectId, categoryName, planItemName, formData } });

        const { planningCategories, price, personal_contribution_value } = updatePlanItem;

        client.writeQuery({
          query: PROJECT_PLAN_QUERY,
          variables: { locale, projectId },
          data: {
            project: { ...project, price, personal_contribution_value },
            projectPlan: { ...projectPlan, price, planningCategories },
          },
        });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [locale, updatePlanItemMutation],
  );
  return handleUpdatePlanItem;
};

export default useUpdatePlanItem;
