import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import routePaths from 'router/route-paths';
import useUser from 'hooks/user/useUser';

export default function NotFound() {
  const [me] = useUser();
  return (
    <>
      <h1>
        <FormattedMessage id="page_not_found.title" />
      </h1>
      {me ? (
        <Link to={routePaths.myProjects} className="link">
          <FormattedMessage id="app.back" />
        </Link>
      ) : (
        <Link to={routePaths.quickCalculator} className="link">
          <FormattedMessage id="page_not_found.back_to_quick_calculator" />
        </Link>
      )}
    </>
  );
}
