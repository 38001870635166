import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { CO2_CALCULATOR_FORM_VALUES } from 'graphql/fragments/co2-calculator';

export const PROJECT_CALCULATIONS = gql`
  query project_calculations($id: ID!) {
    project(id: $id) {
      _id
      calculations {
        ... on NewBuildingCalculations {
          immazing_land(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            request_completed
            form_values {
              coordinates {
                lat
                lng
              }
              land_area
            }
            error
          }
        }
        ... on BuyingHouseCalculations {
          immazing(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            error
            request_completed
            form_values {
              construction_year
              coordinates {
                lat
                lng
              }
              land_area
              house_area
              house_category
              parking
              basement_type
            }
          }
          immazing_land(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            request_completed
            form_values {
              construction_year
              coordinates {
                lat
                lng
              }
              land_area
              house_area
              house_category
              parking
              basement_type
            }
            error
          }
        }
        ... on NewApartmentCalculations {
          immazing(projectId: $id) {
            vw
            vw_lwr
            vw_upr
            error
            request_completed
            form_values {
              coordinates {
                lat
                lng
              }
              floor_number
              elevator
              is_rooftop
              loggia
              garden
              basement
              balcony
              terrace
              apartment_area
              construction_year
              condition_of_apartment
              parking
            }
          }
        }
        ... on RenovationHouseCalculations {
          co2_emissions_before {
            form_values {
              ...co2CalculatorFormValues
            }
          }
        }
      }
    }
    me {
      _id
      immazingRequestsCount
    }
  }
  ${CO2_CALCULATOR_FORM_VALUES}
`;

const useGetProjectCalculations = (projectId) => {
  const { data, loading } = useQuery(PROJECT_CALCULATIONS, {
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
  });

  const calculations = get(data, 'project.calculations', null);
  const immazingRequestsCount = get(data, 'me.immazingRequestsCount', null);

  return { calculations, immazingRequestsCount, loading };
};

export default useGetProjectCalculations;
