import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl } from 'react-intl';
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import ScrollToError from 'components/common/ScrollToError';
import { Col, Row } from 'react-flexbox-grid';
import { ArrowBack, ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { Floor1, Floor2, Floor3 } from 'assets/icons/QuickCalculator';
import { ReactComponent as Img } from 'assets/images/renovation-house/step-3.svg';
import SliderWithTooltip from 'components/inputs/SliderWithTooltip';
import ScrollToTop from 'components/common/ScrollToTop';
import ButtonSelect from 'components/inputs/ButtonSelect';
import validationSchema from '../yupSchemas/renovationHouseThirdStepSchema';
import s from '../../calculators.module.scss';

const RenovationHouseThirdStep = ({
  onSubmitStep,
  lastStep,
  onSubmit,
  isEditMode,
  previousStep,
  nextStep,
  initialValues,
  formValuesRef,
  currentStep,
  onBack,
}) => {
  const { formatMessage: t } = useIntl();

  const validateHouseAreaInput = useCallback(
    (houseArea) => {
      if (isEditMode) {
        if (houseArea < initialValues?.heated_living_area) {
          return t({ id: 'renovation_house_wizard.wrong_min_house_area' }, { value: initialValues.heated_living_area });
        }
      } else if (houseArea < formValuesRef?.current?.heated_living_area) {
        return t(
          { id: 'renovation_house_wizard.wrong_min_house_area' },
          { value: formValuesRef.current.heated_living_area },
        );
      }
      return null;
    },
    [formValuesRef, initialValues, isEditMode, t],
  );

  return (
    <Row between="lg" center="md">
      <ScrollToTop />
      <Col md={8} lg={6} xl={5}>
        <Formik
          initialValues={formValuesRef?.current}
          onSubmit={async (values, formikActions) => {
            onSubmitStep(values);
            await onSubmit({ values, formikActions, currentStep, isEditMode, lastStep, nextStep });
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <ScrollToError />
              <Field name="name" component={TextInput} type="text" label={t({ id: 'project_wizard.project_name' })} />
              <Field name="zip" component={TextInput} label={t({ id: 'project_wizard.zip_code' })} />
              <Field
                name="house_area"
                units="m²"
                component={SliderWithTooltip}
                validate={validateHouseAreaInput}
                min={formValuesRef?.current?.heated_living_area || initialValues?.heated_living_area || 0}
                max={300}
                step={1}
                defaultValue={formValuesRef.current.heated_living_area}
                label={t({ id: 'renovation_house_wizard.house_area' })}
              />
              <Field
                name="number_of_floors"
                component={ButtonSelect}
                nullable={false}
                inline
                options={[
                  {
                    icon: ({ altText }) => <Floor1 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.one_floor' }),
                    label: 1,
                    value: 1,
                  },
                  {
                    icon: ({ altText }) => <Floor2 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.two_floors' }),
                    label: 2,
                    value: 2,
                  },
                  {
                    icon: ({ altText }) => <Floor3 title={altText} />,
                    altText: t({ id: 'alt_text.new_building.number_of_floors.three_floors' }),
                    label: 3,
                    value: 3,
                  },
                ]}
                label={t({ id: `buying_house_wizard.floors` })}
              />

              <div className={s.buttonsWrapper}>
                {!isEditMode ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={() => onBack(values, previousStep)}
                    startIcon={<ArrowBack />}
                    color="outline"
                  >
                    {t({ id: 'app.back' })}
                  </Button>
                ) : null}
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={8} lg={6} xl={5} first="xs" last="lg">
        <div className={s.imgBox}>
          <Img title={t({ id: 'alt_text.project_details_page.renovation_house.third_step' })} />
        </div>
      </Col>
    </Row>
  );
};

export default RenovationHouseThirdStep;
