/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useRef, memo } from 'react';
import { RadioGroup, Radio } from 'react-radio-group';
import get from 'lodash/get';
import ScreenLink from 'components/common/ScreenLink';
import cn from 'classnames';
import { areEqualFields, scrollToNextMatchingSibling } from 'utils/form';
import { useIntl } from 'react-intl';
import { useAppContext } from 'contexts/AppContext';
import s from './InlineSelect.module.scss';

function RadioItem({ imageSrc, img: Img, label, description, value, idx, name, t, theme }) {
  return (
    <div
      style={description && { alignSelf: 'flex-start' }}
      className={cn(s.radioItem, theme === 'green' ? s.greenRadioItem : '')}
    >
      <div className={s.container}>
        <label className={s.imageLabel} htmlFor={`${name}.${idx}`}>
          {imageSrc ? (
            <img src={imageSrc} alt={t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: label })} />
          ) : (
            <Img title={t({ id: 'alt_text.btn_select_with_icon' }, { btnLabel: label })} />
          )}
          <div className={s.wrapper}>
            <div className={s.circle}>
              <Radio type="radio" id={`${name}.${idx}`} name={name} value={value} />
              <label htmlFor={`${name}.${idx}`} />
            </div>
          </div>
        </label>
      </div>
      {label || description ? (
        <div className={cn(s.description, 'muted')}>
          {label ? <label className={s.optionLabel}>{label}</label> : null}
          {description ? <small>{description}</small> : null}
        </div>
      ) : null}
    </div>
  );
}

const InlineSelect = ({ form, options, field, label, sideEffectOnChange, moreDetails, skipScrollingToField }) => {
  const { errors, touched } = form;
  const { name, value } = field;
  const fieldWrapperRef = useRef();
  const { formatMessage: t } = useIntl();
  const { theme } = useAppContext();

  const handleChange = useCallback(
    (v) => {
      form.setFieldValue(name, v);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value: v, form });
      if (v) scrollToNextMatchingSibling(fieldWrapperRef);
    },
    [form, name, sideEffectOnChange],
  );

  return (
    <div ref={fieldWrapperRef} className={!skipScrollingToField ? 'scroll-to-field' : ''}>
      <label className="text-left">
        {label} {moreDetails && <ScreenLink linkData={moreDetails} />}
      </label>
      <RadioGroup className={s.radioGroup} name={name} selectedValue={value} onChange={handleChange}>
        {options.map((option, idx) => (
          <RadioItem key={option.value} {...{ ...option, name, t, idx, theme }} />
        ))}
      </RadioGroup>
      {get(errors, name) && get(touched, name) && <div className={s.errorSelect}>{get(errors, name)}</div>}
    </div>
  );
};

export default memo(InlineSelect, (prevProps, nextProps) => areEqualFields(prevProps, nextProps, ['options']));
