import React from 'react';
import { getInitialValuesFromSteps } from '../utils/form';
import settings from '../config/settings';

export default function useFormValuesRef(wizardSteps, { formValueMock, initialValues }) {
  const formValuesRef = React.useRef();

  if (!formValuesRef.current) {
    formValuesRef.current = {
      ...getInitialValuesFromSteps(wizardSteps),
      ...(settings.isDevelopment ? { ...initialValues, ...formValueMock } : initialValues),
    };
  }
  return formValuesRef;
}
