import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { formatGraphqlErrors } from 'utils/helpers';

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`;

const useForgotPassword = () => {
  const { formatMessage: t } = useIntl();
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => toast.success(t({ id: 'auth.forgot_password.message' })),
    onError: (e) => toast.error(get(e, 'graphQLErrors[0].message', 'Error has been occurred')),
  });

  const forgotPassword = useCallback(
    async ({ email }) => {
      try {
        await forgotPasswordMutation({ variables: { email } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [forgotPasswordMutation],
  );

  return forgotPassword;
};

export default useForgotPassword;
