import React, { useMemo, useCallback } from 'react';
import Select from 'react-select';
import { backgroundColor } from 'styles/variables.scss';
import { SelectCustomStyles } from './customStyle';

const styles = {
  ...SelectCustomStyles,
  valueContainer: (style) => ({
    ...style,
    padding: 0,
  }),
  multiValue: (style) => ({
    ...style,
    padding: '4px 6px',
    backgroundColor,
  }),
  menuPortal: (style) => ({ ...style, zIndex: 50 }),
};

const MultiSelect = ({ field, form: { setFieldValue, setFieldTouched }, options, label, callbackOnChange }) => {
  const handleChange = useCallback(
    (selectedOptions) => {
      const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
      setFieldValue(field.name, values);
      if (typeof callbackOnChange === 'function') callbackOnChange(values);
    },
    [callbackOnChange, field.name, setFieldValue],
  );

  const handleBlur = useCallback(() => {
    setFieldTouched(field.name);
  }, [field, setFieldTouched]);

  const optionValue = useMemo(() => {
    const defaultOption = options.find((o) => o.value === field.defaultValue);
    if (defaultOption) {
      handleChange(defaultOption);
      return defaultOption;
    }
    const _options = options && field.value && options.filter((o) => field.value.includes(o.value));
    return _options;
  }, [options, field.defaultValue, field.value, handleChange]);

  return (
    <div className="field-wrapper">
      <Select
        isMulti
        onBlur={handleBlur}
        options={options}
        styles={styles}
        onChange={handleChange}
        inputId={field.name}
        value={optionValue}
        name={field.name}
        inputProps={{
          autoComplete: 'off',
        }}
        placeholder=""
        components={{
          IndicatorSeparator: () => null,
        }}
        blurInputOnSelect
        menuPortalTarget={document.body}
      />
      <label id={`label-${field.name}`} htmlFor={field.name}>
        {label}
      </label>
    </div>
  );
};

export default MultiSelect;
