/* eslint-disable */
function createEquipmentTypeSelect(options) {
  return {
    label: 'project_wizard.equipment_type',
    type: 'button_select',
    name: options.name,
    options: [
      {
        value: 'standard',
        label: 'equipment_types.standard',
      },
      {
        value: 'premium',
        label: 'equipment_types.premium',
      },
      {
        value: 'superior',
        label: 'equipment_types.superior',
      },
    ],
    required: true,
    nullable: false,
    ...options,
  };
}
export default [
  {
    _id: '5e6d5330-1f3a-4eb9-9850-ba58b6345730',
    name: 'kitchen',
    label: 'apartment_features.kitchen',
    description: 'apartment_features.kitchen_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'kitchen_equipment_type',
        description: 'apartment_features.kitchen_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'a02b8d07-d00c-4a9d-b89c-8559d547d2be',
    name: 'aircondition',
    label: 'apartment_features.aircondition',
    description: 'apartment_features.aircondition_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'aircondition_equipment_type',
        description: 'apartment_features.aircondition_equipment_type_description',
      }),
    ],
  },
  {
    _id: '98543a00-8723-41da-8a45-8aeacb04e876',
    name: 'sanitary_renovation',
    label: 'apartment_features.sanitary_renovation',
    description: 'apartment_features.sanitary_renovation_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'apartment_wizard.sanitary_renovation_area',
        type: 'number',
        name: 'sanitary_renovation_area',
        bigLabel: true,
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'sanitary_renovation_equipment_type',
        description: 'apartment_features.sanitary_renovation_equipment_type_description',
      }),
    ],
  },
  {
    _id: '6d6a1982-bf39-402c-96f5-2e00cf61c0c9',
    name: 'new_flooring',
    label: 'apartment_features.new_flooring',
    description: 'apartment_features.new_flooring_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'apartment_wizard.new_flooring_area',
        type: 'number',
        name: 'new_flooring_area',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'new_flooring_equipment_type',
        description: 'apartment_features.new_flooring_equipment_type_description',
      }),
    ],
  },
  {
    _id: '15b78a14-caad-4552-be29-1854abb29f49',
    name: 'renovation_walls_and_interior_doors',
    label: 'apartment_features.renovation_walls_and_interior_doors',
    description: 'apartment_features.renovation_walls_and_interior_doors_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      {
        label: 'apartment_wizard.renovation_walls_and_interior_doors_area',
        type: 'number',
        name: 'renovation_walls_and_interior_doors_area',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'renovation_walls_and_interior_doors_equipment_type',
        description: 'apartment_features.renovation_walls_and_interior_doors_equipment_type_description',
      }),
    ],
  },
  {
    _id: '337d822c-3da2-4960-85b4-1b6c2b61e514',
    name: 'renewal_of_electric_installation',
    label: 'apartment_features.renewal_of_electric_installation',
    description: 'apartment_features.renewal_of_electric_installation_description',
    autoselect: false,
    group: 'indoor',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'renewal_of_electric_installation_equipment_type',
        description: 'apartment_features.renewal_of_electric_installation_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'ebff0ccd-db83-4d33-8257-466c770306ec',
    name: 'new_windows',
    label: 'apartment_features.new_windows',
    description: 'apartment_features.new_windows_stove_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      {
        label: 'apartment_wizard.new_windows_number',
        type: 'number',
        name: 'new_windows_number',
        required: true,
      },
      createEquipmentTypeSelect({
        name: 'new_windows_equipment_type',
        description: 'apartment_features.new_windows_equipment_type_description',
      }),
    ],
  },
  {
    _id: 'c278b961-5444-424b-a89d-352e1ed4f75c',
    name: 'renewal_of_heating_system',
    label: 'apartment_features.renewal_of_heating_system',
    description: 'apartment_features.renewal_of_heating_system_description',
    autoselect: false,
    group: 'energy',
    costs_type: 'building',
    form: [
      createEquipmentTypeSelect({
        name: 'renewal_of_heating_system_equipment_type',
        description: 'apartment_features.renewal_of_heating_system_equipment_type_description',
      }),
    ],
  },
  {
    id: 'c19a4dc7-03c6-4f26-a31e-b9390ff26ffc',
    name: 'reserves_for_unexpected_costs',
    label: 'apartment_features.reserves_for_unexpected_costs',
    description: 'apartment_features.reserves_for_unexpected_costs_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    form: [],
  },
  {
    _id: 'c278b961-5444-424b-a89d-352e1ed4f75f',
    name: 'other_investments',
    label: 'planning_categories.other_investments',
    description: 'planning_categories.other_investments_description',
    autoselect: false,
    group: 'other',
    costs_type: 'building',
    form: [
      {
        label: 'planning.other_investments_amount',
        type: 'number',
        name: 'other_investments_amount',
        required: true,
      },
      {
        label: 'planning.other_investments_comment',
        type: 'textarea',
        name: 'other_investments_comment',
        required: true,
        minRows: 8,
      },
    ],
  },
];
