/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useLogin from 'hooks/auth/useLogin';
import useAuthFormSubmit from 'hooks/auth/useAuthFormSubmit';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useHistory } from 'react-router-dom';
import LoginForm from 'components/auth/LoginForm';
import PageTitle from 'components/common/PageTitle';
import { QuickCalculatorStep3 } from 'assets/icons/QuickCalculator';
import { useModal } from 'components/common/Modal';
import routePaths from 'router/route-paths';
import get from 'lodash/get';

const emptyFunc = () => {};

export default function Login() {
  const login = useLogin();
  const handleSubmit = useAuthFormSubmit(login);
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const { showModal, hideModal, isVisible, modalProps } = useModal();

  const onSubmitAdminLogin = useCallback(
    async ({ otp, auth, dataForTwoFA, formikActions }) => {
      try {
        await auth({ ...dataForTwoFA, otp });
        history.push(routePaths.myProjects);
      } catch (e) {
        formikActions.setErrors({ otpInvalid: e.message });
      }
    },
    [history],
  );

  const onError = useCallback(
    (error, auth, dataForTwoFA, formikActions) => {
      if (error.message === 'AUTH_OTP_REQUIRED_ERROR') {
        showModal({
          onSubmitAdminLogin: async ({ otp }) => {
            await onSubmitAdminLogin({ otp, auth, dataForTwoFA, formikActions });
          },
        });
      }
    },
    [onSubmitAdminLogin, showModal],
  );

  const onSubmit = useCallback(
    async (values, formikActions) => {
      await handleSubmit(values, formikActions, onError);
    },
    [handleSubmit, onError],
  );

  return (
    <Grid>
      <Row center="md" start="lg">
        <Col md={8}>
          <PageTitle>{t({ id: 'auth.login_page_title' })}</PageTitle>
        </Col>
      </Row>
      <Row center="md" between="lg" top="lg">
        <Col md={8} lg={6} xl={5}>
          <div className="text-left">
            <FormattedHTMLMessage id="auth.login_page_text" />
          </div>
          <LoginForm
            onSubmitAdminLogin={get(modalProps, 'onSubmitAdminLogin', emptyFunc)}
            {...{ showModal, hideModal, isVisible, signUpEnabled: false, onSubmit }}
          />
        </Col>
        <Col md={0} lg={6} xl={5} first="xs" last="lg">
          <QuickCalculatorStep3 width="100%" height="100%" title={t({ id: 'alt_text.auth_pages.login' })} />
        </Col>
      </Row>
    </Grid>
  );
}
