import { useCallback } from 'react';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import useUser from 'hooks/user/useUser';
import { throttle } from 'lodash';
import { formatGraphqlErrors } from 'utils/helpers';

export const SEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation resendVerificationEmail($email: String) {
    resendVerificationEmail(email: $email) {
      success
    }
  }
`;

const useSendVerificationEmail = () => {
  const { formatMessage: __ } = useIntl();
  const [me] = useUser();
  const email = get(me, 'emails[0].address', null);
  const [sendVerificationAccountEmail, { loading }] = useMutation(SEND_VERIFICATION_EMAIL_MUTATION, {
    onCompleted: () => {
      toast.success(__({ id: 'verify_account.email_sent' }));
    },
    onError: (e) => toast.error(get(e, 'graphQLErrors[0].message', 'Error has been occurred')),
  });

  const handleSendVerificationEmail = useCallback(
    throttle(async () => {
      try {
        await sendVerificationAccountEmail({ variables: { email } });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        toast.error(e.message);
      }
    }, 5000),
    [email, sendVerificationAccountEmail],
  );

  return [handleSendVerificationEmail, loading];
};

export default useSendVerificationEmail;
