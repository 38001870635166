/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';
import { ReactComponent as AddPhoto } from 'assets/icons/add_photo.svg';
import cn from 'classnames';
import { Close } from 'assets/icons';
import Modal, { useModal } from 'components/common/Modal';
import { useIntl } from 'react-intl';
import Loading from 'components/common/Loading';
import { ReactComponent as Download } from 'assets/icons/download-file.svg';
import Button from 'components/common/Button';
import isUndefined from 'lodash/isUndefined';
import s from './PaymentForm.module.scss';

const AddPaymentPhotoDropzone = ({ dropzone, images, loading, onRemoveImage, label }) => {
  const { getRootProps, getInputProps } = dropzone;
  const { isVisible, showModal, hideModal, modalProps } = useModal();
  const { formatMessage: t } = useIntl();
  const [fetching, setFetching] = useState(false);

  const download = useCallback(async (url, name) => {
    setFetching(true);
    const response = await fetch(url);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.style = 'display: none';
    setFetching(false);
    if (name?.length) a.download = name;
    document.body.appendChild(a);
    a.click();
  }, []);

  return (
    <div className={cn('field-wrapper', s.fieldContainer)}>
      <input {...getInputProps()} disabled={loading} />
      <label>{label}</label>
      <div {...getRootProps()} className={s.dropzone}>
        {loading ? <Loading size={30} /> : <AddPhoto />}
      </div>
      <div className={s.images}>
        {images.map((img) => (
          <div style={{ position: 'relative' }} key={img.file_name}>
            <button
              type="button"
              className={s.imgBox}
              style={{ backgroundImage: `url("${img.url}")` }}
              onClick={() => showModal(img)}
            />
            <Close
              className={s.removeBtn}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveImage(img.file_name);
              }}
            />
          </div>
        ))}
      </div>
      <Modal headerText={t({ id: 'payments.preview_image' })} close={hideModal} isVisible={isVisible} size="md">
        <div className={s.imgBox}>{modalProps.url ? <img src={modalProps.url} alt="payment" /> : null}</div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {!isUndefined(modalProps.width) ? (
            <Button
              disabled={fetching}
              loading={fetching}
              onClick={() => download(modalProps.url, 'attachment')}
              endIcon={<Download />}
            >
              {t({ id: 'payments.download' })}
            </Button>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default AddPaymentPhotoDropzone;
