import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation, useApolloClient } from '@apollo/client';
import { ME_QUERY } from './useUser';

const DELETE_MY_ACCOUNT = gql`
  mutation deleteMyAccount {
    deleteMyAccount
  }
`;

const useDeleteMyAccount = () => {
  const client = useApolloClient();
  const [deleteMyAccountMutation] = useMutation(DELETE_MY_ACCOUNT);

  const deleteMyAccount = useCallback(async () => {
    await deleteMyAccountMutation();

    client.writeQuery({ query: ME_QUERY, data: { me: null } });
    client.resetStore();
  }, [client, deleteMyAccountMutation]);

  return deleteMyAccount;
};

export default useDeleteMyAccount;
