import React, { memo, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cn from 'classnames';
import { areEqualFields } from 'utils/form';
import { isNil } from 'lodash';
import styles from './TextArea.module.scss';

const TextArea = ({
  field,
  form: { touched, errors, setFieldValue },
  className,
  labelClassName,
  description,
  minRows = 4,
  ...props
}) => {
  const { name, value } = field;
  const { label, style } = props;

  useEffect(() => {
    if (isNil(value)) setFieldValue(name, '');
  }, [name, setFieldValue, value]);

  return (
    <div className={cn('field-wrapper', className)} style={style}>
      <TextareaAutosize {...field} className={styles.textArea} resize="none" minRows={minRows} />
      <label className={cn(styles.textAreaLabel, labelClassName)} htmlFor={field.name}>
        {label}
      </label>
      {description ? (
        <div>
          <small className="muted">{description}</small>
        </div>
      ) : null}
      {errors[field.name] && touched[field.name] && <div className="error-text">{errors[field.name]}</div>}
    </div>
  );
};

export default memo(TextArea, areEqualFields);
