/* eslint-disable react/no-children-prop */
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';
import get from 'lodash/get';
import NotFound from 'pages/NotFound';
import ActivateAccount from 'pages/ActivateAccount';
import VerifyEmail from 'pages/VerifyEmail';
import useUser from 'hooks/user/useUser';
import Plan from 'pages/Plan/Plan';
import PlanForm from 'pages/PlanForm';
import Loan from 'pages/Loan';
import LoanCalculator from 'pages/LoanCalculator';
import LifestyleCalculatorEdit from 'pages/LifestyleCalculator/LifestyleEditMonthlyExpenses';
import AppLayout from 'components/layouts/AppLayout';
import QuickCalculator from 'pages/QuickCalculator';
import Dashboard from 'pages/Dashboard';
import MyProjects from 'pages/MyProjects';
import LifestyleCalculator from 'pages/LifestyleCalculator/LifestyleCalculator';
import ProjectDetailsCalculator from 'pages/ProjectDetailsCalculator';
import Contact from 'pages/Contact';
import Logout from 'pages/Logout';
import ScrollToTop from 'components/common/ScrollToTop';
import Payments from 'pages/Payments';
import Spendings from 'pages/Spendings';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import SignUp from 'pages/SignUp/SignUp';
import useLastProjectId from 'hooks/project/useLastProjectId';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Login from 'pages/Login';
import DebugMode from 'pages/DebugMode';
import { useAppContext } from 'contexts/AppContext';
import useGetProjectTypes from 'hooks/project/useGetProjectTypes';
import BudgetWithProject from 'pages/LifestyleCalculator/LifestyleCalculatorWithProject';
import {
  BUYING_HOUSE,
  DEVELOPER,
  GUEST,
  NEW_APARTMENT,
  NEW_BUILDING,
  PROPERTY_VALUATION,
  RENOVATION_HOUSE,
} from 'constants/variables';
import { Beforeunload } from 'react-beforeunload';
import settings from 'config/settings';
import LifestyleOverviewPage from 'pages/LifestyleCalculator/LifestyleOverview';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import routePaths, { routePatterns } from './route-paths';

const DEFAULT_THEME = 'primary';

const routes = [
  {
    path: routePaths.projectDetailsCalculator,
    component: ProjectDetailsCalculator,
  },
  {
    path: routePaths.myProjects,
    component: MyProjects,
  },
  {
    path: routePaths.dashboard,
    component: Dashboard,
  },
  {
    path: routePaths.lifestyleOverview,
    component: LifestyleOverviewPage,
  },
  {
    path: routePaths.lifestyleOverviewWithProject,
    component: BudgetWithProject,
  },
  {
    path: routePaths.lifestyleCalculator,
    component: LifestyleCalculator,
  },
  {
    path: routePaths.lifestyleCalculatorEditValues,
    component: LifestyleCalculatorEdit,
  },
  {
    path: routePaths.contact,
    component: Contact,
  },
  {
    path: routePaths.logout,
    component: Logout,
  },
  {
    path: routePaths.plan,
    component: Plan,
  },
  {
    path: routePaths.planForm,
    component: PlanForm,
  },
  {
    path: routePaths.loan,
    component: Loan,
  },
  {
    path: routePaths.loanCalculator,
    component: LoanCalculator,
  },
  {
    path: routePaths.newProject,
    component: QuickCalculator,
  },
  {
    path: routePaths.payments,
    component: Payments,
  },
  {
    path: routePaths.spendings,
    component: Spendings,
  },
  {
    path: routePaths.guestSignUp,
    component: SignUp,
  },
  {
    path: routePaths.guestLogin,
    component: Login,
  },
  {
    path: routePaths.guestForgotPassword,
    component: ForgotPassword,
  },
  {
    path: routePaths.guestResetPassword,
    component: ResetPassword,
  },
  {
    path: routePaths.debugMode,
    component: DebugMode,
    requiredRole: DEVELOPER,
  },
];

const projectTypeToThemeMap = {
  [NEW_BUILDING]: DEFAULT_THEME,
  [NEW_APARTMENT]: DEFAULT_THEME,
  [BUYING_HOUSE]: DEFAULT_THEME,
  [PROPERTY_VALUATION]: DEFAULT_THEME,
  [RENOVATION_HOUSE]: 'green',
};

const Theme = ({ lastProjectId }) => {
  const { theme, changeTheme } = useAppContext();
  const { type, loading } = useGetProjectTypes(lastProjectId);

  useEffect(() => {
    if (type) {
      const projectTheme = projectTypeToThemeMap[type] || DEFAULT_THEME;
      if (projectTheme && theme !== projectTheme) changeTheme(projectTheme);
    }
  }, [changeTheme, theme, type]);

  if (loading) return <LoadingOverlay />;
  return null;
};

const Routes = ({ me, lastProjectId }) => {
  const roles = get(me, 'roles', []);

  return (
    <>
      {lastProjectId ? <Theme {...{ lastProjectId }} /> : null}
      {routes.map(({ path, component, requiredRole, ...rest }) => {
        if (requiredRole && !roles.includes(requiredRole)) return null;
        return <Route key={path} path={path} component={component} exact {...rest} />;
      })}
    </>
  );
};

const clearSession = () => {
  if (settings.isDevelopment) return;
  fetch(settings.graphqlServerUrl, {
    headers: {
      'cache-control': 'no-cache',
      'content-type': 'application/json',
    },
    body: '{"operationName":"logout","variables":{},"query":"mutation logout { logout { success } } "}',
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    keepalive: true, // fetch request in background (after page is closed)
  });
};

const AppRouter = () => {
  const [me] = useUser();
  const { lastProjectId, loading } = useLastProjectId();
  const emailVerified = get(me, 'emails[0].verified');
  const isGuest = useHasRequiredRole(GUEST);
  const projectId = lastProjectId || '';

  const searchParams = new URLSearchParams(window.location.search);
  const projectTypeFromUrl = searchParams.get('type');

  const redirectTo = projectId
    ? routePatterns.projectDetailsCalculator.stringify({ id: projectId })
    : routePaths.lifestyleOverview;

  useEffect(() => {
    if (isGuest) {
      window.addEventListener('unload', clearSession);
    } else {
      window.removeEventListener('unload', clearSession);
    }
    return () => {
      window.removeEventListener('unload', clearSession);
    };
  }, [isGuest]);

  if (projectTypeFromUrl) {
    return <Redirect to={{ pathname: routePaths.newProject, state: { projectType: projectTypeFromUrl } }} />;
  }

  if (loading) return <LoadingOverlay />;

  return (
    <Switch>
      {!isGuest && !emailVerified && (
        <Route path={routePaths.activateAccount} exact>
          <ActivateAccount />
        </Route>
      )}
      <Route path={routePaths.verifyEmail} exact>
        <VerifyEmail />
      </Route>

      {!isGuest && !emailVerified && <Redirect to={routePaths.activateAccount} />}

      <Route path="/secure/:path?">
        <AppLayout>
          <ScrollToTop />
          {!settings.isDevelopment && isGuest ? <Beforeunload onBeforeunload={(e) => e.preventDefault()} /> : null}
          <Switch>
            <Routes {...{ me, lastProjectId }} />
            <Route path="*" component={NotFound} />
          </Switch>
        </AppLayout>
      </Route>

      <Redirect to={redirectTo} />
    </Switch>
  );
};

export default AppRouter;
