/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import get from 'lodash/get';
import { backendErrors as enBackendErrors } from 'i18n/en';
import { backendErrors as deBackendErrors } from 'i18n/de';
import { print } from 'graphql';
import { formatQueryByErrorPositions } from 'utils/helpers';
import resolvers from './resolvers';
import settings from '../config/settings';
import introspectionQueryResultData from './fragment-types.json';

function introspectionToPossibleTypes(introspectionQueryData) {
  const possibleTypes = {};

  introspectionQueryData.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
    }
  });

  return possibleTypes;
}

const link = ApolloLink.from([
  setContext((_, { headers }) => {
    return { headers };
  }),
  onError(({ graphQLErrors, networkError, response, operation }) => {
    const code = response?.errors[0]?.extensions?.code;
    if (code === 'FORBIDDEN' || code === 'UNAUTHENTICATED') {
      window.location.replace(`${window.location.origin}/app/secure/logout`);
    }
    const logError = (error, { errorType }) => {
      const { message, locations, path, extensions, stack } = error;
      if (extensions?.exception) extensions.exception.logged = true;
      const lExtensions = JSON.stringify({
        ...extensions,
        ...(extensions?.exception && {
          exception: { ...extensions.exception, ...(extensions.exception?.stacktrace && { stacktrace: '(below)' }) },
        }),
      });
      const lStacktrace =
        get(extensions, 'exception.stacktrace', []).reduce(
          (p, c, index) => (p || '').concat(index !== 0 ? '\n' : '', c),
          '',
        ) ||
        stack ||
        ''; // array to string
      const lVariables = JSON.stringify(operation.variables);
      const lQuery = formatQueryByErrorPositions({ queryString: print(operation.query), errorPositions: locations });
      const lPath = JSON.stringify(path);
      const lLocations = JSON.stringify(locations);
      const lMessage = JSON.stringify(message);
      console.error(
        [
          `[${errorType}]:`,
          lMessage && `Message: ${lMessage}`,
          lLocations && `Location: ${lLocations}`,
          lPath && `Path: ${lPath}`,
          lQuery && `Query:\n${lQuery}`,
          lVariables && `Variables: ${lVariables}`,
          lExtensions && `Extensions: ${lExtensions}`,
          lStacktrace && `Stacktrace: ${lStacktrace}`,
        ]
          .filter(Boolean)
          .join('\n'),
        extensions,
      );
    };
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const doNotLogOnClient = get(error, 'extensions.exception.doNotLogOnClient');
        if (!doNotLogOnClient) logError(error, { errorType: 'GraphQL error' });
      });
    }

    if (!graphQLErrors && networkError) logError(networkError, { errorType: 'Network error' });
  }),
  onError(({ graphQLErrors }) => {
    const locale = window?.localStorage?.getItem('language') || settings.defaultLocale;
    const backendErrorsTranslations = { de: deBackendErrors, en: enBackendErrors };
    if (graphQLErrors) {
      const translateMessage = (error) => {
        const messageKey = `${locale}.${error.message}`;
        const translated = get(backendErrorsTranslations, messageKey, error.message);
        if (translated !== messageKey) {
          error.originalMessage = error.message;
          error.message = translated;
        }
      };
      graphQLErrors.forEach(translateMessage);
    }
  }),
  new HttpLink({
    uri: settings.graphqlServerUrl,
    credentials: 'include',
  }),
]);

const cacheIndexBlacklist = [];

// eslint-disable-next-line no-unused-vars
const merge = (existing = [], incoming) => incoming;

const client = new ApolloClient({
  link,
  resolvers,
  cache: new InMemoryCache({
    possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData),
    freezeResults: true,
    dataIdFromObject: ({ _id, __typename }) => {
      if (cacheIndexBlacklist.includes(__typename)) return null;
      return _id ? `${__typename}___${_id}` : null;
    },
    typePolicies: {
      Project: {
        fields: {
          calculations: { merge },
          progress: { merge },
          form_values: { merge },
          loan: { merge },
        },
      },
      ProjectPlan: {
        fields: {
          pdf: { merge },
          price: { merge },
          projectPlan: { merge },
          planningCategories: { merge },
          subitems: { merge },
        },
      },
      BudgetEstimation: {
        fields: {
          calculation: { merge },
        },
      },
      ProjectPayment: {
        fields: {
          category: { merge },
        },
      },
    },
  }),
  assumeImmutableResults: true,
});

export default client;
