import React, { useCallback } from 'react';
import ContactForm from 'components/ContactForm/ContactForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import useContactSupport from 'hooks/useContactSupport';
import PageTitle from 'components/common/PageTitle';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Support } from 'assets/icons';

export const useContactHandler = (hideModal = () => {}) => {
  const { formatMessage: t } = useIntl();
  const contactSupport = useContactSupport();

  const handleSubmit = useCallback(
    async (variables, { resetForm }) => {
      try {
        await contactSupport(variables);
        hideModal();
        resetForm();
        toast.success(t({ id: 'contact.successfully_sent' }));
      } catch (error) {
        toast.error(error.message);
      }
    },
    [contactSupport, t, hideModal],
  );

  return handleSubmit;
};

export default function Contact() {
  const onSubmit = useContactHandler();
  const { formatMessage: t } = useIntl();

  return (
    <Grid style={{ marginTop: '20px' }}>
      <Row className="mb-4" between="lg">
        <Col lg={6}>
          <PageTitle>
            <FormattedMessage id="page_titles.contact" />
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <ContactForm onSubmit={onSubmit} />
        </Col>
        <Col md={0} lg={6} xl={5} first="xs" last="lg">
          <Support width="100%" height="100%" title={t({ id: 'alt_text.contact_us_img' })} />
        </Col>
      </Row>
    </Grid>
  );
}
