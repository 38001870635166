import React, { useMemo } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { formatCurrency } from 'utils/helpers';
import isNil from 'lodash/isNil';
import { ArrowBack, ArrowForward } from 'assets/icons';
import FormattedHTMLMessage from 'components/common/FormattedHTMLMessage';
import { Header, SectionWrapper } from 'components/common/heap';
import { useIntl } from 'react-intl';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { routePatterns } from 'router/route-paths';
import { Link } from 'react-router-dom';
import { RENOVATION_HOUSE } from 'constants/variables';
import CO2StatusProjectPlanSection from 'components/calculators/CO2CalculatorResult/CO2StatusProjectPlanSection';
import cn from 'classnames';
import useBreakpoint from 'use-breakpoint';
import { Hidden, screenSizeConfig, Visible } from 'components/common/Grid';
import s from './ProjectPlanTables.module.scss';
import PlanningCategoriesChart from './PlanningCategoriesChart';

const orderOfCategories = ['main', 'cost_drivers', 'additional_costs', 'options'];

const ProjectPlanTables = ({ project, projectPlan, goToStep }) => {
  const { formatMessage: t } = useIntl();
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'lg');

  const orderedCategories = useMemo(
    () =>
      orderOfCategories.reduce((res, groupName) => {
        res.push(...projectPlan.planningCategories.filter(({ group }) => group === groupName));
        return res;
      }, []),
    [projectPlan.planningCategories],
  );

  return (
    <Row>
      <Col xs={12}>
        <Row start="xs">
          <Col xl={6}>
            <Header className={cn(s.totalCosts, project.type === RENOVATION_HOUSE ? s.greenBackground : '')}>
              <h2>
                {t({ id: 'project_plan.total_costs' })}
                <span className="float-right">{formatCurrency(projectPlan.price)}</span>
              </h2>
            </Header>

            {project.personal_contribution_value ? (
              <div className={s.personalContribution}>
                <p>{t({ id: 'project_plan.personal_contribution' })}</p>
                <p>{formatCurrency(project.personal_contribution_value)}</p>
              </div>
            ) : null}

            <Hidden xl xxl>
              <div className={s.chartContainer}>
                <PlanningCategoriesChart
                  {...{ project, plan: projectPlan }}
                  chartHeight={220}
                  legendLayout={breakpoint === 'lg' ? 'vertical' : 'horizontal'}
                />
              </div>
            </Hidden>

            <div className={s.tablesWrapper}>
              {orderedCategories.map((category) => {
                return category.calculated_price ? (
                  <SectionWrapper key={category.name}>
                    <table className="table last-col-text-right" style={{ wordBreak: 'break-word' }}>
                      <thead>
                        <tr>
                          <td>
                            <h4>{t({ id: category.label })}</h4>
                          </td>
                          <td>
                            <h4 className="text-primary">
                              {formatCurrency(
                                !isNil(category.user_price) ? category.user_price : category.calculated_price,
                              )}
                            </h4>
                          </td>
                        </tr>
                      </thead>
                      {category.subitems.length > 1 ? (
                        <tbody>
                          {category.subitems.map((item) => (
                            <tr key={item.name}>
                              <td className={s.td}>{t({ id: item.label })}</td>
                              <td>
                                {formatCurrency(!isNil(item.user_price) ? item.user_price : item.calculated_price)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : null}
                    </table>
                  </SectionWrapper>
                ) : null;
              })}
            </div>

            <div className={s.buttonsWrapper}>
              {typeof goToStep === 'function' ? (
                <Button color="outline" onClick={() => goToStep(1)} startIcon={<ArrowBack />}>
                  {t({ id: 'project_wizard.edit_values_btn' })}
                </Button>
              ) : null}
              <Link to={routePatterns.lifestyleOverviewWithProject.stringify({ id: project._id })}>
                <Button endIcon={<ArrowForward />}>
                  <FormattedHTMLMessage id="project_plan.btn_label_to_budget" />
                </Button>
              </Link>
            </div>
          </Col>

          <Visible xl xxl>
            <Col xl={6} first="xs" last="xl">
              <div className={s.chartContainer}>
                <PlanningCategoriesChart {...{ project, plan: projectPlan, chartHeight: 250 }} />
              </div>
            </Col>
          </Visible>
        </Row>

        {project.type === RENOVATION_HOUSE ? <CO2StatusProjectPlanSection project={project} /> : null}

        <Row>
          <Col xs={12}>
            <Alert className={s.disclaimer}>
              <FormattedHTMLMessage id="disclaimers.registration" />
            </Alert>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectPlanTables;
