import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { formatCurrency } from '../../utils/helpers';
import s from './MonthlyNetIncomeInfo.module.scss';

const MonthlyNetIncomeInfo = ({ budget }) => {
  const { formatMessage: t } = useIntl();

  return (
    <table className={cn('table last-col-text-right', s.monthlyIncomeTable)}>
      <thead>
        <tr>
          <td colSpan="2">
            <h4>{t({ id: 'budget.titles.monthly_net_income' })}</h4>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t({ id: 'budget.monthly_net_salary' })}</td>
          <td>{formatCurrency(budget.formData.monthly_net_salary)}</td>
        </tr>
        {budget.formData.alone_or_partner === 'partner' ? (
          <tr>
            <td>{t({ id: 'budget.monthly_net_salary_partner' })}</td>
            <td>{formatCurrency(budget.formData.monthly_net_salary_partner)}</td>
          </tr>
        ) : null}
      </tbody>
      {/* <tbody>
        <tr>
          <td>{t({ id: 'budget.monthly_net_income' })}</td>
          <td>{formatCurrency(budget.formData.monthly_income)}</td>
        </tr>
        {budget.formData.alone_or_partner === 'partner' ? (
          <tr>
            <td>{t({ id: 'budget.monthly_net_income_partner' })}</td>
            <td>{formatCurrency(budget.formData.monthly_income_partner)}</td>
          </tr>
        ) : null}
      </tbody> */}
      <tbody>
        {budget.formData.other_revenues ? (
          <tr>
            <td>{t({ id: 'budget.other_regular_income' })}</td>
            <td>{formatCurrency(budget.formData.other_revenues)}</td>
          </tr>
        ) : null}
      </tbody>
      <tfoot>
        <tr>
          <td>{t({ id: 'budget.titles.total_monthly_income' })}</td>
          <td>{formatCurrency(budget.calculation.total_monthly_income.calculated_value)}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default MonthlyNetIncomeInfo;
