import React, { useCallback } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Formik, Form } from 'formik';
import ScrollToError from 'components/common/ScrollToError';
import { useIntl } from 'react-intl';
import Button from 'components/common/Button';
import { ArrowForward } from 'assets/icons';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as Img } from 'assets/images/renovation-house/step-1.svg';
import { useParams } from 'react-router-dom';
import { useUpdateCO2CalculatorValuesHandler } from 'hooks/co2-calculator/useUpdateCO2CalculatorValues';
import ScrollToTop from 'components/common/ScrollToTop';
import validationSchema from '../../yupSchemas/renovationHouseFirstStepSchema';
import { FirstSection, SecondSection, FourthSection, ThirdSection } from './sections';
import s from '../../../calculators.module.scss';

const CO2CalculatorFormFirstStep = ({
  nextStep,
  initialValues,
  onSubmitStep,
  formValuesRef,
  isEditMode,
  inModal,
  currentStep,
  goToStep,
}) => {
  const { formatMessage: t } = useIntl();
  const { id: projectId } = useParams();
  const onSubmit = useUpdateCO2CalculatorValuesHandler({ projectId, t });

  const goToThirdStep = useCallback(() => {
    goToStep(3);
  }, [goToStep]);

  const onSolarPowerSystemSizeChange = useCallback((newSolarPowerSystemSize, values, setFieldValue) => {
    const { heating_system } = values;
    if (!heating_system) return;
    const temp = heating_system === 'heat_pump' || heating_system === 'electric' ? 300 : 150;
    const percentage = Math.round(temp / newSolarPowerSystemSize);
    setFieldValue('solar_power_system_consuming_percentage', percentage);
  }, []);

  return (
    <Row between="lg" center="md">
      <ScrollToTop />
      <Col md={!inModal ? 8 : 12} lg={!inModal ? 6 : 12}>
        <Formik
          initialValues={formValuesRef?.current || initialValues}
          onSubmit={async (values, formikActions) => {
            const step = isEditMode ? null : currentStep + 1;
            const newValues = { ...values, step };
            if (typeof onSubmitStep === 'function') onSubmitStep(values);
            await onSubmit(newValues, formikActions);
            if (typeof nextStep === 'function') nextStep();
          }}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <ScrollToError />
              <FirstSection {...{ values, initialValues, t, isEditMode, goToThirdStep }} />
              <SecondSection {...{ values, t }} />
              <ThirdSection t={t} />
              <FourthSection {...{ onSolarPowerSystemSizeChange, t, values }} />

              <div className={s.btnWrapper}>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  type="submit"
                  endIcon={isEditMode ? <SaveIcon /> : <ArrowForward />}
                  className="ml-auto"
                >
                  {t({ id: `app.${isEditMode ? 'save' : 'submit_form_next'}` })}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      {!inModal ? (
        <Col md={8} lg={6} xl={5} first="xs" last="lg">
          <div className={s.imgBox}>
            <Img title={t({ id: 'alt_text.project_details_page.renovation_house.first_step' })} />
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

export default CO2CalculatorFormFirstStep;
