import React, { useCallback } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useAppContext } from 'contexts/AppContext';
import s from './Nav.module.scss';

const Nav = ({ currentStep, tabs, goToStep, isEditMode }) => {
  const { formatMessage: t } = useIntl();
  const { theme } = useAppContext();

  const getClassNamesForTab = useCallback(
    (tabIndex) => {
      let classnames = 'tab';
      if (currentStep === tabIndex + 1) classnames += ' activeTab';
      if (currentStep > tabIndex + 1 && !isEditMode) classnames += ' completedTab';
      if (isEditMode) classnames += ' clickableTab completedTab';
      return classnames;
    },
    [currentStep, isEditMode],
  );

  const onTabClick = useCallback(
    (tabIndex) => {
      if (!isEditMode) return;
      goToStep(tabIndex);
    },
    [goToStep, isEditMode],
  );

  return (
    <div className={cn('tabs', s.tabs, theme === 'green' ? 'green-tabs' : '')}>
      {tabs.map(({ label }, i) => {
        const classnames = getClassNamesForTab(i);
        return (
          <button onClick={() => onTabClick(i + 1)} type="button" key={label} className={classnames}>
            <div className="circle">
              <span className="step">{i + 1}</span>
            </div>
            <div className="title">{t({ id: label })}</div>
          </button>
        );
      })}
    </div>
  );
};

export default Nav;
