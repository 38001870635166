import React from 'react';
import { useIntl } from 'react-intl';

const CategoryCell = ({ label, avg_equipment_type, has_mixed_equipment_type, description }) => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <h4>{t({ id: label })}</h4>
      <small>
        {avg_equipment_type
          ? t(
              { id: 'spendings.materials_quality', defaultMessage: ' ' },
              { equipment_type: t({ id: `equipment_types.${avg_equipment_type}`, defaultMessage: ' ' }) },
            )
          : null}{' '}
        {avg_equipment_type && has_mixed_equipment_type
          ? `(${t({ id: 'equipment_types.mixed', defaultMessage: ' ' })})`
          : null}
      </small>
      {description ? <small>{t({ id: description, defaultMessage: ' ' })}</small> : null}
    </>
  );
};

export default CategoryCell;
