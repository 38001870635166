import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from 'graphql/fragments/project';
import { useCallback } from 'react';
import { formatGraphqlErrors } from 'utils/helpers';

const UPDATE_PROJECT_PROPERTY_VALUATION = gql`
  mutation updateProjectPropertyValuation($projectId: ID!, $formData: PropertyValuationFormValuesInput!) {
    updateProjectPropertyValuation(projectId: $projectId, formData: $formData) {
      ...project
    }
  }
  ${PROJECT_FRAGMENT}
`;

const useUpdateProjectPropertyValuation = () => {
  const [updatePropertyValuation] = useMutation(UPDATE_PROJECT_PROPERTY_VALUATION);

  const mutate = useCallback(
    async (variables) => {
      try {
        await updatePropertyValuation({ variables });
      } catch (error) {
        const e = formatGraphqlErrors(error);
        throw e;
      }
    },
    [updatePropertyValuation],
  );
  return mutate;
};

export default useUpdateProjectPropertyValuation;
