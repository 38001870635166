import { GUEST } from 'constants/variables';
import useHasRequiredRole from 'hooks/auth/useHasRequiredRole';
import useDeleteMyAccount from 'hooks/user/useDeleteMyAccount';
import { useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import routePaths from 'router/route-paths';

export const useHandleDeleteMyAccount = () => {
  const deleteMyAccount = useDeleteMyAccount();
  const history = useHistory();
  const { formatMessage: __ } = useIntl();

  const handleSubmit = useCallback(async () => {
    confirmAlert({
      title: __({ id: 'profile.delete_my_account.title' }),
      message: __({ id: 'profile.delete_my_account.message' }),
      buttons: [
        { label: __({ id: 'app.cancel' }) },
        {
          label: __({ id: 'app.confirm' }),
          onClick: async () => {
            await deleteMyAccount();
            history.push(routePaths.quickCalculator);
          },
        },
      ],
    });
  }, [__, deleteMyAccount, history]);

  return handleSubmit;
};

export const useLogoutHandler = () => {
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const isGuest = useHasRequiredRole(GUEST);

  const logout = useCallback(() => history.push(routePaths.logout), [history]);

  const handleLogout = useCallback(() => {
    confirmAlert({
      title: t({ id: 'profile.logout.title' }),
      message: t({ id: 'profile.logout.message' }),
      buttons: [{ label: t({ id: 'app.cancel' }) }, { label: t({ id: 'app.confirm' }), onClick: logout }],
    });
  }, [t, logout]);

  return isGuest ? handleLogout : logout;
};
